import React from 'react';
import PropTypes from 'prop-types';

import DashboardButton from "./ToolbarButtons/DashboardButton";
import CustomerButton from "./ToolbarButtons/CustomerButton";
import LoanerButton from "./ToolbarButtons/LoanerButton";
import ReportButton from "./ToolbarButtons/ReportButton";

const SalesButtons = ({ setPage, mainPage }) => {
  return (
    <React.Fragment>
      <DashboardButton setPage={setPage} currentPage={mainPage} />
      <CustomerButton setPage={setPage} currentPage={mainPage} />
      {false && <LoanerButton setPage={setPage} currentPage={mainPage} />}
      <ReportButton setPage={setPage} currentPage={mainPage} />
    </React.Fragment>
  )
}

SalesButtons.propTypes = {
  setPage: PropTypes.func,
  mainPage: PropTypes.string
}

export default SalesButtons;
