import React from 'react';
import PropTypes from 'prop-types';

import { useIntl, defineMessages } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { utcTimeToLocal } from '../../helpers';

const messages = defineMessages({
  day0: {
    id: "Sunday",
    defaultMessage: "Sunday"
  },
  day1: {
    id: "Monday",
    defaultMessage: "Monday"
  },
  day2: {
    id: "Tuesday",
    defaultMessage: "Tuesday"
  },
  day3: {
    id: "Wednesday",
    defaultMessage: "Wednesday"
  },
  day4: {
    id: "Thursday",
    defaultMessage: "Thursday"
  },
  day5: {
    id: "Friday",
    defaultMessage: "Friday"
  },
  day6: {
    id: "Saturday",
    defaultMessage: "Saturday"
  }
});

const useStyles = makeStyles(() => ({
  column: {
    width: '12.5%'
  }
}));

const HeaderRow = ({ startDate }) => {
  const cells = [];
  const classes = useStyles();
  const intl = useIntl();

  for (let i = 0; i < 7; i++) {
    const date = new Date(startDate * 1000 + (i * 24 * 3600 * 1000));
    const day = date.getUTCDay();
    const localDate = utcTimeToLocal(date);

    const messageID = "day" + day;
    cells.splice(i, 0,
      (
        <th
          key={i}
          className={classes.column}
        >
          <div>
            <Typography>
              {intl.formatMessage(messages[messageID])}
            </Typography>
            <Typography>
              {intl.formatMessage({
                id: "{date, date, medium}",
                defaultMessage: "{date, date, medium}"
              }, { date: localDate })}
            </Typography>
          </div>
        </th>
      )
    );
  }

  return (
    <tr>
      <th className={classes.column}>
        <Typography>
          {intl.formatMessage({
            id: "Vehicle",
            defaultMessage: "Vehicle"
          })}
        </Typography>
      </th>
      {cells}
    </tr>
  );
}

HeaderRow.propTypes = {
  startDate: PropTypes.number.isRequired
}

export default HeaderRow;
