import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

const CustomerListDialog = (WrappedComponent) => {
  const OuterComponent = ({ onClose, ...others }) => {
    return (
      <Dialog fullWidth={true} maxWidth="lg" {...others}>
        <DialogTitle>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <WrappedComponent {...others} />
        </DialogContent>
      </Dialog>
    )
  }

  OuterComponent.propTypes = {
    onClose: PropTypes.func
  }

  return OuterComponent;
}

export default CustomerListDialog;
