import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25)
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ChipMultiSelect = ({ id, label, name, value, onChange, choices, ...others }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root} {...others}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Select
          multiple
          value={value}
          onChange={onChange}
          input={<Input id={id} />}
          renderValue={values => (
            <div className={classes.chips}>
              {
                values.map(value => {
                  const index = choices.findIndex(choice => {
                    return choice.value === value;
                  });
                  const label = choices[index].label;

                  return (
                    <Chip key={value} label={label} className={classes.chip} />
                  )
                })
              }
            </div>
          )}
          MenuProps={MenuProps}
          inputProps={{
            name: name
          }}
        >
          {choices.map(choice => (
            <MenuItem
              key={choice.value}
              value={choice.value}
              style={{
                fontWeight:
                  value.indexOf(choice.value) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
              }}
            >
              {choice.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

ChipMultiSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  choices: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.array,
  name: PropTypes.string
};

ChipMultiSelect.defaultProps = {
  id: null,
  label: '',
  values: [],
  onChange: null,
  selection: []
};

export default ChipMultiSelect;
