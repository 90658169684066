import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ColorListItem from './ColorListItem';

const useStyles = makeStyles(theme => ({
    list: {
        margin: theme.spacing(1),
        listStyleType: 'none',
        paddingInlineStart: 0
    }
}));

const ColorList = ({ colors, onSelect, selectedItem, ...others }) => {
    const classes = useStyles();

    const items = colors.map(c => {
        return (
            <ColorListItem
                key={c.id}
                color={c}
                onSelect={() => { onSelect(c); }}
                selected={c.id === selectedItem}
            />
        );
    })

    return (
        <ul className={classes.list} {...others}>
            {items}
        </ul>
    )
}

ColorList.propTypes = {
    colors: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    selectedItem: PropTypes.string
}

export default ColorList;