import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';

import { Map } from 'immutable';

import Button from '@material-ui/core/Button';

import TemplateSelect from './TemplateSelect';
import { getTemplate, saveTemplate } from '../../../backend';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles({
  select: {
    minWidth: '280px'
  },
  editor: {
    marginTop: '16px',
    paddingBottom: '48px',
    width: '100%',
    height: '400px'
  }
});

const Templates = () => {
  const intl = useIntl();
  const classes = useStyles();
  const [templateID, setTemplateID] = useState('');
  const [template, setTemplate] = useState(null);

  const handleTemplateChange = async (value) => {
    try {
      const template = await getTemplate(value);

      if (template === null) {
        const newTemplate = {
          id: null,
          templateID: value,
          description: '',
          template: ''
        };
        setTemplate(Map(newTemplate));
        setTemplateID(value);
      } else {
        setTemplate(Map(template));
        setTemplateID(value);
      }
    }
    catch (error) {
      const newTemplate = {
        id: null,
        templateID: value,
        description: '',
        template: ''
      };
      setTemplate(Map(newTemplate));
      setTemplateID(value);
    }
  }

  const handleDocumentChange = (value) => {
    setTemplate(template.set('template', value));
  }

  const handleSave = async () => {
    try {
      const templateToSave = template.set('templateID', templateID);
      await saveTemplate(templateToSave.toJS());
    }
    catch (error) {
      console.log("Error saving template");
    }
  }

  return (
    <div>
      <TemplateSelect
        className={classes.select}
        value={templateID}
        onChange={(event) => handleTemplateChange(event.target.value)}
      />
      {templateID !== '' ?
        (<React.Fragment>
          <ReactQuill
            className={classes.editor}
            value={template.get('template')}
            onChange={(value) => handleDocumentChange(value)}
          />
          <Button
            onClick={() => handleSave()}
          >
            {intl.formatMessage({
              id: 'Save',
              defaultMessage: "Save"
            })}
          </Button>
        </React.Fragment>) : null}
    </div>
  )
}

export default Templates;
