import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Departments } from '../Models/User';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%'
  }
}));

const DepartmentSelect = ({ fullWidth, selection, onChange, selectName, ...others }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <FormControl className={fullWidth ? classes.fullWidth : null} margin="normal" {...others}>
      <InputLabel htmlFor={selectName}>
        {intl.formatMessage({
          id: "Default Department",
          defaultMessage: "Default Department"
        })}
      </InputLabel>
      <Select
        value={selection}
        inputProps={{
          name: selectName
        }}
        onChange={onChange}>
        <MenuItem
          value={Departments.Sales}
        >
          {intl.formatMessage({
            id: "Sales",
            defaultMessage: "Sales"
          })}
        </MenuItem>
        <MenuItem
          value={Departments.Service}
        >
          {intl.formatMessage({
            id: "Service",
            defaultMessage: "Service"
          })}
        </MenuItem>
      </Select>
    </FormControl>
  );
}

DepartmentSelect.propTypes = {
  selectName: PropTypes.string,
  selection: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool
};

export default DepartmentSelect;

