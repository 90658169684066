import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import LeftIcon from '@material-ui/icons/ArrowLeft'
import RightIcon from '@material-ui/icons/ArrowRight';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => ({
    mainDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));


const StockYearSelector = ({ year, onChange, ...others }) => {
    const classes = useStyles();

    return (
        <div className={classes.mainDiv} {...others}>
            <IconButton onClick={() => { onChange && onChange(year - 1) }}>
                <LeftIcon />
            </IconButton>
            {year}
            <IconButton onClick={() => { onChange && onChange(year + 1) }}>
                <RightIcon />
            </IconButton>
        </div>
    )
}

StockYearSelector.propTypes = {
    year: PropTypes.number,
    onChange: PropTypes.func
}

export default StockYearSelector;