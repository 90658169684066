import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import { TireIcon } from '../../Icons';
import { PageId } from "../../DataStore/MainPage/Types";

import { getMainPage, setMainPage } from '../../DataStore';

const LoanerButton = ({ ...others }) => {
  const currentPage = useSelector(getMainPage);

  const dispatch = useDispatch();
  const setPage = () => (dispatch(setMainPage(PageId.Storage)));

  return (
    <IconButton
      color="inherit"
      aria-label="Loaners"
      disabled={currentPage === PageId.Storage}
      onClick={setPage}
      {...others}
    >
      <TireIcon />
    </IconButton>
  );
}

export default LoanerButton;
