import React from 'react';
import PropTypes from 'prop-types';
import { Items } from './AccountingMenu';
import NewStockFileController from '../NewStock/NewStockFileController';
import ModelController from '../Models/ModelController';
import ColorController from '../Colors/ColorController';
import NewStocksController from '../NewStock/NewStocksController';
import YearController from '../Years/YearController';

const MainSection = ({ selection }) => {
    return (
        <React.Fragment>
            {selection === Items.ImportStocks && (
                <NewStockFileController />
            )}
            {selection === Items.ManageModels && (
                <ModelController />
            )}
            {selection === Items.ManageColors && (
                <ColorController />
            )}
            {selection === Items.ManageStocks && (
                <NewStocksController />
            )}
            {selection === Items.ManageYears && (
                <YearController />
            )}
        </React.Fragment>
    );
}

MainSection.propTypes = {
    selection: PropTypes.string
}

export default MainSection;
