export const FETCH_USERS_REQUEST = "@@USERS.FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "@@USERS.FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "@@USERS.FETCH_USERS_FAILURE";

export const ADD_USER = "@@USERS.ADD_USER";
export const MODIFY_USER = "@@USERS.MODIFY_USER";

export const SET_PASSWORD_REQUEST = '@@USERS.SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = '@@USERS.SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = '@@USERS.SET_PASSWORD_FAILURE';
