import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SoldIcon = (props, ref) => {
  return (
    <SvgIcon ref={ref} {...props}>
      <g transform="matrix(0.046875,0,0,0.046875,0,0)">
        <path d="M213.931,239.454C211.977,236.092 209.46,233.08 206.379,230.451C203.503,228.028 200.38,225.937 197.052,224.23L188.587,219.946C186.061,218.683 183.911,217.548 182.136,216.558C180.839,215.833 179.815,215.107 179.115,214.425C178.637,213.964 178.313,213.486 178.193,213.154C178.039,212.77 177.869,212.053 177.869,210.893C177.869,208.58 177.869,205.705 186.368,205.705C189.295,205.705 192.094,206.08 194.944,206.857C197.845,207.616 200.397,208.606 202.59,209.767L209.937,213.675L215.04,191.753L210.662,189.449C207.436,187.751 203.665,186.36 199.389,185.31C190.941,183.288 181.563,183.262 174.011,185.387C170.009,186.522 166.51,188.263 163.558,190.575C160.486,192.99 158.054,196.053 156.381,199.646C154.683,203.222 153.83,207.283 153.83,211.746C153.83,215.885 154.683,219.622 156.305,222.797C157.781,225.75 159.804,228.463 162.381,230.895C164.728,233.097 167.458,235.068 170.479,236.732C173.176,238.242 176.026,239.685 179.43,241.297L185.83,244.36C187.554,245.213 189.056,246.143 190.276,247.176C191.3,248.029 192.128,248.959 192.648,249.873C193.049,250.607 193.245,251.639 193.245,252.894C193.245,255.428 192.392,256.529 191.069,257.434C187.229,260.096 179.353,260.267 172.304,258.509C169.053,257.707 166.083,256.7 163.429,255.463L156.38,252.212L151.03,273.801L155.757,276.054C159.631,277.914 163.983,279.331 168.685,280.252C173.233,281.148 177.73,281.6 182.057,281.6C186.682,281.6 191.085,280.977 195.207,279.723C199.405,278.434 203.16,276.549 206.283,274.151C209.585,271.659 212.256,268.579 214.262,264.927C216.259,261.164 217.283,256.965 217.283,252.417C217.284,247.569 216.158,243.191 213.931,239.454Z" style={{ fillRule: 'nonzero' }} />
      </g>
      <g transform="matrix(0.046875,0,0,0.046875,0,0)">
        <path d="M309.632,214.383C307.61,208.461 304.606,203.17 300.681,198.699C296.781,194.176 291.832,190.524 285.978,187.828C274.253,182.478 258.006,182.529 246.375,187.828C240.521,190.516 235.572,194.168 231.646,198.674C227.721,203.171 224.717,208.462 222.72,214.358C220.723,220.161 219.716,226.322 219.716,232.696C219.716,239.07 220.714,245.249 222.694,251.017C224.733,256.939 227.729,262.221 231.654,266.701C235.579,271.241 240.529,274.884 246.383,277.564C252.211,280.235 258.859,281.6 266.18,281.6C273.459,281.6 280.132,280.252 285.977,277.564C291.822,274.876 296.78,271.224 300.68,266.727C304.605,262.23 307.609,256.939 309.606,251.043C311.603,245.24 312.61,239.071 312.61,232.696C312.61,226.321 311.612,220.16 309.632,214.383ZM286.916,243.84C285.943,247.091 284.467,249.967 282.564,252.399C280.789,254.66 278.587,256.401 275.84,257.715C270.413,260.326 261.939,260.318 256.486,257.715C253.764,256.401 251.562,254.669 249.787,252.399C247.884,249.959 246.408,247.083 245.435,243.823C244.385,240.35 243.856,236.595 243.856,232.67C243.856,228.745 244.385,225.016 245.435,221.534C246.408,218.308 247.858,215.458 249.761,213.069C251.536,210.808 253.763,209.041 256.562,207.693C259.259,206.37 262.51,205.696 266.188,205.696C269.866,205.696 273.091,206.37 275.814,207.693C278.587,209.041 280.815,210.808 282.589,213.069C284.492,215.458 285.943,218.309 286.915,221.543C287.965,225.008 288.494,228.754 288.494,232.679C288.494,236.604 287.966,240.35 286.916,243.84Z" style={{ fillRule: 'nonzero' }} />
      </g>
      <g transform="matrix(0.046875,0,0,0.046875,0,0)">
        <path d="M342.409,258.091L342.409,185.259L318.259,185.259L318.259,280.124L376.661,280.124L376.661,258.091L342.409,258.091Z" style={{ fillRule: 'nonzero' }} />
      </g>
      <g transform="matrix(0.046875,0,0,0.046875,0,0)">
        <path d="M460.664,213.7C458.061,207.846 454.315,202.735 449.562,198.528C444.886,194.355 439.211,191.053 432.683,188.715C426.257,186.428 419.081,185.268 411.307,185.268L382.106,185.268L382.106,280.133L411.307,280.133C419.081,280.124 426.257,278.964 432.683,276.677C439.211,274.339 444.886,271.028 449.562,266.864C454.315,262.648 458.061,257.554 460.664,251.692C463.241,245.889 464.538,239.506 464.538,232.688C464.538,225.886 463.241,219.503 460.664,213.7ZM438.229,243.516C436.778,246.639 434.833,249.208 432.332,251.315C429.704,253.525 426.709,255.189 423.159,256.367C419.507,257.596 415.513,258.202 411.306,258.202L406.254,258.202L406.254,207.173L411.306,207.173C415.504,207.173 419.507,207.787 423.159,209.008C426.709,210.186 429.713,211.85 432.307,214.043C434.833,216.168 436.753,218.728 438.229,221.877C439.68,225 440.405,228.627 440.405,232.68C440.405,236.8 439.68,240.452 438.229,243.516Z" style={{ fillRule: 'nonzero' }} />
      </g>
      <g transform="matrix(0.046875,0,0,0.046875,0,0)">
        <path d="M512,64L512,38.4L64,38.4L64,0L38.4,0L38.4,38.4L0,38.4L0,64L38.4,64L38.4,486.4L12.8,486.4C5.726,486.4 0,492.126 0,499.2C0,506.274 5.726,512 12.8,512L89.6,512C96.674,512 102.4,506.274 102.4,499.2C102.4,492.126 96.674,486.4 89.6,486.4L64,486.4L64,64L153.591,64L153.591,102.4L128,102.4C113.86,102.4 102.4,113.86 102.4,128L102.4,332.8C102.4,346.94 113.86,358.4 128,358.4L486.4,358.4C500.54,358.4 512,346.94 512,332.8L512,128C512,113.86 500.54,102.4 486.4,102.4L460.8,102.4L460.8,64L512,64ZM179.191,64L435.2,64L435.2,102.4L179.191,102.4L179.191,64ZM486.4,128L486.4,332.8L128,332.8L128,128L486.4,128Z" style={{ fillRule: 'nonzero' }} />
      </g>
    </SvgIcon>
  );
}

export default React.forwardRef(SoldIcon);
