import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const Filter = ({ onChange, ...others }) => {
  const intl = useIntl();
  const [filter, setFilter] = useState("");

  const handleChange = (event) => {
    const value = event.target.value;

    setFilter(value);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (onChange) {
        onChange(filter);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    }
  }, [filter, onChange]);

  return (
    <div {...others}>
      <TextField
        label={intl.formatMessage({
          id: "Filter",
          defaultMessage: "Filter"
        })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRoundedIcon />
            </InputAdornment>
          )
        }}
        value={filter}
        onChange={handleChange}
      />
    </div>
  )
}

Filter.propTypes = {
  onChange: PropTypes.func,
  filter: PropTypes.string,
  intl: PropTypes.object
};

export default Filter;
