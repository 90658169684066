import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { endOfDay, firstOpenDayOfMonth } from '../helpers/date';
import { fetchPerformanceData } from '../backend';
import { getCustomers } from '../DataStore';
import { wait } from '../helpers';

export const PerformanceProviderTypes = {
  CurrentMonth: 1, // The current month, starting at beginning of month, ending today
  LastYear: 2, // The last 12 months, ending yesterday
  MonthLastYear: 3 // The same month, last year, starting at beginning of month, ending on the same date last year
};

const startOfLastYear = d => {
  const y = yesterday(d);

  return new Date(y.getTime() - (365 * 24 * 3600 * 1000));
}

const startOfMonthLastYear = d => {
  const monthLastYear = new Date(d.getFullYear() - 1, d.getMonth(), d.getDate(), 0, 0, 0, 0);
  return firstOpenDayOfMonth(monthLastYear);
}

const getStartDate = providerType => {
  const now = new Date();

  switch (providerType) {
    case PerformanceProviderTypes.MonthLastYear:
      return startOfMonthLastYear(now);

    case PerformanceProviderTypes.LastYear:
      return startOfLastYear(now);

    default:
      return firstOpenDayOfMonth(now);
  }
}

const yesterday = d => {
  const yesterday = d.getTime() - (24 * 3600 * 1000);
  return endOfDay(new Date(yesterday));
}

const endOfMonthLastYear = d => {
  return new Date(d.getFullYear() - 1, d.getMonth(), d.getDate(), 23, 59, 59, 999);
}

const getEndDate = providerType => {
  const now = new Date();

  switch (providerType) {
    case PerformanceProviderTypes.LastYear:
      return yesterday(now);

    case PerformanceProviderTypes.MonthLastYear:
      return endOfMonthLastYear(now);

    default: return now;
  }
}

const performanceDataProvider = (providerType) => (WrappedComponent) => {
  const DataProvider = ({ ...others }) => {
    const [data, setData] = useState(null);
    //const [retryTimer, setRetryTimer] = useState(null);

    const customers = useSelector(getCustomers);

    useEffect(() => {
      const startDate = getStartDate(providerType);
      const endDate = getEndDate(providerType);

      const loadData = async () => {
        try {
          const data = await fetchPerformanceData(startDate, endDate, true);
          setData(data.reportData);
        }
        catch (error) {
          await wait(5000);
          loadData();
        }
      }

      loadData();
    }, [customers]);

    switch (providerType) {
      case PerformanceProviderTypes.LastYear:
        return (<WrappedComponent {...others} lastYearData={data} />);

      case PerformanceProviderTypes.MonthLastYear:
        return (<WrappedComponent {...others} monthLastYearData={data} />);

      default:
        return (<WrappedComponent {...others} currentMonthData={data} />);
    }
  }

  return DataProvider;
}

export default performanceDataProvider;
