import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { percent } from '../Helper';

const ModelLine = ({ model, ...others }) => {
  return (
    <TableRow {...others}>
      <TableCell>{model.model}</TableCell>
      <TableCell>{model.customers.length}</TableCell>
      <TableCell>{model.walkins.length}</TableCell>
      <TableCell>{model.internets.length}</TableCell>
      <TableCell>{model.phones.length}</TableCell>
      <TableCell>{model.services.length}</TableCell>
      <TableCell>{model.purchases.length}</TableCell>
      <TableCell>{model.leases.length}</TableCell>
      <TableCell>{model.sales.length}({model.demos.length})</TableCell>
      <TableCell>{percent(model.sales.length / model.customers.length)}</TableCell>
      <TableCell>{model.deliveries.length}</TableCell>
    </TableRow>
  );
}

ModelLine.propTypes = {
  model: PropTypes.object
};

export default ModelLine;