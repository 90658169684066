import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import axios from "axios";

import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import md5 from 'md5';

import { ConfirmationDialog } from '../../Components';
import SetPasswordDialog from "../SetPasswordDialog";

import { getUser } from '../../DataStore/Login/Reducer';
import { AccessRight } from "../../Models/User";
import { hasAccessRight } from "../../DataStore/Login/Reducer";

const AvatarButton = () => {
  const currentUser = useSelector(getUser);
  const shutdownRight = useSelector(state => { return hasAccessRight(state, AccessRight.Shutdown) });

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [setPassword, setSetPassword] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const intl = useIntl();

  const hash = md5(currentUser.email);
  const gravatarSrc = 'https://www.gravatar.com/avatar/' + hash;

  const handleSetPassword = () => {
    setMenuAnchor(null);
    setSetPassword(true);
  }

  const handleServerShutdown = () => {
    setConfirmationDialog(true);
    setMenuAnchor(null);
  }

  const handleConfirmationDialogOnConfirm = () => {
    setConfirmationDialog(false);
    shutdownServer();
  }

  const shutdownServer = () => {
    axios.post("/system/shutdown");
  }

  return (
    <React.Fragment>
      <Avatar
        alt={currentUser.firstName + ' ' + currentUser.lastName}
        src={gravatarSrc}
        onClick={(event) => setMenuAnchor(event.currentTarget)}
      />
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
      >
        <MenuItem onClick={handleSetPassword}>
          {intl.formatMessage({
            id: "Change Password",
            defaultMessage: "Change Password"
          })}
        </MenuItem>
        {shutdownRight &&
          <MenuItem onClick={handleServerShutdown}>
            {intl.formatMessage({
              id: "Shutdown Server",
              defaultMessage: "Shutdowm Server"
            })}
          </MenuItem>
        }
      </Menu>
      <SetPasswordDialog open={setPassword} onClose={() => setSetPassword(false)} />
      <ConfirmationDialog
        open={confirmationDialog}
        title={intl.formatMessage({
          id: "Shutdown Confirmation",
          defaultMessage: "Shutdown Confirmation"
        })}
        message={intl.formatMessage({
          id: "Are you sure you want to shutdown the server?",
          defaultMessage: "Are you sure you want to shutdown the server?"
        })}
        onCancel={() => { setConfirmationDialog(false) }}
        onConfirm={handleConfirmationDialogOnConfirm}
      />
    </React.Fragment>
  )
}

export default AvatarButton;
