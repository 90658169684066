import React from 'react';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from "react-beautiful-dnd";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Card from '@material-ui/core/Card';
import DragAndDropHandler from "../../../UI/DragAndDropHandler";

const droppableId = 'model-list';

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : null
});

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
});

const DragAndDropList = ({ value, onChange, ...others }) => {
  const handleDrop = (event) => {
    if (!event.destination) {
      const newList = value.slice();
      newList.splice(event.source.index, 1);
      onChange(newList);
    }
    else {
      const newList = value.slice();
      const [movedModel] = newList.splice(event.source.index, 1);
      newList.splice(event.destination.index, 0, movedModel);
      onChange(newList);
    }
  }

  return (
    <Card {...others}>
      <DragAndDropHandler handler={handleDrop} sourceDroppableId={droppableId}>
        <Droppable droppableId={droppableId}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              <List style={getListStyle(snapshot.isDraggingOver)}>
                {value.map((model, index) => (
                  <Draggable key={model} draggableId={model} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <ListItem>
                          {model}
                        </ListItem>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            </div>
          )}
        </Droppable>
      </DragAndDropHandler>
    </Card>
  );
}

DragAndDropList.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired
};

export default DragAndDropList;
