import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { fetchUsers } from '../../DataStore';
import { useSnackbar } from 'notistack';

const messages = defineMessages({
  errorFetchingUsers: {
    id: "Error while fetching users from server.",
    defaultMessage: "Error while fecthing users from server."
  }
});

const UserProvider = ({ children }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const loadUsers = async () => {
      try {
        dispatch(fetchUsers());
      }
      catch (error) {
        enqueueSnackbar(intl.formatMessage(messages.errorFetchingUsers, { variant: 'error' }));

        setTimeout(() => {
          loadUsers();
        }, 10000);
      }
    }

    loadUsers();
  }, [dispatch, enqueueSnackbar, intl]);

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
}

UserProvider.propTypes = {
  children: PropTypes.any
}

export default UserProvider;
