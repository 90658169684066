import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  addDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cell: {
    border: "1px solid black"
  },
  fulfilled: {
    backgroundColor: 'yellow'
  },
  previousDay: {
    color: 'red'
  }
}));

const ViewerCell = ({ reservation, vehicle, date, openMenu, onAdd, ...others }) => {
  const classes = useStyles();

  const tdClass = clsx(classes.cell);
  const fulfilledClass = clsx(classes.cell, classes.fulfilled);
  const previousDayClass = clsx(classes.cell, classes.previousDay);

  if (reservation) {
    const cellClass = reservation.fulfilled ? fulfilledClass : (reservation.previousDay ? previousDayClass : tdClass);

    return (
      <td
        className={cellClass}

        onClick={(event) => openMenu(event.currentTarget, reservation)}
        {...others}
      >
        {reservation.firstName + ' ' + reservation.lastName}
      </td >
    )
  } else {
    return (
      <td className={tdClass} {...others}>
        <div className={classes.addDiv}>
          <IconButton onClick={onAdd ? () => onAdd(vehicle, date) : undefined}>
            <AddIcon />
          </IconButton>
        </div>
      </td>
    )
  }
}

ViewerCell.propTypes = {
  reservation: PropTypes.object,
  vehicle: PropTypes.object,
  date: PropTypes.number,
  openMenu: PropTypes.func.isRequired,
  onAdd: PropTypes.func
}

export default ViewerCell;
