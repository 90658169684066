import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import { getManagers } from '../DataStore/Users/Reducer';

import { visitTypeToString, VisitType, PurchaseType, validateVisit } from "../Models/Visit";

import VehicleSelector from "./VehicleSelector";
import TakeOverSelect from "./TakeOverSelect";
import EmailEditor from './EmailEditor';
import { dateToText, textToDate } from "../helpers";
import { hasAccessRight } from "../DataStore/Login/Reducer";
import { AccessRight } from "../Models/User";

const styles = theme => ({
  listItem: {
    width: "100%"
  },
  card: {
    width: "100%"
  },
  visitData: {
    display: "flex",
    alignItems: "center"
  },
  entryField: {
    width: "256px",
    margin: theme.spacing(1)
  },
  commentsEntryField: {
    width: '100%',
    margin: theme.spacing(1)
  },
  comments: {
    marginTop: "8px"
  }
});

class VisitEditor extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visit: null,
      modified: false,
      validationStarted: false,
      emailSubject: '',
      emailContent: '',
      invalidFields: [],
      editedDate: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleTakeOverChange = this.handleTakeOverChange.bind(this);
    this.handleNextCommChange = this.handleNextCommChange.bind(this);
    this.handleEmailContentChange = this.handleEmailContentChange.bind(this);
    this.handleEmailSubjectChange = this.handleEmailSubjectChange.bind(this);
    this.handleExpectedDeliveryChange = this.handleExpectedDeliveryChange.bind(this);
    this.handleSetVisitDate = this.handleSetVisitDate.bind(this);
    this.handleChangeVisitDate = this.handleChangeVisitDate.bind(this);
  }

  componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount(this);
    }

    this.setState({
      visit: this.props.initialVisit
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialVisit !== this.props.initialVisit) {
      this.setState({
        visit: this.props.initialVisit,
        modified: false
      })
    }
  }

  getVisit() {
    return Object.assign({}, this.state.visit);
  }

  isModified() {
    return this.state.modified;
  }

  isValid() {
    return this.validate().length <= 0;
  }

  getEmailSubject() {
    return this.state.emailSubject;
  }

  getEmailContent() {
    return this.state.emailContent;
  }

  getEditedDate() {
    const editedDate = this.state.editedDate;

    if (editedDate === null) {
      return null;
    }

    const d = new Date(editedDate * 1000);
    const result = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59, 0);

    return result.getTime() / 1000;
  }

  validate() {
    const invalidFields = validateVisit(this.state.visit);

    if (this.state.visit.type === VisitType.InternetResponse) {
      if (this.state.emailSubject.trim() === "") {
        invalidFields.push("emailSubject");
      }

      if (this.state.emailContent.trim() === "") {
        invalidFields.push("emailContent");
      }
    }

    if (this.state.visit.sold) {
      if (!this.state.visit.order && this.state.visit.stockNumber.trim() === "") {
        invalidFields.push("stockNumber");
      }
    }

    if (this.state.visit.delivered) {
      if (this.state.visit.stockNumber.trim().length === 0) {
        invalidFields.push("stockNumber");
      }
    }

    this.setState({
      invalidFields,
      validationStarted: true
    });

    return invalidFields;
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setValue(name, value);
  }

  setValue(name, value) {
    const newVisit = Object.assign({}, this.state.visit, { [name]: value });

    if (name === 'type') {
      if (value === VisitType.Phone || value === VisitType.Internet || value === VisitType.InternetResponse || value === VisitType.FollowUp || value === VisitType.ManagerComment) {
        newVisit.takeOver = false;
        newVisit.takeOverBy = null;
      }
    }

    this.setState({
      visit: newVisit,
      modified: true
    });

    if (this.state.validationStarted) {
      this.validate();
    }

    if (this.props.onVisitModified) {
      this.props.onVisitModified();
    }
  }

  handleNextCommChange(event) {
    this.setValue(event.target.name, textToDate(event.target.value).getTime() / 1000);
  }

  handleExpectedDeliveryChange(event) {
    this.setValue(event.target.name, textToDate(event.target.value).getTime() / 1000);
  }

  handleTakeOverChange(event) {
    const value = event.target.value;

    if (value === "none") {
      const newVisit = Object.assign({}, this.state.visit, {
        takeOver: false,
        takeOverBy: null
      });
      this.setState({ visit: newVisit });
    } else {
      const newVisit = Object.assign({}, this.state.visit, {
        takeOver: true,
        takeOverBy: value
      });
      this.setState({ visit: newVisit });
    }

    if (this.state.validationStarted) {
      this.validate();
    }

    if (this.props.onVisitModified) {
      this.props.onVisitModified();
    }
  }

  handleEmailContentChange(value) {
    this.setState({
      emailContent: value,
      modified: true
    });

    if (this.props.onVisitModified) {
      this.props.onVisitModified();
    }

    if (this.state.validationStarted) {
      this.validate();
    }
  }

  handleEmailSubjectChange(event) {
    this.setState({
      emailSubject: event.target.value,
      modified: true
    });

    if (this.props.onVisitModified) {
      this.props.onVisitModified();
    }

    if (this.state.validationStarted) {
      this.validate();
    }
  }

  handleSetVisitDate(event) {
    this.setState({ editedDate: event.target.checked ? this.state.visit.date : null });
  }

  handleChangeVisitDate(event) {
    this.setState({ editedDate: textToDate(event.target.value).getTime() / 1000, modified: true });

    if (this.props.onVisitModified) {
      this.props.onVisitModified();
    }
  }

  visitTypes() {
    const { availableVisitTypes, intl } = this.props;
    const allVisitTypes = [
      VisitType.Walkin,
      VisitType.Phone,
      VisitType.Internet,
      VisitType.Service,
      VisitType.Beback,
      VisitType.FollowUp,
      VisitType.InternetResponse,
      VisitType.ManagerComment,
      VisitType.Delivery
    ];

    let visitTypes = availableVisitTypes ? availableVisitTypes : allVisitTypes;

    if (!this.props.isManager) {
      visitTypes = visitTypes.filter(t => {
        return t !== VisitType.ManagerComment;
      });
    }

    return visitTypes.map(type => {
      return (
        <MenuItem key={type} value={type}>
          {visitTypeToString(intl, type)}
        </MenuItem>
      )
    })
  }

  isInvalid(field) {
    return this.state.invalidFields.indexOf(field) >= 0;
  }

  render() {
    const { managers, classes } = this.props;
    const intl = this.props.intl;
    const { visit, editedDate } = this.state;

    if (visit === null) {
      return null;
    }

    return (
      <div>
        <div className={classes.visitData}>
          <div>
            <FormControl
              className={classes.entryField}
              error={this.isInvalid("type")}
              disabled={visit.delivered}
            >
              <InputLabel htmlFor="type">
                {this.props.intl.formatMessage({
                  id: "Visit Type", defaultMessage: "Visit Type"
                })}
              </InputLabel>
              <Select
                value={visit.type}
                inputProps={{
                  name: "type"
                }}
                onChange={this.handleChange}
              >
                {this.visitTypes()}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl
              className={classes.entryField}
              error={this.isInvalid("purchaseType")}
              disabled={visit.delivered}
            >
              <InputLabel htmlFor="purchaseType">
                {this.props.intl.formatMessage({
                  id: "Purchase Type",
                  defaultMessage: "Purchase Type"
                })}
              </InputLabel>
              <Select
                value={visit.purchaseType}
                inputProps={{
                  name: "purchaseType"
                }}
                onChange={this.handleChange}
              >
                <MenuItem value={PurchaseType.New}>
                  {intl.formatMessage({
                    id: "New",
                    defaultMessage: "New"
                  })}
                </MenuItem>
                <MenuItem value={PurchaseType.Lease}>
                  {intl.formatMessage({
                    id: "Lease",
                    defaultMessage: "Lease"
                  })}
                </MenuItem>
                <MenuItem value={PurchaseType.Used}>
                  {intl.formatMessage({
                    id: "Used",
                    defaultMessage: "Used"
                  })}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <VehicleSelector
              purchaseType={visit.purchaseType}
              currentSelection={visit.vehicle}
              onChange={this.handleChange}
              availableVehicles={this.props.availableVehicles}
              intl={this.props.intl}
              error={this.isInvalid("vehicle")}
              className={classes.entryField}
              disabled={visit.delivered}
            />
          </div>
          <div>
            <TakeOverSelect
              className={classes.entryField}
              managers={managers}
              value={visit.takeOver ? visit.takeOverBy : "none"}
              onChange={this.handleTakeOverChange}
              disabled={visit.type === VisitType.Phone || visit.type === VisitType.Internet || visit.type === VisitType.InternetResponse || visit.type === VisitType.FollowUp || visit.delivered}
            />
          </div>
        </div>
        <div>
          <FormGroup row={true}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={visit.reservation}
                  inputProps={{ name: "reservation" }}
                  onChange={this.handleChange}
                  disabled={visit.sold}
                />
              }
              label={this.props.intl.formatMessage({
                id: "Reservation",
                defaultMessage: "Reservation"
              })}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={visit.sold}
                  inputProps={{ name: "sold" }}
                  onChange={this.handleChange}
                  disabled={visit.delivered || visit.reservation}
                />
              }
              label={this.props.intl.formatMessage({
                id: "Sold",
                defaultMessage: "Sold"
              })}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={visit.testDrive}
                  inputProps={{ name: "testDrive" }}
                  onChange={this.handleChange}
                  disabled={visit.delivered}
                />
              }
              label={this.props.intl.formatMessage({
                id: "Test Drive",
                defaultMessage: "Test Drive"
              })}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={visit.vipPresentation}
                  inputProps={{ name: "vipPresentation" }}
                  onChange={this.handleChange}
                  disabled={visit.delivered}
                />
              }
              label={this.props.intl.formatMessage({
                id: "VIP Prfesentation",
                defaultMessage: "VIP Presentation"
              })}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={visit.communicationCompleted}
                  inputProps={{ name: "communicationCompleted" }}
                  onChange={this.handleChange}
                  disabled={visit.delivered}
                />
              }
              label={this.props.intl.formatMessage({
                id: "Cummunication Completed",
                defaultMessage: "Communication Completed"
              })}
            />
            {!visit.sold && !visit.communicationCompleted && (<TextField
              name={"nextScheduledCommunication"}
              type="date"
              label={this.props.intl.formatMessage({
                id: "Next Scheduled Comm.",
                defaultMessage: "Next Scheduled Comm."
              })}
              value={dateToText(new Date(visit.nextScheduledCommunication * 1000))}
              onChange={this.handleNextCommChange}
              disabled={visit.delivered}
            />)}
          </FormGroup>
          {visit.sold && (<div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={visit.order}
                  inputProps={{ name: "order" }}
                  onChange={this.handleChange}
                  disabled={visit.delivered}
                />
              }
              label={this.props.intl.formatMessage({
                id: "Order",
                defaultMessage: "Order"
              })}
            />
            {!visit.order && (<React.Fragment>
              <TextField
                type="text"
                name="stockNumber"
                label={this.props.intl.formatMessage({
                  id: "Stock Number",
                  defaultMessage: "Stock Number"
                })}
                value={visit.stockNumber}
                onChange={this.handleChange}
                error={this.isInvalid("stockNumber")}
                disabled={visit.delivered}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visit.demo}
                    inputProps={{ name: "demo" }}
                    onChange={this.handleChange}
                    disabled={this.state.visit.delivered || this.state.visit.stockNumber.trim().length === 0 || this.state.visit.purchaseType === PurchaseType.Used}
                  />
                }
                label={this.props.intl.formatMessage({
                  id: "Demo",
                  defaultMessage: "Demo"
                })}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visit.delivered}
                    inputProps={{ name: "delivered" }}
                    onChange={this.handleChange}
                    disabled={this.state.visit.stockNumber.trim().length === 0}
                  />
                }
                label={this.props.intl.formatMessage({
                  id: "Delivered",
                  defaultMessage: "Delivered"
                })}
              />
            </React.Fragment>)}
            <TextField
              name={"expectedDeliveryDate"}
              type="date"
              label={this.props.intl.formatMessage({
                id: "Expected Delivery",
                defaultMessage: "Expected Delivery"
              })}
              value={dateToText(new Date(visit.expectedDeliveryDate * 1000))}
              onChange={this.handleExpectedDeliveryChange}
              disabled={visit.delivered}
            />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visit.saleCancelled}
                    inputProps={{ name: "saleCancelled" }}
                    onChange={this.handleChange}
                    disabled={visit.delivered}
                  />
                }
                label={this.props.intl.formatMessage({
                  id: "Sale Cancelled",
                  defaultMessage: "Sale Cancelled"
                })}
              />
            </div>
          </div>)}
          {this.props.isManager && (<div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={editedDate !== null}
                  inputProps={{ name: "editedDate" }}
                  onChange={this.handleSetVisitDate}
                />
              }
              label={this.props.intl.formatMessage({
                id: "Change Visit Date",
                defaultMessage: "Change Visit Date"
              })}
            />
            <TextField
              name={"Visit Date"}
              type="date"
              label={this.props.intl.formatMessage({
                id: "New Visit Date",
                defaultMessage: "New Visit Date"
              })}
              value={dateToText(new Date(editedDate * 1000))}
              onChange={this.handleChangeVisitDate}
              disabled={editedDate === null}
            />
          </div>)}
          <TextField
            className={classes.commentsEntryField}
            type="text"
            name="comments"
            label={this.props.intl.formatMessage({
              id: "Comments",
              defaultMessage: "Comments"
            })}
            value={visit.comments}
            onChange={this.handleChange}
            margin="normal"
            multiline={true}
            error={this.isInvalid("comments")}
          />
          {visit.type === VisitType.InternetResponse && <EmailEditor
            value={this.state.emailContent}
            onChange={this.handleEmailContentChange}
            onSubjectChange={this.handleEmailSubjectChange}
            subjectValue={this.state.emailSubject}
            invalidFields={this.state.invalidFields}
          />}
        </div>
      </div>
    );
  }
}

VisitEditor.propTypes = {
  initialVisit: PropTypes.object,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  availableVisitTypes: PropTypes.array,
  availableVehicles: PropTypes.array,
  validate: PropTypes.bool,
  managers: PropTypes.array,
  isManager: PropTypes.bool,
  onMount: PropTypes.func,
  onVisitModified: PropTypes.func
};

const mapStateToProps = state => ({
  managers: getManagers(state),
  isManager: hasAccessRight(state, AccessRight.Manager)
});

const mapDispatchToProps = (/*dispatch*/) => ({
});

export default withStyles(styles)(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(VisitEditor)
  )
);
