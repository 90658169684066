import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  listItem: {
    margin: theme.spacing(1)
  },
  itemDiv: {
    display: 'flex'
  },
  contentDiv: {
    flexGrow: 1
  },
  actionDiv: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  }
}));

const VehicleItem = ({ vehicle, onEdit, onDelete, ...others }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <li {...others}>
      <Paper className={classes.listItem}>
        <div className={classes.itemDiv}>
          <div className={classes.contentDiv}>
            <Typography>
              {intl.formatMessage(
                {
                  id: "{brand} {model} {year} {color}",
                  defaultMessage: "{brand} {model} {year} {color}"
                },
                {
                  brand: vehicle.brand,
                  model: vehicle.model,
                  year: vehicle.year,
                  color: vehicle.color
                })
              }
            </Typography>
            <Typography>
              {intl.formatMessage(
                {
                  id: "Plate Number: {plateNUmber}",
                  defaultMessage: "PlateNumber: {plateNumber}"
                },
                {
                  plateNumber: vehicle.plateNumber
                })
              }
            </Typography>
            <Typography>
              {intl.formatMessage(
                {
                  id: "Serial Number: {serialNumber}",
                  defaultMessage: "Serial Number: {serialNumber}"
                },
                {
                  serialNumber: vehicle.serialNumber
                })
              }
            </Typography>
          </div>
          <div className={classes.actionDiv}>
            <IconButton onClick={() => { onEdit(vehicle) }}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => { onDelete(vehicle) }}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      </Paper>
    </li>
  )
}

VehicleItem.propTypes = {
  vehicle: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
}

export default VehicleItem;
