import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import FollowUps from './FollowUps';
import SalesmanTurn from '../SalesmanTurn/SalesmanTurn';

const useStyles = makeStyles((/*theme*/) => ({
  rootDiv: {
    width: "100%",
    height: "100%"
  }
}));

const SalesmanDashboard = ({ ...others }) => {
  const classes = useStyles();

  return (
    <div className={classes.rootDiv} {...others}>
      <Grid container>
        <Grid item xs={12} md={4}>
          <SalesmanTurn />
        </Grid>
        <Grid item xs={12} md={4}>
          <FollowUps />
        </Grid>
      </Grid>
    </div>
  )
}

export default SalesmanDashboard;
