import { combineReducers } from 'redux-immutable';
import createReducer from '../CreateReducer';
import * as Types from './Types';
import { Map } from 'immutable';

import usersReducer from './Users/Reducer';
import appSettingsReducer from './Settings/Reducer';

const initialState = Map({
  currentContent: Types.ContentId.None
});

const setContent = (state, {contentId}) => {
  return state.set('currentContent', contentId);
};

/**
 *
 * @param {object} state - The full redux state.
 * @returns {object} - The state subtree for the reducer.
 */
const getState = state => {
  return state.getIn(['settings', 'content']);
};

/**
 *
 * @param {object} state - The full redux state.
 * @returns {string} - The current setting content id.
 */
export function getContentId(state) {
  return getState(state).get('currentContent');
}

const contentReducer = createReducer(initialState, {
  [Types.SET_CONTENT]: setContent
});

const settingsReducer = combineReducers(
  {
    content: contentReducer,
    users: usersReducer,
    settings: appSettingsReducer
  }
);

export default settingsReducer;
