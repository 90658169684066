import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import PerformanceUI from './PerformanceUI';
import { fetchPerformanceData } from '../../backend';
import ReportHeader from '../ReportHeader';
import { useSnackbar } from 'notistack';

const messages = defineMessages({
  errorLoadingData: {
    id: "Error loading report data.",
    defaultMessage: "Error loading report data."
  }
})

const Performance = ({ startTime, endTime, ...others }) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    loaded: false,
    allVehiclesData: [],
    newVehiclesData: [],
    usedVehiclesData: [],
    internetRequestsData: [],
    internetSourceData: [],
    phoneData: [],
    serviceData: [],
    customers: []
  });

  const dataToCustomers = (data) => {
    return data.customers.reduce((customers, customer) => {
      customers[customer.id] = customer;
      return customers;
    }, {});
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchPerformanceData(startTime, endTime);
        setState({
          loaded: true,
          customers: dataToCustomers(data),
          allVehiclesData: data.reportData.allVehicles,
          newVehiclesData: data.reportData.newVehicles,
          usedVehiclesData: data.reportData.usedVehicles,
          internetRequestsData: data.reportData.internetRequests,
          internetSourceData: data.reportData.internetSources,
          phoneData: data.reportData.phoneRequests,
          serviceData: data.reportData.serviceRequests,
          reconnectData: data.reportData.reconnectRequests
        });
      }
      catch (error) {
        enqueueSnackbar(intl.formatMessage(messages.ErrorLoadingData, { variant: 'error' }));
      }
    }

    loadData();
  }, [startTime, endTime, enqueueSnackbar, intl]);

  const {
    loaded,
    allVehiclesData,
    newVehiclesData,
    usedVehiclesData,
    internetRequestsData,
    internetSourceData,
    phoneData,
    serviceData,
    reconnectData,
    customers
  } = state;

  return loaded ?
    (
      <div {...others}>
        <ReportHeader
          title={intl.formatMessage({
            id: "Showroom Performance",
            defaultMessage: "Showroom Performance"
          })}
          startTime={startTime}
          endTime={endTime}
        />
        <PerformanceUI
          allVehiclesData={allVehiclesData}
          newVehiclesData={newVehiclesData}
          usedVehiclesData={usedVehiclesData}
          internetRequestsData={internetRequestsData}
          internetSourceData={internetSourceData}
          phoneData={phoneData}
          serviceData={serviceData}
          reconnectData={reconnectData}
          customers={customers}
        />
      </div >
    ) : null;
}

Performance.propTypes = {
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date)
}

export default Performance;
