import React from 'react';
import { useSelector } from 'react-redux';

import { hasAccessRight } from '../DataStore/Login/Reducer';
import { AccessRight } from '../Models/User';
import SalesmanDashboard from '../SalesmanDashboard/SalesmanDashboard';
import ManagerDashboard from '../ManagerDashboard/ManagerDashboard';

const Dashboard = ({ ...others }) => {
  const isManager = state => hasAccessRight(state, AccessRight.Manager);
  const manager = useSelector(isManager);

  if (manager) {
    return (<ManagerDashboard {...others} />);
  }

  return (
    (<SalesmanDashboard {...others} />)
  )
}

export default Dashboard;
