import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import * as Visit from '../../../Models/Visit';

const visitTypes = [
  { id: Visit.VisitType.Walkin, stringId: 'visit.visittype.walkin' },
  { id: Visit.VisitType.Beback, stringId: 'visit.visittype.beback' },
  { id: Visit.VisitType.FollowUp, stringId: 'visit.visittype.followup' },
  { id: Visit.VisitType.Phone, stringId: 'visit.visittype.phone' },
  { id: Visit.VisitType.Internet, stringId: 'visit.visittype.internet' },
  { id: Visit.VisitType.Service, stringId: 'visit.visittype.service' },
  { id: Visit.VisitType.InternetResponse, stringId: 'visit.visittype.internetresponse' }
];

const VisitTypes = ({ onChange, name, value, ...others }) => {
  const intl = useIntl();

  const handleClick = (event, visitId) => {
    if (onChange) {
      const newList = value.slice();
      const target = event.target;

      if (target.checked) {
        newList.push(visitId);
      }
      else {
        newList.splice(newList.indexOf(visitId), 1);
      }
      onChange(name, newList);
    }
  }

  const items = visitTypes.map(visit => {
    return (
      <ListItem
        key={visit.id}
        dense
        button
        onClick={(event) => handleClick(event, visit.id)}
      >
        <Checkbox
          tabIndex={-1}
          checked={value.indexOf(visit.id) >= 0}
          disableRipple
        />
        <ListItemText primary={Visit.visitTypeToString(intl, visit.id)} />
      </ListItem>
    )
  });

  return (
    <List {...others}>
      {items}
    </List>
  );
}

VisitTypes.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.array
};

VisitTypes.defaultProps = {
  name: '',
  onChange: null,
  value: []
};

export default VisitTypes;
