import * as Types from './Types';
import { advancedSearch as backend_advancedSearch } from '../../../backend';

export const advancedSearchRequest = () => ({
  type: Types.ADVANCED_SEARCH_REQUEST
});

export const advancedSearchSuccess = customers => ({
  type: Types.ADVANCED_SEARCH_SUCCESS,
  customers
});

export const advancedSearchFailure = error => ({
  type: Types.ADVANCED_SEARCH_FAILURE,
  error
});

export const advancedSearch = criteria => {
  return async (dispatch) => {
    try {
      dispatch(advancedSearchRequest());
      const customers = await backend_advancedSearch(criteria);
      dispatch(advancedSearchSuccess(customers));
      return customers;
    }
    catch (error) {
      dispatch(advancedSearchFailure(error));
      throw error;
    }
  }
};
