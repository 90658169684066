import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

import { getContentId } from '../DataStore/Settings/Reducer';
import * as Types from '../DataStore/Settings/Types';

import Menu from './Menu';
import Users from './Users';
import MainSettings from './AppSettings/MainSettings';

const useStyles = makeStyles(() => ({
  scrollDiv: {
    width: '100%',
    height: '100%',
    overflow: 'auto'
  }
}));

const Settings = ({ ...others }) => {
  const classes = useStyles();
  const contentId = useSelector(getContentId);
  let content = null;

  switch (contentId) {
    case Types.ContentId.Users:
      content = (<Users />);
      break;

    case Types.ContentId.AppSettings:
      content = (<MainSettings />);
      break;

    default:
      content = null;
  }

  return (
    <div className={classes.scrollDiv}>
      <Grid container spacing={0} {...others}>
        <Grid item xs={2}>
          <Menu />
        </Grid>
        <Grid item xs={10}>
          {content}
        </Grid>
      </Grid>
    </div>
  )
}

export default Settings;
