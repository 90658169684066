import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  verticalDiv: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 0,
    width: '100%',
    height: '100%'
  },
  scrollDiv: {
    height: '100%',
    width: '100%',
    overflowX: 'auto'
  }
}));

const HorizontalScrollView = ({ children, ...others }) => {
  const classes = useStyles();

  return (
    <div {...others}>
      <div className={classes.verticalDiv} {...others}>
        <div className={classes.scrollDiv}>
          {children}
        </div>
      </div>
    </div>
  )
}

HorizontalScrollView.propTypes = {
  children: PropTypes.any
}

export default HorizontalScrollView;
