import { useEffect, useState } from 'react';
import * as API from '../backend';
import { useEventProvider } from '../Providers/EventProvider';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

const isInRange = (startDate, endDate, reservation) => {
  return (reservation.startDate <= startDate && reservation.endDate >= startDate) ||
    (reservation.startDate <= endDate && reservation.endDate >= endDate) ||
    (reservation.startDate >= startDate && reservation.endDate <= endDate);
}

export const useReservations = (startDate, endDate) => {
  const [reservations, setReservations] = useState([]);
  const [status, setStatus] = useState("loading");
  const eventProvider = useEventProvider();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  useEffect(() => {
    const cancelSource = API.getCancelSource();

    const fetchReservations = async () => {
      try {
        setStatus("loading");
        const reservations = await API.getReservations(startDate, endDate);
        setReservations(reservations);
        setStatus("success");
      }
      catch (error) {
        if (API.isCancel(error)) {
          setStatus("cancelled");
        } else {
          setStatus("failure");
          enqueueSnackbar(
            intl.formatMessage({
              id: "Erreur while fetching reservations.",
              defaultMessage: "Erreur while fetching reservations."
            }),
            {
              variant: 'error'
            }
          );
        }
      }
    }

    fetchReservations();

    const addReservation = (event) => {
      const reservation = JSON.parse(event.data);

      setReservations(reservations => {
        if (isInRange(startDate, endDate, reservation)) {
          const newReservations = reservations.slice();
          newReservations.push(reservation);
          return newReservations;
        }

        return reservations;
      });
    }

    const updateReservation = event => {
      const reservation = JSON.parse(event.data);

      setReservations(reservations => {
        const index = reservations.findIndex((r) => {
          return r.id === reservation.id;
        });

        if (index >= 0) {
          const newReservations = reservations.slice();
          newReservations[index] = reservation;
          return newReservations;
        }

        return reservations;
      });
    }

    const deleteReservation = event => {
      const reservation = JSON.parse(event.data);

      setReservations(reservations => {
        const index = reservations.findIndex(r => {
          return r.id === reservation.id;
        });

        if (index >= 0) {
          const newReservations = reservations.slice();
          newReservations.splice(index, 1);
          return newReservations;
        }

        return reservations;
      });
    }

    eventProvider.addEventListener(API.Events.addReservation, addReservation);
    eventProvider.addEventListener(API.Events.updateReservation, updateReservation);
    eventProvider.addEventListener(API.Events.deleteReservation, deleteReservation);

    return () => {
      cancelSource.cancel();
      eventProvider.removeEventListener(API.Events.addReservation, addReservation);
      eventProvider.removeEventListener(API.Events.updateReservation, updateReservation);
      eventProvider.removeEventListener(API.Events.deleteReservation, deleteReservation);
    }
  }, [startDate, endDate, eventProvider, intl, enqueueSnackbar]);

  return [reservations, status];
}

export const useActiveReservations = () => {
  const [reservations, setReservations] = useState([]);
  const [status, setStatus] = useState("loading");
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  useEffect(() => {
    const cancelSource = API.getCancelSource();

    const fetchReservations = async () => {
      try {
        setStatus("loading");
        const reservations = await API.activeReservations(cancelSource);
        setReservations(reservations);
        setStatus("success");
      }
      catch (error) {
        if (API.isCancel(error)) {
          setStatus("cancelled");
        } else {
          setStatus("failure");
          enqueueSnackbar(
            intl.formatMessage({
              id: "Erreur while fetching reservations.",
              defaultMessage: "Erreur while fetching reservations."
            }),
            {
              variant: 'error'
            }
          );
        }
      }
    }

    fetchReservations();
  }, [enqueueSnackbar, intl]);

  return [reservations, status];
}
