import React, { useState } from "react";
import { useIntl } from "react-intl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Performance from "./Performance/Performance";
import CurrentMonthPerformance from './Performance/CurrentMonthPerformance';
import WalkinsReport from "./Walkins/WalkinReport";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import BackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { firstBusinessDayOfMonth } from "../helpers";
import Deliveries from "../Deliveries/Deliveries";
import FutureDeliveries from "./FutureDeliveries/FutureDeliveries";
import { makeStyles } from "@material-ui/core";

export const ReportType = {
  None: 'none',
  CurrentMonthPerformance: 'currentmonthperformance',
  Performance: 'performance',
  Walkins: 'walkins',
  Customers: 'customers',
  Deliveries: 'deliveries',
  FutureDeliveries: 'futuredeliveries'
};

const useStyles = makeStyles(() => ({
  mainDiv: {
    height: '100%',
    overflowY: 'auto'
  },
  reportDiv: {
    '@media print': {
      backgroundColor: 'white',
      height: '100%',
      width: '100%',
      position: 'absolute',
      display: 'block',
      top: 0,
      left: 0,
      margin: 0,
      padding: '15px',
      zIndex: 19000
    }
  }
}));

const textToDate = s => {
  const exp = /(\d\d\d\d).(\d\d).(\d\d)/g;
  const matches = exp.exec(s);

  if (matches) {
    return new Date(parseInt(matches[1], 10), parseInt(matches[2], 10) - 1, parseInt(matches[3], 10), 0, 0, 0, 0);
  }

  return new Date();
}

const endOfDay = d => {
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();

  return new Date(year, month, day, 23, 59, 59);
};

const startOfDay = d => {
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();

  return new Date(year, month, day, 0, 0, 0);
}

const padLeft = (s, c, nb) => {
  let result = s;

  while (result.length < nb) {
    result = c + result;
  }

  return result;
}

const dateToText = d => {
  return d.getFullYear() + "-" + padLeft((d.getMonth() + 1).toString(), '0', 2) + "-" + padLeft(d.getDate().toString(), '0', 2);
}

const Reports = ({ ...others }) => {
  const intl = useIntl();
  const classes = useStyles();

  const [displayReport, setDisplayReport] = useState(false);
  const [startTime, setStartTime] = useState(firstBusinessDayOfMonth(new Date()));
  const [endTime, setEndTime] = useState(endOfDay(new Date()));
  const [reportType, setReportType] = useState(ReportType.CurrentMonthPerformance);

  const handleGoButton = () => {
    setDisplayReport(true);
  }

  const handleOnClose = () => {
    setDisplayReport(false);
  }

  const handleReportTypeChange = (event) => {
    setReportType(event.target.value);
  }

  const handleTimeChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    const date = textToDate(value);

    if (name === 'startTime') {
      setStartTime(startOfDay(date));
    } else {
      setEndTime(endOfDay(date));
    }
  }

  const reportView = (reportType) => {
    let report = null;

    switch (reportType) {
      case ReportType.CurrentMonthPerformance:
        report = (<CurrentMonthPerformance />);
        break;
      case ReportType.Performance:
        report = (<Performance startTime={startTime} endTime={endTime} />);
        break;
      case ReportType.Walkins:
        report = (<WalkinsReport startTime={startTime} endTime={endTime} />);
        break;
      case ReportType.Deliveries:
        report = (<Deliveries />);
        break;
      case ReportType.FutureDeliveries:
        report = (<FutureDeliveries />);
        break;
      default:
        report = null;
    }

    return (
      <div className={classes.mainDiv}>
        <IconButton onClick={handleOnClose}><BackIcon /></IconButton>
        <div className={classes.reportDiv}>
          {report}
        </div>
      </div>
    )
  }

  const reportTypeView = () => {
    return (
      <div {...others}>
        <Grid container spacing={1}>
          <Grid item>
            <FormControl>
              <InputLabel htmlFor="report-type">
                {intl.formatMessage({
                  id: "Report Type",
                  defaultMessage: "Report Type"
                })}
              </InputLabel>
              <Select
                value={reportType}
                onChange={handleReportTypeChange}
                inputProps={{
                  name: 'reportType',
                  id: 'report-type',
                }}
              >
                <MenuItem value={ReportType.CurrentMonthPerformance}>
                  {intl.formatMessage({
                    id: "Current Month Live Showroom Performance",
                    defaultMessage: "Current Month Live Showroom Performance"
                  })}
                </MenuItem>
                <MenuItem value={ReportType.Performance}>
                  {intl.formatMessage({
                    id: "Showroom Performance",
                    defaultMessage: "Showroom Performance"
                  })}
                </MenuItem>
                <MenuItem value={ReportType.Walkins}>
                  {intl.formatMessage({
                    id: "Walkins",
                    defaultMessage: "Walkins"
                  })}
                </MenuItem>
                <MenuItem value={ReportType.Deliveries}>
                  {intl.formatMessage({
                    id: "Deliveries",
                    defaultMessage: "Deliveries"
                  })}
                </MenuItem>
                <MenuItem value={ReportType.FutureDeliveries}>
                  {intl.formatMessage({
                    id: "Future Deliveries",
                    defaultMessage: "Future Deliveries"
                  })}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {(reportType !== ReportType.CurrentMonthPerformance && reportType !== ReportType.FutureDeliveries && reportType !== ReportType.Deliveries) && (<Grid item>
            <TextField
              label={intl.formatMessage({
                id: "Start Time",
                defaultMessage: "Start Time"
              })}
              type="date"
              name="startTime"
              value={dateToText(startTime)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleTimeChange}
            />
          </Grid>)}
          {(reportType !== ReportType.CurrentMonthPerformance && reportType !== ReportType.FutureDeliveries && reportType !== ReportType.Deliveries) && (<Grid item>
            <TextField
              label={intl.formatMessage({
                id: "End Time",
                defaultMessage: "End Time"
              })}
              type="date"
              name="endTime"
              value={dateToText(endTime)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleTimeChange}
            />
          </Grid>)}
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={handleGoButton}
            >
              {intl.formatMessage({
                id: "Generate Report",
                defaultMessage: "Generate Report"
              })}
            </Button>
          </Grid>
        </Grid>
      </div >
    );
  }

  return displayReport ?
    reportView(reportType) :
    reportTypeView();
}

export default Reports;
