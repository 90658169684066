import { Map, List } from 'immutable';
import createReducer from '../../CreateReducer';
import { createSelector } from 'reselect';
import * as Types from './Types';
import { RequestStatus } from '../../Types';

const initialState = Map({
  users: List(),
  fetchUserStatus: RequestStatus.None,
  openedSection: Types.SettingSection.General,
  fetchSettingsStatus: RequestStatus.None,
  saveSettingsStatus: RequestStatus.None,
  modified: false,
  settings: Map()
});

const fetchUsersRequest = state => {
  return state.withMutations(state => {
    state.set('fetchUsersStatus', RequestStatus.Requested)
      .set('users', List());
  });
};

const fetchUsersFailure = state => {
  return state.withMutations(state => {
    state.set('fetchUsersStatus', RequestStatus.Failure)
      .set('users', List());
  });
};

const fetchUsersSuccess = (state, {users}) => {
  return state.withMutations(state => {
    state.set('fetchUsersStatus', RequestStatus.Success)
      .set('users', users);
  })
};

const fetchSettingsRequest = state => {
  return state.withMutations(state => {
    state.set('fetchSettingsStatus', RequestStatus.Requested)
      .set('settings', null);
  })
};

const fetchSettingsFailure = state => {
  return state.withMutations(state => {
    state.set('fetchSettingsStatus', RequestStatus.Failure)
      .set('settings', null);
  })
};

const fetchSettingsSuccess = (state, { settings }) => {
  return state.withMutations(state => {
    state.set('fetchSettingsStatus', RequestStatus.Success)
      .set('settings', settings)
      .set('modified', false);
  })
};

const saveSettingsRequest = state => {
  return state.set('saveSettingsStatus', RequestStatus.Requested);
};

const saveSettingsFailure = state => {
  return state.set('saveSettingsStatus', RequestStatus.Failure);
};

const saveSettingsSuccess = (state, {settings}) => {
  return state.withMutations(state => {
    state.set('saveSettingsStatus', RequestStatus.Success)
      .set('settings', settings)
      .set('modified', false);
  })
};

const setOpenedSection = (state, { section }) => {
  return state.set('openedSection', section);
};

const changeSettings = (state, { section, name, value }) => {
  return state.withMutations(state => {
    if (section.length > 0) {
      state.set('modified', true)
        .setIn(['settings', section, name], value);
    }
    else {
      state.set('modified', true)
        .setIn(['settings', name], value);
    }
  });
};

const settingsReducer = createReducer(initialState, {
  [Types.FETCH_USERS_REQUEST]: fetchUsersRequest,
  [Types.FETCH_USERS_FAILURE]: fetchUsersFailure,
  [Types.FETCH_USERS_SUCCESS]: fetchUsersSuccess,

  [Types.FETCH_SETTINGS_REQUEST]: fetchSettingsRequest,
  [Types.FETCH_SETTINGS_FAILURE]: fetchSettingsFailure,
  [Types.FETCH_SETTINGS_SUCCESS]: fetchSettingsSuccess,

  [Types.SAVE_SETTINGS_REQUEST]: saveSettingsRequest,
  [Types.SAVE_SETTINGS_FAILURE]: saveSettingsFailure,
  [Types.SAVE_SETTINGS_SUCCESS]: saveSettingsSuccess,

  [Types.SET_OPENED_SECTION]: setOpenedSection,
  [Types.CHANGE_SETTINGS]: changeSettings
});

const getSettingsReducer = state => {
  return state.getIn(['settings', 'settings']);
};

/**
 * Returns the currently opened section of the settings view.
 * @param {object} state - The global state
 * @returns {string} - The id of the section.
 */
export const openedSection = state => {
  return getSettingsReducer(state).get('openedSection');
};

/**
 * Returns the status of the fetch users operation.
 * @param {object} state - The global state
 * @returns {string} - The current status of the operation.
 */
export const fetchUsersStatus = state => {
  return getSettingsReducer(state).get('fetchUsersStatus');
};

/**
 * Returns the status of the fetch settings operation.
 * @param {object} state - The global state
 * @returns {string} - The current status of the operation.
 */
export const fetchSettingsStatus = state => {
  return getSettingsReducer(state).get('fetchSettingsStatus');
};

/**
 * Returns the status of the save settings operation.
 * @param {object} state - The global state
 * @returns {string} - The current status of the operation.
 */
export const saveSettingsStatus = state => {
  return getSettingsReducer(state).get('saveSettingsStatus');
};

export const getSettings = state => {
  return getSettingsReducer(state).get('settings');
};

/**
 * Returns the current value of the settings.
 * @param {object} state - The global state
 * @returns {object} - The settings object.
 */
export const settings = createSelector(
  [getSettings],
  settings => {
    return settings.toJS();
  });

const getUsers = state => {
  return getSettingsReducer(state).get('users');
};

/**
 * Returns the most recently fetched list of users.
 * @param {object} state - The global state
 * @returns {array} - An array os users
 */
export const users = createSelector(
  [getUsers],
  (users) => {
    return users.toJS();
  });

/**
 * Returns an array of active users.
 * @param {object} state - The global state
 * @returns { array } - An array of active users.
 */
export const activeUsers = createSelector(
  [getUsers],
  (users) => {
    return users.toJS().filter(user => {
      return user.active;
    });
  });

/**
 * Returns true of the settings have been modified since the last fetch or save operation.
 * @param {object} state - The global state
 * @returns {bool} - True if the settings have been changed, false otherwise.
 */
export const isModified = state => {
  return getSettingsReducer(state).get('modified');
};

export default settingsReducer;
