import axios from 'axios';

/**
 * login sends a request to the server to authenticate the user. If successful,
 * it returns a token to access the server, user information and a refresh
 * token used to refresh the main token without the need to ask user
 * credentials each time.
 * @param {string} emailAddress - The user email address
 * @param {string} password - The user password
 * @param {object} cancelSource - Object to cancel the request.
 */
export const login = async (emailAddress, password, cancelSource) => {
  const response = await axios.post("/login", { emailAddress, password, apiVersion: '2' }, { cancelToken: cancelSource ? cancelSource.token : undefined });

  // eslint-disable-next-line
  axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.result.token;

  return response.data.result;
}

/**
 * refresh send a request to the server to refresh the token used to access
 * it. The token passed as parameter must be valid. On success, it returns
 * a new token, user information and a new refresh token.
 * @param {string} token - The refresh token for the user.
 * @param {object} cancelSource - Object used to cancel the request.
 */
export const refresh = async (token, cancelSource) => {
  const response = await axios.post("/refreshlogin", {}, {
    'headers': { 'Authorization': token },
    cancelToken: cancelSource ? cancelSource.token : undefined
  });

  // eslint-disable-next-line
  axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.result.token;
  return response.data.result;
}