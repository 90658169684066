import * as Types from './Types';
import Axios from 'axios';

export const fetchSalesmanAvailabilityRequest = () => ({
  type: Types.FETCH_SALESMAN_AVAILABILITY_REQUEST
});

export const fetchSalesmanAvailabilityFailure = (error) => ({
  type: Types.FETCH_SALESMAN_AVAILABILITY_FAILURE,
  error
});

export const fetchSalesmanAvailabilitySuccess = (firstSalesman, availableSalesmen) => ({
  type: Types.FETCH_SALESMAN_AVAILABILITY_SUCCESS,
  firstSalesman,
  availableSalesmen
});

export const fetchSalesmanAvailability = () => {
  return async function (dispatch) {
    try {
      dispatch(fetchSalesmanAvailabilityRequest());
      const response = await Axios.get('/settings/salesmanavailability');
      dispatch(fetchSalesmanAvailabilitySuccess(response.data.result.firstSalesman, response.data.result.availableSalesmen));
      return response.data.result;
    }
    catch (error) {
      dispatch(fetchSalesmanAvailabilityFailure(error));
      throw error;
    }
  }
}

export const setSettingsDialogVisibility = visible => ({
  type: Types.SET_SETTINGS_DIALOG_VISIBILITY,
  visible
});

export const modifyFirstSalesman = salesmanID => ({
  type: Types.MODIFY_FIRST_SALESMAN,
  salesmanID
});

export const modifyAvailableSalesmen = salesmen => ({
  type: Types.MODIFY_AVAILABLE_SALESMEN,
  salesmen
});

export const saveSalesmanAvailabilityRequest = () => ({
  type: Types.SAVE_SALESMAN_AVAILABILITY_REQUEST
});

export const saveSalesmanAvailibilityFailure = error => ({
  type: Types.SAVE_SALESMAN_AVAILABILITY_FAILURE,
  error
});

export const saveSalesmanAvailabilitySuccess = (firstSalesman, availableSalesmen) => ({
  type: Types.SAVE_SALESMAN_AVAILABILITY_SUCCESS,
  firstSalesman,
  availableSalesmen
});

export const saveSalesmanAvailability = (firstSalesman, availableSalesmen) => {
  return async function (dispatch) {
    try {
      dispatch(saveSalesmanAvailabilityRequest());
      const newValue = {
        firstSalesman,
        availableSalesmen
      };
      const response = await Axios.put(
        "/settings/salesmanavailability",
        newValue
      );
      dispatch(saveSalesmanAvailabilitySuccess(firstSalesman, availableSalesmen));
      return response.data.result;
    }
    catch (error) {
      dispatch(saveSalesmanAvailibilityFailure());
      throw error;
    }
  }
}
