import React from 'react';
import { Record } from 'immutable';
import InternetIcon from '@material-ui/icons/ContactMail';
import PhoneIcon from '@material-ui/icons/ContactPhone';
import WalkinIcon from '@material-ui/icons/DirectionsWalk';
import ServiceIcon from '@material-ui/icons/Build';
import InternetResponseIcon from '@material-ui/icons/Email';
import CommentIcon from '@material-ui/icons/Comment';
import BebackIcon from '@material-ui/icons/Loop';
import FollowUpIcon from '@material-ui/icons/NoteAdd';
import DeliveryIcon from '../Icons/Delivery';
import ReconnectIcon from '@material-ui/icons/Loop';
import Tooltip from '@material-ui/core/Tooltip';
import { defineMessages } from 'react-intl';

export const VisitType = {
  Walkin: 1,
  Phone: 2,
  Internet: 3,
  Beback: 4,
  FollowUp: 5,
  Service: 6,
  InternetResponse: 7,
  ManagerComment: 8,
  Delivery: 9,
  Reconnect: 10
};

export const PurchaseType = {
  New: 1,
  Lease: 2,
  Used: 3
};

const messages = defineMessages({
  walkin: {
    id: "Walkins",
    defaultMessage: "Walkins"
  },
  phone: {
    id: "Phone",
    defaultMessage: "Phone"
  },
  internet: {
    id: "Internet",
    defaultMessage: "Internet"
  },
  beback: {
    id: "Beback",
    defaultMessage: "Beback"
  },
  followUp: {
    id: "Follow Up",
    defaultMessage: "Follow Up"
  },
  service: {
    id: "Service",
    defaultMessage: "Service"
  },
  internetResponse: {
    id: 'Internet Response',
    defaultMessage: "Internet Response"
  },
  reconnect: {
    id: "Reconnect",
    defaultMessage: "Reconnect"
  },
  managerComment: {
    id: "Manager Comment",
    defaultMessage: "Manager Comment"
  },
  delivery: {
    id: "Delivery",
    defaultMessage: "Delivery"
  },
  purchaseTypeNew: {
    id: "New",
    defaultMessage: "New"
  },
  purchaseTypeLease: {
    id: "Lease",
    defaultMessage: "Lease"
  },
  purchaseTypeUsed: {
    id: "Used",
    defaultMessage: "Used"
  }
})

export const visitTypeToString = (intl, visitType) => {
  switch (visitType) {
    case VisitType.Walkin: return intl.formatMessage(messages.walkin);
    case VisitType.Phone: return intl.formatMessage(messages.phone);
    case VisitType.Internet: return intl.formatMessage(messages.internet);
    case VisitType.Beback: return intl.formatMessage(messages.beback);
    case VisitType.FollowUp: return intl.formatMessage(messages.followUp);
    case VisitType.Service: return intl.formatMessage(messages.service);
    case VisitType.InternetResponse: return intl.formatMessage(messages.internetResponse);
    case VisitType.ManagerComment: return intl.formatMessage(messages.managerComment);
    case VisitType.Delivery: return intl.formatMessage(messages.delivery);
    case VisitType.Reconnect: return intl.formatMessage(messages.reconnect);
    default: return "";
  }
}

const visitTypeIcon = (visitType) => {
  switch (visitType) {
    case VisitType.Walkin: return (<WalkinIcon />);
    case VisitType.Phone: return (<PhoneIcon />);
    case VisitType.Internet: return (<InternetIcon />);
    case VisitType.Service: return (<ServiceIcon />);
    case VisitType.Beback: return (<BebackIcon />);
    case VisitType.FollowUp: return (<FollowUpIcon />);
    case VisitType.InternetResponse: return (<InternetResponseIcon />);
    case VisitType.ManagerComment: return (<CommentIcon />);
    case VisitType.Delivery: return (<DeliveryIcon />);
    case VisitType.Reconnect: return (<ReconnectIcon />);

    default: return null;
  }
}

export const visitTypeToIcon = (visitType, intl) => {
  if (intl) {
    return (
      <Tooltip
        enterDelay={500} leaveDelay={100}
        title={visitTypeToString(intl, visitType)}
      >
        {visitTypeIcon(visitType)}
      </Tooltip>
    );
  }
  else {
    return visitTypeIcon(visitType);
  }
}

export const purchaseTypeToString = (intl, purchaseType) => {
  switch (purchaseType) {
    case PurchaseType.New: return intl.formatMessage(messages.purchaseTypeNew);
    case PurchaseType.Lease: return intl.formatMessage(messages.purchaseTypeLease);
    case PurchaseType.Used: return intl.formatMessage(messages.purchaseTypeUsed);
    default: return "";
  }
}

const Visit = Record({
  comments: '',
  communicationCompleted: false,
  createdBy: null,
  date: 0,
  depositAmount: 0,
  lastSalesmanNotification: 0,
  nextScheduledCommunication: 0,
  purchaseType: PurchaseType.New,
  sold: false,
  takeOver: false,
  takeOverBy: null,
  testDrive: false,
  type: VisitType.Walkin,
  vehicle: '',
  vipPresentation: false,
  email: '',
  internetSource: '',
  stockNumber: '',
  order: false,
  expectedDeliveryDate: 0,
  delivered: false,
  reservation: false
});

export const validateVisit = (visit) => {
  const fields = [];

  if ((visit.type === VisitType.FollowUp || visit.type === VisitType.ManagerComment) && visit.comments.trim().length <= 0) {
    fields.push("comments");
  }

  if (visit.type === VisitType.Reconnect && visit.comments.trim().length <= 0) {
    fields.push("comments");
  }

  if (visit.communicationCompleted && !visit.sold && visit.comments.trim().length <= 0) {
    fields.push("comments");
  }

  if (visit.vehicle.trim().length <= 0) {
    fields.push("vehicle");
  }

  if (visit.sold && visit.reservation) {
    fields.push("sold");
    fields.push("reservation");
  }

  return fields;
};


export default Visit;
