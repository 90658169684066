/**
 * Valid status used ofor server communication.
 * @type {{None: string, Requested: string, Success: string, Failure: string}}
 */
export const RequestStatus = {
  None: 'None',
  Requested: 'Requested',
  Success: 'Success',
  Failure: 'Failure'
};
