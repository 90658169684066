import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

import * as API from '../../backend';

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    width: '100%',
    height: '100%'
  },
  actionDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1)
  },
  inputField: {
    width: '250px',
    margin: theme.spacing(1)
  },
  formDiv: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const VehicleEditor = ({ initialVehicle, onSave, onCancel, ...others }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [vehicle, setVehicle] = useState(initialVehicle);

  const handleChange = event => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === 'year') {
      if (value === '') {
        value = 0;
      } else {
        value = parseInt(value, 10);
      }
    }

    setVehicle(Object.assign({}, vehicle, { [name]: value }))
  }

  const handleSave = async () => {
    await API.saveVehicle(vehicle);

    if (onSave) {
      onSave(vehicle);
    }
  }

  return (
    <div className={classes.mainDiv} {...others}>
      <div className={classes.actionDiv}>
        <IconButton onClick={onCancel}>
          <CancelIcon
            color="secondary"
          />
        </IconButton>
        <IconButton onClick={handleSave}>
          <SaveIcon
            color="primary"
          />
        </IconButton>
      </div>
      <div className={classes.formDiv}>
        <TextField
          className={classes.inputField}
          name="description"
          value={vehicle.description}
          onChange={handleChange}
          label={intl.formatMessage({
            id: "Description",
            defaultMessage: "Description"
          })}
        />
        <TextField
          className={classes.inputField}
          name="brand"
          value={vehicle.brand}
          onChange={handleChange}
          label={intl.formatMessage({
            id: "Brand",
            defaultMessage: "Brand"
          })}
        />
        <TextField
          className={classes.inputField}
          name="model"
          value={vehicle.model}
          onChange={handleChange}
          label={intl.formatMessage({
            id: "Model",
            defaultMessage: "Model"
          })}
        />
        <TextField
          className={classes.inputField}
          name="color"
          value={vehicle.color}
          onChange={handleChange}
          label={intl.formatMessage({
            id: "Color",
            defaultMessage: "Color"
          })}
        />
        <TextField
          className={classes.inputField}
          name="year"
          value={vehicle.year || ''}
          onChange={handleChange}
          label={intl.formatMessage({
            id: "Year",
            defaultMessage: "Year"
          })}
        />
        <TextField
          className={classes.inputField}
          name="serialNumber"
          value={vehicle.serialNumber}
          onChange={handleChange}
          label={intl.formatMessage({
            id: "Serial NUmber",
            defaultMessage: "Serial Number"
          })}
        />
        <TextField
          className={classes.inputField}
          name="plateNumber"
          value={vehicle.plateNumber}
          onChange={handleChange}
          label={intl.formatMessage({
            id: "Plate Number",
            defaultMessage: "Plate Number"
          })}
        />
      </div>
    </div>
  )
}

VehicleEditor.propTypes = {
  initialVehicle: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
}

export default VehicleEditor;
