import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import MainToolbar from "./MainToolbar";
import CustomerManager from "../CustomerManager/CustomerManager";
import Dashboard from '../Dashboard/Dashboard';
import Settings from "../Settings/Settings";
import { Loaner } from "../Features";
import { useSelector } from "react-redux";

import { getMainPage } from "../DataStore/MainPage/Reducer";
import { PageId } from "../DataStore/MainPage/Types";
import Reports from "../Reports/Reports";
import ServiceReports from "../Features/ServiceReports/Reports";
import StoragePage from '../Features/Storage/StoragePage';
import { Vehicles, Reservations, NewStockFileController } from '../Features';

import { CustomerProvider, UserProvider, EventProvider } from "../Providers";
import ModelController from "../Features/Models/ModelController";
import ColorController from "../Features/Colors/ColorController";
import AccountingMainPage from "../Features/Accounting/AccountingMainPage";

const useStyles = makeStyles(() => ({
  rootDiv: {
    width: "100%",
    height: "100%"
  },
  flexContainer: {
    display: "flex",
    direction: "column",
    flexGrow: 1,
    width: "100%",
    height: "100%",
    paddingTop: "64px",
    alignContent: "stretch",
    boxSizing: "border-box"
  },
  mainContent: {
    //position: "relative",
    //top: "64px", // We need to offset the main content so it does not appear under the main toolbar.
    //bottom: "0px"
    width: "100%"
  }
}));

const MainPage = ({ fetchUsers, ...others }) => {
  const mainPage = useSelector(getMainPage);

  const customerManager = () => {
    return <CustomerManager />;
  }

  const reports = () => {
    return <Reports />;
  }

  const settings = () => {
    return <Settings />;
  }

  const dashboard = () => {
    return <Dashboard />
  }

  const loaner = () => {
    return <Loaner />
  }

  const storage = () => {
    return <StoragePage />
  }

  const vehicles = () => {
    return <Vehicles />
  }

  const reservations = () => {
    return <Reservations />
  }

  const serviceReports = () => {
    return <ServiceReports />
  }

  const uploadStockFile = () => {
    return <NewStockFileController />
  }

  const modelManagement = () => {
    return <ModelController />
  }

  const colorManagement = () => {
    return <ColorController />
  }

  const accounting = () => {
    return <AccountingMainPage />
  }

  const classes = useStyles();

  let content = null;

  switch (mainPage) {
    case PageId.Dashboard:
      content = dashboard();
      break;

    case PageId.Customers:
      content = customerManager();
      break;
    case PageId.Reports:
      content = reports();
      break;
    case PageId.Settings:
      content = settings();
      break;
    case PageId.Loaner:
      content = loaner();
      break;
    case PageId.Storage:
      content = storage();
      break;
    case PageId.Vehicles:
      content = vehicles();
      break;
    case PageId.Reservations:
      content = reservations();
      break;
    case PageId.ServiceReports:
      content = serviceReports();
      break;
    case PageId.UploadStockFile:
      content = uploadStockFile();
      break;
    case PageId.ModelManagement:
      content = modelManagement();
      break;
    case PageId.ColorManagement:
      content = colorManagement();
      break;
    case PageId.Accounting:
      content = accounting();
      break;
    default:
      content = null;
  }

  return (
    <EventProvider>
      <CustomerProvider>
        <UserProvider>
          <div className={classes.rootDiv} {...others}>
            <MainToolbar />
            <div className={classes.flexContainer}>
              <div className={classes.mainContent}>{content}</div>
            </div>
          </div>
        </UserProvider>
      </CustomerProvider>
    </EventProvider>
  );
}

MainPage.propTypes = {
  fetchUsers: PropTypes.func
};

export default MainPage;
