import { combineReducers } from 'redux-immutable';
import createReducer from '../../CreateReducer';
import * as Types from './Types';
import { Map, List } from 'immutable';
import { RequestStatus } from '../../Types';


const userListInitialState = Map({
  fetchUsersStatus: RequestStatus.None,
  users: List()
});

const userEditorInitialState = Map({
  editedUser: null,
  modified: false,
  saveUserStatus: RequestStatus.None
});

const saveUserRequest = (state) => {
  return state.set('saveUserStatus', RequestStatus.Requested);
};

const saveUserSuccess = (state) => {
  return state.set('saveUserStatus', RequestStatus.Success);
};

const saveUserFailure = (state) => {
  return state.set('saveUserStatus', RequestStatus.Failure);
};

const fetchUsersRequest = (state) => {
  return state.withMutations(state => {
    state.set('fetchUsersStatus', RequestStatus.Requested)
      .set('users', List());
  });
};

const fetchUsersSuccess = (state, {users}) => {
  return state.withMutations(state => {
    state.set('fetchUsersStatus', RequestStatus.Success)
      .set('users', users);
  });
};

const fetchUsersFailure = (state) => {
  return state.withMutations(state => {
    state.set('fetchUsersStatus', RequestStatus.Failure)
      .set('users', List());
  });
};

const editUser = (state, {user}) => {
  return state.withMutations(state => {
    state.set('editedUser', user)
      .set('editedUserModified', false);
  });
};

const changeEditedUser = (state, { name, value }) => {
  return state.withMutations(state => {
    state.setIn(['editedUser', name], value)
      .set('editedUserModified', true);
  });
};

const updateUser = (state, { index, user }) => {
  if (index) {
    return state.setIn(['users', index], user);
  }
  else {
    const newList = state.get('users').push(user);
    return state.set('users', newList);
  }
};

const userListReducer = createReducer(userListInitialState, {
  [Types.FETCH_USERS_REQUEST]: fetchUsersRequest,
  [Types.FETCH_USERS_SUCCESS]: fetchUsersSuccess,
  [Types.FETCH_USERS_FAILURE]: fetchUsersFailure,
  [Types.UPDATE_USER]: updateUser
});

const userEditorReducer = createReducer(userEditorInitialState, {
  [Types.SAVE_USER_REQUEST]: saveUserRequest,
  [Types.SAVE_USER_SUCCESS]: saveUserSuccess,
  [Types.SAVE_USER_FAILURE]: saveUserFailure,
  [Types.SET_EDITED_USER]: editUser,
  [Types.CHANGE_EDITED_USER]: changeEditedUser
});

const usersReducer = combineReducers({
  userList: userListReducer,
  userEditor: userEditorReducer
});

const getUserListReducerState = state => {
  return state.getIn(['settings', 'users', 'userList']);
};

const getUserEditorReducerState = state => {
  return state.getIn(['settings', 'users', 'userEditor']);
};

/**
 * Returns an array of users, loaded from the server.
 * @param {object} state - The global state
 * @returns {object} - An immutable List of User objects
 */
export function users(state) {
  return getUserListReducerState(state).get('users');
}

/**
 * Returns the index of the specified user or undefined if not found.
 * @param {object} state - The global state
 * @param {Map }user - The user to find
 * @returns {number} - The index of the user, undefined is not found.
 */
export function indexOfUser(state, user) {
  return users(state).findIndex(u => {
    return user.get('id') === u.get('id');
  });
}

/**
 * Returns the status of the last fetch operation. If there's been no fetch, returns null.
 * @param {object} state - The global state
 * @returns {string}
 */
export function fetchUsersStatus(state) {
  return getUserListReducerState(state).get('usersFetchStatus');
}

/**
 * Returns the currently edited user.
 * @param {object} state - The global state
 * @returns {object} - The currently edited object. null if there is no currently edited user.
 */
export function editedUser(state) {
  return getUserEditorReducerState(state).get('editedUser');
}

/**
 * Returns true is the edited user has been modified since the last save operation.
 * @param {object} state - The global state
 * @returns {bool} - The edited user current status.
 */
export function isEditedUserModified(state) {
  return getUserEditorReducerState(state).get('editedUserModified');
}

/**
 * Returns the status of the last save user operation.
 * @param {object} state - The global state
 * @returns {string} - The status of the last save operation.
 */
export function saveUserStatus(state) {
  return getUserEditorReducerState(state).get('saveUserStatus');
}

export default usersReducer;
