import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import { fetchWalkinsData } from '../../backend';
import WalkinReportUI from './WalkinReportUI';
import ReportHeader from '../ReportHeader';
import { useSnackbar } from 'notistack';

const messages = defineMessages({
  errorLoadingData: {
    id: "Error loading report data.",
    defaultMessage: "Error loading report data"
  }
});

const WalkinsReport = ({ startTime, endTime, ...others }) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [models, setModels] = useState(null);
  const [total, setTotal] = useState(null);

  const customerList = (data) => {
    return data.customers.reduce((customers, customer) => {
      customers[customer.id] = customer;
      return customers;
    }, {});
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchWalkinsData(startTime, endTime);
        setCustomers(customerList(data));
        setModels(data.reportData.models);
        setTotal(data.reportData.total);
        setLoaded(true);
      }
      catch (error) {
        enqueueSnackbar(intl.formatMessage(messages.errorLoadingData, { variant: 'error' }));
      }
    }

    loadData();
  }, [intl, enqueueSnackbar, startTime, endTime]);


  return loaded ?
    (
      <div {...others}>
        <ReportHeader
          title={intl.formatMessage({
            id: "Walkins",
            defaultMessage: "Walkins"
          })}
          startTime={startTime}
          endTime={endTime}
        />
        <WalkinReportUI
          customers={customers}
          models={models}
          total={total}
        />
      </div>
    ) : null;
}

WalkinsReport.propTypes = {
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date),
  showMessage: PropTypes.func
};

export default WalkinsReport;
