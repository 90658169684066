import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        width: '20em'
    },
    line: {
        marginTop: theme.spacing(1)
    }
}));

const ModelViewer = ({ model, ...others }) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Paper className={classes.paper} {...others}>
            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {intl.formatMessage({
                        id: "Model Code",
                        defaultMessage: "Model Code"
                    })}
                </Typography>
                <Typography>
                    {model.code}
                </Typography>
            </div>
            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {intl.formatMessage({
                        id: "Description",
                        defaultMessage: "Description"
                    })}
                </Typography>
                <Typography>
                    {model.description}
                </Typography>
            </div>
            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {intl.formatMessage({
                        id: "Trim",
                        defaultMessage: "Trim"
                    })}
                </Typography>
                <Typography>
                    {model.trim}
                </Typography>
            </div>
            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {intl.formatMessage({
                        id: "Doors",
                        defaultMessage: "Doors"
                    })}
                </Typography>
                <Typography>
                    {model.doors}
                </Typography>
            </div>
            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {intl.formatMessage({
                        id: "Transmission",
                        defaultMessage: "Transmission"
                    })}
                </Typography>
                <Typography>
                    {model.transmission}
                </Typography>
            </div>
        </Paper>
    )
}

ModelViewer.propTypes = {
    model: PropTypes.object.isRequired
}

export default ModelViewer;
