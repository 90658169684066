import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { DepartmentIcon } from '../../Icons';
import { Departments } from '../../Models/User';

import { setDepartment as store_SetDepartment } from '../../DataStore';

const DepartmentButton = () => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const intl = useIntl();
  const dispatch = useDispatch();

  const setDepartment = department => {
    setMenuAnchor(null);
    dispatch(store_SetDepartment(department));
  }

  return (
    <React.Fragment>
      <IconButton
        color="inherit"
        aria-label="Department"
        onClick={(event) => setMenuAnchor(event.currentTarget)}
      >
        <DepartmentIcon />
      </IconButton>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
      >
        <MenuItem onClick={() => { setDepartment(Departments.Sales) }}>
          {intl.formatMessage({
            id: "Sales Department",
            defaultMessage: "Sales Department"
          })}
        </MenuItem>
        <MenuItem onClick={() => { setDepartment(Departments.Service) }}>
          {intl.formatMessage({
            id: "Service Department",
            defaultMessage: "Service Department"
          })}
        </MenuItem>
        <MenuItem onClick={() => { setDepartment(Departments.Accounting) }}>
          {intl.formatMessage({
            id: "Accounting Department",
            defaultMessage: "Accounting Department"
          })}
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default DepartmentButton;
