import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { getFirstSalesman, getAvailableSalesmen } from '../DataStore/SalesmanAvailability/Reducer';
import { fetchSalesmanAvailability, setSettingsDialogVisibility, modifyFirstSalesman, modifyAvailableSalesmen, saveSalesmanAvailability } from '../DataStore/SalesmanAvailability/Actions';
import { getSalesmen } from '../DataStore/Users/Reducer';
import { useSnackbar } from 'notistack';

const messages = defineMessages({
  saveError: {
    id: "Error saving changes.",
    defaultMessage: "Error saving changes."
  }
});

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const SalesmanAvailability = ({ open, ...others }) => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const firstSalesman = useSelector(getFirstSalesman);
  const availableSalesmen = useSelector(getAvailableSalesmen);
  const salesmen = useSelector(getSalesmen);

  useEffect(() => {
    dispatch(fetchSalesmanAvailability());
  }, [dispatch]);

  const handleCancel = () => {
    dispatch(setSettingsDialogVisibility(false));
  }

  const handleOk = () => {
    dispatch(saveSalesmanAvailability(firstSalesman, availableSalesmen))
      .then(() => {
        dispatch(setSettingsDialogVisibility(false));
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage(messages.saveError), { variant: 'error ' });
      });
  }

  const handleAvailableSalesmenChange = (salesmanID) => {
    const salesmen = availableSalesmen.slice();
    const index = salesmen.indexOf(salesmanID);

    if (index >= 0) {
      salesmen.splice(index, 1);
    }
    else {
      salesmen.push(salesmanID);
    }

    dispatch(modifyAvailableSalesmen(salesmen));
  }

  const selectOptions = salesmen.map(salesman => {
    return (
      <option key={salesman.id} value={salesman.id}>{salesman.firstName + " " + salesman.lastName}</option>
    )
  })

  const listItems = salesmen.map(salesman => {
    return (
      <ListItem
        key={salesman.id}
        role={undefined}
        dense
        button
        onClick={() => handleAvailableSalesmenChange(salesman.id)}
      >
        <Checkbox
          checked={availableSalesmen.indexOf(salesman.id) >= 0}
          disableRipple
        />
        <ListItemText primary={salesman.firstName + ' ' + salesman.lastName} />
      </ListItem>
    )
  })

  return (
    <Dialog open={open} {...others}>
      <DialogTitle>
        {intl.formatMessage({
          id: "Availability",
          defaultMessage: "Availability"
        })}
      </DialogTitle>
      <DialogContent>
        <form className={classes.form}>
          <FormControl margin={'dense'} className={classes.formControl}>
            <InputLabel margin={'dense'} htmlFor="salesmanavailability-firstsalesman">
              {intl.formatMessage({
                id: "First Salesman",
                defaultMessage: "First Salesman"
              })}
            </InputLabel>
            <Select
              native
              value={firstSalesman}
              input={<Input id={'salesmanavailability-firstsalesman'} />}
              onChange={(event) => { dispatch(modifyFirstSalesman(event.target.value)) }}
            >
              {selectOptions}
            </Select>
          </FormControl>
          <List dense={true} disablePadding={true}>
            {listItems}
          </List>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>
          {intl.formatMessage({
            id: "Cancel",
            defaultMessage: "Cancel"
          })}
        </Button>
        <Button onClick={handleOk}>
          {intl.formatMessage({
            id: "Ok",
            defaultMessage: "Ok"
          })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SalesmanAvailability.propTypes = {
  open: PropTypes.bool
};

export default SalesmanAvailability;
