import { defineMessages } from 'react-intl';

const messages = defineMessages({
  wheelTypeNone: {
    id: 'No Wheel',
    defaultMessage: 'No Wheel'
  },
  wheelTypeSteel: {
    id: 'Steel Wheels',
    defaultMessage: 'Steel Wheels'
  },
  wheelTypeAlloy: {
    id: 'Alloy Wheels',
    defaultMessage: 'Alloy Wheels'
  },
})

/**
 * wheelTypeToString returns the string representation of the specified
 * wheel type.
 * @param {object} intl - Intl instance.
 * @param {Number} wheelType - The wheel type.
 */
export const wheelTypeToString = (intl, wheelType) => {
  switch (wheelType) {
    case 2: return intl.formatMessage(messages.wheelTypeSteel);
    case 3: return intl.formatMessage(messages.wheelTypeAlloy);
    default: return intl.formatMessage(messages.wheelTypeNone);
  }
}
