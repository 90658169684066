import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";

import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import SalesmanSelect from "./SalesmanSelect";

import VisitList from "./VisitList";
import VisitEditor from "./VisitEditor";
import { loadCustomer, newCustomer, newVisit, saveCustomer, saveVisit } from "../backend";
import { validateCustomer, lastVisit } from '../Models/Customer';

import { injectIntl, defineMessages } from "react-intl";
import { getUsersArray } from "../DataStore/Users/Reducer";

import { showMessageByID } from "../DataStore/Message/Actions";
import { MessageType } from "../DataStore/Message/Types";

import { getUser } from "../DataStore/Login/Reducer";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  rootDiv: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  actionDiv: {
    flexGrow: 0,
    flexShrink: 0,
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  },
  nextComm: {
    marginRight: 'auto'
  },
  nameDiv: {
    flexGrow: 0,
    flexShrink: 0,
    display: "flex",
    flexWrap: "wrap",
    width: "100%"
  },
  phoneDiv: {
    flexGrow: 0,
    flexShrink: 0,
    display: "flex",
    flexWrap: "wrap",
    width: "100%"
  },
  addVisitDiv: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  otherDiv: {
    flexGrow: 0,
    flexShrink: 0,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    marginBottom: "32px"
  },
  inputField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "300px",
    maxWidth: "100%"
  },
  visitDiv: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "stretch",
    width: "100%",
    overflow: "auto"
  }
});

const messages = defineMessages({
  errorAttachLicense: {
    id: 'Error while saving driver license.',
    defaultMessage: 'Error while saving driver license.'
  },
  saveCustomerError: {
    id: 'Error while saving customer',
    defaultMessage: "Error while saving customer."
  },
  invalidCustomerError: {
    id: 'The customer is invalid. Please correct invalid fields.',
    defaultMessage: 'The customer is invalid. Please correct invalid fields.'
  },
  getNewVisitError: {
    id: 'Error while creating new visit.',
    defaultMessage: 'Error while creating new visit.'
  }
})

class CustomerEditor extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      customer: null,
      validVisitTypes: [],
      availableVehicles: [],
      modified: false,
      invalidFields: [],
      validationStarted: false,
      loading: true,
      saving: false,
      editedVisitIndex: null,
      editedVisit: null,
      visitModified: false
    };

    this.visitEditor = null;

    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleAddVisit = this.handleAddVisit.bind(this);
    this.handleEditVisit = this.handleEditVisit.bind(this);
    this.visitEditorMounted = this.visitEditorMounted.bind(this);
    this.handleVisitModified = this.handleVisitModified.bind(this);
  }

  componentDidMount() {
    const { customerID } = this.props;
    this.getCustomer(customerID);
  }

  componentDidUpdate(prevProps) {
    if (this.props.customerID !== prevProps.customerID) {
      this.getCustomer(this.props.customerID);
    }
  }

  visitEditorMounted(visitEditor) {
    this.visitEditor = visitEditor;
  }

  getCustomer(customerID) {
    if (customerID !== "") {
      this.loadCustomer(customerID);
    } else {
      this.newCustomer();
    }
  }

  loadCustomer(customerID) {
    this.setState({ loading: true });

    loadCustomer(customerID)
      .then(result => {
        this.setState({
          loading: false,
          customer: result.customer,
          availableVehicles: result.availableVehicles,
          validVisitTypes: result.validVisitTypes
        });
      })
      .catch(() => {
        this.setState({ customer: null, loading: false });
      });
  }

  newCustomer() {
    this.setState({ loading: true });

    newCustomer()
      .then(result => {
        this.setState({
          loading: false,
          customer: result.customer,
          availableVehicles: result.availableVehicles,
          validVisitTypes: result.validVisitTypes,
          editedVisitIndex: 0,
          editedVisit: result.customer.visits[0]
        });
      })
      .catch(() => {
        this.setState({ customer: null, loading: false });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const customer = Object.assign({}, this.state.customer, { [name]: value });
    this.setState({
      customer,
      modified: true
    });

    if (this.state.validationStarted) {
      this.validate();
    }
  }

  handleCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  handleVisitModified() {
    this.setState({ visitModified: true });
  }

  validate() {
    const invalidFields = validateCustomer(this.state.customer);
    this.setState({
      invalidFields,
      validationStarted: true
    })

    return invalidFields;
  }

  async handleSave() {
    const { customer, modified, editedVisitIndex } = this.state;

    const { saving } = this.state;
    if (saving) {
      return;
    }

    this.setState({ saving: true });

    const { showMessage } = this.props;

    let invalidFields = [];

    if (modified || !customer.id) {
      invalidFields = this.validate();
    }

    if (invalidFields.length > 0) {
      this.setState({
        saving: false
      });
      return;
    }

    if (!customer.id) {
      const editedVisit = this.visitEditor.getVisit();
      const editedDate = this.visitEditor.getEditedDate();

      if (editedDate !== null) {
        editedVisit.date = editedDate;
      }

      if (!this.visitEditor.isValid()) {
        this.setState({
          saving: false
        });
        return;
      }

      const newCustomer = Object.assign({}, customer);
      newCustomer.visits = [editedVisit];

      try {
        await saveCustomer(newCustomer);
      }
      catch (error) {
        showMessage(messages.saveCustomerError, MessageType.Error);
      }
    } else {
      try {
        if (modified) {
          await saveCustomer(customer);
        }

        const editedVisit = this.visitEditor.getVisit();
        const visitModified = this.visitEditor.isModified(editedVisit);
        const editedDate = this.visitEditor.getEditedDate();

        if (visitModified) {
          if (!this.visitEditor.isValid()) {
            this.setState({
              saving: false
            });
            return;
          }

          const email = {
            to: customer.email,
            subject: this.visitEditor.getEmailSubject(),
            content: this.visitEditor.getEmailContent()
          };

          if (editedDate !== null) {
            editedVisit.date = editedDate;
          }

          await saveVisit(customer.id, editedVisitIndex, editedVisit, email, editedVisit !== null ? true : undefined);
        }
      }
      catch (error) {
        if (error.response && error.response.status >= 400 && error.response.status < 500) {
          showMessage(messages.invalidCustomerError, MessageType.Error);
          this.setState({ saving: false });
          return;
        }
        showMessage(messages.saveCustomerError, MessageType.Error);
      }
    }

    if (this.props.onSaved) {
      this.props.onSaved();
    }
  }

  handleAddVisit() {
    const { showMessage } = this.props;
    const { customer } = this.state;

    newVisit(customer.id)
      .then(result => {
        this.setState({
          editedVisitIndex: -1,
          editedVisit: result.visit,
          validVisitTypes: result.validVisitTypes
        })
      })
      .catch(() => {
        showMessage(messages.getNewVisitError, MessageType.Error);
      })
  }

  handleEditVisit(index, visit) {
    this.setState({
      editedVisit: visit,
      editedVisitIndex: index
    });
  }

  isInvalid(fieldName) {
    const { invalidFields } = this.state;
    return invalidFields.indexOf(fieldName) >= 0;
  }

  render() {
    const { classes, intl } = this.props;
    const { loading, customer, modified, visitModified, editedVisit, validVisitTypes, availableVehicles } = this.state;
    const editingVisit = editedVisit !== null;

    if (loading) {
      return (<div></div>);
    } else if (customer !== null) {
      const visit = lastVisit(customer);

      return (
        <div className={classes.rootDiv}>
          <div className={classes.actionDiv}>
            {visit && !visit.sold && visit.nextScheduledCommunication > 0 && (
              <Typography className={classes.nextComm}>
                {intl.formatMessage({ id: 'Next Scheduled Communication: {commDate, date, medium}', defaultMessage: 'Next Scheduled Communication: {commDate, date, medium}' }, { commDate: visit.nextScheduledCommunication * 1000 })}
              </Typography>)}
            <IconButton
              aria-label="Cancel"
              onClick={this.handleCancel}
              color="primary"
            >
              <CancelIcon />
            </IconButton>
            <IconButton
              aria-label="Save"
              onClick={this.handleSave}
              disabled={!(modified || visitModified)}
              color="primary"
            >
              <SaveIcon />
            </IconButton>
          </div>
          <div className={classes.nameDiv}>
            <TextField
              className={classes.inputField}
              name="lastName"
              label={intl.formatMessage({ id: 'lastname', defaultMessage: 'Last Name' })}
              value={customer.lastName}
              margin="normal"
              onChange={this.handleChange}
              error={this.isInvalid("lastName")}
            />
            <TextField
              className={classes.inputField}
              name="firstName"
              label={intl.formatMessage({ id: "firstname", defaultMessage: 'First Name' })}
              value={customer.firstName}
              margin="normal"
              onChange={this.handleChange}
              error={this.isInvalid("firstName")}
            />
            <TextField
              className={classes.inputField}
              type="email"
              name="email"
              label={intl.formatMessage({ id: "emailaddress", defaultMessage: 'Email Address' })}
              value={customer.email}
              margin="normal"
              onChange={this.handleChange}
              error={this.isInvalid("email")}
            />
          </div>
          <div className={classes.phoneDiv}>
            <TextField
              className={classes.inputField}
              name="homePhone"
              label={intl.formatMessage({ id: "homephone", defaultMessage: 'Home Phone' })}
              value={customer.homePhone}
              margin="normal"
              onChange={this.handleChange}
              error={this.isInvalid("homePhone")}
            />
            <TextField
              className={classes.inputField}
              name="cellPhone"
              label={intl.formatMessage({ id: "cellphone", defaultMessage: "Cell Phone" })}
              value={customer.cellPhone}
              margin="normal"
              onChange={this.handleChange}
              error={this.isInvalid("cellPhone")}
            />
            <TextField
              className={classes.inputField}
              name="workPhone"
              label={intl.formatMessage({ id: "workphone", defaultMessage: 'Work Phone' })}
              value={customer.workPhone}
              margin="normal"
              onChange={this.handleChange}
              error={this.isInvalid("workPhone")}
            />
          </div>
          <div className={classes.otherDiv}>
            <TextField
              className={classes.inputField}
              name="tradeIn"
              label={intl.formatMessage({ id: "tradein", defaultMessage: 'Trade In' })}
              value={customer.tradeIn}
              margin="normal"
              onChange={this.handleChange}
              error={this.isInvalid("tradeIn")}
            />
            <SalesmanSelect
              className={classes.inputField}
              users={this.props.users}
              selection={customer.salesman}
              onChange={this.handleChange}
              alwaysPresent={this.state.salesman}
              selectName="salesman"
            />
          </div>
          <div className={classes.addVisitDiv}>
            <Button
              variant="contained"
              color="primary"
              disabled={editingVisit}
              onClick={this.handleAddVisit}
            >
              {intl.formatMessage({ id: 'Add Visit', defaultMessage: "Add Visit" })}
            </Button>
          </div>
          <div className={classes.visitDiv}>
            <Collapse in={editingVisit}>
              <Divider />
              <VisitEditor
                initialVisit={editedVisit}
                availableVisitTypes={validVisitTypes}
                availableVehicles={availableVehicles}
                onMount={this.visitEditorMounted}
                onVisitModified={this.handleVisitModified}
              />
            </Collapse>
            <Collapse in={!editingVisit}>
              {<VisitList
                visits={customer.visits}
                canEditVisit={true}
                onEditVisit={this.handleEditVisit}
              />}
            </Collapse>
          </div>
        </div>
      );
    } else {
      return (<div></div>);
    }

  }
}

CustomerEditor.propTypes = {
  customerID: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  canAddVisit: PropTypes.bool,
  canEditVisit: PropTypes.bool,
  classes: PropTypes.object,
  intl: PropTypes.object,
  showMessage: PropTypes.func,
  currentUser: PropTypes.object,
  onSaved: PropTypes.func,
  onCancel: PropTypes.func
};

const mapStateToProps = state => ({
  users: getUsersArray(state),
  currentUser: getUser(state)
});

const mapDispatchToProps = dispatch => ({
  showMessage: (message, type) => dispatch(showMessageByID(message, 5000, type)),
});

export default injectIntl(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CustomerEditor)
  )
);
