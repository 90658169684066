import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import ReservationIcon from '@material-ui/icons/DateRange';
import { PageId } from "../../DataStore/MainPage/Types";

import { getMainPage, setMainPage } from '../../DataStore';

const ReservationButton = ({ ...others }) => {
  const currentPage = useSelector(getMainPage);

  const dispatch = useDispatch();
  const setPage = () => (dispatch(setMainPage(PageId.Reservations)));

  return (
    <IconButton
      color="inherit"
      aria-label="Reservations"
      disabled={currentPage === PageId.Reservations}
      onClick={setPage}
      {...others}
    >
      <ReservationIcon />
    </IconButton>
  );
}

export default ReservationButton;
