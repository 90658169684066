import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import StorageList from './StorageList';

const useStyles = makeStyles(() => ({
  rootDiv: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  actionDiv: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  listDiv: {
    display: 'flex',
    flexGrow: 1,
    overflowY: 'auto'
  }
}));

const StorageViewer = ({ storages, onClose, onEdit, onPrint, ...others }) => {
  const classes = useStyles();

  return (
    <div {...others}>
      <div className={classes.rootDiv}>
        <div className={classes.actionDiv}>
          <IconButton
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.listDiv}>
          <StorageList
            storages={storages}
            onEdit={onEdit}
            onPrint={onPrint}
          />
        </div>
      </div>
    </div>
  );
}

StorageViewer.propTypes = {
  storages: PropTypes.array,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  onPrint: PropTypes.func
}

export default StorageViewer;
