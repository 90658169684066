import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import Button from "@material-ui/core/Button";
import Customers from './Customers';
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { getCustomers, getUsersObject } from "../DataStore";
import { hasAccessRight, getUser } from "../DataStore/Login/Reducer";
import { AccessRight } from "../Models/User";
import SearchSelector, { SearchModeSimple } from "./SearchSelector";
import { getAdvancedSearchCustomers } from "../DataStore/CustomerManager/AdvancedSearch/Reducer";

const useStyles = makeStyles(theme => ({
  mainDiv: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  searchDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexShrink: 0,
    flexGrow: 0
  },
  addCustomerDiv: {
    flexShrink: 0,
    flexGrow: 0,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  customerListDiv: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "stretch"
  },

  '@supports (-webkit-overflow-scrolling: touch)': {
    customerListDiv: {
      overflow: "scroll",
      WebkitOverflowScrolling: "touch"
    },
  },
  '@supports not (-webkit-overflow-scrolling: touch)': {
    customerListDiv: {
      overflow: "auto"
    }
  },
  filter: {
    margin: theme.spacing(1)
  },
  addButton: {
    margin: theme.spacing(1)
  }
}));

const CustomerListWithSearch = ({ onAddCustomer, onEditCustomer, onDeleteCustomer, canAddCustomer, ...others }) => {
  const intl = useIntl();
  const classes = useStyles();

  const [filter, setFilter] = useState('');
  const [ownCustomers, setOwnCustomers] = useState(false);
  const [searchMode, setSearchMode] = useState(SearchModeSimple);

  const customers = useSelector(getCustomers);
  const salesmen = useSelector(getUsersObject);

  const isManager = useSelector(state => hasAccessRight(state, AccessRight.Manager));
  const isSalesman = useSelector(state => hasAccessRight(state, AccessRight.Salesman));
  const isDataEntry = useSelector(state => hasAccessRight(state, AccessRight.DataEntry));

  const canEdit = isManager || isSalesman || isDataEntry;

  const canDelete = isManager;
  const currentUser = useSelector(getUser);
  const advancedSearchCustomers = useSelector(getAdvancedSearchCustomers);

  const handleFilterChange = (value) => {
    setFilter(value);
  }

  const handleOwnCustomersChange = (value) => {
    setOwnCustomers(value);
  }

  const filteredCustomers = (customers, filter, ownCustomers, currentUser) => {
    const regExp = new RegExp(".*" + filter + ".*", "gi")

    return customers.filter(customer => {
      const passed = (
        customer.lastName.search(regExp) >= 0 ||
        customer.firstName.search(regExp) >= 0 ||
        customer.email.search(regExp) >= 0 ||
        customer.homePhone.search(regExp) >= 0 ||
        customer.cellPhone.search(regExp) >= 0 ||
        customer.workPhone.search(regExp) >= 0
      );

      if (passed && ownCustomers) {
        return customer.salesman === currentUser.id ||
          customer.visits.findIndex(visit => {
            return visit.takeOverBy === currentUser.id;
          }) >= 0;
      }

      return passed;
    });
  }

  let c = [];
  if (searchMode === SearchModeSimple) {
    c = filteredCustomers(customers, filter, ownCustomers, currentUser);
  } else {
    c = advancedSearchCustomers
  }

  return (
    <div className={classes.mainDiv} {...others}>
      <div className={classes.searchDiv}>
        <SearchSelector
          onOwnCustomersChange={handleOwnCustomersChange}
          onFilterChange={handleFilterChange}
          searchMode={searchMode}
          onSearchModeChange={(mode) => { setSearchMode(mode) }}
        />
      </div>
      <div className={classes.addCustomerDiv}>
        <Button
          className={classes.addButton}
          variant="contained"
          color="primary"
          onClick={onAddCustomer}
        >
          {intl.formatMessage({
            id: "Add Customer",
            defaultMessage: "Add Customer"
          })}
        </Button>
      </div>
      <div className={classes.customerListDiv}>
        <Customers
          customers={c}
          salesmen={salesmen}
          onEditCustomer={onEditCustomer}
          onDeleteCustomer={onDeleteCustomer}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      </div>
    </div>
  );
}

CustomerListWithSearch.propTypes = {
  onAddCustomer: PropTypes.func,
  onEditCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  canAddCustomer: PropTypes.bool
};

CustomerListWithSearch.defaultProps = {
  customers: [],
  salesmen: {},
  canAddCustomer: true
};

export default CustomerListWithSearch;
