import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';

import CustomerList from '../CustomerList';

import VehiclePerformanceTable from './VehiclePerformanceTable';
import FollowUpPerformanceTable from './FollowUpPerformanceTable';

import { getUsersObject } from "../../DataStore/Users/Reducer";

const useStyles = makeStyles(theme => ({
  sectionDiv: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1)
  }
}));

const mapServiceToInternet = data => {
  return data.map(d => {
    return Object.assign({}, d, {
      internet: d.service,
      internetToWalkin: d.serviceToWalkin,
      internetToSale: d.serviceToSale
    });
  });
}

const mapPhoneToInternet = data => {
  return data.map(d => {
    return Object.assign({}, d, {
      internet: d.phone,
      internetToWalkin: d.phoneToWalkin,
      internetToSale: d.phoneToSale
    });
  });
}

const mapReconnectToInternet = data => {
  return data.map(d => {
    return Object.assign({}, d, {
      internet: d.reconnect,
      internetToWalkin: d.reconnectToWalkin,
      internetToSale: d.reconnectToSale
    });
  });
}

const PerformanceUI = ({ customers, serviceData, phoneData, reconnectData,
  allVehiclesData, newVehiclesData, usedVehiclesData, internetRequestsData,
  internetSourceData,
  ...others }) => {

  const intl = useIntl();
  const classes = useStyles();
  const users = useSelector(getUsersObject);

  const [customerIDs, setCustomerIDs] = useState(null);

  const handleCellClick = (anchorEl, customerIDs) => {
    setCustomerIDs(customerIDs);
  }

  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setCustomerIDs(null);
  }

  const mappedServiceData = mapServiceToInternet(serviceData);
  const mappedPhoneData = mapPhoneToInternet(phoneData);
  const mappedReconnectData = mapReconnectToInternet(reconnectData);

  return (
    <div {...others}>
      <Paper>
        <div className={classes.sectionDiv}>
          <Typography variant="h6">
            {intl.formatMessage({
              id: "All Vehicles",
              defaultMessage: "All Vehicles"
            })}
          </Typography>
          <VehiclePerformanceTable
            users={users}
            vehiclesData={allVehiclesData}
            onCellClick={handleCellClick}
          />
        </div>

        <div className={classes.sectionDiv}>
          <Typography variant="h6">
            {intl.formatMessage({
              id: "New Vehicles",
              defaultMessage: "New Vehicles"
            })}
          </Typography>
          <VehiclePerformanceTable
            users={users}
            vehiclesData={newVehiclesData}
            onCellClick={handleCellClick}
          />
        </div>

        <div className={classes.sectionDiv}>
          <Typography variant="h6">
            {intl.formatMessage({
              id: "Used Vehicles",
              defaultMessage: "Used Vehicles"
            })}
          </Typography>
          <VehiclePerformanceTable
            users={users}
            vehiclesData={usedVehiclesData}
            onCellClick={handleCellClick}
          />
        </div>

        <div className={classes.sectionDiv}>
          <Typography variant="h6">
            {intl.formatMessage({
              id: "Internet Requests",
              defaultMessage: "Internet Requests"
            })}
          </Typography>
          <FollowUpPerformanceTable
            users={users}
            data={internetRequestsData}
            onCellClick={handleCellClick}
          />
        </div>

        <div className={classes.sectionDiv}>
          <Typography variant="h6">
            {intl.formatMessage({
              id: "Internet Source",
              defaultMessage: "Internet Source"
            })}
          </Typography>
          <FollowUpPerformanceTable
            users={users}
            data={internetSourceData}
            isInternetSource={true}
            onCellClick={handleCellClick}
          />
        </div>

        <div className={classes.sectionDiv}>
          <Typography variant="h6">
            {intl.formatMessage({
              id: "Phones",
              defaultMessage: "Phones"
            })}
          </Typography>
          <FollowUpPerformanceTable
            typeOfCustomer={intl.formatMessage({
              id: "Phone",
              defaultMessage: "Phone"
            })}
            noResponseTime={true}
            users={users}
            data={mappedPhoneData}
            onCellClick={handleCellClick}
          />
        </div>

        <div className={classes.sectionDiv}>
          <Typography variant="h6">
            {intl.formatMessage({
              id: "Services",
              defaultMessage: "Services"
            })}
          </Typography>
          <FollowUpPerformanceTable
            typeOfCustomer={intl.formatMessage({
              id: "Service",
              defaultMessage: "Service"
            })}
            noResponseTime={true}
            users={users}
            data={mappedServiceData}
            onCellClick={handleCellClick}
          />
        </div>

        <div className={classes.sectionDiv}>
          <Typography variant="h6">
            {intl.formatMessage({
              id: "Reconnect",
              defaultMessage: "Reconnect"
            })}
          </Typography>
          <FollowUpPerformanceTable
            typeOfCustomer={intl.formatMessage({
              id: "Reconnect",
              defaultMessage: "Reconnect"
            })}
            noResponseTime={true}
            users={users}
            data={mappedReconnectData}
            onCellClick={handleCellClick}
          />
        </div>

        {customerIDs && (<CustomerList open={customerIDs !== null} onClose={handleClose} customerIDs={customerIDs} customers={customers} />)}
      </Paper>
    </div>
  );
}

PerformanceUI.propTypes = {
  allVehiclesData: PropTypes.array,
  newVehiclesData: PropTypes.array,
  usedVehiclesData: PropTypes.array,
  internetRequestsData: PropTypes.array,
  internetSourceData: PropTypes.array,
  phoneData: PropTypes.array,
  serviceData: PropTypes.array,
  reconnectData: PropTypes.array,
  customers: PropTypes.object
};

Performance.defaultProps = {
  data: {}
};

export default PerformanceUI;
