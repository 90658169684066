import axios from 'axios';

/**
 * getDeliveries returns the delivery data for the specified time frame. If
 * no time is specified, the current business month is used.
 * @param {Date} startTime - Start time for the deliveries. Optional. If not specified, current month will be used.
 * @param {Date} endTime - End time for the deliveries. Optional. If not specified, current month will be used.
 * @param {Object} cancelSource - Optional. Used to cancel the request.
 */
export const getDeliveries = async (startTime, endTime, cancelSource) => {
  const response = await axios.get("/reports/deliveries", {
    params: {
      startTime: startTime ? startTime.valueOf() / 1000 : undefined,
      endTime: endTime ? endTime.valueOf() / 1000 : undefined
    },
    cancelToken: cancelSource ? cancelSource.token : undefined,
  });

  const data = response.data.result;
  return data;
}