import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as API from '../backend';
import { useIntl } from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const PrinterSelect = ({ label, selection, onChange, ...others }) => {
    const [printers, setPrinters] = useState([]);
    const intl = useIntl();

    useEffect(() => {
        const cancelSource = API.getCancelSource();

        const fetchPrinters = async () => {
            try {
                const result = await API.getPrinters(cancelSource);
                setPrinters(result);
            }
            catch (error) {
                /// TODO
            }
        }

        fetchPrinters();

        return () => {
            cancelSource.cancel();
        }
    }, []);

    const handleChange = (evt) => {
        if (evt.target.value === "none") {
            onChange("");
        } else {
            onChange(evt.target.value);
        }
    }

    const items = printers.map(p => {
        return (
            <MenuItem key={p.name} value={p.name}>
                {p.description}
            </MenuItem>
        );
    });

    return (
        <FormControl {...others}>
            <InputLabel>{label || intl.formatMessage({ id: "Select Printer", defaultMessage: "Select Printer" })}</InputLabel>
            <Select
                value={selection === "" ? "none" : selection}
                onChange={handleChange}
            >
                <MenuItem value={"none"}>{intl.formatMessage({ id: 'None', defaultMessage: 'None' })}</MenuItem>
                {items}
            </Select>
        </FormControl >
    );
}

PrinterSelect.propTypes = {
    label: PropTypes.string,
    selection: PropTypes.string,
    onChange: PropTypes.func
}

export default PrinterSelect;
