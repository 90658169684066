import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const ReservationMenu = ({ anchor, reservation, onFulfill, onEdit, onDelete, ...others }) => {
  const intl = useIntl();

  return (
    <Menu
      anchorEl={anchor}
      open={Boolean(anchor)}
      {...others}
    >
      <MenuItem onClick={onFulfill ? () => onFulfill(reservation) : undefined}>
        {intl.formatMessage({
          id: "Fullfill",
          defaultMessage: "Fullfill"
        })}
      </MenuItem>
      <MenuItem onClick={onEdit ? () => onEdit(reservation) : undefined}>
        {intl.formatMessage({
          id: "Edit",
          defaultMessage: "Edit"
        })}
      </MenuItem>
      <MenuItem onClick={onDelete ? () => onDelete(reservation) : undefined}>
        {intl.formatMessage({
          id: "Delete",
          defaultMessage: "Delete"
        })}
      </MenuItem>
    </Menu>
  )
}

ReservationMenu.propTypes = {
  anchor: PropTypes.object,
  reservation: PropTypes.object,
  onFulfill: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
}

export default ReservationMenu;
