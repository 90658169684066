import axios from 'axios';

/**
 * uploadNewStockFile uploads a file containing all vehicles that potentially
 * need to be stocked. On success, a list of vehicles not already stocked is
 * returned.
 * @param {File} file 
 * @param {*} cancelSource 
 * @returns An array of NewStock objects.
 */
export const uploadNewStockFile = async (file, cancelSource) => {
    const data = new FormData();
    data.append("StockFile", file)
    const response = await axios.post("/stocks/addstockfile", data, { cancelToken: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * stockNewVehicle stocks the vehicle identified in the passed new stock object.
 * @param {object} newStock - The new stock object to stock. Its stock number must be empty.
 * @param {*} cancelSource
 * @returns On success it returns the stock object with a new stock number.
 */
export const stockNewVehicle = async (newStock, printerName, cancelSource) => {
    const data = {
        printerName: printerName,
        stock: newStock
    };

    const response = await axios.post("/stocks/stocknewvehicle", data, { cancelToken: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * saveNewStock saves the specified new vehicle stock.
 * @param {object} newStock - The stock that needs to be saved.
 * @param {*} cancelSource 
 * @returns On success, returns the saved new vehicle stock.
 */
export const saveNewStock = async (newStock, cancelSource) => {
    const response = await axios.put("/stocks/stock/" + newStock.id, newStock, { cancelToken: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * getAggregatedNewStocks retrieve all the new stocks for the given year
 * from the server. The stocks are returned with the model and color embedded,
 * not just their ids.
 * @param {Number} year - The year for which we want the stocks.
 * @returns On success, it returns an array of new stocks.
 */
export const getAggregatedNewStocks = async (year, cancelSource) => {
    const response = await axios.get("/stocks/aggregatedstocks/" + year, { cancelToken: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * getNewStock retrieve the new stock specified by id from the server.
 * @param {string} id - The is of the new stock we want to retrieve
 * @param {*} cancelSource
 * @returns On success, it returns the new stock specified by id.
 */
export const getNewStock = async (id, cancelSource) => {
    const response = await axios.get("/stocks/stock/" + id, { cancelToken: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * deleteNewStock deletes the stock specified by id from the database.
 * @param {string} id 
 * @param {*} cancelSource 
 * @returns On sucess, it returns the deleted stock.
 */
export const deleteNewStock = async (id, cancelSource) => {
    const response = await axios.delete("/stocks/stock/" + id, { cancelToken: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * getModel gets the model specified by id from the server.
 * @param {string} id - The id of the model to get from the server
 * @param {object} cancelSource
 * @returns On success, returns the model requested.
 */
export const getModel = async (id, cancelSource) => {
    const response = await axios.get("/stocks/model/" + id, { cancelToken: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * getModels fetches all models from the server.
 * @param {object} cancelSource 
 * @returns On success, all models are returned.
 */
export const getModels = async (cancelSource) => {
    const response = await axios.get("stocks/model", { cancelToken: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * saveModel saves the model to the server.
 * @param {object} model - model to save.
 * @param {object} cancelSource 
 * @returns On success, it returns the saved model.
 */
export const saveModel = async (model, cancelSource) => {
    const response = await axios.post("/stocks/model", model, { cancelToken: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * getColor gets the color specified by id from the server.
 * @param {string} id 
 * @param {object} cancelSource 
 * @returns On success, returns the color requested.
 */
export const getColor = async (id, cancelSource) => {
    const response = await axios.get("/stocks/color/" + id, { cancelToken: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * getColors fetches all colors from the server.
 * @param {object} cancelSource 
 * @returns On success, all colors are returned.
 */
export const getColors = async (cancelSource) => {
    const response = await axios.get("stocks/color", { cancelToken: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * saveColor saves the color to the server.
 * @param {object} color - The color object to be saved.
 * @param {object} cancelSource 
 * @returns On success, it returns the saved color object.
 */
export const saveColor = async (color, cancelSource) => {
    const response = await axios.post("/stocks/color", color, { cancelToken: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * getPrinters gets the list of available printers from the server.
 * @param {object} cancelSource 
 * @returns On success, it returns the list of available printers.
 */
export const getPrinters = async (cancelSource) => {
    const response = await axios.get("/stocks/listprinters", { cancelToken: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * getPattterns gets the list of patterns, defined for each year.
 * @param {object} cancelSource 
 * @returns On success, it returns an array of Pattern.
 */
export const getPatterns = async (cancelSource) => {
    const response = await axios.get("/stocks/pattern", { cancelSource: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * savePattern save the pattern to the server.
 * @param {object} pattern
 * @param {object} cancelSource 
 * @returns On success, it returns the saved pattern.
 */
export const savePattern = async (pattern, cancelSource) => {
    const response = await axios.put("/stocks/pattern", pattern, { cancelSource: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}

/**
 * printTags sends a request to the server to print the tags with the stockInfo
 * passed. The stockInfo object must contains a field with the printer 
 * name(printerName) and a field with the stock(stock). The stock field is an
 * object representing the full stock.
 * @param {object} stockInfo 
 * @param {object} cancelSource 
 * @returns On succes, it returns the same stockInfo that was passed.
 */
export const printTags = async (stockInfo, cancelSource) => {
    const response = await axios.post("/stocks/print", stockInfo, { cancelSource: cancelSource ? cancelSource.token : undefined });
    return response.data.result;
}