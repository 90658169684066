import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const UserSelect = ({ id, value, onChange, name, users, ...others }) => {
  const intl = useIntl();
  const items = users.map(user => {
    return (
      <MenuItem key={user.id} value={user.id}>
        {user.firstName + " " + user.lastName}
      </MenuItem>
    )
  });

  return (
    <FormControl {...others}>
      <InputLabel htmlFor={id}>
        {intl.formatMessage({
          id: "Assign To",
          defaultMessage: "Assign To"
        })}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        inputProps={{
          name: name,
          id: id,
        }}
      >
        {items}
      </Select>
    </FormControl>
  )
}

UserSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  users: PropTypes.array
};

UserSelect.defaultProps = {
  id: '',
  name: '',
  value: '',
  onChange: null,
  users: []
};

export default UserSelect;
