import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createEventSource } from '../backend';
import { wait } from '../helpers/time';

const EventProviderContext = createContext([]);

const EventProvider = ({ children }) => {
  const [eventSource, setEventSource] = useState(null);

  useEffect(() => {
    let es = null;
    var stopped = false;

    const setupEventSource = async (delay = 0) => {
      if (delay > 0) {
        await wait(delay);
      }

      es = createEventSource();
      setEventSource(es);

      es.onerror = () => {
        if (!stopped) {
          console.log("Event source error. Trying to reconnect.");
          es.close();
          setupEventSource(10000);
        }
      }
    }

    setupEventSource();

    return () => {
      stopped = true;

      if (es !== null) {
        es.close();
      }
    }
  }, []);

  if (eventSource === null) {
    return (
      <div>
      </div>
    )
  } else {
    return (
      <EventProviderContext.Provider value={eventSource}>
        {children}
      </EventProviderContext.Provider>
    )
  }
}

EventProvider.propTypes = {
  children: PropTypes.any
}

export const useEventProvider = () => {
  return useContext(EventProviderContext);
}

export default EventProvider;
