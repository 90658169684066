import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
    line: {
        marginTop: theme.spacing(1)
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2)
    },
    inputField: {
        width: '20em'
    }
}));

const ModelEditorUI = ({ model, onSave, onChange, ...others }) => {
    const intl = useIntl();
    const classes = useStyles();

    const changeValue = (evt) => {
        const target = evt.target;
        const name = target.name;
        const value = target.value;

        onChange(Object.assign({}, model, { [name]: value }));
    }

    const changeDoors = (evt) => {
        const target = evt.target;
        const name = target.name;
        const value = target.value;

        let doors = parseInt(value);
        if (isNaN(doors)) {
            doors = 0;
        }

        onChange(Object.assign({}, model, { [name]: doors }));
    }

    const isValid = model.code.trim().length > 0 && model.description.trim().length > 0 && model.brand.trim().length > 0 && parseInt(model.doors, 10) > 0;

    return (
        <Paper className={classes.paper} {...others}>
            {!model.userEdited && (
                <Typography
                    color="secondary"
                    variant="h6"
                >
                    {intl.formatMessage({
                        id: "New Model",
                        defaultMessage: "New Model"
                    })}
                </Typography>
            )}
            <div className={classes.line}>
                <TextField
                    name="code"
                    className={classes.inputField}
                    value={model.code}
                    label={
                        intl.formatMessage({
                            id: "Model Code",
                            defaultMessage: "Model Code"
                        })
                    }
                    onChange={changeValue}
                />
            </div>
            <div className={classes.brand}>
                <TextField
                    name="brand"
                    className={classes.inputField}
                    value={model.brand}
                    label={
                        intl.formatMessage({
                            id: "Brand",
                            defaultMessage: "Brand"
                        })
                    }
                    onChange={changeValue}
                />
            </div>
            <div className={classes.line}>
                <TextField
                    name="description"
                    className={classes.inputField}
                    value={model.description}
                    label={
                        intl.formatMessage({
                            id: "Description",
                            defaultMessage: "Description"
                        })
                    }
                    onChange={changeValue}
                />
            </div>
            <div className={classes.line}>
                <TextField
                    name="trim"
                    className={classes.inputField}
                    value={model.trim}
                    label={
                        intl.formatMessage({
                            id: "Trim",
                            defaultMessage: "Trim"
                        })
                    }
                    onChange={changeValue}
                />
            </div>
            <div className={classes.line}>
                <TextField
                    name="doors"
                    className={classes.inputField}
                    value={model.doors}
                    label={
                        intl.formatMessage({
                            id: "Doors",
                            defaultMessage: "Doors"
                        })
                    }
                    onChange={changeDoors}
                />
            </div>
            <div className={classes.line}>
                <TextField
                    name="transmission"
                    className={classes.inputField}
                    value={model.transmission}
                    label={
                        intl.formatMessage({
                            id: "Transmission",
                            defaultMessage: "Transmission"
                        })
                    }
                    onChange={changeValue}
                />
            </div>
            <div className={classes.buttonDiv}>
                <Button
                    onClick={() => onSave(model)}
                    variant="contained"
                    disabled={!isValid}
                >
                    {intl.formatMessage({
                        id: "Save",
                        defaultMessage: "Save"
                    })}
                </Button>
            </div>
        </Paper>
    )
}

ModelEditorUI.propTypes = {
    model: PropTypes.object,
    onSave: PropTypes.func,
    onChange: PropTypes.func
}

export default ModelEditorUI;
