import { Map } from "immutable";
import * as Types from "./Types";
import frMessages from "../../Language/frMessages";
import enMessages from "../../Language/enMessages";

const getDefaultLanguage = () => {
  if (navigator && navigator.languages && navigator.languages.length > 0) {
    const l = navigator.languages[0];
    return l.substr(0, 2);
  }

  return "en";
}

const initialLanguage = getDefaultLanguage();
const initialState = Map({ language: initialLanguage, messages: initialLanguage === 'fr' ? frMessages : enMessages });

function languageReducer(state = initialState, action) {
  switch (action.type) {
    case Types.LANGUAGE_SET:
      switch (action.language) {
        case "fr":
          return Map({ language: "fr", messages: frMessages });
        default:
          return initialState;
      }

    default:
      return state;
  }
}

const getReducerState = state => {
  return state.get("language");
};

/**
 * Returns the current user language
 * @param {object} state - The global state
 */
export const language = state => {
  return getReducerState(state).get("language");
};

/**
 * Returns the messages for the current language.
 * @param {object} state  - The global state
 */
export const messages = state => {
  return getReducerState(state).get("messages");
};

export default languageReducer;
