import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, } from '@material-ui/styles';

import HistoryItem from './HistoryItem';

const useStyles = makeStyles((/*theme*/) => ({
  rootDiv: {
    width: '100%',
    height: '100%'
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  }
}));

const History = ({ storage, ...others }) => {
  let classes = useStyles();

  let items = storage.history.map((s, idx) => {
    return (
      <HistoryItem key={idx} storage={s} />
    );
  });

  items.push((
    <HistoryItem key={storage.length + 1} storage={storage} />
  ));

  return (
    <div className={classes.rootDiv} {...others} >
      <ul className={classes.list}>
        {items}
      </ul>
    </div>
  )
}

History.propTypes = {
  storage: PropTypes.object
}

export default History;
