import * as Types from './Types';

export const showMessage = (message, delay = 5000, messageType = Types.MessageType.Info) => {
  return {
    type: Types.SHOW_MESSAGE,
    message,
    delay,
    messageType
  }
};

export const showMessageByID = (messageID, delay = 5000, messageType = Types.MessageType.Info) => {
  return {
    type: Types.SHOW_MESSAGE_BY_ID,
    messageID,
    delay,
    messageType
  }
};

export const hideMessage = () => {
  return {
    type: Types.HIDE_MESSAGE
  }
};
