import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { VisitType, visitTypeToString } from '../Models/Visit';

const VisitTypeSelect = ({ label, value, onChange, noValue, ...others }) => {
  const intl = useIntl();

  return (
    <FormControl {...others}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={(event) => { onChange && onChange(event.target.value) }}
      >
        {noValue && (<MenuItem value={0}>{intl.formatMessage({ id: "None", defaultMessage: "None" })}</MenuItem>)}
        <MenuItem value={VisitType.Walkin}>{visitTypeToString(intl, VisitType.Walkin)}</MenuItem>
        <MenuItem value={VisitType.Internet}>{visitTypeToString(intl, VisitType.Internet)}</MenuItem>
        <MenuItem value={VisitType.Phone}>{visitTypeToString(intl, VisitType.Phone)}</MenuItem>
        <MenuItem value={VisitType.Service}>{visitTypeToString(intl, VisitType.Service)}</MenuItem>
        <MenuItem value={VisitType.Beback}>{visitTypeToString(intl, VisitType.Beback)}</MenuItem>
        <MenuItem value={VisitType.FollowUp}>{visitTypeToString(intl, VisitType.FollowUp)}</MenuItem>
        <MenuItem value={VisitType.InternetResponse}>{visitTypeToString(intl, VisitType.InternetResponse)}</MenuItem>
      </Select>
    </FormControl>
  );
}

VisitTypeSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  noValue: PropTypes.bool
};

VisitTypeSelect.defaultProps = {
  noValue: false
};

export default VisitTypeSelect;
