import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const ValidatedTextField = ({ validator, value, ...others }) => {
  const checkValidity = (value) => {
    if (validator) {
      if (typeof validator === 'function') {
        return validator(value);
      }
      else {
        return validator.test(value);
      }
    }

    return true;
  }

  return (
    <TextField value={value} {...others} error={!checkValidity(value)} />
  )
}

ValidatedTextField.propTypes = {
  validator: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(RegExp)
  ]),
  value: PropTypes.string
};

export default ValidatedTextField;
