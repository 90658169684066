import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { percent } from '../Helper';

const SalesmanPerformanceRow = ({ salesman, data, users, onCellClick, ...others }) => {
  const intl = useIntl();

  const handleCellClick = (event, customerIDs) => {
    onCellClick(event.currentTarget, customerIDs);
  }

  const dataCell = (text, data) => {
    return (
      <TableCell
        onClick={(event) => { handleCellClick(event, data) }}
      >
        {text}
      </TableCell>
    )
  }

  return (
    <TableRow key={salesman} {...others}>
      <TableCell>
        {salesman === "total" ?
          intl.formatMessage({
            id: "Total",
            defaultMessage: "Total"
          }) :
          users[salesman].firstName + " " + users[salesman].lastName}</TableCell>
      {dataCell(data.totalCustomers.length, data.totalCustomers)}
      {dataCell(data.inCustomers.length, data.inCustomers)}
      {dataCell(data.walkins.length, data.walkins)}
      {dataCell(data.bebacks.length, data.bebacks)}
      {dataCell(data.sales.length, data.sales)}
      {dataCell(percent(data.sales.length / data.inCustomers.length), data.sales)}
      {dataCell(percent(data.walkinSales.length / data.walkins.length), data.walkinSales)}
      {dataCell(percent(data.bebackSales.length / data.bebacks.length), data.bebackSales)}
      {dataCell(percent(data.roadTests.length / data.inCustomers.length), data.testDrive)}
      {dataCell(percent(data.vipPresentations.length / data.inCustomers.length), data.vipPresentations)}
      {dataCell(percent(data.assistances.length / data.inCustomers.length), data.takeOver)}
      {dataCell(percent(data.assistanceSales.length / data.assistances.length), data.takeOverSales)}
    </TableRow>
  );
}

SalesmanPerformanceRow.propTypes = {
  salesman: PropTypes.string,
  data: PropTypes.object,
  users: PropTypes.object,
  onCellClick: PropTypes.func
};

export default SalesmanPerformanceRow;
