import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { useIntl } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';
import { dateToText, textToDate } from "../../helpers";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import ConfirmTextField from '../../Components/ConfirmTextField';
import PrinterSelect from '../../Components/PrinterSelect';

const useStyles = makeStyles((theme) => ({
    editor: {
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
    line: {
        display: 'flex',
        marginTop: theme.spacing(1)
    },
    inputField: {
        width: '20em'
    },
    descriptionDiv: {
        display: 'flex',
        width: '20em',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    saveDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: theme.spacing(1)
    },
    cancelButton: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    }
}));

const NewStockEditorUI = ({ stock, model, color, printer, onChange, onSave, onCancel, onDelete, onChangeModel, onChangeColor, onSetPrinter, onPrint, ...others }) => {
    const intl = useIntl();
    const classes = useStyles();
    const [confirmDelete, setConfirmDelete] = useState(false);

    const changeStock = (name, value) => {
        const newStock = Object.assign({}, stock, { [name]: value });
        onChange && onChange(newStock);
    }

    const handleOnDelete = () => {
        setConfirmDelete(false);
        onDelete && onDelete(stock);
    }

    const isValid = stock.vin.trim().length === 17 && stock.model && stock.model.length > 0 && stock.color && stock.color.length > 0;

    return (
        <Paper className={classes.editor} {...others}>
            {stock.stockNumber.length > 0 && (
                <div className={classes.line}>
                    <Typography
                        variant="h5"
                    >
                        {stock.stockNumber}
                    </Typography>
                </div>
            )}
            <div className={classes.line}>
                <TextField
                    name="vin"
                    className={classes.inputField}
                    label={
                        intl.formatMessage({
                            id: "Serial Number",
                            defaultMessage: "Serial Number"
                        })
                    }
                    value={stock.vin}
                    onChange={e => changeStock('vin', e.target.value)}
                />
            </div>
            <div className={classes.line}>
                <TextField
                    name="year"
                    className={classes.inputField}
                    label={
                        intl.formatMessage({
                            id: "Year",
                            defaultMessage: "Year"
                        })
                    }
                    value={stock.year}
                    onChange={e => changeStock('year', parseInt(e.target.value))}
                />
            </div>
            <div className={classes.line}>
                <TextField
                    name="boughtFrom"
                    className={classes.inputField}
                    label={
                        intl.formatMessage({
                            id: "Purchased From",
                            defaultMessage: "Purchased From"
                        })
                    }
                    value={stock.boughtFrom}
                    onChange={e => changeStock('boughtFrom', e.target.value)}
                />
            </div>

            <div className={classes.descriptionDiv}>
                {model === null ? intl.formatMessage({
                    id: "Undefined Model",
                    defaultMessage: "Undefined Model"
                }) : model.description + ' ' + model.trim}
                <IconButton
                    size={"small"}
                    onClick={onChangeModel}
                >
                    <EditIcon />
                </IconButton>
            </div>

            <div className={classes.descriptionDiv}>
                {color === null ? intl.formatMessage({
                    id: "Undefined Color",
                    defaultMessage: "Undefined Color"
                }) : color.description}
                <IconButton
                    size={"small"}
                    onClick={onChangeColor}
                >
                    <EditIcon />
                </IconButton>
            </div>

            <div className={classes.line}>
                <TextField
                    name={"dateAllocated"}
                    type="date"
                    label={intl.formatMessage({
                        id: "Allocation Date",
                        defaultMessage: "Allocation Date"
                    })}
                    value={dateToText(new Date(stock.dateAllocated * 1000))}
                    InputLabelProps={{ shrink: true }}
                    onChange={e => changeStock('dateAllocated', textToDate(e.target.value).getTime() / 1000)}
                />
            </div>

            <div className={classes.line}>
                <TextField
                    name={"expectedDate"}
                    type="date"
                    label={intl.formatMessage({
                        id: "Expected Date",
                        defaultMessage: "Expected Date"
                    })}
                    value={dateToText(new Date(stock.expectedDate * 1000))}
                    InputLabelProps={{ shrink: true }}
                    onChange={e => changeStock('expectedDate', textToDate(e.target.value).getTime() / 1000)}
                />
            </div>

            <div className={classes.line}>
                <TextField
                    name={"deliveryDate"}
                    type="date"
                    label={intl.formatMessage({
                        id: "DeliveryDate",
                        defaultMessage: "DeliveryDate"
                    })}
                    value={dateToText(new Date(stock.deliveryDate * 1000))}
                    InputLabelProps={{ shrink: true }}
                    onChange={e => changeStock('deliveryDate', textToDate(e.target.value).getTime() / 1000)}
                />
            </div>

            <div className={classes.line}>
                <TextField
                    name={"tradeInDate"}
                    type="date"
                    label={intl.formatMessage({
                        id: "Trade In Date",
                        defaultMessage: "Trade In Date"
                    })}
                    value={dateToText(new Date(stock.tradeInDate * 1000))}
                    InputLabelProps={{ shrink: true }}
                    onChange={e => changeStock('tradeInDate', textToDate(e.target.value).getTime() / 1000)}
                />
            </div>
            <div className={classes.line}>
                <IconButton
                    size="small"
                    onClick={() => { onPrint && onPrint(stock); }}
                >
                    <PrintIcon />
                </IconButton>
                <PrinterSelect onChange={(p) => { onSetPrinter && onSetPrinter(p); }} selection={printer || "none"} fullWidth={true} />
            </div>
            <div className={classes.saveDiv}>
                {stock.stockNumber.trim().length > 0 && (
                    <IconButton
                        size="small"
                        disabled={confirmDelete}
                        onClick={() => setConfirmDelete(true)}
                    >
                        <DeleteIcon />
                    </IconButton>
                )}
                <Button
                    variant={"contained"}
                    onClick={onCancel}
                    className={classes.cancelButton}
                >
                    {
                        intl.formatMessage({
                            id: "Cancel",
                            defaultMessage: "Cancel"
                        })
                    }
                </Button>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    disabled={!isValid}
                    onClick={() => { onSave && onSave(stock); }}
                >
                    {
                        stock.stockNumber.length > 0 ? intl.formatMessage({
                            id: "Save",
                            defaultMessage: "Save"
                        }) :
                            intl.formatMessage({
                                id: "Stock",
                                defaultMessage: "Stock"
                            })
                    }
                </Button>
            </div>
            {confirmDelete && (
                <ConfirmTextField
                    label={
                        intl.formatMessage({
                            id: "Enter stock number to confirm.",
                            defaultMessage: "Enter stock number to confirm."
                        })
                    }
                    valueToMatch={stock.stockNumber}
                    onConfirmation={handleOnDelete}
                    onCancel={() => { setConfirmDelete(false) }}
                />
            )}
        </Paper>
    )
}

NewStockEditorUI.propTypes = {
    stock: PropTypes.object,
    model: PropTypes.object,
    color: PropTypes.object,
    printer: PropTypes.string,
    onChangeModel: PropTypes.func,
    onChangeColor: PropTypes.func,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    onSetPrinter: PropTypes.func,
    onPrint: PropTypes.func
}

export default NewStockEditorUI;
