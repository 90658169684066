import React from "react";
import PropTypes from "prop-types";
import { useIntl, defineMessages } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import {
  PurchaseType,
  visitTypeToString,
  visitTypeToIcon
} from "../Models/Visit";
import { followUpNeeded } from "../Models/Customer";

const useStyles = makeStyles(theme => ({
  card: {
    display: "flex",
    width: "100%"
  },
  content: {
    width: "100%"
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "64px"
  },
  visitList: {
    display: "inline",
    marginLeft: "0",
    paddingLeft: "0"
  },
  visitListItem: {
    display: "inline",
    marginRight: "2px"
  },
  div: {
    padding: theme.spacing(1)
  },
  lineContent: {
    marginTop: "0.25em",
    marginBottom: "0.25em"
  },
  soldNew: {
    backgroundColor: "yellow"
  },
  soldUsed: {
    backgroundColor: "green"
  },
  notSold: {}
}));

const messages = defineMessages({
  name: {
    id: "Name: {name}",
    defaultMessage: "Name: {name}"
  },
  email: {
    id: "Email: {email}",
    defaultMessage: "Email: {email}"
  },
  homePhone: {
    id: "Home Phone: {phone}",
    defaultMessage: "Home Phone: {phone}"
  },
  cellPhone: {
    id: "Cell Phone: {phone}",
    defaultMessage: "Cell Phone: {phone}"
  },
  workPhone: {
    id: "Work Phone: {phone}",
    defaultMessage: "Work Phone: {phone}"
  },
  vehicle: {
    id: "Vehicle: {vehicle}",
    defaultMessage: "Vehicle: {vehicle}"
  },
  lastVisit: {
    id: "Last Visit: {visitType} on {lastVisit, date, medium}",
    defaultMessage: "Last Visit: {visitType} on {lastVisit, date, medium}"
  },
  nextCommunication: {
    id: "Next Communication: {nextComm, date, medium}",
    defaultMessage: "Next Communication: {nextComm, date, medium}"
  },
  salesman: {
    id: "Salesman: {salesman}",
    defaultMessage: "Salesman: {salesman}"
  }
});

const CustomerItem = ({ onEdit, onDelete, customer, canEdit, canDelete, salesman, ...others }) => {
  const classes = useStyles();
  const intl = useIntl();

  const handleEdit = () => {
    if (onEdit) {
      onEdit(customer.id);
    }
  }

  const handleDelete = () => {
    if (onDelete) {
      onDelete(customer.id);
    }
  }

  const lineContent = (id, values, lineCount) => {
    if (!values) {
      return null;
    }

    const entries = Object.entries(values);
    for (let i = 0; i < entries.length; i++) {
      if (!entries[i][1]) {
        return null;
      }
    }

    // If we made it here, increment
    lineCount.count = lineCount.count + 1;

    return (
      <Typography variant={lineCount.count === 1 ? "h6" : "body1"}>
        {intl.formatMessage(id, values)}
      </Typography>
    );
  }

  const getVehicle = (customer) => {
    for (let i = customer.visits.length - 1; i >= 0; i--) {
      const visit = customer.visits[i];
      if (visit.vehicle) {
        return visit.vehicle;
      }
    }

    return "";
  }

  const cardStyle = (customer) => {
    for (let i = customer.visits.length - 1; i >= 0; i--) {
      const visit = customer.visits[i];
      if (visit.sold) {
        if (visit.purchaseType === PurchaseType.Used) {
          return {
            backgroundColor: "green"
          };
        }

        return {
          backgroundColor: "yellow"
        };
      }
    }

    if (followUpNeeded(customer)) {
      return {
        backgroundColor: 'red'
      };
    }

    const lastVisit = customer.visits[customer.visits.length - 1];
    if (lastVisit.reservation) {
      return {
        backgroundColor: 'blue'
      }
    }

    return {};
  }

  const getLastVisit = (customer) => {
    if (customer.visits.length > 0) {
      return customer.visits[customer.visits.length - 1];
    }

    return null;
  }

  const visitResume = (customer) => {
    const visits = customer.visits.map((visit, index) => {
      return (
        <li key={index} className={classes.visitListItem}>
          {visitTypeToIcon(visit.type, intl)}
        </li>
      );
    });

    return <ul className={classes.visitList}>{visits}</ul>;
  }

  const getSalesman = () => {
    if (salesman) {
      return salesman.firstName + " " + salesman.lastName;
    }

    return "";
  }

  const cardActions = () => {
    if (!canEdit && !canDelete) {
      return null;
    }

    return (
      <CardActions className={classes.actions}>
        {canEdit && (
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        )}
        {canDelete && (
          <IconButton onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </CardActions>
    );
  }

  const vehicle = getVehicle(customer);
  const lastVisit = getLastVisit(customer);
  let lineCount = { count: 0 };

  return (
    <div className={classes.div} {...others}>
      <Card className={classes.card} style={cardStyle(customer)}>
        <CardContent className={classes.content}>
          {visitResume(customer)}
          {lineContent(
            messages.name,
            { name: customer.firstName + " " + customer.lastName },
            lineCount
          )}
          {lineContent(
            messages.email,
            { email: customer.email },
            lineCount
          )}
          {lineContent(
            messages.homePhone,
            { phone: customer.homePhone },
            lineCount
          )}
          {lineContent(
            messages.cellPhone,
            { phone: customer.cellPhone },
            lineCount
          )}
          {lineContent(
            messages.workPhone,
            { phone: customer.workPhone },
            lineCount
          )}
          {lineContent(
            messages.vehicle,
            { vehicle: vehicle },
            lineCount
          )}
          {lineContent(
            messages.salesman,
            { salesman: getSalesman() },
            lineCount
          )}
          {lineContent(
            messages.lastVisit,
            {
              visitType: visitTypeToString(intl, lastVisit.type),
              lastVisit: new Date(lastVisit.date * 1000)
            },
            lineCount
          )}
          {!lastVisit.sold && lastVisit.nextScheduledCommunication > 0 && lineContent(
            messages.nextCommunication,
            {
              nextComm: new Date(lastVisit.nextScheduledCommunication * 1000)
            },
            lineCount
          )}
        </CardContent>
        {cardActions()}
      </Card>
    </div>
  );
}

CustomerItem.propTypes = {
  customer: PropTypes.object.isRequired,
  salesman: PropTypes.object,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

CustomerItem.defaultProps = {
  customer: {},
  salesman: null,
  canEdit: true,
  canDelete: true
};

export default CustomerItem;
