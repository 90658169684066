import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as API from '../../backend';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import NewStockEditorUI from './NewStockEditorUI';
import ModelSelectorDialog from '../Models/ModelSelectorDialog';
import ColorSelectorDialog from '../Colors/ColorSelectorDialog';
import { getUser } from "../../DataStore/Login/Reducer";
import { useSelector } from 'react-redux';

const NewStockEditorController = ({ stockID, onSaved, onCancelled, onDeleted, ...others }) => {
    const snackbar = useSnackbar();
    const intl = useIntl();
    const [stock, setStock] = useState(null);
    const [model, setModel] = useState(null);
    const [models, setModels] = useState([]);
    const [color, setColor] = useState(null);
    const [colors, setColors] = useState([]);
    const [showModelSelector, setShowModelSelector] = useState(false);
    const [showColorSelector, setShowColorSelector] = useState(false);
    const currentUser = useSelector(getUser);
    const [printer, setPrinter] = useState(currentUser.defaultPrinter);

    useEffect(() => {
        const cancelSource = API.getCancelSource();

        const getStock = async () => {
            try {
                const result = await API.getNewStock(stockID, cancelSource);
                setStock(result);

                setModel(await API.getModel(result.model, cancelSource));
                setColor(await API.getColor(result.color, cancelSource));
            }
            catch (error) {
                snackbar.enqueueSnackbar(
                    intl.formatMessage({
                        id: "Error while loading the stock from the server.",
                        defaultMessage: "Error while loading the stock from the server."
                    })
                ),
                {
                    variant: 'error'
                }
            }
        }

        if (stockID) {
            getStock();
        } else {
            const today = new Date();

            setStock({
                id: null,
                stockNumber: '',
                vin: '',
                year: today.getFullYear(),
                color: null,
                boughtFrom: '',
                tradeInDate: 0,
                dateAllocated: 0,
                expectedDate: 0,
                deliveryDate: 0
            });

            setModel(null);
            setColor(null);
        }
        return () => {
            cancelSource.cancel();
        }
    }, [stockID]);

    useEffect(() => {
        const cancelSource = API.getCancelSource();

        const getModels = async () => {
            try {
                const result = await API.getModels(cancelSource);
                setModels(result);
            }
            catch (error) {
                snackbar.enqueueSnackbar(
                    intl.formatMessage({
                        id: "Error while loading models.",
                        defaultMessage: "Error while loading models."
                    }),
                    {
                        variant: 'error'
                    }
                )
            }
        }

        getModels();

        return () => {
            cancelSource.cancel();
        }
    }, []);

    useEffect(() => {
        const cancelSource = API.getCancelSource();

        const getColors = async () => {
            try {
                const result = await API.getColors(cancelSource);
                setColors(result);
            }
            catch (error) {
                snackbar.enqueueSnackbar(
                    intl.formatMessage({
                        id: "Error while loading colors.",
                        defaultMessage: "Error while loading colors."
                    }),
                    {
                        variant: 'error'
                    }
                )
            }
        }

        getColors();

        return () => {
            cancelSource.cancel();
        }
    }, []);

    const saveStock = async s => {
        try {
            if (s.id) {
                await API.saveNewStock(s);
            } else {
                await API.stockNewVehicle(s, '');
            }

            onSaved && onSaved();
        }
        catch (error) {
            snackbar.enqueueSnackbar(
                intl.formatMessage({
                    id: "Error while saving new vehicle stock.",
                    defaultMessage: "Error while saving new vehicle stock."
                }),
                {
                    variant: 'error'
                }
            )
        }
    }

    const deleteStock = async s => {
        try {
            await API.deleteNewStock(s.id);
            onDeleted && onDeleted(s);
        }
        catch (error) {
            snackbar.enqueueSnackbar(
                intl.formatMessage({
                    id: "Error while deleting new vehicle stock.",
                    defaultMessage: "Error while deleting new vehicle stock."
                }),
                {
                    variant: 'error'
                }
            )
        }
    }

    const setNewModel = m => {
        setModel(m);
        setStock(Object.assign({}, stock, { model: m.id }));
        setShowModelSelector(false);
    }

    const changeColor = c => {
        setColor(c);
        setStock(Object.assign({}, stock, { color: c.id }));
        setShowColorSelector(false);
    }

    return (
        <div {...others}>
            {stock &&
                <div>
                    <NewStockEditorUI
                        stock={stock}
                        model={model}
                        color={color}
                        printer={printer}
                        onChange={(s) => setStock(s)}
                        onChangeModel={() => setShowModelSelector(true)}
                        onChangeColor={() => setShowColorSelector(true)}
                        onSave={saveStock}
                        onDelete={deleteStock}
                        onCancel={onCancelled}
                        onSetPrinter={setPrinter}
                    />
                </div>
            }
            {models &&
                <ModelSelectorDialog
                    open={showModelSelector}
                    models={models}
                    selection={model ? model.id : ''}
                    onChange={setNewModel}
                />
            }
            {colors &&
                <ColorSelectorDialog
                    open={showColorSelector}
                    colors={colors}
                    selection={color ? color.id : ''}
                    onChange={changeColor}
                />
            }
        </div>
    )
}

NewStockEditorController.propTypes = {
    stockID: PropTypes.string,
    onSaved: PropTypes.func,
    onCancelled: PropTypes.func,
    onDeleted: PropTypes.func
}

export default NewStockEditorController;
