import React, { useState, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import { VictoryChart, VictoryGroup, VictoryBar, VictoryAxis } from 'victory';
import { firstBusinessDayOfMonth, lastBusinessDayOfMonth } from '../helpers';
import { fetchPerformanceData } from '../backend';
import { getCustomers } from '../DataStore/CustomerManager/CustomerList/Reducer';

const useStyles = makeStyles((/*theme*/) => ({
  rootDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const messages = defineMessages({
  title: {
    id: 'Yearly Sales',
    defaultMessage: 'Yearly sales'
  },
  january: {
    id: 'Jan',
    defaultMessage: "Jan"
  },
  february: {
    id: 'Feb',
    defaultMessage: 'Feb'
  },
  march: {
    id: 'Mar',
    defaultMessage: 'Mar'
  },
  april: {
    id: 'Apr',
    defaultMessage: 'Apr'
  },
  may: {
    id: 'May',
    defaultMessage: 'May'
  },
  june: {
    id: 'Jun',
    defaultMessage: 'Jun'
  },
  july: {
    id: 'Jul',
    defaultMessage: 'Jul'
  },
  august: {
    id: 'Aug',
    defaultMessage: 'Aug'
  },
  september: {
    id: 'Sep',
    defaultMessage: 'Sep'
  },
  october: {
    id: 'Oct',
    defaultMessage: 'Oct'
  },
  november: {
    id: 'Nov',
    defaultMessage: 'Nov'
  },
  december: {
    id: 'Dec',
    defaultMessage: 'Dec'
  }
});

/**
 * getPreviousMonth returns a new Date object set to howMany months in the past.
 * The day of the month returned is always set to the 15th to avoid conflict with
 * automotive businesses month beginning and ending.
 * @param {d} d - The date where we start.
 * @param {*} howMany - The number of months to go in the past.
 */
const getPreviousMonth = (d, howMany) => {
  // Return the 15th of the month just to make sure we are not near beginning or end of month.
  const startMonth = d.getMonth();
  const startYear = d.getFullYear();

  let prevMonth = startMonth - howMany;
  let year = startYear;

  while (prevMonth < 0) {
    prevMonth = prevMonth + 12;
    year = year - 1;
  }

  return new Date(year, prevMonth, 15, 0, 0, 0, 0);
}

const getTotalSales = (data) => {
  for (let i = 0; i < data.length; i++) {
    const stats = data[i];
    if (stats.salesmanID === "total") {
      return stats.sales;
    }
  }

  return 0;
}

const YearlySales = ({ ...others }) => {
  const intl = useIntl();
  const classes = useStyles();

  const [data, setData] = useState([]);
  const customers = useSelector(getCustomers);

  useEffect(() => {
    const loadData = async () => {
      const newData = prepareData();

      for (let i = 0; i < newData.length; i++) {
        const data = newData[i];
        const result = await fetchPerformanceData(data.startDate.getTime(), data.endDate.getTime(), true);
        data.value = getTotalSales(result.reportData.allVehicles);
      }

      setData(newData);
    }

    const prepareData = () => {
      const now = new Date();
      const startOfMonth = firstBusinessDayOfMonth(now);
      const elapsed = now.getTime() - startOfMonth.getTime();
      const data = [];

      for (let i = 0; i < 24; i++) {
        const start = firstBusinessDayOfMonth(getPreviousMonth(startOfMonth, i));
        let end = null;

        if (i % 12 === 0) {
          end = new Date(start.getTime() + elapsed);
          const endOfMonth = lastBusinessDayOfMonth(start);

          // Make sure we do not pass end of the month. The end of the month
          // can differ from one year to another.
          if (end.getTime() > endOfMonth.getTime()) {
            end = endOfMonth;
          }
        } else {
          end = lastBusinessDayOfMonth(getPreviousMonth(startOfMonth, i));
        }

        data.push({
          index: i,
          value: 0,
          startDate: start,
          endDate: end
        });
      }

      return data;
    }

    loadData();
  }, [customers]);

  const getTickValues = () => {
    const result = [];

    for (let i = 0; i < 12; i++) {
      result.push(i);
    }

    return result;
  }

  const getTickFormat = (values) => {
    return values.map(value => {
      switch (value.startDate.getMonth()) {
        case 0: return intl.formatMessage(messages.january);
        case 1: return intl.formatMessage(messages.february);
        case 2: return intl.formatMessage(messages.march);
        case 3: return intl.formatMessage(messages.april);
        case 4: return intl.formatMessage(messages.may);
        case 5: return intl.formatMessage(messages.june);
        case 6: return intl.formatMessage(messages.july);
        case 7: return intl.formatMessage(messages.august);
        case 8: return intl.formatMessage(messages.september);
        case 9: return intl.formatMessage(messages.october);
        case 10: return intl.formatMessage(messages.november);
        case 11: return intl.formatMessage(messages.december);
        default: return "-"
      }
    });
  }

  const color = (d1, d2) => {
    if (d1 < 0.85 * d2) {
      return 'red';
    }

    if (d1 < d2) {
      return 'yellow';
    }

    return 'green'
  }

  let values = [];
  let valuesB = [];

  if (data.length >= 24) {
    for (let i = 0; i < 12; i++) {
      const value = {
        x: i,
        y: data[i].value,
        label: data[i].value,
        startDate: data[i].startDate,
        endDate: data[i].endDate
      }

      values.push(value);
    }

    for (let i = 0; i < 12; i++) {
      const value = {
        x: i,
        y: data[i + 12].value,
        label: data[i + 12].value,
        startDate: data[i + 12].startDate,
        endDate: data[i + 12].endDate
      }

      valuesB.push(value);
    }
  }

  const tickValues = getTickValues();
  const tickFormat = getTickFormat(values);

  return (
    <div {...others}>
      <div className={classes.rootDiv}>
        <Typography variant="h6">
          {intl.formatMessage(messages.title)}
        </Typography>
        <VictoryChart>
          <VictoryAxis
            tickValues={tickValues}
            tickFormat={tickFormat}
            style={{
              tickLabels: {
                fontSize: 15,
                fill: "black",
              }
            }}
          />
          <VictoryGroup
            offset={10}
            colorScale="grayscale"
          >
            <VictoryBar
              data={values}
              style={{
                data: {
                  fillOpacity: 0.9,
                  stroke: ({ datum }) => (color(datum.y, valuesB[datum.x].y)),
                  strokeWidth: 2,
                  fill: ({ datum }) => (color(datum.y, valuesB[datum.x].y))
                }
              }}
            />
            <VictoryBar
              data={valuesB}
            />
          </VictoryGroup>
        </VictoryChart>
      </div>
    </div>
  )
}

export default YearlySales;
