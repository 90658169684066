import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Login from "./Login/Login";
import { RequestStatus } from "./DataStore/Types";
import MainPage from "./MainPage/MainPage";
import { loginStatus as getLoginStatus } from "./DataStore/Login/Reducer";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles(theme => ({
  fullPage: {
    backgroundColor: grey[800],
    width: "100%",
    height: '100vh',
    display: 'flex'
  },
  mainDiv: {
    width: theme.mainPage.maxWidth,
    maxWidth: "100%",
    display: 'flex',
    justifyContent: 'center',
    margin: "0 auto",
    backgroundColor: "white",
    overflow: "hidden"
  }
}));

const StartPage = () => {
  const classes = useStyles();
  const loginStatus = useSelector(getLoginStatus);

  const loginVisible = loginStatus !== RequestStatus.Success;
  const waitVisible = loginStatus === RequestStatus.Requested;
  const loginSuccess = loginStatus === RequestStatus.Success;

  return (
    <div className={classes.fullPage}>
      <div className={classes.mainDiv}>

        {loginVisible && (
          <Login showProgress={waitVisible} />
        )}

        {loginSuccess && (
          <MainPage />
        )}
      </div>
    </div>
  );
}

export default StartPage;
