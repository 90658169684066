export const MenuSelection = {
  AddStorage: 'add_storage',
  FindStorage: 'find_storage'
};

export const WheelType = {
  None: 1,
  Steel: 2,
  Alloy: 3
};
