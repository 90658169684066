import axios from 'axios';

export const saveStorage = async (storage, cancelSource) => {
  const response = await axios.post("/storage", storage, { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}

export const getStorage = async (id, cancelSource) => {
  const response = await axios.get("/storage/" + id, { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}

export const getActiveStorages = async (cancelSource) => {
  const response = await axios.get("/storage/active", { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}

/**
 * findStorages finds all storages that contains the value specified in
 * their names, work order number, tire brand or tire model.
 * If onlyActive is true, only active storages will be returned, otherwise
 * the search will be made for all storages.
 * @param {string} value - The filter used to find storages.
 * @param {bool} onlyActive - When true, returns only active storages.
 * @param {*} cancelSource - Used to cancel the request.
 */
export const findStorages = async (value, onlyActive, cancelSource) => {
  const response = await axios.get("/storage", {
    cancelToken: cancelSource ? cancelSource.token : undefined,
    params: {
      value,
      onlyActive
    }
  });

  return response.data.result;
}
