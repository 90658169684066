import { Map } from "immutable";
import { RequestStatus } from "../../Types";
import { createSelector } from "reselect";
import createReducer from "../../CreateReducer";
import * as Types from "./Types";
import { NewCustomer } from '../../../Models/Customer';

const initialState = Map({
  customers: Map(),
  timestamp: 0,
  fetchStatus: RequestStatus.None,
  fetchCustomerStatus: RequestStatus.None,
  fetchedCustomer: null,
  newCustomerStatus: RequestStatus.None
});

const fetchCustomersRequest = (state) => {
  return state.set("fetchStatus", RequestStatus.Requested);
};

const fetchCustomersSuccess = (state, { result }) => {
  let newCustomers = Map().asMutable();
  newCustomers = result.customers.reduce((result, customer) => {
    return result.set(customer.id, NewCustomer(customer));
  }, newCustomers);

  return state.withMutations(state => {
    state
      .set("fetchStatus", RequestStatus.Success)
      .set("timestamp", result.timestamp)
      .set("customers", newCustomers.asImmutable());
  });
};

const refreshCustomersSuccess = (state, { result }) => {
  return state.withMutations(state => {
    state.set("timestamp", result.timestamp)

    result.customers.forEach(customer => {
      state.setIn(["customers", customer.id], NewCustomer(customer));
    })
  })
}

const fetchCustomersFailure = state => {
  return state.set("fetchStatus", RequestStatus.Failure);
};

const addCustomer = (state, { customer }) => {
  return state.setIn(["customers", customer.id], NewCustomer(customer));
};

const deleteCustomer = (state, { customerId }) => {
  return state.deleteIn(["customers", customerId]);
};

const modifyCustomer = (state, { customer }) => {
  const customerId = customer.id;
  return state.setIn(["customers", customerId], NewCustomer(customer));
};

const fetchCustomerRequest = state => {
  return state.set("fetchCustomerStatus", RequestStatus.Requested);
};

const fetchCustomerSuccess = (state, { customer }) => {
  return state.withMutations(state => {
    state
      .set("fetchedCustomer", customer)
      .set("fetchCustomerStatus", RequestStatus.Success);
  });
};

const fetchCustomerFailure = state => {
  return state.withMutations(state => {
    state
      .set("fetchCustomerStatus", RequestStatus.Failure)
      .set("fetchedCustomer", null);
  });
};

const newCustomerRequest = state => {
  return state.set("newCustomerStatus", RequestStatus.Requested);
};

const newCustomerSuccess = state => {
  return state.set("newCustomerStatus", RequestStatus.Success);
};

const newCustomerFailure = state => {
  return state.set("newCustomerStatus", RequestStatus.Failure);
};

const reducer = createReducer(initialState, {
  [Types.FETCH_CUSTOMERS_REQUEST]: fetchCustomersRequest,
  [Types.FETCH_CUSTOMERS_SUCCESS]: fetchCustomersSuccess,
  [Types.FETCH_CUSTOMERS_FAILURE]: fetchCustomersFailure,
  [Types.ADD_CUSTOMER]: addCustomer,
  [Types.DELETE_CUSTOMER]: deleteCustomer,
  [Types.MODIFY_CUSTOMER]: modifyCustomer,
  [Types.FETCH_SINGLE_CUSTOMER_REQUEST]: fetchCustomerRequest,
  [Types.FETCH_SINGLE_CUSTOMER_SUCCESS]: fetchCustomerSuccess,
  [Types.FETCH_SINGLE_CUSTOMER_FAILURE]: fetchCustomerFailure,
  [Types.NEW_CUSTOMER_REQUEST]: newCustomerRequest,
  [Types.NEW_CUSTOMER_SUCCESS]: newCustomerSuccess,
  [Types.NEW_CUSTOMER_FAILURE]: newCustomerFailure,
  [Types.REFRESH_CUSTOMERS_SUCCESS]: refreshCustomersSuccess
});

const getReducerState = state => {
  return state.getIn(["customerManager", "customerList"]);
};

/**
 * Returns the status of the last request to fetch customers.
 * @param {object} state - The global state
 */
export const getFetchStatus = state => {
  return getReducerState(state).get("fetchStatus");
};

const getCustomersMap = state => {
  return getReducerState(state).get("customers");
};

/**
 * Returns an array of customers.
 * @param {object} state - The global state
 */
export const getCustomers = createSelector([getCustomersMap], customers => {
  const sortedList = customers.toList().sort((c1, c2) => {
    if (c1.lastModificationDate > c2.lastModificationDate) {
      return -1;
    } else if (c1.lastModificationDate < c2.lastModificationDate) {
      return 1;
    }

    return 0;
  });
  return sortedList.toJS();
});

/**
 * Returns the state of the last fetch customer request.
 * @param {object} state - The global state
 */
export const getFetchSingleCustomerStatus = state => {
  return getReducerState(state).get("fetchCustomerStatus");
};

const getCustomerMap = state => {
  return getReducerState(state).get("fetchedCustomer");
};

/**
 * Returns the last single customer fetched. Returns null of none available or
 * if there was an error while fetching the customer.
 * @param {object} state - The global state
 */
export const getSingleCustomer = createSelector([getCustomerMap], customer => {
  return customer.toJS();
});

/**
 * Returns the status of the last new customer request.
 * @param {object} state - The global state.
 */
export const getNewCustomerStatus = state => {
  return getReducerState(state).get("newCustomerStatus");
};

export const getCustomersTimestamp = state => {
  return getReducerState(state).get("timestamp");
}

export default reducer;
