import React from 'react';
import PropTypes from 'prop-types';

import { useIntl, defineMessages } from 'react-intl';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';

import { wheelTypeToString } from './helper';

const useStyles = makeStyles(theme => ({
  listItem: {
    margin: theme.spacing(1)
  },
  itemDiv: {
    display: 'flex'
  },
  contentDiv: {
    flexGrow: 1
  },
  actionDiv: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  }
}));

const messages = defineMessages({
  workOrder: {
    id: 'Work Order: {workOrder}',
    defaultMessage: 'Work Order: {workOrder}'
  },
  location: {
    id: 'Location: {location}',
    defaultMessage: 'Location: {location}'
  },
  name: {
    id: 'Name: {lastName}, {firstName}',
    defaultMessage: 'Name: {lastName}, {firstName}'
  },
  tires: {
    id: 'Tires: {count} {brand} {model} {size} {threadDepth}',
    defaultMessage: 'Tires: {count} {brand} {model} {size} {threadDepth}'
  },
  wheels: {
    id: 'Wheels: {wheelType}',
    defaultMessage: 'Wheels: {wheelType}'
  },
  dateStored: {
    id: 'Storage date: {storageDate, date, medium}',
    defaultMessage: 'Storage date: {storageDate, date, medium}'
  },
  dateRetrieved: {
    id: 'Date Retrieved: {dateRetrieved, date, medium}',
    defaultMessage: 'Date Retrieved: {dateRetrieved, date, medium}'
  },
  comments: {
    id: 'Comments:',
    defaultMessage: 'Comments:'
  }
})

const StorageItem = ({ storage, onEdit, onPrint, ...others }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <li{...others}>
      <Paper className={classes.listItem}>
        <div className={classes.itemDiv}>
          <div className={classes.contentDiv}>
            <Typography variant="h6">
              {intl.formatMessage(messages.name, { lastName: storage.lastName, firstName: storage.firstName })}
            </Typography>
            <Typography>
              {intl.formatMessage(messages.workOrder, { workOrder: storage.workOrder })}
            </Typography>
            <Typography>
              {intl.formatMessage(messages.location, { location: storage.location })}
            </Typography>
            <Typography>
              {intl.formatMessage(messages.tires, { count: storage.count, brand: storage.brand, model: storage.model, size: storage.size, threadDepth: storage.threadDepth })}
            </Typography>
            <Typography>
              {intl.formatMessage(messages.wheels, { wheelType: wheelTypeToString(intl, storage.wheelType) })}
            </Typography>
            <Typography>
              {intl.formatMessage(messages.dateStored, { storageDate: new Date(storage.dateStored * 1000) })}
            </Typography>
            {storage.dateRetrieved > 0 && (<Typography>
              {intl.formatMessage(messages.dateRetrieved, { dateRetrieved: new Date(storage.dateRetrieved * 1000) })}
            </Typography>)}
            {storage.comments.length > 0 && (
              <React.Fragment>
                <Typography variant="subtitle2">
                  {intl.formatMessage(messages.comments)}
                </Typography>
                <Typography>
                  {storage.comments}
                </Typography>
              </React.Fragment>)}
          </div>
          <div className={classes.actionDiv}>
            {onEdit &&
              <IconButton onClick={() => { onEdit(storage) }}>
                <EditIcon />
              </IconButton>
            }
            {onPrint &&
              <IconButton onClick={() => { onPrint(storage) }}>
                <PrintIcon />
              </IconButton>
            }
          </div>
        </div>
      </Paper>
    </li>
  )
}

StorageItem.propTypes = {
  storage: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onPrint: PropTypes.func
}

export default StorageItem;
