import * as Types from "./Types";
import axios from "axios";
import { loadCustomers, refreshCustomers as backend_refreshCustomers } from "../../../backend/customers";

export const fetchCustomersRequest = () => {
  return {
    type: Types.FETCH_CUSTOMERS_REQUEST
  };
};

export const fetchCustomersSuccess = result => {
  return {
    type: Types.FETCH_CUSTOMERS_SUCCESS,
    result
  };
};

export const fetchCustomersFailure = error => {
  return {
    type: Types.FETCH_CUSTOMERS_FAILURE,
    error
  };
};

export const addCustomer = customer => {
  return {
    type: Types.ADD_CUSTOMER,
    customer
  };
};

export const deleteCustomer = customerId => {
  return {
    type: Types.DELETE_CUSTOMER,
    customerId
  };
};

export const modifyCustomer = customer => {
  return {
    type: Types.MODIFY_CUSTOMER,
    customer
  };
};

export const fetchCustomers = () => {
  return async function (dispatch) {
    try {
      // Check if there is a previous request and if so, cancel it.
      dispatch(fetchCustomersRequest());
      const result = await loadCustomers();
      dispatch(fetchCustomersSuccess(result));
      return result;
    } catch (error) {
      dispatch(fetchCustomersFailure(error));
      throw error;
    }
  };
};

export const fetchSingleCustomerRequest = () => {
  return {
    type: Types.FETCH_SINGLE_CUSTOMER_REQUEST
  };
};

export const fetchSingleCustomerSuccess = customer => {
  return {
    type: Types.FETCH_SINGLE_CUSTOMER_SUCCESS,
    customer
  };
};

export const fetchSingleCustomerFailure = error => {
  return {
    type: Types.FETCH_SINGLE_CUSTOMER_FAILURE,
    error
  };
};

export const fetchSingleCustomer = customerId => {
  return async function (dispatch) {
    try {
      const response = await axios.get("/customer/" + customerId);

      dispatch(fetchSingleCustomerSuccess(response.data.result.customer));
      //dispatch(modifyCustomer(response.data.result.customer)); // Modify the list of customers to reflect the most recent data.
      return response.data.result;
    } catch (error) {
      dispatch(fetchSingleCustomerFailure(error));
      throw error;
    }
  };
};

export const newCustomerRequest = () => {
  return {
    type: Types.NEW_CUSTOMER_REQUEST
  };
};

export const newCustomerSuccess = customer => {
  return {
    type: Types.NEW_CUSTOMER_SUCCESS,
    customer
  };
};

export const newCustomerFailure = error => {
  return {
    type: Types.NEW_CUSTOMER_FAILURE,
    error
  };
};

export const newCustomer = () => {
  return async function (dispatch) {
    try {
      dispatch(newCustomerRequest());
      const response = await axios.get("/customer/newcustomer");
      dispatch(newCustomerSuccess(response.data.result));
      return response.data.result;
    } catch (error) {
      dispatch(newCustomerFailure(error));
      throw error;
    }
  };
};

const refreshCustomersRequest = () => ({
  type: Types.REFRESH_CUSTOMERS_REQUEST
});

const refreshCustomersFailure = (error) => ({
  type: Types.REFRESH_CUSTOMERS_FAILURE,
  error
});

const refreshCustomersSuccess = (result) => ({
  type: Types.REFRESH_CUSTOMERS_SUCCESS,
  result
});

let refreshCount = 0;

export const refreshCustomers = (timestamp) => {
  return async (dispatch) => {
    try {
      dispatch(refreshCustomersRequest());
      let result = {};
      if (refreshCount >= 50) {
        result = await loadCustomers();
        dispatch(fetchCustomersSuccess(result));
        // eslint-disable-next-line
        refreshCount = 0;
      } else {
        result = await backend_refreshCustomers(timestamp);
        dispatch(refreshCustomersSuccess(result));
        // eslint-disable-next-line
        refreshCount = refreshCount + 1;
      }
    }
    catch (error) {
      dispatch(refreshCustomersFailure(error));
    }
  }
}