import axios from 'axios';

/**
 * fetchWalkinsData is an async function returninf the walkins report data
 * for the time period specified.
 * The result is an object of the following form:
 * {
 *  customers: array of customer objects
 *  reportData: {
 *    models: [
 *      {
 *        model: string,
 *        customers: [id]
 *	      sales: [id]
 *        walkins: [id]
 *        bebacks: [id]
 *        internets: [id]
 *        phones: [id]
 *        services: [id]
 *        purchases: [id]
 *        leases: [id]
 *        deliveries: [id]
 *      }
 *    ],
 *    total: {
 *        model: string,
 *        customers: [id]
 *	      sales: [id]
 *        walkins: [id]
 *        bebacks: [id]
 *        internets: [id]
 *        phones: [id]
 *        services: [id]
 *        purchases: [id]
 *        leases: [id]
*         deliveries:[id]
 *      }
 *  }
 * }
 * @param {Date} startTime - The starting time to collect data.
 * @param {Date} endTime - The end time to collect data.
 */
export const fetchWalkinsData = async (startTime, endTime) => {
  const response = await axios.get("/reports/walkins", {
    params: {
      startTime: startTime.valueOf() / 1000,
      endTime: endTime.valueOf() / 1000
    }
  });

  const data = response.data.result;
  return data;
}
