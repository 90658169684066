import axios from 'axios';

export const TemplateID = {
  LoanerAgreement: 'template.loaner.agreement'
};

export const saveTemplate = async (template, cancelSource) => {
  const response = await axios.post("/template", template, { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}

export const getTemplate = async (id, cancelSource) => {
  const response = await axios.get("/template/" + id, { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}
