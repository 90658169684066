import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
    line: {
        marginTop: theme.spacing(1)
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2)
    },
    inputField: {
        width: '20em'
    }
}));

const textToNumber = t => {
    let result = parseInt(t, 10);

    if (isNaN(result)) {
        result = 0;
    }

    return result;
}

const YearEditor = ({ pattern, onSave, onChange, ...others }) => {
    const intl = useIntl();
    const classes = useStyles();

    const isValid = pattern.year > 2020
        && pattern.pattern.trim().length > 0
        && pattern.startingNumber > 0;

    const handleChange = (name, value) => {
        onChange && onChange(Object.assign({}, pattern, { [name]: value }));
    }

    return (
        <Paper className={classes.paper} {...others}>
            <div className={classes.line}>
                <TextField
                    value={pattern.year}
                    onChange={(e) => handleChange('year', textToNumber(e.target.value))}
                    name="year"
                    label={intl.formatMessage({
                        id: 'Year',
                        defaultMessage: 'Year'
                    })}
                />
            </div>

            <div className={classes.line}>
                <TextField
                    value={pattern.pattern}
                    onChange={(e) => handleChange('pattern', e.target.value.trim())}
                    name='pattern'
                    label={intl.formatMessage({
                        id: 'Pattern',
                        defaultMessage: 'Pattern'
                    })}
                />
            </div>

            <div className={classes.line}>
                <TextField
                    value={pattern.startingNumber}
                    onChange={(e) => handleChange('startingNumber', textToNumber(e.target.value))}
                    name="startingNumber"
                    label={intl.formatMessage({
                        id: 'Starting Number',
                        defaultMessage: 'Starting Number'
                    })}
                />
            </div>
            <div className={classes.buttonDiv}>
                <Button
                    onClick={() => onSave(pattern)}
                    variant="contained"
                    disabled={!isValid}
                >
                    {intl.formatMessage({
                        id: "Save",
                        defaultMessage: "Save"
                    })}
                </Button>
            </div>
        </Paper>
    )
}

YearEditor.propTypes = {
    pattern: PropTypes.object,
    onSave: PropTypes.func,
    onChange: PropTypes.func
}

export default YearEditor;
