export const SHOW_MESSAGE = '@@MESSAGE.SHOW_MESSAGE';
export const SHOW_MESSAGE_BY_ID = '@@MESSAGES.SHOW_MESSAGE_BY_ID';
export const HIDE_MESSAGE = '@@MESSAGE.HIDE_MESSAGE';

export const MessageType = {
  Success:  'success',
  Info:     'info',
  Warning : 'warning',
  Error :   'error'
};
