import React from 'react';
import PropTypes from 'prop-types';

import { useActiveStorages } from '../../../Hooks';
import { useIntl } from 'react-intl';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles((theme) => ({
  rootDiv: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto'
  },
  headerDiv: {
    marginBottom: theme.spacing(2)
  },
  lineDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  viewer: {
    height: '100%'
  },
  sectionDiv: {
    marginBottom: '16px'
  }
}));

const ReportLine = ({ storages, onClick, message, ...others }) => {
  const classes = useStyles();

  return (
    <div {...others}>
      <div className={classes.lineDiv} >
        <Typography>
          {message}
        </Typography>
        <IconButton
          color="primary"
          size="small"
          onClick={onClick}
        >
          <ListIcon />
        </IconButton>
      </div>
    </div>
  )
}

ReportLine.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func,
  storages: PropTypes.array
}

const StorageInfos = ({ storages, showStorages }) => {
  const intl = useIntl();

  return (
    <div>
      <ReportLine
        message={
          intl.formatMessage(
            {
              id: "There are {count} storages.",
              defaultMessage: "There are {count} storages."
            },
            { count: storages.all.length }
          )
        }
        onClick={() => { showStorages(storages.all) }}
      />
      <ReportLine
        message={
          intl.formatMessage(
            {
              id: "There are {count} storages without a location.",
              defaultMessage: "There are {count} storages without a location."
            },
            { count: storages.noLocation.length }
          )
        }
        onClick={() => { showStorages(storages.noLocation) }}
      />
      <ReportLine
        message={
          intl.formatMessage(
            {
              id: "There are {count} storages over 90 days.",
              defaultMessage: "There are {count} storages over 90 days."
            },
            { count: storages.over90days.length }
          )
        }
        onClick={() => showStorages(storages.over90days)}
      />
      <ReportLine
        message={
          intl.formatMessage(
            {
              id: "There are {count} storages over 120 days.",
              defaultMessage: "There are {count} storages over 120 days."
            },
            { count: storages.over120days.length }
          )
        }
        onClick={() => showStorages(storages.over120days)}
      />
      <ReportLine
        message={
          intl.formatMessage(
            {
              id: "There are {count} storages over 180 days.",
              defaultMessage: "There are {count} storages over 180 days."
            },
            { count: storages.over180days.length }
          )
        }
        onClick={() => showStorages(storages.over180days)}
      />
      <ReportLine
        message={
          intl.formatMessage(
            {
              id: "There are {count} storages over 270 days.",
              defaultMessage: "There are {count} storages over 270 days."
            },
            { count: storages.over270days.length }
          )
        }
        onClick={() => { showStorages(storages.over270days) }}
      />
      <ReportLine
        message={
          intl.formatMessage(
            {
              id: "There are {count} storages over 365 days.",
              defaultMessage: "There are {count} storages over 365 days."
            },
            { count: storages.over365days.length }
          )
        }
        onClick={() => { showStorages(storages.over365days) }}
      />
    </div>
  );
}

StorageInfos.propTypes = {
  storages: PropTypes.object.isRequired,
  showStorages: PropTypes.func
}

const StorageReport = ({ showStorages, ...others }) => {
  const [storages] = useActiveStorages();
  const intl = useIntl();
  const classes = useStyles();

  let summerStorages = {
    noLocation: [],
    over90days: [],
    over120days: [],
    over180days: [],
    over270days: [],
    over365days: [],
    all: []
  }

  let winterStorages = {
    noLocation: [],
    over90days: [],
    over120days: [],
    over180days: [],
    over270days: [],
    over365days: [],
    all: []
  }

  let unspecifiedStorages = {
    noLocation: [],
    over90days: [],
    over120days: [],
    over180days: [],
    over270days: [],
    over365days: [],
    all: []
  }

  const currentTime = Date.now() / 1000;

  const update = ((storages, s) => {
    storages.all.push(s);

    if (s.location === '') {
      storages.noLocation.push(s);
    }

    const diff = currentTime - s.dateStored;

    if (diff >= (365 * 24 * 3600)) {
      storages.over365days.push(s);
    } else if (diff >= (270 * 24 * 3600)) {
      storages.over270days.push(s);
    } else if (diff >= (180 * 24 * 3600)) {
      storages.over180days.push(s);
    } else if (diff >= (120 * 24 * 3600)) {
      storages.over120days.push(s);
    } else if (diff >= (90 * 24 * 3600)) {
      storages.over90days.push(s);
    }
  });

  storages.forEach(s => {
    switch (s.tireType) {
      case 1:
        update(summerStorages, s);
        break;
      case 2:
        update(winterStorages, s);
        break;
      default:
        update(unspecifiedStorages, s);
    }
  });

  return (
    <div className={classes.rootDiv} {...others}>
      <div className={classes.headerDiv}>
        <Typography variant="h5">
          {
            intl.formatMessage(
              {
                id: "Storage Report",
                defaultMessage: "Storage Report"
              }
            )
          }
        </Typography>
        <Typography variant="h6">
          {
            intl.formatMessage(
              {
                id: "Date: {d, date, medium}",
                defaultMessage: "Date: {d, date, medium}"
              },
              {
                d: new Date()
              }
            )
          }
        </Typography>
      </div>
      <div className={classes.sectionDiv}>
        <Typography variant="h6">
          {
            intl.formatMessage({
              id: "Summer Tire Storages",
              defaultMessage: "Summer Tire Storages"
            })
          }
        </Typography>
        <StorageInfos storages={summerStorages} showStorages={showStorages} />
      </div>
      <div className={classes.sectionDiv}>
        <Typography variant="h6">
          {
            intl.formatMessage({
              id: "Winter Tire Storages",
              defaultMessage: "Winter Tire Storages"
            })
          }
        </Typography>
        <StorageInfos storages={winterStorages} showStorages={showStorages} />
      </div>
      <div className={classes.sectionDiv}>
        <Typography variant="h6">
          {
            intl.formatMessage({
              id: "Unspecified Tire Storages",
              defaultMessage: "Unspecified Tire Storages"
            })
          }
        </Typography>
        <StorageInfos storages={unspecifiedStorages} showStorages={showStorages} />
      </div>
    </div>
  );
}

StorageReport.propTypes = {
  showStorages: PropTypes.func
}

export default StorageReport;
