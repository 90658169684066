import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DepartmentIcon = (props, ref) => {
  return (
    <SvgIcon ref={ref} {...props}>
      <g id="Layer_1">
        <path d="M8.484,9.188 L8.484,11.297 L15.516,11.297 L15.516,9.188 C15.516,7.249 13.938,5.672 12,5.672 C10.062,5.672 8.484,7.249 8.484,9.188 z M8.484,9.188" />
        <path d="M14.812,15.516 C14.812,13.965 13.551,12.703 12,12.703 C10.449,12.703 9.188,13.965 9.188,15.516 C9.188,17.067 10.449,18.328 12,18.328 C13.551,18.328 14.812,17.067 14.812,15.516 z M14.812,15.516" />
        <path d="M6.328,9.891 C6.328,8.584 5.429,7.492 4.219,7.178 L4.219,2.812 L9.188,2.812 C9.188,4.364 10.449,5.672 12,5.672 C13.551,5.672 14.812,4.364 14.812,2.812 L19.781,2.812 L19.781,7.178 C18.571,7.492 17.672,8.584 17.672,9.891 C17.672,11.442 18.933,12.703 20.484,12.703 C22.035,12.703 23.297,11.442 23.297,9.891 C23.297,8.584 22.397,7.492 21.188,7.178 L21.188,1.406 L14.421,1.406 C13.934,0.57 13.037,0 12,0 C10.963,0 10.066,0.57 9.579,1.406 L2.812,1.406 L2.812,7.178 C1.603,7.492 0.703,8.584 0.703,9.891 C0.703,11.442 1.965,12.703 3.516,12.703 C5.067,12.703 6.328,11.442 6.328,9.891 z M6.328,9.891" />
        <path d="M20.484,12.703 C18.546,12.703 16.969,14.28 16.969,16.219 L16.969,18.328 L19.781,18.328 L19.781,22.594 L15.516,22.594 L15.516,21.844 C15.516,19.905 13.938,18.328 12,18.328 C10.062,18.328 8.484,19.905 8.484,21.844 L8.484,22.594 L4.219,22.594 L4.219,18.328 L7.031,18.328 L7.031,16.219 C7.031,14.28 5.454,12.703 3.516,12.703 C1.577,12.703 0,14.28 0,16.219 L0,18.328 L2.812,18.328 L2.812,24 L21.188,24 L21.188,18.328 L24,18.328 L24,16.219 C24,14.28 22.423,12.703 20.484,12.703 z M20.484,12.703" />
      </g>
    </SvgIcon >
  );
}


export default React.forwardRef(DepartmentIcon);
