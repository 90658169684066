import React from 'react';
import PropTypes from 'prop-types';

import { useIntl, defineMessages } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import PrintPage from './PrintPage';

const messages = defineMessages({
  title: {
    id: 'Print Storage Tickets',
    defaultMessage: 'Print Storage Tickets'
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel'
  },
  print: {
    id: 'Print',
    defaultMessage: 'Print'
  }
});

const PrintDialog = ({ storage, onClose, ...others }) => {
  const intl = useIntl();

  const handleOnPrint = () => {
    window.print();
    onClose();
  }

  return (
    <Dialog {...others}>
      <DialogTitle>{intl.formatMessage(messages.title)}</DialogTitle>
      <DialogContent>
        <PrintPage storage={storage} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {intl.formatMessage(messages.cancel)}
        </Button>
        <Button onClick={handleOnPrint} color="primary">
          {intl.formatMessage(messages.print)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PrintDialog.propTypes = {
  storage: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

export default PrintDialog;
