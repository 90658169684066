import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  listItem: {
    margin: theme.spacing(1)
  }
}));

const messages = defineMessages({
  description: {
    id: 'Change made by {name} on {timestamp, date, medium}',
    defaultMessage: 'Change made by {name} on {timestamp, date, medium}'
  }
})

const HistoryItem = ({ storage, ...others }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <li className={classes.listItem} {...others}>
      <Paper>
        <Typography>
          {intl.formatMessage(messages.description, { name: storage.userName, timestamp: new Date(storage.modificationTimestamp * 1000) })}
        </Typography>
      </Paper>
    </li>
  );
}

HistoryItem.propTypes = {
  storage: PropTypes.object.isRequired
}

export default HistoryItem;
