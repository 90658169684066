import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import ReportsIcon from "@material-ui/icons/Assessment";
import { PageId } from "../../DataStore/MainPage/Types";
import { setMainPage, getMainPage } from '../../DataStore';

const ServiceReportButton = ({ ...others }) => {
  const mainPage = useSelector(getMainPage);
  const dispatch = useDispatch();
  const setPage = pageId => (dispatch(setMainPage(pageId)));

  return (
    <IconButton
      color="inherit"
      aria-label="Reports"
      disabled={mainPage === PageId.ServiceReports}
      onClick={() => {
        setPage(PageId.ServiceReports);
      }}
      {...others}
    >
      <ReportsIcon />
    </IconButton>
  );
}

export default ServiceReportButton;
