import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";

import { language, messages } from "../DataStore/Language/Reducer";

const LanguageProvider = ({ children, ...others }) => {
  const lang = useSelector(language);
  const msg = useSelector(messages);

  return (
    <IntlProvider
      locale={lang}
      key={lang}
      messages={msg}
      {...others}
    >
      {children}
    </IntlProvider>
  );
}

LanguageProvider.propTypes = {
  children: PropTypes.any
};

export default LanguageProvider;
