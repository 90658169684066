import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDeliveries } from '../../Hooks';
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, } from '@material-ui/styles';
import { IconButton, Typography, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import PreviousIcon from '@material-ui/icons/ArrowBackIos';
import NextIcon from '@material-ui/icons/ArrowForwardIos';
import { useIntl } from 'react-intl';
import { firstBusinessDayOfMonth, getBusinessMonth, lastBusinessDayOfMonth } from '../../helpers';

const useStyles = makeStyles((theme) => ({
    delivery: {
        display: 'flex'
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        flexGrow: 1,
        flexShrink: 1
    },
    salesmanDiv: {
        marginBottom: '24px'
    },
    headerButton: {
        flexGrow: 0,
        flexShrink: 0
    },
    header: {
        display: 'flex'
    },
    fullWidth: {
        width: '100%'
    }
}));

const Delivery = ({ d, ...others }) => {
    return (
        <TableRow
            key={d.stockNumber}
            {...others}
        >
            <TableCell>
                {d.model}
            </TableCell>
            <TableCell>
                {d.firstName + " " + d.lastName}
            </TableCell>
            <TableCell>
                {d.stockNumber}
            </TableCell>
        </TableRow >
    )
}

Delivery.propTypes = {
    d: PropTypes.object.isRequired
}

const FutureDeliveries = () => {
    const now = new Date();
    const [startTime, setStartTime] = useState(firstBusinessDayOfMonth(now));
    const [endTime, setEndTime] = useState(lastBusinessDayOfMonth(now));
    const [deliveries, status] = useDeliveries(startTime, endTime);
    const classes = useStyles();
    const intl = useIntl();

    const goNextMonth = () => {
        const nextMonth = new Date(endTime.getTime() + 15 * 24 * 3600 * 1000);
        const nextStartTime = firstBusinessDayOfMonth(nextMonth);
        const nextEndTime = lastBusinessDayOfMonth(nextMonth);

        setStartTime(nextStartTime);
        setEndTime(nextEndTime);
    }

    const goPreviousMonth = () => {
        const nextMonth = new Date(startTime.getTime() - 15 * 24 * 3600 * 1000);
        const nextStartTime = firstBusinessDayOfMonth(nextMonth);
        const nextEndTime = lastBusinessDayOfMonth(nextMonth);

        setStartTime(nextStartTime);
        setEndTime(nextEndTime);
    }

    if (status === 'success') {
        const items = deliveries.map((s) => {
            let futureDeliveries = s.futureDeliveries.map(d => {
                return (<Delivery key={d.stockNumber + d.firstName + d.lastName} d={d} />);
            });

            return (
                <div
                    key={s.salesmanID}
                    className={classes.salesmanDiv}
                >
                    <Typography variant="h5">
                        {s.name}
                    </Typography>
                    <Table>
                        <TableBody>
                            {futureDeliveries}
                        </TableBody>
                    </Table>
                </div>
            )
        });

        return (
            <div>
                <div className={classes.header}>
                    <IconButton onClick={goPreviousMonth}>
                        <PreviousIcon />
                    </IconButton>
                    <div className={classes.title}>
                        <Typography variant="h4">
                            {intl.formatMessage(
                                {
                                    id: "Future deiveries for {month} {year}",
                                    defaultMessage: "Future deliveries for {month} {year}"
                                },
                                {
                                    month: getBusinessMonth(startTime, intl),
                                    year: startTime.getFullYear()
                                }
                            )}
                        </Typography>
                    </div>
                    <IconButton onClick={goNextMonth}>
                        <NextIcon />
                    </IconButton>
                </div>
                <div>
                    {items}
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <CircularProgress
                    size={24}
                    color="primary"
                />
            </div >
        )
    }
}

export default FutureDeliveries;
