import { createStore, applyMiddleware, compose } from "redux";
import { combineReducers } from "redux-immutable";
import thunkMiddleware from "redux-thunk";

import { Record } from "immutable";

import customerManagerReducer from "./CustomerManager/Reducer";
import loginReducer from "./Login/Reducer";
import languageReducer from "./Language/Reducer";
import usersReducer from "./Users/Reducer";
import mainPageReducer from "./MainPage/Reducer";
import settingsReducer from "./Settings/Reducer";
import messageReducer from "./Message/Reducer";
import salesmanAvailabilityReducer from './SalesmanAvailability/Reducer';
import advancedSearchReducer from './CustomerManager/AdvancedSearch/Reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const StateRecord = Record({
  customerManager: undefined, // should be removed
  login: undefined,
  mainPage: undefined,
  language: undefined,
  users: undefined, // should be removed
  settings: undefined, // should be removed
  message: undefined, //should be removed
  salesmanAvailability: undefined, // should be removed ?
  advancedSearch: undefined // should be removed
});

const reducer = combineReducers(
  {
    customerManager: customerManagerReducer,
    login: loginReducer,
    mainPage: mainPageReducer,
    language: languageReducer,
    users: usersReducer,
    settings: settingsReducer,
    message: messageReducer,
    salesmanAvailability: salesmanAvailabilityReducer,
    advancedSearch: advancedSearchReducer
  },
  StateRecord
);

export function createDataStore() {
  return createStore(
    reducer,
    /* preloadedState, */ composeEnhancers(applyMiddleware(thunkMiddleware))
  );
}
