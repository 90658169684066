import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import StartPage from './StartPage';
import { Deliveries } from './Deliveries';

const Router = () => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={StartPage} />
        <Route exact path="/deliveries" component={Deliveries} />
      </Switch>
    </HashRouter>
  );
}

export default Router;
