import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import VehicleItem from './VehicleItem';

const useStyles = makeStyles(() => ({
  rootDiv: {
    width: '100%',
    height: '100%'
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  }
}));

const VehicleList = ({ vehicles, onEdit, onDelete, ...others }) => {
  const classes = useStyles();

  const items = vehicles.map(v => {
    return <VehicleItem
      key={v.id}
      vehicle={v}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  });

  return (
    <div className={classes.rootDiv} {...others}>
      <ul className={classes.list}>
        {items}
      </ul>
    </div>
  )
}

VehicleList.propTypes = {
  vehicles: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
}

export default VehicleList;
