import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { ListSubheader } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useIntl } from 'react-intl';

export const Items = {
    ImportStocks: 'importstocks',
    NewStock: 'newstock',
    ManageStocks: 'managestocks',
    ManageModels: 'managemodels',
    ManageColors: 'managecolors',
    ManageYears: 'manageyears'
};

const AccountingMenu = ({ onSelect, selection, ...others }) => {
    const intl = useIntl();

    return (
        <List
            component="nav"
            aria-labelledby="Accounting"
            subheader={
                <ListSubheader component="div">
                    {
                        intl.formatMessage({
                            id: 'Accounting',
                            defaultMessage: 'Accounting'
                        })
                    }
                </ListSubheader>
            }
            {...others}
        >
            <ListItem
                button
                selected={selection === Items.ImportStocks}
                onClick={() => onSelect(Items.ImportStocks)}
            >
                <ListItemText primary=
                    {
                        intl.formatMessage({
                            id: "Import Stocks",
                            defaultMessage: "Import Stocks"
                        })
                    }
                >
                </ListItemText>
            </ListItem>
            <ListItem
                button
                selected={selection === Items.ManageStocks}
                onClick={() => onSelect(Items.ManageStocks)}
            >
                <ListItemText primary=
                    {
                        intl.formatMessage({
                            id: "Manage Stocks",
                            defaultMessage: "Manage Stocks"
                        })
                    }
                >
                </ListItemText>
            </ListItem>
            <ListItem
                button
                selected={selection === Items.ManageModels}
                onClick={() => onSelect(Items.ManageModels)}
            >
                <ListItemText primary=
                    {
                        intl.formatMessage({
                            id: "Manage Models",
                            defaultMessage: "Manage Models"
                        })
                    }
                >
                </ListItemText>
            </ListItem>
            <ListItem
                button
                selected={selection === Items.ManageColors}
                onClick={() => onSelect(Items.ManageColors)}
            >
                <ListItemText primary=
                    {
                        intl.formatMessage({
                            id: "Manage Colors",
                            defaultMessage: "Manage Colors"
                        })
                    }
                >
                </ListItemText>
            </ListItem>
            <ListItem
                button
                selected={selection === Items.ManageYears}
                onClick={() => onSelect(Items.ManageYears)}
            >
                <ListItemText primary=
                    {
                        intl.formatMessage({
                            id: "Manage Years",
                            defaultMessage: "Manage Years"
                        })
                    }
                >
                </ListItemText>
            </ListItem>
        </List>
    )
}

AccountingMenu.propTypes = {
    onSelect: PropTypes.func,
    selection: PropTypes.string
}

export default AccountingMenu;
