import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import * as API from '../backend';

/**
 * useDeliveries is a hook that retrieved delivery data from the backend.
 * If startTime and endTime are specified, they are used for the data
 * retrieval. Otherwise the current business month is used.
 * @param {Date} startTime - Optional.
 * @param {Date} endTime - Optional.
 */
export const useDeliveries = (startTime, endTime, refresh = 30000) => {

  const [deliveries, setDeliveries] = useState([]);
  const [status, setStatus] = useState("loading");
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  useEffect(() => {
    const cancelSource = API.getCancelSource();
    let timeout = undefined;

    const fetchDeliveries = async () => {
      try {
        const deliveries = await API.getDeliveries(startTime, endTime, cancelSource);
        setDeliveries(deliveries.deliveries);
        setStatus("success");

        if (refresh) {
          timeout = setTimeout(() => {
            fetchDeliveries();
          }, refresh);
        }
      }
      catch (error) {
        if (API.isCancel(error)) {
          setStatus("cancelled");
        } else {
          setStatus("failure");
          enqueueSnackbar(
            intl.formatMessage({
              id: "Erreur while fetching deliveries.",
              defaultMessage: "Erreur while fetching deliveries."
            }),
            {
              variant: 'error'
            }
          );
        }
      }
    }

    fetchDeliveries();

    return () => {
      clearTimeout(timeout);
      cancelSource.cancel();
    }
  }, [enqueueSnackbar, intl, startTime, endTime, refresh]);

  return [deliveries, status];
}
