export const SET_MAIN_PAGE = "@@MAINSCREEN_SET_MAIN_PAGE";
export const SET_DEPARTMENT = "@@MAINSCREEN_SET_DEPARTMENT";

export const PageId = {
  None: "NONE",
  Dashboard: "DASHBOARD",
  Customers: "CUSTOMERS",
  Reports: "REPORTS",
  Maps: "MAPS",
  Settings: "SETTINGS",
  Phones: "PHONES",
  Loaner: "LOANER",
  Storage: "STORAGE",
  Vehicles: "VEHICLES",
  Reservations: "RESERVATIONS",
  ServiceReports: 'SERVICEREPORTS',
  UploadStockFile: "UPLOADSTOCKFILE",
  ColorManagement: "COLORMANAGEMENT",
  ModelManagement: "MODELMANAGEMENT",
  Accounting: "ACCOUNTING"
};
