import axios from 'axios';

/**
 * fetchPerformanceData is an async function returning the performance data
 * for the time period specified.
 * The result is an object of the following form:
 * {
 *   customers: array of customer object
 *   reportData: {
 *     allVehicles: array of object with performance data
 *     newVehicles: array of object with performance data
 *     usedVehicles: array of object with performance data
 *   }
 * }
 * @param {Date} startTime - The starting time to collect data.
 * @param {Date} endTime - The end time to collect data.
 */
export const fetchPerformanceData = async (startTime, endTime, summary = false) => {
  const params = {
    startTime: startTime.valueOf() / 1000,
    endTime: endTime.valueOf() / 1000
  };

  if (summary) {
    params.summary = true;
  }

  const response = await axios.get("/reports/performance", {
    params: params
  });
  const data = response.data.result;
  return data;
};

