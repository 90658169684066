import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ModelLine from './ModelLine';

const WalkinReportUI = ({ models, total, ...others }) => {
  const intl = useIntl();

  const rows = models.map(model => {
    return (
      <ModelLine key={model.model} model={model} />
    )
  });

  return (
    <div {...others}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {intl.formatMessage({
                id: "Model",
                defaultMessage: "Model"
              })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: "Customers",
                defaultMessage: "Customers"
              })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: "Walkins",
                defaultMessage: "Walkins"
              })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: "Internet",
                defaultMessage: "Internet"
              })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: "Phones",
                defaultMessage: "Phones"
              })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: "Service",
                defaultMessage: "Service"
              })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: "Purchases",
                defaultMessage: "Purchases"
              })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: "Leases",
                defaultMessage: "Leases"
              })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: "Sales",
                defaultMessage: "Sales"
              })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: "Sale Ratio",
                defaultMessage: "Sale Ratio"
              })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: "Deliveries",
                defaultMessage: "Deliveries"
              })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows}
          <ModelLine model={total} />
        </TableBody>
      </Table>
    </div>
  );
}

WalkinReportUI.propTypes = {
  models: PropTypes.array,
  total: PropTypes.object
};

export default WalkinReportUI;
