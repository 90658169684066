import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import CustomerList from "../CustomerList/CustomerListWithSearch";
import CustomerEditor from "../CustomerEditor/CustomerEditor";

import { showMessage } from "../DataStore/Message/Actions";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classNames from "classnames";
import SalesmanTurn from "../SalesmanTurn/SalesmanTurn";
import { ConfirmationDialog } from "../Components";
import { deleteCustomer } from "../backend";
import { getUser } from "../DataStore/Login/Reducer";

const styles = theme => ({
  rootDiv: {
    display: "flex",
    overflow: "hidden",
    width: "100%",
    height: "100%"
  },
  viewDiv: {
    width: "100%",
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflow: 'hidden'
  },
  viewDivMinimized: {
    width: 0,
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflow: "hidden"
  },
  splitDiv: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  listDiv: {
    maxWidth: '80%',
    height: '100%'
  },
  customerEditorDiv: {
    height: "100%"
  },
  salesmanTurn: {

  }
});

const CustomerManager = ({ intl, classes, showMessage, currentUser, ...others }) => {
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [editedCustomer, setEditedCustomer] = useState(null);

  const largeScreen = useMediaQuery('(min-width:600px)');

  const closeCustomerEditor = () => {
    setEditedCustomer(null);
  }

  // We create the following handlers with useMemo to prevent them from
  // changing on each re-render to prevent the customer list to re-render
  // each time since it receives them as props.
  const handleEditCustomer = useMemo(() => (customerId) => {
    setEditedCustomer(customerId);
  }, [setEditedCustomer]);

  const handleAddCustomer = useMemo(() => () => {
    setEditedCustomer('')
  }, [setEditedCustomer]);

  const handleDeleteCustomer = useMemo(() => (id) => {
    setCustomerToDelete(id);
    setConfirmationDialog(true);
  }, [setCustomerToDelete, setConfirmationDialog]);

  const handleConfirmationDialogOnCancel = () => {
    setConfirmationDialog(false);
    setCustomerToDelete(null);
  }

  const handleConfirmationDialogOnConfirm = () => {
    const customerID = customerToDelete;
    setConfirmationDialog(false);
    setCustomerToDelete(null);

    deleteCustomer(customerID)
      .then(() => {

      })
      .catch(() => {

      })
  }

  return (
    <div className={classes.rootDiv} {...others}>
      <div
        className={classNames({
          [classes.viewDiv]: editedCustomer === null,
          [classes.viewDivMinimized]: editedCustomer !== null
        })}
      >
        <div className={classes.splitDiv}>
          <div className={classes.listDiv}>
            <CustomerList
              onEditCustomer={handleEditCustomer}
              onAddCustomer={handleAddCustomer}
              onDeleteCustomer={handleDeleteCustomer}
            />
          </div>
          {largeScreen &&
            <SalesmanTurn className={classes.salesmanTurn} />
          }
        </div>
      </div>
      <div className={classes.customerEditorDiv}>
        {editedCustomer !== null &&
          <CustomerEditor
            customerID={editedCustomer}
            onCancel={closeCustomerEditor}
            onSaved={closeCustomerEditor}
          />
        }
      </div>
      <ConfirmationDialog
        open={confirmationDialog}
        onCancel={handleConfirmationDialogOnCancel}
        onConfirm={handleConfirmationDialogOnConfirm}
        title={intl.formatMessage({
          id: "Confirm Delete",
          defaultMessage: "Confirm Delete"
        })}
        message={intl.formatMessage({
          id: "Are you sure you want to delete the customer?",
          defaultMessage: "Are you sure you want to delete the customer?"
        })}
      />
    </div>
  );
}

CustomerManager.propTypes = {
  intl: PropTypes.object,
  classes: PropTypes.object,
  showMessage: PropTypes.func,
  currentUser: PropTypes.object
};

const mapStateToProps = state => ({
  currentUser: getUser(state)
});

const mapDispatchToProps = dispatch => ({
  showMessage: (msg, type) => dispatch(showMessage(msg, 5000, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles)(CustomerManager)));
