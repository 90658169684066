import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchUsersStatus as getFetchUsersStatus, fetchSettingsStatus as getFetchSettingsStatus } from '../../DataStore/Settings/Settings/Reducer';
import { fetchUsers, fetchSettings } from '../../DataStore/Settings/Settings/Actions';

import { RequestStatus } from '../../DataStore/Types';

import WaitSpinner from '../../Wait/WaitSpinner';
import AppSettings from "./AppSettings";

const MainSettings = ({ ...others }) => {
  const dispatch = useDispatch();
  const fetchUsersStatus = useSelector(getFetchUsersStatus);
  const fetchSettingsStatus = useSelector(getFetchSettingsStatus);

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchSettings());
  }, [dispatch]);

  if (fetchUsersStatus === RequestStatus.Success && fetchSettingsStatus === RequestStatus.Success) {
    return (
      <AppSettings {...others} />
    )
  }
  else {
    return (
      <WaitSpinner show={true} />
    )
  }
}

export default MainSettings;
