import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { setPassword } from '../DataStore/Users/Actions';
import { useSnackbar } from 'notistack';

const messages = defineMessages({
  passwordMismatch: {
    id: "Passwords are not the same.",
    defaultMessage: "Passwords are not the same"
  },
  passwordError: {
    id: "Error while changing password.",
    defaultMessage: "Error while changing password"
  }
})

const SetPasswordDialog = ({ open, onClose, ...others }) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [newPassword, setNewPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");

  const handleSetPassword = () => {
    if (newPassword !== confirmation) {
      enqueueSnackbar(
        intl.formatMessage(messages.passwordMismatch),
        {
          variant: 'error'
        }
      );

      return;
    }

    dispatch(setPassword(newPassword))
      .then(() => {
        onClose();
      })
      .catch(() => {
        enqueueSnackbar(
          intl.formatMessage(messages.passwordError),
          {
            variant: 'error'
          }
        );
      })
  }

  return (
    <Dialog open={open} {...others}>
      <DialogTitle>
        {intl.formatMessage({
          id: "Set Password",
          defaultMessage: "Set Password"
        })}
      </DialogTitle>
      <DialogContent>
        <TextField
          type="password"
          fullWidth={true}
          label={intl.formatMessage({
            id: "Password",
            defaultMessage: "Password"
          })}
          value={newPassword}
          onChange={(event) => { setNewPassword(event.target.value) }}
          name="newpassword"
        />
        <TextField
          type="password"
          fullWidth={true}
          label={intl.formatMessage({
            id: "Password Confirmation",
            defaultMessage: "Password Confirmation"
          })}
          value={confirmation}
          onChange={(event) => { setConfirmation(event.target.value) }}
          name="confirmation"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {intl.formatMessage({
            id: "Cancel",
            defaultMessage: "Cancel"
          })}
        </Button>
        <Button onClick={handleSetPassword}>
          {intl.formatMessage({
            id: "Ok",
            defaultMessage: "Ok"
          })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SetPasswordDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default SetPasswordDialog;
