import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import * as API from '../backend';
import { useEventProvider } from '../Providers/EventProvider';

export const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState("loading");
  const { enqueueSnackbar } = useSnackbar();
  const eventProvider = useEventProvider();
  const intl = useIntl();

  useEffect(() => {
    const cancelSource = API.getCancelSource();

    const fetchUsers = async () => {
      try {
        setStatus("loading");
        const users = await API.allUsers(cancelSource);
        setUsers(users);
        setStatus("success");
      }
      catch (error) {
        if (API.isCancel(error)) {
          setStatus("cancelled");
        } else {
          setStatus("failure");
          enqueueSnackbar(
            intl.formatMessage({
              id: "Erreur while fetching users.",
              defaultMessage: "Erreur while fetching users."
            }),
            {
              variant: 'error'
            }
          );
        }
      }
    }

    fetchUsers();

    eventProvider.addEventListener(API.Events.addUser, fetchUsers);
    eventProvider.addEventListener(API.Events.updateUser, fetchUsers);
    eventProvider.addEventListener(API.Events.deleteUser, fetchUsers);

    return () => {
      cancelSource.cancel();
      eventProvider.removeEventListener(API.Events.addUser, fetchUsers);
      eventProvider.removeEventListener(API.Events.updateUser, fetchUsers);
      eventProvider.removeEventListener(API.Events.deleteUser, fetchUsers);
    }
  }, [enqueueSnackbar, intl, eventProvider]);

  return [users, status];
}
