import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { fromJS } from 'immutable';
import UserList from "./UserList";
import {
  editedUser as getEditedUser,
  isEditedUserModified as isUserModified,
  saveUserStatus as getSaveUserStatus
} from "../DataStore/Settings/Users/Reducer";
import {
  editUser,
  changeEditedUser,
  saveUser
} from "../DataStore/Settings/Users/Actions";
import { RequestStatus } from "../DataStore/Types";
import { token as getToken } from "../DataStore/Login/Reducer";
import UserEditor from "./UserEditor";
import WaitSpinner from "../Wait/WaitSpinner";
import { User } from "../Models/User";
import { useUsers } from "../Hooks";

const Users = ({ ...others }) => {
  const dispatch = useDispatch();
  const editedUser = useSelector(getEditedUser);
  const [users] = useUsers();
  const token = useSelector(getToken);
  const userModified = useSelector(isUserModified);
  const saveUserStatus = useSelector(getSaveUserStatus);

  const handleSaveUser = async (user) => {
    await dispatch(saveUser(token, user));
    dispatch(editUser(null));
  }

  const handleAddUser = () => {
    dispatch(editUser(new User()));
  }

  const handleEditUser = (user) => {
    dispatch(editUser(fromJS(user)));
  }

  const handleCancelEdit = () => {
    dispatch(editUser(null));
  }

  const handleUserChange = (name, value) => {
    dispatch(changeEditedUser(name, value));
  }

  if (editedUser) {
    return (
      <div {...others}>
        <UserEditor
          user={editedUser}
          onCancel={handleCancelEdit}
          onChange={handleUserChange}
          onSave={handleSaveUser}
          saveDisabled={
            !userModified || !editedUser.isValid()
          }
        />
        {saveUserStatus === RequestStatus.Requested ? (
          <WaitSpinner show={true} />
        ) : null}
      </div>
    );
  } else {
    return (
      <UserList
        users={users}
        onEditUser={handleEditUser}
        onAddUser={handleAddUser}
        {...others}
      />
    );
  }
}

export default Users;
