import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
    line: {
        marginTop: theme.spacing(1)
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2)
    },
    inputField: {
        width: '20em'
    }
}));

const ColorEditorUI = ({ color, onSave, onChange, ...others }) => {
    const classes = useStyles();
    const intl = useIntl();

    const changeValue = evt => {
        const target = evt.target;
        const name = target.name;
        const value = target.value;

        const newColor = Object.assign({}, color, { [name]: value });
        onChange(newColor);
    }

    const isValid = color.code.trim().length > 0 && color.description.trim().length > 0;

    return (
        <Paper className={classes.paper} {...others}>
            {!color.userEdited && (
                <Typography
                    color="secondary"
                    variant="h6"
                >
                    {intl.formatMessage({
                        id: "New Color",
                        defaultMessage: "New Color"
                    })}
                </Typography>
            )}
            <div className={classes.line}>
                <TextField
                    name="code"
                    className={classes.inputField}
                    value={color.code}
                    label={
                        intl.formatMessage({
                            id: "Color Code",
                            defaultMessage: "Color Code"
                        })
                    }
                    onChange={changeValue}
                />
            </div>
            <div className={classes.line}>
                <TextField
                    name="description"
                    className={classes.inputField}
                    value={color.description}
                    label={
                        intl.formatMessage({
                            id: "Description",
                            defaultMessage: "Description"
                        })
                    }
                    onChange={changeValue}
                />
            </div>
            <div className={classes.buttonDiv}>
                <Button
                    onClick={() => onSave(color)}
                    variant="contained"
                    disabled={!isValid}
                >
                    {intl.formatMessage({
                        id: "Save",
                        defaultMessage: "Save"
                    })}
                </Button>
            </div>
        </Paper>
    )
}

ColorEditorUI.propTypes = {
    color: PropTypes.object,
    onSave: PropTypes.func,
    onChange: PropTypes.func
}

export default ColorEditorUI;
