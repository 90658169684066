import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const messages = defineMessages({
  noButton: {
    id: 'No',
    defaultMessage: "No"
  },
  yesButton: {
    id: 'Yes',
    defaultMessage: "Yes"
  }
})

const ConfirmationDialog = ({ title, message, onCancel, onConfirm, ...others }) => {
  const intl = useIntl();

  return (
    <Dialog
      onClose={onCancel}
      disableEscapeKeyDown
      maxWidth="xs"
      {...others}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {intl.formatMessage(messages.noButton)}
        </Button>
        <Button onClick={onConfirm} color="primary">
          {intl.formatMessage(messages.yesButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ConfirmationDialog;
