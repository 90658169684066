import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  rootDiv: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const EmailEditor = ({ onChange, value, subjectValue, onSubjectChange, invalidFields, ...others }) => {
  const classes = useStyles();
  const intl = useIntl();

  const isInvalid = field => {
    return invalidFields.indexOf(field) >= 0;
  }

  return (
    <div className={classes.rootDiv} {...others}>
      <TextField
        type="text"
        name="comments"
        label={intl.formatMessage({
          id: "Email Subject",
          defaultMessage: "Email Subject"
        })}
        value={subjectValue}
        onChange={onSubjectChange}
        margin="normal"
        error={isInvalid("emailSubject")}
      />
      <ReactQuill value={value} onChange={onChange} style={{ backgroundColor: isInvalid("emailContent") ? 'red' : '' }} />
    </div>
  )
}

EmailEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  onSubjectChange: PropTypes.func,
  subjectValue: PropTypes.string,
  invalidFields: PropTypes.array
};

export default EmailEditor;
