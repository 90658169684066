import createReducer from "../CreateReducer";
import * as Types from "./Types";
import { Departments } from "../../Models/User";
import { Map } from "immutable";

const initialState = Map({
  mainPage: Types.PageId.Customers,
  department: Departments.Sales
});

const setMainPage = (state, { pageId }) => {
  return state.set("mainPage", pageId);
};

const setDepartment = (state, { department }) => {
  return state.set('department', department)
}

const getReducerState = state => {
  return state.get('mainPage');
}

export function getMainPage(state) {
  return getReducerState(state).get("mainPage");
}

export function getDepartment(state) {
  return getReducerState(state).get('department');
}

export default createReducer(initialState, {
  [Types.SET_MAIN_PAGE]: setMainPage,
  [Types.SET_DEPARTMENT]: setDepartment
});
