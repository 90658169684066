import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import CustomerSource from './CustomerSource';
import Closing from './Closing';

import { getCustomers } from '../DataStore/CustomerManager/CustomerList/Reducer';
import performanceDataProvider, { PerformanceProviderTypes } from './performanceDataProvider';
import YearlySales from './YearlySales';
import MonthlySales from './MonthlySales';
import { HorizontalScrollView } from '../Components';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rootDiv: {
    width: "100%",
    height: "100%"
  },
  horizontalScrollView: {
    width: '100%',
    height: '100%'
  },
  widget: {
    width: '375px',
    '@media (max-width: 500px)': {
      height: '100%',
      width: '300px'
    },
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  widgetRow: {
    height: '100%',
    display: 'inline-flex',
    flexDirection: 'row',
    flexGrow: 0,
    flexShrink: 0
  },
  staticRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    overflowX: 'auto'
  }
}));

const ManagerDashboard = ({ currentMonthData, lastYearData, ...others }) => {
  const classes = useStyles();
  const customers = useSelector(getCustomers);
  const smallScreen = useMediaQuery('(max-width:500px)');

  const widgets = (
    <React.Fragment>
      {customers && <CustomerSource className={classes.widget} customers={customers} />}
      {currentMonthData && lastYearData && <Closing className={classes.widget} data={currentMonthData.allVehicles} lastYearData={lastYearData.allVehicles} />}
      <YearlySales className={classes.widget} />
      <MonthlySales className={classes.widget} />
    </React.Fragment>
  );

  if (smallScreen) {
    return (
      <div className={classes.rootDiv} {...others}>
        <HorizontalScrollView className={classes.horizontalScrollView}>
          <div className={classes.widgetRow}>
            {widgets}
          </div>
        </HorizontalScrollView>
      </div>
    )
  } else {
    return (
      <div className={classes.rootDiv} {...others}>
        <div className={classes.staticRow}>
          {widgets}
        </div>
      </div>
    )
  }
}

ManagerDashboard.propTypes = {
  currentMonthData: PropTypes.object,
  lastYearData: PropTypes.object
};

export default performanceDataProvider(PerformanceProviderTypes.LastYear)(
  performanceDataProvider(PerformanceProviderTypes.CurrentMonth)(ManagerDashboard));
