import { Record, List, fromJS } from 'immutable';
import Visit, { VisitType } from './Visit';

const Customer = Record({
  id: null,
  lastName: '',
  firstName: '',
  email: '',
  active: true,
  cellPhone: '',
  homePhone: '',
  workPhone: '',
  lastModificationDate: 0,
  salesman: null,
  tradeIn: '',
  tradeInBrand: '',
  zipCode: '',
  visits: List(),
  fiInfo: null,
  driverLicenseNumber: '',
  driverLicenseRefNb: '',
  driverLicenseExp: '',
  birthdate: '',
  address: '',
  city: '',
  postalCode: '',
  gender: ''
});

export const NewCustomer = jsCustomer => {
  const { visits, ...others } = jsCustomer;
  const visitList = fromJS(jsCustomer.visits.map(visit => {
    return Visit(visit);
  }));

  return Customer({ ...others, visits: visitList })
};

export const validateCustomer = (customer) => {
  const fields = [];

  if (customer.lastName.trim().length <= 0 && customer.firstName.trim().length <= 0 && customer.email.trim().length <= 0) {
    fields.push("lastName");
    fields.push("firstName");
    fields.push("email");
  }

  if (customer.homePhone.trim().length <= 0 && customer.cellPhone.trim().length <= 0 && customer.workPhone.trim().length <= 0 && customer.email.trim().length <= 0) {
    fields.push("homePhone");
    fields.push("cellPhone");
    fields.push("homePhone");
    fields.push("email");
  }

  return fields;
};

/**
 * lastVisit returns the last visit for the customer. If the customer has
 * no visit, it returns null.
 * @param {object} customer - The customer for which we need the last visit.
 */
export const lastVisit = (customer) => {
  let i = customer.visits.length;

  while (i > 0) {
    const visit = customer.visits[i - 1];
    if (visit.type !== VisitType.ManagerComment) {
      return visit;
    }

    i--;
  }

  return null;
}

/**
 * followUpNeeded returns true if follow up is needed for the customer, false otherwise.
 * @param {object} customer - The customer for which we need to know if follow up is needed.
 */
export const followUpNeeded = (customer) => {
  const visit = lastVisit(customer);

  if (visit) {
    const currentTime = Date.now() / 1000;
    if (!visit.sold && !visit.communicationCompleted && (currentTime >= visit.nextScheduledCommunication)) {
      return true;
    }
  }

  return false;
};

/**
 * followUpOverdue returns the number of seconds since the follow up is needed.
 * If no follow up is needed, it returns 0.
 */
export const followUpOverdue = (customer) => {
  const visit = lastVisit(customer);

  if (visit) {
    const currentTime = Date.now() / 1000;
    if (!visit.sold && !visit.communicationCompleted && (currentTime >= visit.nextScheduledCommunication)) {
      return currentTime - visit.nextScheduledCommunication;
    }
  }

  return 0;
}

export default Customer;
