import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import ChipMultiSelect from './ChipMultiSelect';

import { AccessRight } from "../Models/User";

const messages = defineMessages({
  emails: {
    id: "Emails",
    defaultMessage: "Emails"
  },
  dataEntry: {
    id: "Data Entry",
    defaultMessage: "Data Entry"
  },
  fAndI: {
    id: "F&I",
    defaultMessage: "F&I"
  },
  manager: {
    id: "Manager",
    defaultMessage: "Manager"
  },
  reports: {
    id: "Reports",
    defaultMessage: "Reports"
  },
  settings: {
    id: "Settings",
    defaultMessage: "Settings"
  },
  shutdown: {
    id: "Shutdown",
    defaultMessage: "Shutdown"
  },
  followUp: {
    id: "Follow Up",
    defaultMessage: "Follow Up"
  },
  followUpMaps: {
    id: "Follow Up Map",
    defaultMessage: "Follow Up Map"
  },
  importFollowUp: {
    id: "Import Follow Up",
    defaultMessage: "Import Follow Up"
  },
  salesman: {
    id: "Salesman",
    defaultMessage: "Salesman"
  },
  storages: {
    id: "Storages",
    defaultMessage: "Storages"
  },
  storageSave: {
    id: "Save Storage",
    defaultMessage: "Save Storage"
  },
  deleteStorage: {
    id: "Delete Storage",
    defaultMessage: "Delete Storage"
  },
  storageHistory: {
    id: "Storage History",
    defaultMessage: "Storage History"
  },
  customers: {
    id: "Customers",
    defaultMessage: "Customers"
  },
  loaners: {
    id: "Loaners",
    defaultMessage: "Loaners"
  },
  users: {
    id: "Users",
    defaultMessage: "Users"
  },
  vehicles: {
    id: "Vehicles",
    defaultMessage: "Vehicles"
  },
  saveVehicle: {
    id: "Save Vehicle",
    defaultMessage: "Save Vehicle"
  },
  deleteVehicle: {
    id: "Delete Vehicle",
    defaultMessage: "Delete Vehicle"
  },
  reservations: {
    id: "Reservations",
    defaultMessage: "Reservations"
  },
  saveReservation: {
    id: "Save Reservation",
    defaultMessage: "Save Reservation"
  },
  deleteReservation: {
    id: "Delete Reservation",
    defaultMessage: "Delete Reservation"
  },
  accountingModelSave: {
    id: "Save Model",
    defaultMessage: "Save Model"
  },
  accountingModelDelete: {
    id: "Delete Model",
    defaultMessage: "Delete Model"
  },
  accountingModels: {
    id: "Models",
    defaultMessage: "Models"
  },
  accountingColorSave: {
    id: "Save Color",
    defaultMessage: "Save Color"
  },
  accountingColorDelete: {
    id: "Delete Color",
    defaultMessage: "Delete Color"
  },
  accountingColors: {
    id: "Colors",
    defaultMessage: "Colors"
  },
  accountingNewStockSave: {
    id: "Save New Stock",
    defaultMessage: "Save New Stock"
  },
  accountingNewStockDelete: {
    id: "Delete New Stock",
    defaultMessage: "Delete New Stock"
  },
  accountingNewStocks: {
    id: "New Stocks",
    defaultMessage: "New Stocks"
  },
});

const accessRights = [
  { value: AccessRight.Emails, stringId: messages.emails },
  { value: AccessRight.DataEntry, stringId: messages.dataEntry },
  { value: AccessRight.FAndI, stringId: messages.fAndI },
  { value: AccessRight.Manager, stringId: messages.manager },
  { value: AccessRight.Reports, stringId: messages.reports },
  { value: AccessRight.Settings, stringId: messages.settings },
  { value: AccessRight.Shutdown, stringId: messages.shutdown },
  { value: AccessRight.FollowUp, stringId: messages.followUp },
  { value: AccessRight.FollowUpMaps, stringId: messages.followUpMaps },
  { value: AccessRight.ImportFollowUp, stringId: messages.importFollowUp },
  { value: AccessRight.Salesman, stringId: messages.salesman },
  { value: AccessRight.Storage, stringId: messages.storages },
  { value: AccessRight.Customers, stringId: messages.customers },
  { value: AccessRight.Users, stringId: messages.users },
  { value: AccessRight.Loaners, stringId: messages.loaners },
  { value: AccessRight.SaveStorage, stringId: messages.storageSave },
  { value: AccessRight.DeleteStorage, stringId: messages.deleteStorage },
  { value: AccessRight.StorageHistory, stringId: messages.storageHistory },
  { value: AccessRight.Vehicles, stringId: messages.vehicles },
  { value: AccessRight.SaveVehicle, stringId: messages.saveVehicle },
  { value: AccessRight.DeleteVehicle, stringId: messages.deleteVehicle },
  { value: AccessRight.Reservations, stringId: messages.reservations },
  { value: AccessRight.SaveReservation, stringId: messages.saveReservation },
  { value: AccessRight.DeleteReservation, stringId: messages.deleteReservation },
  { value: AccessRight.AccountingModels, stringId: messages.accountingModels },
  { value: AccessRight.AccountingModelSave, stringId: messages.accountingModelSave },
  { value: AccessRight.AccountingModelDelete, stringId: messages.accountingModelDelete },
  { value: AccessRight.AccountingColors, stringId: messages.accountingColors },
  { value: AccessRight.AccountingColorSave, stringId: messages.accountingColorSave },
  { value: AccessRight.AccountingColorDelete, stringId: messages.accountingColorDelete },
  { value: AccessRight.AccountingNewStocks, stringId: messages.accountingNewStocks },
  { value: AccessRight.AccountingNewStockSave, stringId: messages.accountingNewStockSave },
  { value: AccessRight.AccountingNewStockDelete, stringId: messages.accountingNewStockDelete }
];

const AccessRightSelect = ({ selectName, selection, onChange, ...others }) => {
  const intl = useIntl();
  const choices = accessRights.map(accessRight => {
    return {
      value: accessRight.value,
      label: intl.formatMessage(accessRight.stringId)
    }
  });

  return (
    <ChipMultiSelect
      choices={choices}
      value={selection}
      name={selectName}
      label={intl.formatMessage({
        id: "Access Rights",
        defaultMessage: "Access Rights"
      })}
      id="AccessRightsSelect"
      onChange={onChange}
      {...others}
    />
  )
}

AccessRightSelect.propTypes = {
  onChange: PropTypes.func,
  selection: PropTypes.array,
  selectName: PropTypes.string
};

AccessRightSelect.defaultProps = {
  selection: []
};

export default AccessRightSelect;
