import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const messages = defineMessages({
  sunday: {
    id: 'Sunday',
    defaultMessage: "Sunday"
  },
  monday: {
    id: 'Monday',
    defaultMessage: "Monday"
  },
  tuesday: {
    id: "Tuesday",
    defaultMessage: "Tuesday"
  },
  wednesday: {
    id: "Wednesday",
    defaultMessage: "Wednesday"
  },
  thursday: {
    id: "Thursday",
    defaultMessage: "Thursday"
  },
  friday: {
    id: "Friday",
    defaultMessage: "Friday"
  },
  saturday: {
    id: "Saturday",
    defaultMessage: "Saturday"
  }
});

const days = [
  messages.sunday,
  messages.monday,
  messages.tuesday,
  messages.wednesday,
  messages.thursday,
  messages.friday,
  messages.saturday
]

const AvailableDays = ({ onChange, value, name, ...others }) => {
  const intl = useIntl();

  const handleClick = (event, index) => {
    if (onChange) {
      const newList = value.slice();
      const target = event.target;

      if (target.checked) {
        newList.push(index);
      }
      else {
        newList.splice(newList.indexOf(index), 1);
      }

      newList.sort();

      onChange(name, newList);
    }
  }

  const items = days.map((day, index) => {
    return (
      <ListItem
        key={index}
        dense
        button
        onClick={(event) => handleClick(event, index)}
      >
        <Checkbox
          tabIndex={-1}
          checked={value.indexOf(index) >= 0}
          disableRipple
        />
        <ListItemText primary={intl.formatMessage(day)} />
      </ListItem>
    )
  });

  return (
    <List {...others}>
      {items}
    </List>
  );
}

AvailableDays.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func
};

AvailableDays.defaultProps = {
  name: '',
  value: [],
  onChange: null
};

export default AvailableDays;
