import { Record, List } from 'immutable';

export const AssignToType = {
  CurrentTurn: 1,
  FirstOfDay: 2,
  AlwaysSame: 3
};

export const SaleNotificationSettings = Record({
  availableDays: List(),
  delay: 24 * 60 * 60,
  notifyOtherUsersOnlyOnRepeat: true,
  repeat: true,
  startTime: 9 * 60 * 60,
  endTime: 21 * 60 * 60,
  otherUsers: List(),
  usersToNotifyForSales: List(),
  visitTypes: List()
});

export const InternetLeadSettings = Record({
  imapServer: '',
  imapPortNumber: 0,
  imapUserName: '',
  imapPassword: '',
  smtpServer: '',
  smtpPortNumber: 0,
  smtpUserName: '',
  smtpPassword: '',
  moveToFolder: '',
  emailAddress: '',
  subject: '',
  assignToType: 0,
  assignTo: null
});

export const Settings = Record({
  customerLongevity: 90 * 30 * 24 * 60 * 60,
  dealerAddress: '',
  firstSalesman: null,
  pushOverKey: '',
  salesmanTurnOrder: List(),
  salesmanAvailability: List(),
  vehicleModels: List(),
  saleNotifications: new SaleNotificationSettings(),
  internetLead: new InternetLeadSettings()
});
