import axios from 'axios';

/**
 * createEventSource creates a new EventSource and returns it.
 */
export const createEventSource = () => {
  const token = encodeURIComponent(axios.defaults.headers.common['Authorization'].substring(7));
  const ev = new EventSource('/api/notifications?jwt=' + token);
  return ev;
}

export const Events = {
  addCustomer: 'customers.new',
  updateCustomer: 'customers.update',
  deleteCustomer: 'customers.delete',
  addUser: 'users.new',
  updateUser: 'users.update',
  deleteUser: 'users.delete',
  addLoaner: 'loaners.new',
  updateLoaner: 'loaners.update',
  deleteLoaner: 'loaners.delete',
  addStorage: 'storages.new',
  updateStorage: 'storages.update',
  deleteStorage: 'storages.delete',
  salesmenTurn: 'customers.turn',
  addVehicle: 'vehicles.new',
  updateVehicle: 'vehicles.update',
  deleteVehicle: 'vehicles.delete',
  addReservation: 'reservations.new',
  updateReservation: 'reservations.update',
  deleteReservation: 'reservations.delete',
  addNewStock: 'newstocks.new',
  updateNewStock: 'newstocks.update',
  deleteNewStock: 'newstocks.delete'
};
