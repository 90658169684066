import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

import VehicleList from './VehicleList';
import VehicleEditor from './VehicleEditor';
import { useVehicles } from '../../Hooks';

import ConfirmationDialog from '../../Components/ConfirmationDialog';
import { useIntl } from 'react-intl';

import * as API from '../../backend';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  actionDiv: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: theme.spacing(1)
  }
}));

const Vehicles = ({ ...others }) => {
  const classes = useStyles();
  const [editedVehicle, setEditedVehicle] = useState(null);
  const [vehicles] = useVehicles();
  const [vehicleToDelete, setVehicleToDelete] = useState(null);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async (vehicle) => {
    setVehicleToDelete(null);
    try {
      await API.deleteVehicle(vehicle.id, true);
    }
    catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          enqueueSnackbar(
            intl.formatMessage({
              id: "There are existing reservations for the vehicles. Can't delete vehicle.",
              defaultMessage: "There are existing reservations for the vehicles. Can't delete vehicle."
            }),
            {
              variant: 'error'
            }
          );

          return;
        }
      }

      enqueueSnackbar(
        intl.formatMessage({
          id: "Error deleting vehicle. Please retry.",
          defaultMessage: "Error deleting vehicle. Please retry."
        }),
        {
          variant: 'error'
        }
      )
    }
  }

  if (editedVehicle !== null) {
    return (
      <VehicleEditor
        initialVehicle={editedVehicle}
        onCancel={() => setEditedVehicle(null)}
        onSave={() => setEditedVehicle(null)}
      />
    )
  }
  else {
    return (
      <div className={classes.mainDiv} {...others}>
        <div className={classes.actionDiv}>
          <IconButton
            onClick={() => setEditedVehicle({
              description: '',
              brand: '',
              model: '',
              year: new Date().getFullYear(),
              color: '',
              plateNumber: '',
              serialNumber: ''
            })}
          >
            <AddIcon />
          </IconButton>
        </div>
        <VehicleList
          vehicles={vehicles}
          onEdit={(v) => setEditedVehicle(v)}
          onDelete={(v) => setVehicleToDelete(v)}
        />
        <ConfirmationDialog
          open={Boolean(vehicleToDelete)}
          title={intl.formatMessage({
            id: "Confirmation",
            defaultMessage: "Confirmation"
          })}
          message={intl.formatMessage(
            {
              id: "Are you sure you want to delete the vehicle {vehicle}?",
              defaultMessage: "Are you sure you want to delete the vehicle {vehicle}?"
            },
            {
              vehicle: vehicleToDelete ? vehicleToDelete.brand + ' ' + vehicleToDelete.model + ' ' + vehicleToDelete.year + ' ' + vehicleToDelete.color : ''
            }
          )}
          onCancel={() => setVehicleToDelete(null)}
          onConfirm={() => handleDelete(vehicleToDelete)}
        />
      </div>
    )
  }
}

export default Vehicles;
