import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DragAndDropHandler from "../../UI/DragAndDropHandler";
import Grid from '@material-ui/core/Grid';
import UserList from './UserList';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';

const sourceDroppableId = 'source-user-list';
const destinationDroppableId = 'turn-user-list';

const useStyles = makeStyles(() => ({
  card: {
    padding: '16px'
  }
}));

const SalesmanTurnOrder = ({ name, value, onChange, users, ...others }) => {
  const classes = useStyles();
  const intl = useIntl();

  const handleDragEnd = (result) => {
    // Check if we remove the element from the list
    if (result.source.droppableId === destinationDroppableId && (!result.destination || result.destination.droppableId === sourceDroppableId)) {
      let turnList = value.slice();
      turnList.splice(result.source.index, 1);

      if (onChange) {
        onChange(name, turnList);
      }
    }
    // dropped outside the list
    else if (!result.destination) { // eslint-disable-line
    }
    else if (result.destination.droppableId === destinationDroppableId) {
      if (result.source.droppableId === sourceDroppableId) {
        let newList = value.slice();
        newList.splice(result.destination.index, 0, result.draggableId);

        if (onChange) {
          onChange(name, newList);
        }
      }
      else if (result.source.droppableId === destinationDroppableId) {
        let newList = value.slice();
        const [removedItem] = newList.splice(result.source.index, 1);
        newList.splice(result.destination.index, 0, removedItem);

        if (onChange) {
          onChange(name, newList);
        }
      }
    }
  }

  const userList = users.filter(user => {
    return value.indexOf(user.id) === -1;
  });

  const turnList = value.map(userId => {
    return users.find(user => {
      return user.id === userId;
    });
  });

  return (
    <DragAndDropHandler handler={handleDragEnd} sourceDroppableId={destinationDroppableId} destinationDroppableId={destinationDroppableId}>
      <DragAndDropHandler handler={handleDragEnd} sourceDroppableId={destinationDroppableId} destinationDroppableId={sourceDroppableId}>
        <DragAndDropHandler handler={handleDragEnd} sourceDroppableId={sourceDroppableId} destinationDroppableId={destinationDroppableId}>
          <Card className={classes.card} {...others}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >
              {intl.formatMessage({
                id: "Salesman Turn Order",
                defaultMessage: "Salesman Turn Order"
              })}
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <Card>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                  >
                    {intl.formatMessage({
                      id: "User List",
                      defaultMessage: "User List"
                    })}
                  </Typography>
                  <UserList users={userList} droppableId={sourceDroppableId} />
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                  >
                    {intl.formatMessage({
                      id: "Turn Order",
                      defaultMessage: "Turn Order"
                    })}
                  </Typography>
                  <UserList users={turnList} droppableId={destinationDroppableId} />
                </Card>
              </Grid>
            </Grid>
          </Card>
        </DragAndDropHandler>
      </DragAndDropHandler>
    </DragAndDropHandler>
  )
}

SalesmanTurnOrder.propTypes = {
  name: PropTypes.string,
  users: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func
};

SalesmanTurnOrder.defaultProps = {
  name: '',
  users: [],
  value: [],
  onChange: null
};

export default SalesmanTurnOrder;
