import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

import { dateToText, textToDate, startOfDayUTC, endOfDayUTC, utcTimeToLocal } from "../../helpers";

import { VehicleSelect } from '../../Components';
import { useAvailableVehicles } from '../../Hooks';

import * as API from '../../backend';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  rootDiv: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  actionDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: theme.spacing(1)
  },
  editorDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  formDiv: {
    display: 'flex',
    flexDirection: 'column'
  },
  inputField: {
    width: '250px'
  }
}));

const newReservation = {
  vehicle: undefined,
  lastName: '',
  firstName: '',
  email: '',
  homePhone: '',
  cellPhone: '',
  workPhone: '',
  startDate: Math.floor(startOfDayUTC(new Date()).getTime() / 1000),
  endDate: Math.floor(endOfDayUTC(new Date()).getTime() / 1000),
  fulfilled: false
}

const ReservationEditor = ({ initialReservation, onSave, onCancel, ...others }) => {
  const classes = useStyles();
  const intl = useIntl();
  const snackbar = useSnackbar();
  const [reservation, setReservation] = useState(initialReservation || newReservation);

  const handleChange = event => {
    const name = event.target.name;
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    setReservation(Object.assign({}, reservation, { [name]: value }));
  }

  const handleDateChange = event => {
    const name = event.target.name;
    const value = name === 'startDate' ?
      Math.floor(startOfDayUTC(textToDate(event.target.value)).getTime() / 1000) :
      Math.floor(endOfDayUTC(textToDate(event.target.value)).getTime() / 1000);

    const changes = {
      [name]: value
    };

    const newReservation = Object.assign({}, reservation, changes);
    setReservation(newReservation);
  }

  const saveEnabled = () => {
    if (!reservation.vehicle) {
      return false;
    }

    const index = vehicles.findIndex((v) => {
      return v.id === reservation.vehicle.id;
    });

    return reservation.lastName.trim().length > 0 &&
      reservation.firstName.trim().length > 0 &&
      index >= 0 &&
      reservation.vehicle &&
      reservation.startDate < reservation.endDate;
  }

  const handleVehicleChange = vehicleID => {
    if (vehicleID === 'none') {
      setReservation(Object.assign({}, reservation, { vehicle: null }));
    } else {
      vehicles.forEach(v => {
        if (v.id === vehicleID) {
          setReservation(Object.assign({}, reservation, { vehicle: v }));
        }
      });
    }
  }

  const startDate = reservation.startDate;
  const endDate = reservation.endDate;

  const [vehicles/*, status*/] = useAvailableVehicles(startDate, endDate, reservation.id ? reservation.id : undefined);

  const vehicleSelection = (vehicle, vehicles) => {
    if (!vehicle) {
      return 'none';
    }

    const index = vehicles.findIndex((v) => {
      return v.id === vehicle.id;
    });

    if (index >= 0) {
      return vehicle.id;
    }

    return 'none';
  }

  const handleSave = async () => {
    try {
      const result = await API.saveReservation(reservation);
      if (onSave) { onSave(result); }
    }
    catch (error) {
      snackbar.enqueueSnackbar(
        intl.formatMessage({
          id: "Error while saving reservation.",
          defaultMessage: "Error while saving reservation."
        }),
        {
          variant: 'error'
        }
      );
    }
  }

  return (
    <div className={classes.rootDiv} {...others}>
      <div className={classes.actionDiv}>
        <IconButton
          color="secondary"
          onClick={onCancel}
        >
          <CancelIcon />
        </IconButton>
        <IconButton
          color="primary"
          disabled={!saveEnabled()}
          onClick={handleSave}
        >
          <SaveIcon />
        </IconButton>
      </div>
      <div className={classes.editorDiv}>
        <div className={classes.formDiv}>
          <TextField
            className={classes.inputField}
            name="lastName"
            label={intl.formatMessage({
              id: "Last Name",
              defaultMessage: "Last Name"
            })}
            value={reservation.lastName}
            onChange={handleChange}
          />
          <TextField
            className={classes.inputField}
            name="firstName"
            label={intl.formatMessage({
              id: "First Name",
              defaultMessage: "First Name"
            })}
            value={reservation.firstName}
            onChange={handleChange}
          />
          <TextField
            className={classes.inputField}
            name="email"
            label={intl.formatMessage({
              id: "Email Address",
              defaultMessage: "Courriel"
            })}
            value={reservation.email}
            onChange={handleChange}
          />
          <TextField
            className={classes.inputField}
            name="homePhone"
            label={intl.formatMessage({
              id: "Home Phone",
              defaultMessage: "Home Phone"
            })}
            value={reservation.homePhone}
            onChange={handleChange}
          />
          <TextField
            className={classes.inputField}
            name="cellPhone"
            label={intl.formatMessage({
              id: "Cell Phone",
              defaultMessage: "Cell Phone"
            })}
            value={reservation.cellPhone}
            onChange={handleChange}
          />
          <TextField
            className={classes.inputField}
            name="workPhone"
            label={intl.formatMessage({
              id: "Work Phone",
              defaultMessage: "Work Phone"
            })}
            value={reservation.workPhone}
            onChange={handleChange}
          />
          <TextField
            className={classes.inputField}
            name="startDate"
            type="date"
            label={intl.formatMessage({
              id: "Start Date",
              defaultMessage: "Start Date"
            })}
            value={dateToText(utcTimeToLocal(new Date(reservation.startDate * 1000)))}
            onChange={handleDateChange}
          />
          <TextField
            className={classes.inputField}
            name="endDate"
            type="date"
            label={intl.formatMessage({
              id: "End Date",
              defaultMessage: "End Date"
            })}
            value={dateToText(utcTimeToLocal(new Date(reservation.endDate * 1000)))}
            onChange={handleDateChange}
          />
          <VehicleSelect
            className={classes.inputField}
            vehicles={vehicles}
            onChange={handleVehicleChange}
            value={vehicleSelection(reservation.vehicle, vehicles)}
          />
          <FormControlLabel
            control={
              <Switch checked={reservation.previousDay} onChange={handleChange} name="previousDay" />
            }
            label={intl.formatMessage({
              id: "Previous Day",
              defaultMessage: "Previous Day"
            })}
          />
          <FormControlLabel
            control={
              <Switch checked={reservation.fulfilled} onChange={handleChange} name="fulfilled" />
            }
            label={intl.formatMessage({
              id: "Fulfilled",
              defaultMessage: "Fulfilled"
            })}
          />
        </div>
      </div>
    </div>
  )
}

ReservationEditor.propTypes = {
  initialReservation: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
}

export default ReservationEditor;
