export const FETCH_SALESMAN_AVAILABILITY_REQUEST = '@@SALESMAN_AVAILABILITY.FETCH_SALESMAN_AVAILABILITY_REQUEST';
export const FETCH_SALESMAN_AVAILABILITY_SUCCESS = '@@SALESMAN_AVAILABILITY.FETCH_SALESMAN_AVAILABILITY_SUCCESS';
export const FETCH_SALESMAN_AVAILABILITY_FAILURE = '@@SALESMAN_AVAILABILITY.FETCH_SALESMAN_AVAILABILITY_FAILURE';

export const SAVE_SALESMAN_AVAILABILITY_REQUEST = '@@SALESMAN_AVAILABILITY.SAVE_SALESMAN_AVAILABILITY_REQUEST';
export const SAVE_SALESMAN_AVAILABILITY_SUCCESS = '@@SALESMAN_AVAILABILITY.SAVE_SALESMAN_AVAILABILITY_SUCCESS';
export const SAVE_SALESMAN_AVAILABILITY_FAILURE = '@@SALESMAN_AVAILABILITY.SAVE_SALESMAN_AVAILABILITY_FAILURE';

export const SET_SETTINGS_DIALOG_VISIBILITY = '@@SALESMAN_AVAILABILITY.SET_SETTINGS_DIALOG_VISIBILITY';

export const MODIFY_FIRST_SALESMAN = '@@SALESMAN_AVAILABILITY.MODIFY_FIRST_SALESMAN';
export const MODIFY_AVAILABLE_SALESMEN = '@@SALESMAN_AVAILABILITY.MODIFY_AVAILABLE_SALESMEN';
