import * as Types from './Types';

/**
 * This will generate an action that is used to set the content of the settings.(Usually to display it).
 * @param contentId - The id of the settings content.
 * @returns {{type: string, contentId: *}}
 */
export function setContent(contentId) {
  return {
    type: Types.SET_CONTENT,
    contentId
  }
}
