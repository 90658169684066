import React, { useEffect, useState } from 'react';
import * as API from '../../backend';
import ModelList from './ModelList';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import SearchBox from '../../Components/SearchBox';
import ModelEditorController from './ModelEditorController';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
    mainDiv: {
        display: 'flex',
        width: '100%',
        height: '100%'
    },
    listDiv: {
        overflow: 'auto',
        height: '100%',
        width: '50%'
    },
    searchDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(1)
    },
    searchBox: {
        width: '25em'
    },
    editorDiv: {
        paddingTop: '64px',
        height: '100%',
        overflow: 'auto',
        width: '50%'
    }
}));

const ModelController = ({ ...others }) => {
    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [newModel, setNewModel] = useState(false);
    const [filter, setFilter] = useState('');
    const snackbar = useSnackbar();
    const intl = useIntl();
    const classes = useStyles();

    useEffect(() => {
        const cancelSource = API.getCancelSource();
        const getModels = async () => {
            try {
                const result = await API.getModels();
                setModels(result.sort((m1, m2) => {
                    if (m1.brand < m2.brand) {
                        return -1;
                    } else if (m1.brand > m2.brand) {
                        return 1;
                    } else {
                        if (m1.description < m2.description) {
                            return -1;
                        } else if (m1.description > m2.description) {
                            return 1;
                        } else {
                            if (m1.trim < m2.trim) {
                                return -1;
                            } else {
                                if (m1.trim > m2.trim) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            }
                        }
                    }
                }));
            }
            catch (error) {
                snackbar.enqueueSnackbar(
                    intl.formatMessage({
                        id: "Error while loading models.",
                        defaultMessage: "Error while loading models."
                    }),
                    {
                        variant: 'error'
                    }
                );
            }
        }

        getModels();

        return () => {
            cancelSource.cancel();
        }
    }, []);

    const filteredModels = filter === '' ? models : models.filter(m => {
        const e = new RegExp('.*' + filter + '.*', 'i');
        return m.description.search(e) >= 0 || m.code.search(e) >= 0 || m.trim.search(e) >= 0;
    });

    const handleUpdate = updatedModel => {
        setModels(models.map(m => {
            if (m.id == updatedModel.id) {
                return updatedModel;
            }

            return m;
        }));
    }

    const handleCreate = newModel => {
        const newModelList = models.slice();
        newModelList.push(newModel);

        setModels(newModelList);
        setSelectedModel(newModel.id);
    }

    return (
        <div className={classes.mainDiv}>
            <div className={classes.listDiv} {...others}>
                <div className={classes.searchDiv}>
                    <SearchBox
                        variant="outlined"
                        className={classes.searchBox}
                        value={filter}
                        onChange={v => { setFilter(v); }}
                    />
                    <IconButton
                        onClick={
                            () => {
                                setSelectedModel(null);
                                setNewModel(true);
                            }
                        }
                    >
                        <AddIcon />
                    </IconButton>
                </div>
                <ModelList
                    models={filteredModels}
                    selectedItem={selectedModel}
                    onSelect={m => { setSelectedModel(m.id); }}
                />
            </div>

            {(selectedModel || newModel) &&
                (
                    <div className={classes.editorDiv}>
                        <ModelEditorController
                            modelID={selectedModel}
                            onUpdate={handleUpdate}
                            onCreate={handleCreate}
                        />
                    </div>
                )
            }
        </div >
    )
}

export default ModelController;
