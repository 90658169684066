export const FETCH_USERS_REQUEST = '@@SETTINGS_FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = '@@SETTINGS_FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = '@@SETTINGS_FETCH_USERS_FAILURE';

export const SAVE_USER_REQUEST = '@@SETTINGS_SAVE_USER_REQUEST';
export const SAVE_USER_SUCCESS = '@@SETTINGS_SAVE_USER_SUCCESS';
export const SAVE_USER_FAILURE = '@@SETTINGS_SAVE_USER_FAILURE';

export const SET_EDITED_USER = '@@SETTINGS_SET_EDITED_USER';
export const CHANGE_EDITED_USER = '@@SETTINGS_CHANGE_EDITED_USER';
export const UPDATE_USER = '@@SETTINGS_UPDATE_USER';
