import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import { PageId } from "../../DataStore/MainPage/Types";
import { AccessRight } from "../../Models/User";
import { hasAccessRight } from "../../DataStore/Login/Reducer";
import { setMainPage } from "../../DataStore/MainPage/Actions";
import { getMainPage } from "../../DataStore/MainPage/Reducer";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";

const SettingButton = () => {
  const settingsRight = useSelector(state => { return hasAccessRight(state, AccessRight.Settings) });
  const mainPage = useSelector(getMainPage);

  const dispatch = useDispatch();
  const setPage = pageId => (dispatch(setMainPage(pageId)));

  if (settingsRight) {
    return (
      <IconButton
        color="inherit"
        aria-label="Settings"
        disabled={mainPage === PageId.Settings}
        onClick={() => {
          setPage(PageId.Settings);
        }}
      >
        <SettingsIcon />
      </IconButton>
    );
  }

  return null;
}

export default SettingButton;
