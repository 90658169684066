import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TemplateID } from '../../../backend';

const TemplateSelect = ({ value, onChange, ...others }) => {
  const intl = useIntl();

  return (
    <FormControl {...others}>
      <InputLabel htmlFor="age-simple">
        {intl.formatMessage({
          id: 'Select Template',
          defaultMessage: 'Select Template'
        })}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
      >
        <MenuItem value={TemplateID.LoanerAgreement}>
          {intl.formatMessage({
            id: 'Loaner Agreement',
            defaultMessage: 'Loaner Agreement'
          })}
        </MenuItem>
      </Select>
    </FormControl>
  )
}

TemplateSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func
}

export default TemplateSelect;
