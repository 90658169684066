import React, { useRef } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SignatureCanvas from 'react-signature-canvas';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  signatureDiv: {
    background: 'lightgray',
    padding: '8px',
    width: '500px'
  },
});

const Loaner = () => {
  const classes = useStyles();
  const canvasRef = useRef(null);

  return (
    <div>
      <TextField label="Model" placeholder="Model" />
      <TextField label="Make" placeholder="Make" />
      <TextField label="Year" placeholder="Year" />
      <TextField label="Plate Number" placeholder="Plate Number" />
      <TextField label="Last Name" placeholder="Last Name" />
      <TextField label="First Name" placeholder="First Name" />
      <TextField label="Address" placeholder="Address" />
      <TextField label="City" placeholder="City" />
      <TextField label="Province" placeholder="Province" />
      <TextField label="Postal Code" placeholder="Postal Code" />
      <TextField label="Home Phone" placeholder="Home Phone" />
      <TextField label="Cell Phone" placeholder="Cell Phone" />
      <TextField label="Work Phone" placeholder="Work Phone" />
      <TextField label="License Number" placeholder="License Number" />
      <TextField label="License Expiration" placeholder="License Expiration" />
      <Button onClick={() => { canvasRef.current.clear() }}>Clear</Button>
      <div className={classes.signatureDiv}>
        <SignatureCanvas ref={canvasRef} penColor='green'
          canvasProps={{ width: 500, height: 200 }} />
      </div>
    </div>
  )
}

export default Loaner;
