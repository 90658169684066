import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import { CarIcon } from '../../Icons';
import { PageId } from "../../DataStore/MainPage/Types";

import { getMainPage, setMainPage } from '../../DataStore';

const VehicleButton = ({ ...others }) => {
  const currentPage = useSelector(getMainPage);

  const dispatch = useDispatch();
  const setPage = () => (dispatch(setMainPage(PageId.Vehicles)));

  return (
    <IconButton
      color="inherit"
      aria-label="Loaners"
      disabled={currentPage === PageId.Vehicles}
      onClick={setPage}
      {...others}
    >
      <CarIcon />
    </IconButton>
  );
}

export default VehicleButton;
