import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SupportIcon = (props, ref) => {
  return (
    <SvgIcon ref={ref} {...props}>
      <g transform="matrix(0.046875,0,0,0.046875,0,0)">
        <path d="M495.984,252.588C478.865,238.479 451.807,237.269 434.048,256.328L389.961,303.655C384.261,285.336 367.152,271.997 346.984,271.997L268.309,271.997C262.339,271.997 260.34,269.717 249.97,261.728C210.432,227.26 151.046,227.37 111.628,262.058L82.71,287.516C69.711,280.636 54.532,280.466 41.462,286.996L8.294,303.575C0.884,307.285 -2.115,316.294 1.584,323.704L91.579,503.693C95.289,511.103 104.298,514.102 111.708,510.403L144.876,493.814C161.225,485.645 170.324,468.965 169.734,451.987L346.983,451.987C379.851,451.987 411.259,436.288 430.978,409.99L502.984,313.976C516.953,295.366 514.743,268.077 495.984,252.588ZM131.456,466.985L111.707,476.864L35.122,323.704L54.881,313.825C62.291,310.125 71.29,313.115 75,320.535L138.166,446.867C141.866,454.276 138.866,463.275 131.456,466.985ZM478.985,295.976L406.98,391.99C392.891,410.779 370.462,421.988 346.984,421.988L159.265,421.988L103.058,309.565L131.446,284.577C159.694,259.728 202.292,259.728 230.54,284.577C247.179,299.226 257.528,301.996 268.308,301.996L346.983,301.996C355.253,301.996 361.982,308.726 361.982,316.995C361.982,325.264 355.252,331.994 346.983,331.994L270.378,331.994C262.098,331.994 255.379,338.714 255.379,346.993C255.379,355.272 262.099,361.992 270.378,361.992L357.033,361.992C369.482,361.992 381.482,356.772 389.961,347.663L455.997,276.777C462.037,270.297 471.296,270.837 476.976,275.807C482.915,281.006 483.556,289.896 478.985,295.976Z" style={{ fillRule: 'nonzero' }} />
      </g>
      <g transform="matrix(0.046875,0,0,0.046875,0,0)">
        <path d="M315.385,102.367C325.654,91.598 331.984,77.039 331.984,61.009C331.984,27.991 305.306,0.013 271.988,0.013C238.92,0.013 210.992,27.941 210.992,61.009C210.992,76.548 217.082,91.217 228.141,102.487C200.713,117.866 180.994,147.384 180.994,182.002L180.994,197.001C180.994,205.28 187.714,212 195.993,212L346.984,212C355.263,212 361.983,205.28 361.983,197.001L361.983,182.002C361.982,148.064 343.315,118.086 315.385,102.367ZM271.988,30.012C288.247,30.012 301.986,44.211 301.986,61.01C301.986,77.549 288.527,91.008 271.988,91.008C255.189,91.008 240.99,77.269 240.99,61.01C240.99,44.501 255.479,30.012 271.988,30.012ZM210.992,182.002C210.992,148.934 238.92,121.006 271.988,121.006C305.066,121.006 331.984,148.364 331.984,182.002L210.992,182.002Z" style={{ fillRule: 'nonzero' }} />
      </g>
    </SvgIcon>
  );
}

export default React.forwardRef(SupportIcon);
