import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl, defineMessages } from "react-intl";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import TestDriveIcon from "../Icons/Car";
import VIPIcon from "../Icons/VIP";
import TakeOverIcon from "../Icons/Support";
import SoldIcon from "../Icons/Sold";
import CommCompletedIcon from "../Icons/Stop";

import { purchaseTypeToString, visitTypeToIcon, VisitType } from "../Models/Visit";

import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import * as API from "../backend";

const messages = defineMessages({
  visitDate: {
    id: "{visitDate, date, medium} {visitTime, time, short}",
    defaultMessage: "{visitDate, date, medium} {visitTime, time, short}"
  },
  visitInternet: {
    id: "{source} -  {visitDate, date, medium} {visitTime, time, short}",
    defaultMessage: "{source} -  {visitDate, date, medium} {visitTime, time, short}"
  },
  vehicleAndType: {
    id: "Vehicle: {vehicle} Type: {purchaseType}",
    defaultMessage: "Vehicle: {vehicle} Type: {purchaseType}"
  }
})

const useStyles = makeStyles(theme => ({
  rootDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: theme.spacing(1)
  },
  headerDiv: {
    display: "flex",
    width: "100%",
    alignItems: "center"
  },
  visitTypeIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  vehicleDiv: {
    display: "flex"
  },
  time: {
    marginRight: theme.spacing(1)
  },
  editButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  statusDiv: {
    marginLeft: "auto"
  },
  vehicle: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  statusIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  commentDiv: {
    borderRadius: "16px",
    marginTop: theme.spacing(1),
    backgroundColor: "LightSkyBlue",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1)
  },
  commentText: {
    //margin: theme.spacing(1)
  },
  emailDiv: {
    borderRadius: "16px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: ""
  },
  emailFrame: {
    width: "100%",
    height: "400px",
    overflowX: "auto",
    overflowY: "auto",
    backgroundColor: "White"
  }
}));

const VisitItem = ({ visit, index, onEdit, canEditVisit, ...others }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [email, setEmail] = useState(null);

  useEffect(() => {
    const cancelSource = API.getCancelSource();

    const fetchEmail = async () => {
      const email = await API.loadEmail(visit.email);
      setEmail(email);
    }

    if (visit.email) {
      fetchEmail();
    }

    return () => {
      cancelSource.cancel();
    }
  }, [visit]);

  const addTooltip = (comp, tooltip) => {
    return (
      <Tooltip title={tooltip} enterDelay={500} leaveDelay={100}>
        {comp}
      </Tooltip>
    );
  }

  const visitStatus = (visit) => {
    return (
      <div className={classes.statusDiv}>
        {visit.sold &&
          addTooltip(
            <SoldIcon className={classes.statusIcon} />,
            intl.formatMessage({
              id: "Sold",
              defaultMessage: "Sold"
            })
          )}
        {visit.takeOver &&
          addTooltip(
            <TakeOverIcon className={classes.statusIcon} />,
            intl.formatMessage({
              id: "Take Over",
              defaultMessage: "Take Over"
            })
          )}
        {visit.testDrive &&
          addTooltip(
            <TestDriveIcon className={classes.statusIcon} />,
            intl.formatMessage({
              id: "Test Drive",
              defaultMessage: "Test Drive"
            })
          )}
        {visit.vipPresentation &&
          addTooltip(
            <VIPIcon className={classes.statusIcon} />,
            intl.formatMessage({
              id: "VIP Presentation",
              defaultMessage: "VIP Presentation"
            })
          )}
        {visit.communicationCompleted &&
          addTooltip(
            <CommCompletedIcon className={classes.statusIcon} />,
            intl.formatMessage({
              id: "Communication Completed",
              defaultMessage: "Communication Completed"
            })
          )}
      </div>
    );
  }

  const emailFrame = () => {
    return (
      <iframe
        className={classes.emailFrame}
        title={email.subject}
        srcDoc={email.body}
        scrolling="auto"
      />
    );
  }

  const visitDate = new Date(visit.date * 1000);

  return (
    <div className={classes.rootDiv} {...others}>
      <div className={classes.headerDiv}>
        <div className={classes.visitTypeIcon}>
          {visitTypeToIcon(visit.type, intl)}
        </div>
        {visit.type === VisitType.Internet &&
          <Typography
            className={classes.time}
            variant="subtitle1"
            color="primary"
          >
            {
              intl.formatMessage(
                messages.visitInternet,
                {
                  source: visit.internetSource,
                  visitDate: visitDate,
                  visitTime: visitDate
                }
              )
            }
          </Typography>
        }
        {visit.type !== VisitType.Internet &&
          <Typography
            className={classes.time}
            variant="subtitle1"
            color="primary"
          >
            {
              intl.formatMessage(
                messages.visitDate,
                {
                  visitDate: visitDate,
                  visitTime: visitDate
                }
              )
            }
          </Typography>
        }
        {canEditVisit && (
          <div className={classes.editButton}>
            <IconButton onClick={onEdit}>
              <EditIcon />
            </IconButton>
          </div>
        )}
        {visitStatus(visit)}
      </div>
      <div className={classes.vehicleDiv}>
        <Typography
          className={classes.vehicle}
        >
          {
            intl.formatMessage(
              messages.vehicleAndType,
              {
                vehicle: visit.vehicle,
                purchaseType: purchaseTypeToString(
                  intl,
                  visit.purchaseType
                )
              }
            )
          }
        </Typography>
      </div>
      <div className={classes.commentDiv}>
        <Typography className={classes.commentText}>
          {visit.comments ||
            intl.formatMessage({
              id: "No Comment",
              defaultMessage: "No Comment"
            })}
        </Typography>
        {email && emailFrame()}
      </div>
    </div>
  );
}

VisitItem.propTypes = {
  visit: PropTypes.object,
  index: PropTypes.number,
  onEdit: PropTypes.func,
  canEditVisit: PropTypes.bool
};

export default VisitItem;
