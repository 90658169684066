import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { AccessRight } from "../Models/User";

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%'
  }
}));

const SalesmanSelect = ({ fullWidth, selectName, users, selection, alwaysPresent, onChange, ...others }) => {
  const classes = useStyles();
  const intl = useIntl();

  const salesmen = users.reduce((result, user) => {
    const isSalesman = user.accessRights.indexOf(AccessRight.Salesman) >= 0;

    if ((user.active && isSalesman) || user.id === alwaysPresent) {
      result.push(
        <MenuItem value={user.id} key={user.id}>
          {user.firstName + ' ' + user.lastName}
        </MenuItem>
      );
    }

    return result;
  }, []);

  return (
    <FormControl className={fullWidth ? classes.fullWidth : null} margin="normal" {...others}>
      <InputLabel htmlFor={selectName}>{intl.formatMessage({ id: 'Salesman', defaultMessage: "Salesman" })}</InputLabel>
      <Select
        value={selection}
        inputProps={{
          name: selectName
        }}
        onChange={onChange}>
        {salesmen}
      </Select>
    </FormControl>
  );
}

SalesmanSelect.propTypes = {
  selectName: PropTypes.string,
  users: PropTypes.array.isRequired,
  selection: PropTypes.string,
  alwaysPresent: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool
};

export default SalesmanSelect;
