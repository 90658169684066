import axios from 'axios';

/**
 * saveUser saves the specfied user. If the user has an id, an update is done,
 * otherwise a new user is created. It returns the saved user on success.
 * @param {object} user - User to save.
 * @param {object} cancelSource - Object used to cancel the request.
 */
export const saveUser = async (user, cancelSource) => {
  const response = await axios.post("/user", user, { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}

/**
 * getUser loads the specified user from the server.
 * @param {string} id - The id of the user to load from the server.
 * @param {object} cancelSource - Object used to cancel the request.
 */
export const getUser = async (id, cancelSource) => {
  const response = await axios.get("/user/" + id, { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}

/**
 * allUsers returns an array od all users from the server.
 * @param {object} cancelSource - Object used to cancel the request.
 */
export const allUsers = async (cancelSource) => {
  const response = await axios.get("/user", { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}
