import React from 'react';
import { useIntl } from 'react-intl';

import { useSelector, useDispatch } from 'react-redux';

import { activeUsers as getActiveUsers, settings as getSettings } from '../../../DataStore/Settings/Settings/Reducer';
import { changeSettings as _changeSettings } from '../../../DataStore/Settings/Settings/Actions';
import { AssignToType } from '../../../Models/Settings';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AssignToTypeSelect from "./AssignToTypeSelect";
import UserSelect from './UserSelect';

const InternetLeads = ({ ...others }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const users = useSelector(getActiveUsers);
  const settings = useSelector(getSettings);

  const changeSettings = (name, value) => {
    dispatch(_changeSettings('internetLead', name, value));
  }

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    changeSettings(name, value);
  }

  const handleNumberChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const intValue = parseInt(value, 10);
    changeSettings(name, isNaN(intValue) ? 0 : intValue);
  }

  return (
    <Grid container direction={'column'} spacing={1} {...others}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={intl.formatMessage({
              id: "IMAP Server",
              defaultMessage: "IMAP Server"
            })}
            name={'imapServer'}
            value={settings.internetLead.imapServer}
            onChange={handleChange}
            fullWidth={true}
          />
        </Grid>
        <Grid item>
          <TextField
            label={intl.formatMessage({
              id: "IMAP Port Number",
              defaultMessage: "IMAP Port Number"
            })}
            name={'imapPortNumber'}
            value={settings.internetLead.imapPortNumber}
            onChange={handleNumberChange}
            fullWidth={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={intl.formatMessage({
              id: "IMAP User Name",
              defaultMessage: "IMAP User Name"
            })}
            name={'imapUserName'}
            value={settings.internetLead.imapUserName}
            onChange={handleChange}
            fullWidth={true}
          />
        </Grid>
        <Grid item>
          <TextField
            label={intl.formatMessage({
              id: "IMAP Password",
              defaultMessage: "IMAP Password"
            })}
            name={'imapPassword'}
            value={settings.internetLead.imapPassword}
            onChange={handleChange}
            fullWidth={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={intl.formatMessage({
              id: 'SMTP Server',
              defaultMessage: "SMTP Server"
            })}
            name={'smtpServer'}
            value={settings.internetLead.smtpServer}
            onChange={handleChange}
            fullWidth={true}
          />
        </Grid>
        <Grid item>
          <TextField
            label={intl.formatMessage({
              id: "SMTP Port Number",
              defaultMessage: "SMTP Port Number"
            })}
            name={'smtpPortNumber'}
            value={settings.internetLead.smtpPortNumber}
            onChange={handleNumberChange}
            fullWidth={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={intl.formatMessage({
              id: "SMTP User Name",
              defaultMessage: "SMTP User Name"
            })}
            name={'smtpUserName'}
            value={settings.internetLead.smtpUserName}
            onChange={handleChange}
            fullWidth={true}
          />
        </Grid>
        <Grid item>
          <TextField
            label={intl.formatMessage({
              id: "SMTP Password",
              defaultMessage: "SMTP Password"
            })}
            name={'smtpPassword'}
            value={settings.internetLead.smtpPassword}
            onChange={handleChange}
            fullWidth={true}
          />
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          label={intl.formatMessage({
            id: "Move To Folder",
            defaultMessage: "Move To Folder"
          })}
          name={'moveToFolder'}
          value={settings.internetLead.moveToFolder}
          onChange={handleChange}
          fullWidth={true}
        />
      </Grid>
      <Grid item>
        <TextField
          label={intl.formatMessage({
            id: "Email Address",
            defaultMessage: "Email Address"
          })}
          name={'emailAddress'}
          value={settings.internetLead.emailAddress}
          onChange={handleChange}
          fullWidth={true}
        />
      </Grid>
      <Grid item>
        <TextField
          label={intl.formatMessage({
            id: "Subjec",
            defaultMessage: "Subject"
          })}
          name={'subject'}
          value={settings.internetLead.subject}
          onChange={handleChange}
          fullWidth={true}
        />
      </Grid>
      <Grid item>
        <AssignToTypeSelect
          name={'assignToType'}
          id={'assignToType'}
          value={settings.internetLead.assignToType}
          onChange={handleChange}
        />
      </Grid>
      {
        settings.internetLead.assignToType === AssignToType.AlwaysSame ? (
          <Grid item>
            <UserSelect
              name={'assignTo'}
              id={'assignTo'}
              users={users}
              value={settings.internetLead.assignTo}
              onChange={handleChange}
            />
          </Grid>
        ) : null
      }
    </Grid>
  )
}

export default InternetLeads;
