import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import StorageReport from './Storage/Storage';

const useStyles = makeStyles({
  rootDiv: {
    width: '100%',
    height: '100%'
  }
});

const Reports = () => {
  const classes = useStyles();

  return (
    <div className={classes.rootDiv}>
      <StorageReport />
    </div>
  )
}

export default Reports;
