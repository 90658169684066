import { Map } from 'immutable';
import createReducer from '../CreateReducer';
import * as Types from './Types';

const initialState = Map({
  messageID: null,
  message: null,
  delay: 5000,
  messageType: Types.MessageType.Info
});

const showMessage = (state, { message, delay, messageType }) => {
  return Map({messageID: null, message, delay, messageType});
};

const showMessageByID = (state, { messageID, delay, messageType }) => {
  return Map({messageID, message: null, delay, messageType});
};

const hideMessage = state => {
  return state.withMutations(state => {
    state.set('message', null)
      .set('messageID', null);
  })
};

const messageReducer = createReducer(initialState, {
  [Types.SHOW_MESSAGE]: showMessage,
  [Types.SHOW_MESSAGE_BY_ID]: showMessageByID,
  [Types.HIDE_MESSAGE]: hideMessage
});

const getState = state => {
  return state.get('message');
};

/**
 * Returns the currently displayed message.
 * @param {object} state - The global state
 * @returns {string} - The currenly displayed message.
 */
export const message = state => {
  return getState(state).get('message');
};

/**
 * Returns the ID of the currently displayed message, for internationalized message.
 * @param {object} state - The global state.
 * @returns {string} - The ID of the currently displayed message.
 */
export const messageID = state => {
  return getState(state).get('messageID');
};

/**
 * Returns the time in ms that the message will be displayed before being automatically removed.
 * @param {object} state - The global state
 * @returns {number} - The time in ms.
 */
export const delay = state => {
  return getState(state).get('delay');
};

/**
 * Returns the type(info, warning, error) of the message currently displayed.
 * @param {object} state - The global state
 * @returns {number} - The message type, as defined in Types/MessageType.
 */
export const messageType = state => {
  return getState(state).get('messageType');
};

export default messageReducer;