import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AdvancedSearch from './AdvancedSearch';
import BasicSearch from './BasicSearch';

export const SearchModeSimple = 'simple';
export const SearchModeAdvanced = 'advanced';

const style = (theme) => ({
  rootDiv: {
    display: 'flex',
    flexDirection: 'column'
  },
  modeDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing(1)
  }
});


const SearchSelector = ({ intl, classes, searchMode, onOwnCustomersChange, onFilterChange, onSearchModeChange, ...others }) => {
  const setSearchMode = mode => {
    if (onSearchModeChange) {
      onSearchModeChange(mode);
    }
  }

  const largeScreen = useMediaQuery('(min-width:600px) and (min-height:600px)');

  return (
    <div className={classes.rootDiv} {...others}>
      <div className={classes.modeDiv}>
        {largeScreen &&
          <Button
            className={classes.button}
            onClick={() => setSearchMode(SearchModeSimple)}
            disabled={searchMode === SearchModeSimple}
          >
            {intl.formatMessage({
              id: "Basic Search",
              defaultMessage: "Basic Search"
            })}
          </Button>
        }
        {largeScreen &&
          <Button
            className={classes.button}
            onClick={() => setSearchMode(SearchModeAdvanced)}
            disabled={searchMode === SearchModeAdvanced}
          >
            {intl.formatMessage({
              id: "Advanced Search",
              defaultMessage: "Advanced Search"
            })}
          </Button>
        }
      </div>
      <div>
        {searchMode === SearchModeAdvanced && (<AdvancedSearch />)}
        {searchMode === SearchModeSimple && (
          <BasicSearch
            onFilterChange={onFilterChange}
            onOwnCustomersChange={onOwnCustomersChange}
          />
        )}
      </div>
    </div>
  );
}

SearchSelector.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  searchMode: PropTypes.string,
  onSearchModeChange: PropTypes.func,
  onOwnCustomersChange: PropTypes.func,
  onFilterChange: PropTypes.func
};

export default injectIntl(withStyles(style)(SearchSelector));
