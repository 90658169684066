import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import * as API from '../../backend';
import YearList from './YearList';
import YearEditor from './YearEditor';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(() => ({
    mainDiv: {
        display: 'flex',
        width: '100%',
        height: '100%'
    },
    addDiv: {
        display: 'flex',
        justifyContent: 'center'
    },
    listDiv: {
        overflow: 'auto',
        height: '100%',
        width: '50%'
    },
    editorDiv: {
        height: '100%',
        overflow: 'auto',
        width: '50%'
    }
}));

const YearController = () => {
    const [patterns, setPatterns] = useState([]);
    const classes = useStyles();
    const intl = useIntl();
    const snackbar = useSnackbar();
    const [selection, setSelection] = useState(null);

    useEffect(() => {
        const cancelSource = API.getCancelSource();

        const getPatterns = async () => {
            try {
                const patterns = await API.getPatterns(cancelSource);
                setPatterns(patterns);
            }
            catch (error) {
                snackbar.enqueueSnackbar(
                    intl.formatMessage({
                        id: 'Error while loading years.',
                        defaultMessage: 'Error while loading years.'
                    }),
                    {
                        variant: 'error'
                    }
                )
            }
        }

        getPatterns();

        return () => {
            cancelSource.cancel();
        }
    }, []);

    const savePattern = async p => {
        try {
            await API.savePattern(p);
        }
        catch (error) {
            snackbar.enqueueSnackbar(
                intl.formatMessage({
                    id: "Error while saving year's pattern.",
                    defaultMessage: "Error while saving year's pattern."
                }),
                {
                    variant: 'error'
                }
            )
        }
    }

    const handleSavePattern = async p => {
        await savePattern(p);

        let newPatterns = patterns.slice();
        for (let i = 0; i < newPatterns.length; i++) {
            if (newPatterns[i].year == p.year) {
                newPatterns[i] = p;
                setPatterns(newPatterns);
                return;
            }
        }

        newPatterns.push(p);
        setPatterns(newPatterns);
    }

    const handleChange = p => {
        selection && setSelection(p);
    }

    const handleAddPattern = () => {
        const now = new Date();
        const year = now.getFullYear();
        setSelection({ year: year, pattern: '?####', startingNumber: 1 });
    }

    return (
        <div className={classes.mainDiv}>
            <div className={classes.listDiv}>
                <div className={classes.addDiv}>
                    <IconButton
                        onClick={handleAddPattern}
                    >
                        <AddIcon />
                    </IconButton>
                </div>
                <YearList
                    years={patterns}
                    onSelect={y => setSelection(y)}
                    selectedItem={selection ? selection.year : null}
                />
            </div>
            <div className={classes.editorDiv}>
                {selection && (
                    <YearEditor
                        pattern={selection}
                        onSave={handleSavePattern}
                        onChange={handleChange}
                    />
                )}
            </div>
        </div>
    )
}

export default YearController;
