import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { User } from '../Models/User';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Fab from '@material-ui/core/Fab';
import { CancelIcon, SaveIcon } from "../Icons/Icons";
import TextField from "@material-ui/core/TextField";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LanguageSelect from '../UI/LanguageSelect';
import AccessRightSelect from "../UI/AccessRightSelect";
import ValidatedTextField from '../UI/ValidatedTextField';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ReactQuill from 'react-quill';
import Typography from '@material-ui/core/Typography';
import DepartmentSelect from '../UI/DepartmentSelect';
import PrinterSelect from '../Components/PrinterSelect';

const useStyles = makeStyles(() => ({
  divStyle: {
    width: '800px',
    marginLeft: '16px',
    maxWidth: '100%'
  }
}));

const UserEditor = ({ user, onChange, onSave, onCancel, saveDisabled, ...others }) => {
  const intl = useIntl();
  const classes = useStyles();

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (onChange) {
      onChange(name, value);
    }
  }

  const handleSave = () => {
    if (onSave) {
      onSave(user);
    }
  }

  return (
    <Card className={classes.divStyle} {...others}>
      <CardContent>
        <form>
          <Grid container direction="column" spacing={1}>
            <Grid container justify="flex-end">
              <Grid item>
                <IconButton aria-label="Cancel" onClick={onCancel} color="secondary">
                  <CancelIcon />
                </IconButton>
                <IconButton aria-label="Save" onClick={handleSave} disabled={saveDisabled} color="primary">
                  <SaveIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={1} justify="flex-start">
              <Grid item xs={6}>
                <ValidatedTextField
                  name="lastName"
                  label={intl.formatMessage({
                    id: 'Last Name',
                    defaultMessage: "Last Name"
                  })}
                  value={user.get('lastName')}
                  margin="normal"
                  fullWidth={true}
                  onChange={handleChange}
                  validator={/^\w.*/}
                />
              </Grid>
              <Grid item xs={6}>
                <ValidatedTextField
                  name="firstName"
                  label={intl.formatMessage({
                    id: 'First Name',
                    defaultMessage: "First Name"
                  })}
                  value={user.get('firstName')}
                  margin="normal"
                  fullWidth={true}
                  onChange={handleChange}
                  validator={/^\w.*/}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} justify="flex-start">
              <Grid item xs={6}>
                <ValidatedTextField
                  name="homePhone"
                  label={intl.formatMessage({
                    id: 'Home Phone',
                    defaultMessage: "Home Phone"
                  })}
                  value={user.get('homePhone')}
                  margin="normal"
                  fullWidth={true}
                  onChange={handleChange}
                  validator={/(^$)|(^\(?\d\d\d\)?\s*-?\s*\d\d\d-?\d\d\d\d$)/}
                />
              </Grid>
              <Grid item xs={6}>
                <ValidatedTextField
                  name="cellPhone"
                  label={intl.formatMessage({
                    id: 'Cell Phone',
                    defaultMessage: "Cell Phone"
                  })}
                  value={user.get('cellPhone')}
                  margin="normal"
                  fullWidth={true}
                  onChange={handleChange}
                  validator={/(^$)|(^\(?\d\d\d\)?\s*-?\s*\d\d\d-?\d\d\d\d$)/}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ValidatedTextField
                type="email"
                name="email"
                label={intl.formatMessage({
                  id: 'Email Address',
                  defaultMessage: "Email Address"
                })}
                value={user.get('email')}
                margin="normal"
                fullWidth={true}
                onChange={handleChange}
                validator={/(^$)|(^\w+[\w.]*@\w+[\w.]*$)/}
              />
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  name="pushOverKey"
                  label={intl.formatMessage({
                    id: 'Push Over Key',
                    defaultMessage: "Push Over Key"
                  })}
                  value={user.get('pushOverKey')}
                  margin="normal"
                  fullWidth={true}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <Fab variant="extended" aria-label="Validate" className={classes.button}>
                  {intl.formatMessage({
                    id: "Validate",
                    defaultMessage: "Validate"
                  })}
                </Fab>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  name="password"
                  type="password"
                  label={intl.formatMessage({
                    id: "Password",
                    defaultMessage: "Password"
                  })}
                  value={user.get('password')}
                  margin="normal"
                  fullWidth={true}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <LanguageSelect
                selection={user.get('language')}
                onChange={handleChange}
                selectName="language"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <DepartmentSelect
                selection={user.get('defaultDepartment')}
                onChange={handleChange}
                selectName="defaultDepartment"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <AccessRightSelect
                selection={user.get('accessRights').toArray()}
                onChange={handleChange}
                selectName="accessRights"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {intl.formatMessage({
                  id: "Email Signature",
                  defaultMessage: "Email Signature"
                })}
              </Typography>
              <ReactQuill value={user.get('emailSignature')} onChange={(value) => { onChange("emailSignature", value) }} />
            </Grid>
            <Grid item xs={6}>
              <PrinterSelect
                selection={user.get('defaultPrinter')}
                onChange={(p) => { onChange("defaultPrinter", p); }}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <FormGroup row={true}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={user.get('active')}
                      inputProps={{ name: 'active' }}
                      onChange={handleChange}
                    />
                  }
                  label={intl.formatMessage({
                    id: "Active",
                    defaultMessage: "Active"
                  })}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

UserEditor.propTypes = {
  user: PropTypes.instanceOf(User).isRequired,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  saveDisabled: PropTypes.bool
};

UserEditor.defaultProps = {
  saveDisabled: false
};

export default UserEditor;
