import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles, TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(() => ({
    mainDiv: {
        width: '100%',
        display: 'flex'
    },
    textField: {
        flexGrow: 1
    },
    button: {
        flexGrow: 0
    }
}));

const ConfirmTextField = ({ label, valueToMatch, onConfirmation, onCancel, ...others }) => {
    const [value, setValue] = useState('');
    const classes = useStyles();

    const handleChange = newValue => {
        setValue(newValue);

        if (newValue === valueToMatch) {
            onConfirmation && onConfirmation();
        }
    }

    return (
        <div className={classes.mainDiv} {...others}>
            <TextField
                className={classes.textField}
                label={label}
                value={value}
                error={true}
                onChange={e => handleChange(e.target.value)}
            />

            <IconButton
                className={classes.button}
                size="small"
                onClick={() => { onCancel && onCancel(); }}
            >
                <CancelIcon />
            </IconButton>
        </div>
    )
}

ConfirmTextField.propTypes = {
    label: PropTypes.string,
    valueToMatch: PropTypes.string.isRequired,
    onConfirmation: PropTypes.func,
    onCancel: PropTypes.func
}

export default ConfirmTextField;
