import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { startOfDayUTC } from '../../helpers';
import { useVehicles, useReservations } from '../../Hooks';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, IconButton } from '@material-ui/core';
import NextIcon from '@material-ui/icons/FastForward';
import PreviousIcon from '@material-ui/icons/FastRewind';
import HeaderRow from './HeaderRow';
import ReservationMenu from './ReservationMenu';
import * as API from '../../backend';
import ViewerCell from './ViewerCell';
import { useSnackbar } from 'notistack';
import { ConfirmationDialog } from '../../Components';

const useStyles = makeStyles(() => ({
  column: {
    width: '12.5%'
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainDiv: {
    display: 'flex',
    width: '100%',
    outline: 'none'
  },
  table: {
    width: '100%'
  },
  evenRow: {
    backgroundColor: '#d0d0d0'
  },
  changeDateDiv: {
    width: '48px'
  }
}));


const calculateStartDate = () => {
  const currentDate = new Date();
  const sunday = new Date(currentDate.getTime() - currentDate.getDay() * (24 * 3600 * 1000));
  return Math.floor(startOfDayUTC(sunday) / 1000);
}

const Viewer = ({ onAdd, onEdit, ...others }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [startDate, setStartDate] = useState(calculateStartDate);
  const endDate = startDate + 7 * 24 * 3600 - 1;

  const [vehicles, vehicleFetchStatus] = useVehicles();
  const [reservations, reservationFetchStatus] = useReservations(startDate, endDate);

  const [menu, setMenu] = useState({ anchor: null, reservation: null });
  const [reservationToDelete, setReservationToDelete] = useState(null);

  const vehicleArray = vehicles.map(v => {
    const days = [null, null, null, null, null, null, null];
    for (let i = 0; i < 7; i++) {
      days[i] = {
        vehicle: v,
        date: startDate + i * 24 * 3600,
        reservation: null
      }
    }

    return {
      vehicle: v,
      days
    }
  });

  reservations.forEach(r => {
    const vIndex = vehicleArray.findIndex(v => {
      return r.vehicle.id === v.vehicle.id;
    });

    if (vIndex >= 0) {
      const vehicle = vehicleArray[vIndex];
      let startCell = -1;

      if (r.startDate >= startDate) {
        startCell = (r.startDate - startDate) / (24 * 3600);
      } else {
        startCell = 0;
      }

      let endCell = Math.floor((r.endDate - startDate) / (24 * 3600));

      for (let i = startCell; i <= Math.min(6, endCell); i++) {
        vehicle.days[i].reservation = r;
      }
    }
  });

  const openMenu = (anchor, reservation) => {
    setMenu({ anchor, reservation });
  }

  const rows = vehicleArray.map((v, rowIndex) => {
    let dayIndex = 0;
    const cells = [];

    while (dayIndex <= 6) {
      const reservation = v.days[dayIndex].reservation;
      let span = 1;
      const day = v.days[dayIndex];

      if (reservation) {
        while (dayIndex + span <= 6) {
          const nextReservation = v.days[dayIndex + span].reservation;
          if (nextReservation && reservation.id === nextReservation.id) {
            span = span + 1;
          } else {
            break;
          }
        }
      }

      cells.push(<ViewerCell
        key={v.vehicle.id + '-' + day.date}
        colSpan={span}
        reservation={day.reservation}
        vehicle={v.vehicle}
        date={day.date}
        openMenu={openMenu}
        onAdd={onAdd}
      />);

      dayIndex += span;
    }

    return (
      <tr key={v.vehicle.id} className={rowIndex % 2 === 0 ? classes.evenRow : undefined}>
        <td>
          {v.vehicle.description + ' ' + v.vehicle.model + ' ' + v.vehicle.color}
        </td>
        {cells}
      </tr>
    )
  });

  const handleNextClick = () => {
    setStartDate(startDate + 7 * 24 * 3600);
  }

  const handlePreviousClick = () => {
    setStartDate(startDate - 7 * 24 * 3600);
  }

  const closeMenu = () => {
    setMenu({ anchor: null, reservation: null });
  }

  const handleEdit = (reservation) => {
    closeMenu();
    if (onEdit) {
      onEdit(reservation);
    }
  }

  const handleDelete = (reservation) => {
    closeMenu();
    setReservationToDelete(reservation);
  }

  const deleteReservation = async (reservation) => {
    setReservationToDelete(null);

    try {
      await API.deleteReservation(reservation.id);
    }
    catch (error) {
      enqueueSnackbar(
        intl.formatMessage({
          id: "Error while deleting reservation.",
          defaultMessage: "Error while deleting reservation."
        }),
        {
          variant: 'error'
        }
      );
    }
  }

  const handleFulfill = async (reservation) => {
    closeMenu();
    const fr = Object.assign({}, reservation, { fulfilled: true });
    try {
      await API.saveReservation(fr);
    }
    catch (error) {
      enqueueSnackbar(
        intl.formatMessage({
          id: "Error while updating reservation.",
          defaultMessage: "Error while updating reservation."
        }),
        {
          variant: 'error'
        }
      );
    }
  }

  const handleKeyDown = event => {
    if (event.keyCode === 37) {
      setStartDate(startDate - 7 * 24 * 3600);
    } else if (event.keyCode === 39) {
      setStartDate(startDate + 7 * 24 * 3600);
    }
  }

  const mainDivRef = useRef(null);

  return (
    <div
      {...others}
    >
      {(vehicleFetchStatus !== 'success' || reservationFetchStatus !== 'success') && (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      )}

      <div
        className={classes.mainDiv}
        tabIndex="0"
        onKeyDown={handleKeyDown}
        ref={mainDivRef}
      >
        <div className={classes.changeDateDiv}>
          <IconButton onClick={handlePreviousClick}>
            <PreviousIcon />
          </IconButton>
        </div>
        <table className={classes.table}>
          <thead>
            <HeaderRow startDate={startDate} />
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
        <div className={classes.changeDateDiv}>
          <IconButton onClick={handleNextClick}>
            <NextIcon />
          </IconButton>
        </div>
      </div>

      <ReservationMenu
        anchor={menu.anchor}
        open={Boolean(menu.anchor)}
        reservation={menu.reservation}
        onClose={closeMenu}
        onEdit={handleEdit}
        onFulfill={handleFulfill}
        onDelete={handleDelete}
      />
      <ConfirmationDialog
        title={intl.formatMessage({
          id: "Delete Reservation",
          defaultMessage: "Delete Reservation"
        })}
        message={intl.formatMessage({
          id: "Are you sure you want to delete the reservation?",
          defaultMessage: "Are you sure you want to delete the reservation?"
        })}
        open={Boolean(reservationToDelete)}
        onCancel={() => { setReservationToDelete(null) }}
        onConfirm={() => { deleteReservation(reservationToDelete) }}
      />
    </div>
  )
}

Viewer.propTypes = {
  onAdd: PropTypes.func,
  onEdit: PropTypes.func
}

export default Viewer;
