import { setLanguage } from "../Language/Actions";
import { defineMessages } from 'react-intl';
import { showMessageByID } from "../Message/Actions";
import { MessageType } from "../Message/Types";
import * as Types from "./Types";
import IdTokenVerifier from "idtoken-verifier";
import { getRefreshToken, getUser, getRefreshTokenRetry } from "./Reducer";
import { Departments } from "../../Models/User";
import { setDepartment } from "../MainPage/Actions";
import * as API from '../../backend';

const TimeoutRatio = 0.5;

const messages = defineMessages({
  loginError: {
    id: "Error while connecting to server. Please retry.",
    defaultMessage: "Error while connecting to server. Please retry."
  }
});

export function loginRequest() {
  return {
    type: Types.LOGIN_REQUEST
  };
}

export function loginSuccess(result) {
  return {
    type: Types.LOGIN_SUCCESS,
    result
  };
}

export function loginFailure(error) {
  return {
    type: Types.LOGIN_FAILURE,
    error
  };
}

export function login(emailAddress, password) {
  return async function (dispatch, getState) {
    dispatch(loginRequest());

    try {
      const response = await API.login(emailAddress, password);

      if (response.user.language) {
        dispatch(setLanguage(response.user.language));
      }

      dispatch(loginSuccess(response));

      const state = getState();
      const user = getUser(state);

      switch (user.defaultDepartment) {
        case Departments.Service:
          dispatch(setDepartment(Departments.Service));
          break;
        default:
          dispatch(setDepartment(Departments.Sales));
          break;
      }

      const timeout =
        (getTokenExpiration(response.token) - Date.now()) *
        TimeoutRatio;
      dispatch(scheduleTokenRefresh(timeout));
    } catch (error) {
      dispatch(loginFailure(error));
      dispatch(showMessageByID(messages.loginError, 5000, MessageType.Error));
    }
  };
}

export const refreshTokenRequest = () => {
  return {
    type: Types.REFRESH_TOKEN_REQUEST
  };
};

export const refreshTokenSuccess = result => {
  return {
    type: Types.REFRESH_TOKEN_SUCCESS,
    result
  };
};

export const refreshTokenFailure = error => {
  return {
    type: Types.REFRESH_TOKEN_FAILURE,
    error
  };
};

const getTokenExpiration = token => {
  const verifier = new IdTokenVerifier();
  const decodedToken = verifier.decode(token);
  const expiry = decodedToken.payload.exp;
  return expiry * 1000;
};

export const refreshToken = () => {
  return async function (dispatch, getState) {
    dispatch(refreshTokenRequest());

    try {
      const state = getState();
      const token = getRefreshToken(state);
      const response = await API.refresh(token);
      dispatch(refreshTokenSuccess(response));

      const timeout =
        (getTokenExpiration(response.token) - Date.now()) *
        TimeoutRatio;
      dispatch(scheduleTokenRefresh(timeout));
    } catch (error) {
      const state = getState();
      const retries = getRefreshTokenRetry(state);

      if (retries < 2) {
        dispatch(refreshTokenFailure(error));
        dispatch(scheduleTokenRefresh(1 * 1000))
      } else {
        dispatch(loginFailure(error));
      }
    }
  };
};

export const setRefreshTokenTimer = timer => {
  return {
    type: Types.SET_TOKEN_REFRESH_TIMER,
    timer
  };
};

/**
 * Schedule the next token refresh.
 * @param {number} timeout - The delay in seconds before refreshing the token.
 */
export const scheduleTokenRefresh = timeout => {
  return async function (dispatch) {
    try {
      const timer = setTimeout(() => {
        dispatch(refreshToken());
      }, timeout > 0 ? timeout : 1 * 60 * 1000);
      dispatch(setRefreshTokenTimer(timer));
    } catch (error) {
      dispatch(refreshTokenFailure(error));
    }
  };
};
