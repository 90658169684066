import * as Types from "./Types";
import { Map } from "immutable";
import { RequestStatus } from "../Types";
import createReducer from "../CreateReducer";
import IdTokenVerifier from "idtoken-verifier";

const initialState = Map({
  token: null,
  tokenExpiry: null,
  refreshToken: null,
  refreshTokenTimer: undefined,
  refreshTokenRetry: 0,
  user: null,
  status: RequestStatus.None,
  refreshTokenStatus: RequestStatus.None,
  error: ""
});

const loginRequest = state => {
  return state.withMutations(state => {
    state
      .set("token", null)
      .set("tokenExpiry", null)
      .set("refreshToken", null)
      .set("refreshTokenRetry", 0)
      .set("user", null)
      .set("status", RequestStatus.Requested)
      .set("refreshTokenStatus", RequestStatus.None)
      .set("error", "");
  });
};

const loginSuccess = (state, { result }) => {
  const verifier = new IdTokenVerifier();
  const token = result.token;
  const decodedToken = verifier.decode(token);
  const expiry = decodedToken.payload.exp;

  return state.withMutations(state => {
    state
      .set("token", "Bearer " + result.token)
      .set("tokenExpiry", expiry)
      .set("refreshToken", "Bearer " + result.refreshToken)
      .set("user", result.user)
      .set("status", RequestStatus.Success)
      .set("refreshTokenStatus", RequestStatus.None)
      .set("refreshTokenRetry", 0)
      .set("error", "");
  });
};

const loginFailure = state => {
  return state.withMutations(state => {
    state
      .set("token", null)
      .set("tokenExpiry", null)
      .set("refreshToken", null)
      .set("user", null)
      .set("status", RequestStatus.Failure)
      .set("refreshTokenStatus", RequestStatus.None)
      .set("refreshTokenRetry", 0)
      .set("error", "");
  });
};

const refreshTokenRequest = state => {
  return state.set("refreshTokenStatus", RequestStatus.Requested);
};

const refreshTokenFailure = state => {
  return state.withMutations(state => {
    state
      .set("refreshTokenStatus", RequestStatus.Failure)
      .set("refreshTokenRetry", state.get("refreshTokenRetry") + 1);
  });
};

const refreshTokenSuccess = (state, action) => {
  return loginSuccess(state, action);
};

const reducer = createReducer(initialState, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.REFRESH_TOKEN_REQUEST]: refreshTokenRequest,
  [Types.REFRESH_TOKEN_SUCCESS]: refreshTokenSuccess,
  [Types.REFRESH_TOKEN_FAILURE]: refreshTokenFailure
});

export default reducer;

const getReducerState = state => {
  return state.get("login");
};

export const token = state => {
  return getReducerState(state).get("token");
};

export const getToken = token;

export const tokenExpiry = state => {
  return getReducerState(state).get("tokenExpiry");
};

export const getRefreshToken = state => {
  return getReducerState(state).get("refreshToken");
};

export const getRefreshTokenRetry = state => {
  return getReducerState(state).get("refreshTokenRetry");
}

export const loginStatus = state => {
  return getReducerState(state).get("status");
};

/**
 * Returns the currently logged in user.
 * @param {object} state - The global state
 */
export const getUser = state => {
  return getReducerState(state).get("user");
}

/**
 * Returns whether or not the current user has the specified access right.
 * @param {object} state - The global state.
 * @param {string} right - The access right to check.
 */
export const hasAccessRight = (state, right) => {
  const user = getUser(state);

  if (user) {
    return user.accessRights.indexOf(right) >= 0;
  }

  return false;
}
