import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((/*theme*/) => ({
  buttonWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const ProgressButton = ({ progress, progressColor, ...others }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonWrapper}>
      <Button {...others} />
      {progress && (
        <CircularProgress
          className={classes.buttonProgress}
          size={24}
          color={progressColor}
        />
      )}
    </div>
  );
}

ProgressButton.propTypes = {
  progress: PropTypes.bool,
  progressColor: PropTypes.string
};

ProgressButton.defaultProps = {
  progress: false,
  progressColor: "secondary"
};

export default ProgressButton;
