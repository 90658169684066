export const SettingSection = {
  General: 'general',
  InternetLeads: 'internet_leads',
  saleNotifications: 'sale_notifications'
};

export const FETCH_USERS_REQUEST = '@@settings.appsettings.fetchusersRequest';
export const FETCH_USERS_FAILURE = '@@settings.appsettings.fetchusersFailure';
export const FETCH_USERS_SUCCESS = '@@settings.appsettings.fetchusersSuccess';

export const FETCH_SETTINGS_REQUEST = '@@settings.appsettings.fetchsettingsrequest';
export const FETCH_SETTINGS_FAILURE = '@@settings.appsettings.fetchsettingsfailure';
export const FETCH_SETTINGS_SUCCESS = '@@settings.appsettings.fetchsettingssuccess';

export const SAVE_SETTINGS_REQUEST = '@@settings.appsettings.savesettingsrequest';
export const SAVE_SETTINGS_FAILURE = '@@settings.appsettings.savesettingsfailure';
export const SAVE_SETTINGS_SUCCESS = '@@settings.appsettings.savesettingssuccess';

export const SET_OPENED_SECTION = '@@settings.appsettings.set_opened_section';

export const CHANGE_SETTINGS = '@@settings.appsettings.changesettings';
