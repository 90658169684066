import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import ColorEditorUI from './ColorEditorUI';
import * as API from '../../backend';

const ColorEditorController = ({ colorID, onUpdate, onCreate, ...others }) => {
    const [color, setColor] = useState(null);
    const intl = useIntl();
    const snackbar = useSnackbar();

    useEffect(() => {
        const cancelSource = API.getCancelSource();

        const getColor = async () => {
            try {
                const result = await API.getColor(colorID);
                setColor(result);
            }
            catch (error) {
                snackbar.enqueueSnackbar(
                    intl.formatMessage({
                        id: "Error while loading color.",
                        defaultMessage: "Error while loading color."
                    }),
                    {
                        variant: 'error'
                    }
                );
            }
        }

        if (colorID) {
            getColor();
        } else {
            const color = {
                id: null,
                userEdited: true,
                code: '',
                description: ''
            };

            setColor(color);
        }


        return () => {
            cancelSource.cancel();
        }
    }, [colorID]);

    const handleOnSave = async (color) => {
        try {
            const result = await API.saveColor(color);

            if (color.id) {
                onUpdate && onUpdate(result);
            } else {
                onCreate && onCreate(result);
            }

        }
        catch (error) {
            snackbar.enqueueSnackbar(
                intl.formatMessage({
                    id: "Error while saving color.",
                    defaultMessage: "Error while saving color."
                }),
            )
        }
    }

    return (
        <div {...others}>
            {color && (
                <ColorEditorUI
                    color={color}
                    onSave={handleOnSave}
                    onChange={(c) => { setColor(c); }}
                />
            )}
        </div>
    )
}

ColorEditorController.propTypes = {
    colorID: PropTypes.string,
    onUpdate: PropTypes.func,
    onCreate: PropTypes.func
}

export default ColorEditorController;
