import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import WalkinIcon from "@material-ui/icons/Face";
import { PageId } from "../../DataStore/MainPage/Types";

const CustomerButton = ({ setPage, currentPage, ...others }) => {
  return (
    <IconButton
      color="inherit"
      aria-label="Customers"
      disabled={currentPage === PageId.Customers}
      onClick={() => {
        setPage(PageId.Customers);
      }}
      {...others}
    >
      <WalkinIcon />
    </IconButton>
  );
}

CustomerButton.propTypes = {
  setPage: PropTypes.func,
  currentPage: PropTypes.string
}

export default CustomerButton;
