import { useEffect, useState, useCallback } from 'react';
import * as API from '../backend';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useEventProvider } from '../Providers/EventProvider';

const useVehicles_ = (fetch) => {
  const [vehicles, setVehicles] = useState([]);
  const [status, setStatus] = useState("loading");
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const eventProvider = useEventProvider();

  useEffect(() => {
    const cancelSource = API.getCancelSource();

    const fetchVehicles = async () => {
      try {
        setStatus("loading");
        const vehicles = await fetch(cancelSource);
        setVehicles(vehicles);
        setStatus("success");
      }
      catch (error) {
        if (API.isCancel(error)) {
          setStatus("cancelled");
        } else {
          setStatus("failure");
          enqueueSnackbar(
            intl.formatMessage({
              id: "Erreur while fetching vehicles.",
              defaultMessage: "Erreur while fetching vehicles."
            }),
            {
              variant: 'error'
            }
          );
        }
      }
    }

    fetchVehicles();

    eventProvider.addEventListener(API.Events.addVehicle, fetchVehicles);
    eventProvider.addEventListener(API.Events.updateVehicle, fetchVehicles);
    eventProvider.addEventListener(API.Events.deleteVehicle, fetchVehicles);

    return () => {
      cancelSource.cancel();
      eventProvider.removeEventListener(API.Events.addVehicle, fetchVehicles);
      eventProvider.removeEventListener(API.Events.updateVehicle, fetchVehicles);
      eventProvider.removeEventListener(API.Events.deleteVehicle, fetchVehicles);
    }
  }, [setVehicles, setStatus, intl, enqueueSnackbar, fetch, eventProvider]);

  return [vehicles, status];
}

export const useVehicles = () => {
  return useVehicles_(API.allVehicles);
}

export const useAvailableVehicles = (startDate, endDate, currentReservationID) => {
  const fetch = useCallback(async (cancelSource) => {
    return await API.availableVehicles(startDate, endDate, currentReservationID, cancelSource)
  }, [startDate, endDate, currentReservationID]);

  return useVehicles_(fetch);
}
