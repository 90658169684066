import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import User from './User';
import AddUserItem from './AddUserItem';

const UserList = ({ users, onEditUser, onAddUser, ...others }) => {
  const usersList = users.map(user => {
    return (
      <User key={user.id} user={user} onEdit={onEditUser} />
    )
  });

  return (
    <List {...others}>
      <AddUserItem onAdd={onAddUser} />
      {usersList}
    </List>
  )
}


UserList.propTypes = {
  users: PropTypes.array.isRequired,
  onEditUser: PropTypes.func,
  onAddUser: PropTypes.func
};

export default UserList;
