import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { PurchaseType } from '../Models/Visit';

const VehicleAsTextField = ({ currentSelection, onChange, ...others }) => {
  const intl = useIntl();

  return (
    <TextField
      {...others}
      type="text"
      name="vehicle"
      label={intl.formatMessage({
        id: "Vehicle", defaultMessage: "Vehicle"
      })}
      value={currentSelection}
      onChange={onChange}
    />
  )
}

VehicleAsTextField.propTypes = {
  currentSelection: PropTypes.string,
  onChange: PropTypes.func,
  intl: PropTypes.object.isRequired
};

const VehicleAsSelect = ({ currentSelection, availableVehicles, onChange, ...others }) => {
  const intl = useIntl();

  var allVehicles = availableVehicles;

  if (currentSelection.length > 0 && availableVehicles.indexOf(currentSelection) < 0) {
    allVehicles.push(currentSelection);
  }

  const items = allVehicles.map(vehicle => {
    return (
      <MenuItem key={vehicle} value={vehicle}>
        {vehicle}
      </MenuItem>
    )
  });

  return (
    <FormControl {...others}>
      <InputLabel htmlFor="vehicle">
        {intl.formatMessage({ id: "Vehicle", defaultMessage: "Vehicle" })}
      </InputLabel>
      <Select
        value={currentSelection}
        inputProps={{
          name: "vehicle"
        }}
        onChange={onChange}
      >
        {items}
      </Select>
    </FormControl>
  )
}

VehicleAsSelect.propTypes = {
  currentSelection: PropTypes.string,
  availableVehicles: PropTypes.array,
  onChange: PropTypes.func
};

const VehicleSelector = ({ purchaseType, availableVehicles, ...others }) => {
  if (purchaseType === PurchaseType.Used) {
    return (<VehicleAsTextField {...others} />);
  }
  else {
    return (<VehicleAsSelect availableVehicles={availableVehicles} {...others} />);
  }
}

VehicleSelector.propTypes = {
  purchaseType: PropTypes.number,
  availableVehicles: PropTypes.array,
  currentSelection: PropTypes.string,
  onChange: PropTypes.func
}

export default VehicleSelector;