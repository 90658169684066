import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { VisitTypeSelect, UserSelect, LabeledCheckbox } from '../Components';
import { getSalesmen, getManagers } from '../DataStore';
import { dateToText, textToDate } from '../helpers';
import { advancedSearch } from '../DataStore/CustomerManager/AdvancedSearch/Actions';

const useStyles = makeStyles(theme => ({
  entryField: {
    margin: theme.spacing(1),
    width: '200px'
  },
  mainDiv: {
    display: 'flex',
    flexDirection: 'column'
  },
  rowDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  searchButtonDiv: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const AdvancedSearch = ({ ...others }) => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();

  const now = new Date();
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [visitType, setVisitType] = useState(0);
  const [visitTimeStart, setVisitTimeStart] = useState(new Date(now.getTime() - 3 * 30 * 24 * 3600 * 1000));
  const [visitTimeEnd, setVisitTimeEnd] = useState(now);
  const [salesman, setSalesman] = useState("none");
  const [takeOver, setTakeOver] = useState("none");
  const [followUp, setFollowUp] = useState(false);

  const salesmen = useSelector(getSalesmen);
  const managers = useSelector(getManagers);

  const search = criteria => {
    return dispatch(advancedSearch(criteria));
  }

  const handleSearch = () => {
    let criteria = {};

    if (lastName.trim().length > 0) {
      criteria.lastName = lastName.trim();
    }

    if (firstName.trim().length > 0) {
      criteria.firstName = firstName.trim();
    }

    if (email.trim().length > 0) {
      criteria.email = email.trim();
    }

    if (phone.trim().length > 0) {
      criteria.phone = phone.trim()
    }

    if (visitType > 0) {
      criteria.visitType = visitType;
    }

    criteria.visitTimeStart = Math.floor(visitTimeStart.getTime() / 1000);
    criteria.visitTimeEnd = Math.floor(visitTimeEnd.getTime() / 1000);

    if (salesman !== "none") {
      criteria.salesman = salesman;
    }

    if (takeOver !== "none") {
      criteria.takeOver = takeOver;
    }

    if (followUp) {
      criteria.needFollowUp = followUp;
    }

    search(criteria);
  }

  return (
    <div className={classes.mainDiv} {...others}>
      <div className={classes.rowDiv}>
        <div>
          <LabeledCheckbox
            className={classes.entryField}
            label={intl.formatMessage({
              id: "Follow Up",
              defaultMessage: "Follow Up"
            })}
            value={followUp}
            onChange={(value) => { setFollowUp(value) }}
          />
        </div>
        <div>
          <TextField
            className={classes.entryField}
            name="lastName"
            label={intl.formatMessage({
              id: "Last Name",
              defaultMessage: "Last Name"
            })}
            value={lastName}
            onChange={(event) => { setLastName(event.target.value) }}
          />
        </div>
        <div>
          <TextField
            className={classes.entryField}
            name="firstName"
            label={intl.formatMessage({
              id: "First Name",
              defaultMessage: "First Name"
            })}
            value={firstName}
            onChange={(event) => { setFirstName(event.target.value) }}
          />
        </div>
      </div>
      <div className={classes.rowDiv}>
        <div>
          <TextField
            className={classes.entryField}
            name="email"
            label={intl.formatMessage({
              id: "Email",
              defaultMessage: "Email"
            })}
            value={email}
            onChange={(event) => { setEmail(event.target.value) }}
          />
        </div>
        <div>
          <TextField
            className={classes.entryField}
            name="phone"
            label={intl.formatMessage({
              id: "Phone",
              defaultMessage: "Phone"
            })}
            value={phone}
            onChange={(event) => { setPhone(event.target.value) }}
          />
        </div>
      </div>
      <div className={classes.rowDiv}>
        <div>
          <UserSelect
            className={classes.entryField}
            name="salesman"
            users={salesmen}
            label={intl.formatMessage({
              id: "Salesman",
              defaultMessage: "Salesman"
            })}
            value={salesman}
            noValue={true}
            onChange={(value) => { setSalesman(value) }}
          />
        </div>
        <div>
          <UserSelect
            className={classes.entryField}
            name="takeover"
            label={intl.formatMessage({
              id: "Take Over",
              defaultMessage: "Take Over"
            })}
            users={managers}
            value={takeOver}
            noValue={true}
            onChange={(value) => { setTakeOver(value) }}
          />
        </div>
        <div>
          <VisitTypeSelect
            className={classes.entryField}
            name="visitType"
            label={intl.formatMessage({
              id: "Visit Type",
              defaultMessage: "VisitType"
            })}
            value={visitType}
            noValue={true}
            onChange={(value) => { setVisitType(value) }}
          />
        </div>
      </div>
      <div className={classes.rowDiv}>
        <div>
          <TextField
            className={classes.entryField}
            name="visitTimeStart"
            label={intl.formatMessage({
              id: "Time Start",
              defaultMessage: "Time Start"
            })}
            type="date"
            value={dateToText(visitTimeStart)}
            onChange={(event) => { setVisitTimeStart(textToDate(event.target.value)) }}
          />
        </div>
        <div>
          <TextField
            className={classes.entryField}
            name="visitTimeEnd"
            label={intl.formatMessage({
              id: "End Time",
              defaultMessage: "End Time"
            })}
            type="date"
            value={dateToText(visitTimeEnd)}
            onChange={(event) => { setVisitTimeEnd(textToDate(event.target.value)) }}
          />
        </div>
      </div>
      <div className={classes.searchButtonDiv}>
        <Button onClick={handleSearch}>
          {intl.formatMessage({
            id: "Search",
            defaultMessage: "Search"
          })}
        </Button>
      </div>
    </div>
  );
}

export default AdvancedSearch;
