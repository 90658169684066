import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const VehicleSelect = ({ label, vehicles, value, onChange, ...others }) => {
  const intl = useIntl();

  const items = vehicles.map(v => {
    return (
      <MenuItem key={v.id} value={v.id}>{v.description + " " + v.brand + " " + v.model + " " + v.year + " " + v.color}</MenuItem>
    )
  });

  return (
    <FormControl {...others}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={(event) => { onChange && onChange(event.target.value) }}
      >
        <MenuItem
          value="none">
          {intl.formatMessage({
            id: 'None',
            defaultMessage: "None"
          })}
        </MenuItem>
        {items}
      </Select>
    </FormControl>
  )
}

VehicleSelect.propTypes = {
  label: PropTypes.string,
  vehicles: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default VehicleSelect;
