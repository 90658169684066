import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%'
  }
}));

const languages = [
  { id: 'fr', description: 'Français' },
  { id: 'en', description: 'English' }
];

const LanguageSelect = ({ selectName, selection, onChange, fullWidth, ...others }) => {
  const classes = useStyles();
  const intl = useIntl();

  const items = languages.map(language => {
    return (
      <MenuItem value={language.id} key={language.id}>
        {language.description}
      </MenuItem>
    )
  });

  return (
    <FormControl
      className={fullWidth ? classes.fullWidth : null}
      margin="normal"
      {...others}
    >
      <InputLabel htmlFor={selectName}>
        {intl.formatMessage({
          id: "Language",
          defaultMessage: "Language"
        })}
      </InputLabel>
      <Select
        value={selection}
        inputProps={{
          name: selectName
        }}
        onChange={onChange}>
        {items}
      </Select>
    </FormControl>
  );
}


LanguageSelect.propTypes = {
  selectName: PropTypes.string,
  selection: PropTypes.string,
  alwaysPresent: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool
};

export default LanguageSelect;

