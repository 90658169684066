import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    item: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    card: props => ({
        backgroundColor: props.selected ? 'blue' : undefined
    })
}));

const ColorListItem = ({ color, onChange, onSelect, selected, ...others }) => {
    const classes = useStyles({ selected });

    return (
        <li className={classes.item} {...others}>
            <Card
                className={classes.card}
                onClick={onSelect}
            >
                <CardContent>
                    <Typography variant="caption">
                        {color.code}
                    </Typography>
                    <Typography variant="h6">
                        {color.description}
                    </Typography>
                </CardContent>
            </Card>
        </li>
    );
}

ColorListItem.propTypes = {
    color: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    selected: PropTypes.bool
}

export default ColorListItem;
