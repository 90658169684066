import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { AssignToType } from '../../../Models/Settings';

const AssignToTypeSelect = ({ id, name, value, onChange, ...others }) => {
  const intl = useIntl();

  return (
    <FormControl {...others}>
      <InputLabel htmlFor={id}>
        {intl.formatMessage({
          id: "Assign To",
          defaultMessage: "Assign To"
        })}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        inputProps={{
          name: name,
          id: id,
        }}
      >
        <MenuItem value={AssignToType.CurrentTurn}>
          {intl.formatMessage({
            id: "Turn",
            defaultMessage: "Turn"
          })}
        </MenuItem>
        <MenuItem value={AssignToType.FirstOfDay}>
          {intl.formatMessage({
            id: "First Of Day",
            defaultMessage: "First Of Day"
          })}
        </MenuItem>
        <MenuItem value={AssignToType.AlwaysSame}>
          {intl.formatMessage({
            id: "Always Same",
            defaultMessage: "Always Same"
          })}
        </MenuItem>
      </Select>
    </FormControl>
  )
}

AssignToTypeSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

AssignToTypeSelect.defaultProps = {
  id: '',
  name: '',
  value: 1,
  onChange: null
};

export default AssignToTypeSelect;
