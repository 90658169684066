import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import ModelEditorUI from './ModelEditorUI';

import * as API from '../../backend';

const ModelEditorController = ({ modelID, onUpdate, onCreate, ...others }) => {
    const [model, setModel] = useState(null);
    const intl = useIntl();
    const snackbar = useSnackbar();

    useEffect(() => {
        const cancelSource = API.getCancelSource();
        const getModel = async () => {
            try {
                const result = await API.getModel(modelID, cancelSource);
                setModel(result);
            }
            catch (error) {
                snackbar.enqueueSnackbar(
                    intl.formatMessage({
                        id: "Error while loading model.",
                        defaultMessage: "Error while loading model."
                    }),
                    {
                        variant: 'error'
                    }
                );
            }
        }

        if (modelID) {
            getModel();
        } else {
            const model = {
                id: null,
                userEdited: true,
                code: '',
                brand: '',
                description: '',
                doors: 4,
                transmission: '',
                trim: ''
            };

            setModel(model);
        }

        return () => {
            cancelSource.cancel();
        }
    }, [modelID]);

    const handleOnSave = async m => {
        try {
            const result = await API.saveModel(m);

            if (m.id) {
                onUpdate && onUpdate(result);
            } else {
                onCreate && onCreate(result);
            }
        }
        catch (error) {
            snackbar.enqueueSnackbar(
                intl.formatMessage({
                    id: "Error while saving model.",
                    defaultMessage: "Error while saving model."
                })
            );
        }
    }

    return (
        <div {...others}>
            {model && (
                <ModelEditorUI
                    model={model}
                    onChange={(m) => { setModel(m); }}
                    onSave={(m) => { handleOnSave(m); }}
                />
            )}
        </div>
    )
}

ModelEditorController.propTypes = {
    modelID: PropTypes.string,
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func
}

export default ModelEditorController;
