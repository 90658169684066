import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl, defineMessages } from 'react-intl';
import { firstOpenDayOfMonth } from '../helpers/date';
import { VisitType } from '../Models/Visit';
import { VictoryBar, VictoryChart, VictoryLabel, VictoryAxis } from 'victory';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((/*theme*/) => ({
  rootDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const messages = defineMessages({
  titleLabel: {
    id: 'Customers Sources',
    defaultMessage: 'Customer Sources'
  },
  walkinsLabel: {
    id: 'Walkins',
    defaultMessage: 'Walkins'
  },
  internetLabel: {
    id: 'Internet',
    defaultMessage: 'Internet'
  },
  phoneLabel: {
    id: 'Phone',
    defaultMessage: 'Phone'
  },
  serviceLabel: {
    id: 'Service',
    defaultMessage: 'Service'
  }
});


const CustomerSource = ({ customers, ...others }) => {
  const intl = useIntl();
  const classes = useStyles();

  const getValues = (customers) => {
    const result = [
      { x: 1, y: 0, color: 'red' },
      { x: 2, y: 0, color: 'yellow' },
      { x: 3, y: 0, color: 'orange' },
      { x: 4, y: 0, color: 'gray' }
    ];

    const startOfMonth = firstOpenDayOfMonth(new Date()).getTime() / 1000;
    customers.forEach(customer => {
      if (customer.visits && customer.visits.length > 0) {
        const visit = customer.visits[0];
        if (visit.date >= startOfMonth) {

          switch (visit.type) {
            case VisitType.Walkin:
              result[0].y = result[0].y + 1;
              break;
            case VisitType.Internet:
              result[1].y = result[1].y + 1;
              break;
            case VisitType.Phone:
              result[2].y = result[2].y + 1;
              break;
            case VisitType.Service:
              result[3].y = result[3].y + 1;
              break;
            default:
              break;
          }
        }
      }
    });

    return result.map((value, index) => {
      const newValue = {
        x: index + 1,
        y: value.y,
        label: `${value.y}`
      }

      return newValue;
    });
  }

  const values = getValues(customers);

  return (
    <div {...others}>
      <div className={classes.rootDiv}>
        <Typography variant="h6">
          {intl.formatMessage(messages.titleLabel)}
        </Typography>
        <div>
          <VictoryChart>
            <VictoryAxis
              tickValues={[1, 2, 3, 4]}
              tickFormat={[
                intl.formatMessage(messages.walkinsLabel),
                intl.formatMessage(messages.internetLabel),
                intl.formatMessage(messages.phoneLabel),
                intl.formatMessage(messages.serviceLabel)
              ]}
              style={{
                tickLabels: {
                  fontSize: 20,
                  fill: "black",
                }
              }}
            />
            <VictoryBar
              standalone={true}
              horizontal={false}
              barRatio={0.9}
              labelComponent={<VictoryLabel textAnchor="middle" />}
              data={values}
              style={{
                data: {
                  fillOpacity: 0.9, stroke: "#bbbbbb", strokeWidth: 2, fill: "#bbbbbb"
                },
                labels: {
                  fontSize: 25, fill: "black"
                }
              }}
            />
          </VictoryChart>
        </div>
      </div>
    </div>
  )
}

CustomerSource.propTypes = {
  customers: PropTypes.array.isRequired
}

export default CustomerSource;
