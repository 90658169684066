import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const TireIcon = (props, ref) => {
  return (
    <SvgIcon ref={ref} {...props}>
      <g>
        <path d="M12,0 C5.373,0 0,5.373 0,12 C0,18.627 5.373,24 12,24 C18.627,24 24,18.627 24,12 C23.993,5.376 18.624,0.008 12,0 z M22.766,15.078 L22.015,14.833 L21.767,15.593 L22.522,15.838 C22.21,16.69 21.795,17.5 21.288,18.252 L20.648,17.788 L20.177,18.435 L20.812,18.897 C20.253,19.61 19.611,20.254 18.898,20.814 L18.436,20.179 L17.789,20.649 L18.253,21.289 C17.502,21.796 16.691,22.21 15.84,22.522 L15.595,21.767 L14.835,22.015 L15.079,22.766 C14.207,23.016 13.307,23.158 12.4,23.19 L12.4,22.4 L11.6,22.4 L11.6,23.19 C10.694,23.158 9.794,23.016 8.922,22.766 L9.167,22.015 L8.407,21.767 L8.16,22.522 C7.308,22.21 6.498,21.795 5.746,21.288 L6.21,20.648 L5.563,20.177 L5.102,20.812 C4.388,20.253 3.745,19.61 3.186,18.896 L3.821,18.435 L3.35,17.788 L2.71,18.252 C2.204,17.5 1.791,16.691 1.48,15.84 L2.235,15.595 L1.987,14.835 L1.236,15.079 C0.985,14.207 0.842,13.307 0.81,12.4 L1.6,12.4 L1.6,11.6 L0.81,11.6 C0.842,10.694 0.984,9.794 1.234,8.922 L1.985,9.167 L2.233,8.407 L1.48,8.16 C1.792,7.308 2.207,6.498 2.714,5.746 L3.354,6.21 L3.825,5.563 L3.186,5.102 C3.746,4.389 4.389,3.746 5.102,3.186 L5.564,3.821 L6.211,3.351 L5.747,2.711 C6.498,2.204 7.309,1.791 8.16,1.48 L8.405,2.235 L9.165,1.987 L8.921,1.236 C9.793,0.985 10.693,0.842 11.6,0.81 L11.6,1.6 L12.4,1.6 L12.4,0.81 C13.306,0.842 14.206,0.984 15.078,1.234 L14.833,1.985 L15.593,2.233 L15.84,1.48 C16.692,1.792 17.502,2.207 18.254,2.714 L17.79,3.354 L18.437,3.825 L18.898,3.19 C19.612,3.749 20.255,4.392 20.814,5.106 L20.179,5.567 L20.65,6.214 L21.29,5.75 C21.796,6.501 22.21,7.31 22.522,8.16 L21.767,8.405 L22.015,9.165 L22.766,8.921 C23.016,9.793 23.158,10.693 23.19,11.6 L22.4,11.6 L22.4,12.4 L23.19,12.4 C23.158,13.306 23.016,14.206 22.766,15.078 z" />
        <path d="M12,3.2 C7.14,3.2 3.2,7.14 3.2,12 C3.2,16.86 7.14,20.8 12,20.8 C16.86,20.8 20.8,16.86 20.8,12 C20.795,7.142 16.858,3.206 12,3.2 z M12,20 C7.582,20 4,16.418 4,12 C4,7.582 7.582,4 12,4 C16.418,4 20,7.582 20,12 C19.995,16.416 16.416,19.995 12,20 z" />
        <path d="M12,5.2 C8.244,5.2 5.2,8.244 5.2,12 C5.2,15.756 8.244,18.8 12,18.8 C15.756,18.8 18.8,15.756 18.8,12 C18.796,8.246 15.754,5.204 12,5.2 z M17.144,8.927 C17.609,9.699 17.892,10.566 17.973,11.464 L15.468,11.262 C15.247,11.243 15.084,11.05 15.103,10.83 C15.111,10.725 15.161,10.628 15.24,10.56 L17.144,8.927 z M13.336,6.155 L12.369,8.475 C12.284,8.679 12.05,8.776 11.846,8.691 C11.749,8.65 11.671,8.573 11.631,8.475 L10.664,6.155 C11.543,5.948 12.457,5.948 13.336,6.155 z M6.856,8.927 L8.76,10.56 C8.928,10.704 8.947,10.956 8.803,11.124 C8.735,11.204 8.637,11.254 8.532,11.262 L6.027,11.465 C6.107,10.567 6.391,9.699 6.856,8.927 z M10.225,15.07 L9.644,17.517 C8.816,17.16 8.08,16.621 7.488,15.942 L7.486,15.942 L9.627,14.636 C9.816,14.52 10.062,14.58 10.177,14.769 C10.232,14.859 10.249,14.967 10.225,15.07 z M14.356,17.517 L14.355,17.517 L13.773,15.07 C13.722,14.855 13.854,14.639 14.069,14.588 C14.172,14.563 14.28,14.581 14.37,14.636 L16.511,15.942 C15.92,16.621 15.183,17.159 14.356,17.517 z M14.788,13.952 C14.222,13.607 13.484,13.786 13.139,14.352 C12.974,14.622 12.922,14.947 12.996,15.255 L13.596,17.778 C12.552,18.074 11.447,18.074 10.404,17.778 L10.403,17.778 L11.003,15.255 C11.156,14.61 10.758,13.963 10.113,13.81 C9.805,13.737 9.481,13.788 9.21,13.953 L6.997,15.304 C6.399,14.4 6.059,13.35 6.014,12.268 L8.597,12.059 C9.257,12.005 9.749,11.425 9.695,10.765 C9.669,10.45 9.52,10.158 9.28,9.952 L7.312,8.264 C7.988,7.418 8.88,6.77 9.894,6.388 L10.891,8.782 C11.146,9.393 11.848,9.683 12.46,9.428 C12.752,9.306 12.985,9.074 13.106,8.782 L14.104,6.388 C15.117,6.77 16.009,7.418 16.686,8.264 L14.72,9.952 C14.217,10.384 14.159,11.141 14.591,11.644 C14.797,11.884 15.089,12.033 15.404,12.059 L17.988,12.268 C17.942,13.35 17.602,14.4 17.004,15.304 L14.788,13.952 z" />
        <path d="M12,10.4 C11.116,10.4 10.4,11.116 10.4,12 C10.4,12.884 11.116,13.6 12,13.6 C12.884,13.6 13.6,12.884 13.6,12 C13.6,11.116 12.884,10.4 12,10.4 z M12,12.8 C11.558,12.8 11.2,12.442 11.2,12 C11.2,11.558 11.558,11.2 12,11.2 C12.442,11.2 12.8,11.558 12.8,12 C12.8,12.442 12.442,12.8 12,12.8 z" />
      </g>
    </SvgIcon>
  )
}

export default React.forwardRef(TireIcon);
