import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { login } from "../DataStore/Login/Actions";
import { makeStyles } from "@material-ui/core/styles";
import ProgressButton from "../UI/ProgressButton";
import TextField from "@material-ui/core/TextField";
import Typogragpy from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useIntl } from "react-intl";
import Cookies from "js-cookie";

const useStyles = makeStyles(theme => ({
  loginPage: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%'
  },
  container: {
    width: "100%",
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-100px'
  },
  heading: {
    width: "300px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: "0.25em"
  },
  textField: {
    width: "300px"
  },
  okButton: {
    margin: theme.spacing(1)
  },
  rememberMe: {
    marginTop: "50px"
  }
}));

const Login = ({ showProgress, ...others }) => {
  const [rememberMe, setRememberMe] = useState(Cookies.get("emailAddress") ? true : false);
  const [emailAddress, setEmailAddress] = useState(Cookies.get("emailAddress") || "");
  const [password, setPassword] = useState("");
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleSubmit = event => {
    event.preventDefault();

    if (rememberMe) {
      Cookies.set("emailAddress", emailAddress, { expires: 30 });
    } else {
      Cookies.remove("emailAddress");
    }
    dispatch(login(emailAddress, password));
  }

  return (
    <div className={classes.loginPage} {...others}>
      <form className={classes.container} onSubmit={handleSubmit}>
        <Typogragpy variant="h6" color="primary">
          {intl.formatMessage({
            id: "Login",
            defaultMessage: "Login"
          })}
        </Typogragpy>

        <TextField
          className={classes.textField}
          name="emailAddress"
          label={intl.formatMessage({
            id: "Email Address",
            defaultMessage: "Email Address"
          })}
          value={emailAddress}
          onChange={e => { setEmailAddress(e.target.value) }}
          autoComplete="username"
          type="email"
          margin="normal"
        />

        <TextField
          className={classes.textField}
          type="password"
          autoComplete="current-password"
          name="password"
          label={intl.formatMessage({
            id: "Password",
            defaultMessage: "Password"
          })}
          value={password}
          onChange={e => { setPassword(e.target.value) }}
          margin="normal"
        />

        <ProgressButton
          type="submit"
          variant="contained"
          color="primary"
          progress={showProgress}
          onClick={handleSubmit}
          className={classes.okButton}
        >
          {intl.formatMessage({
            id: "Ok",
            defaultMessage: "Ok"
          })}
        </ProgressButton>

        <FormControlLabel
          className={classes.rememberMe}
          control={
            <Switch
              checked={rememberMe}
              onChange={e => { setRememberMe(e.target.checked) }}
              value="rememberMe"
              name="rememberMe"
              color="primary"
            />
          }
          label={intl.formatMessage({
            id: "Remember Me",
            defaultMessage: "Remember Me"
          })}
        />
      </form >
    </div >
  );
}

Login.propTypes = {
  showProgress: PropTypes.bool
};

export default Login;
