import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

import { MenuSelection } from './Types';

const messages = defineMessages({
  addButton: {
    id: 'Add Storage',
    defaultMessage: 'Add Storage'
  },
  findButton: {
    id: 'Find Storage',
    defaultMessage: 'Find Storage'
  }
});

const useStyles = makeStyles(theme => ({
  menuDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-15vh'
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const StorageMenu = ({ onSelect, ...others }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.menuDiv} {...others}>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => onSelect(MenuSelection.AddStorage)}
      >
        {intl.formatMessage(messages.addButton)}
      </Button>
      <Button
        name={MenuSelection.FindStorage}
        variant="contained"
        className={classes.button}
        onClick={() => onSelect(MenuSelection.FindStorage)}
      >
        {intl.formatMessage(messages.findButton)}
      </Button>
    </div>
  )
}

StorageMenu.propTypes = {
  onSelect: PropTypes.func
}

export default StorageMenu;
