import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import CustomerItem from './CustomerItem';
import { getUsersObject } from '../DataStore';

const useStyles = makeStyles((/*theme*/) => ({
  gridList: {
    width: "100%"
  }
}));

const Customers = ({ customers, onEditCustomer, onDeleteCustomer, canEdit, canDelete, currentUser, ...others }) => {
  const classes = useStyles();
  const salesmen = useSelector(getUsersObject);

  const salesman = (customer) => {
    const salesman = salesmen[customer.salesman];
    return salesman;
  }

  const items = customers.map(customer => {
    return (
      <GridListTile key={customer.id} {...others}>
        <CustomerItem
          customer={customer}
          salesman={salesman(customer)}
          onEdit={onEditCustomer}
          onDelete={onDeleteCustomer}
          canDelete={canDelete}
          canEdit={canEdit}
        />
      </GridListTile>
    );
  });

  return (
    <GridList className={classes.gridList} cellHeight={"auto"} cols={1}>
      {items}
    </GridList>
  )
}

Customers.propTypes = {
  customers: PropTypes.array.isRequired,
  onEditCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  currentUser: PropTypes.object
}

export default Customers;
