import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const TakeOverSelect = ({ managers, value, onChange, ...others }) => {
  const intl = useIntl();

  const items = managers.map(manager => {
    return (
      <MenuItem key={manager.id} value={manager.id}>
        {manager.firstName + " " + manager.lastName}
      </MenuItem>
    )
  });

  return (
    <FormControl {...others}>
      <InputLabel htmlFor="vehicle">
        {intl.formatMessage({ id: 'Take Over', defaultMessage: "Take Over" })}
      </InputLabel>
      <Select
        value={value}
        inputProps={{
          name: "vehicle"
        }}
        onChange={onChange}
      >
        <MenuItem value="none">
          {intl.formatMessage({ id: "None", defaultMessage: "None" })}
        </MenuItem>
        {items}
      </Select>
    </FormControl>
  )
}

TakeOverSelect.propTypes = {
  managers: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  intl: PropTypes.object
}

export default TakeOverSelect;
