import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    item: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),

    },
    card: props => ({
        backgroundColor: props.selected ? 'blue' : ''
    })
}));

const ModelListItem = ({ model, onSelect, selected, ...others }) => {
    const classes = useStyles({ selected });

    return (
        <li className={classes.item} onClick={onSelect} {...others}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="caption">
                        {model.code}
                    </Typography>
                    <Typography variant="h6">
                        {model.brand} {model.description}
                    </Typography>
                    <Typography>
                        {model.trim}
                    </Typography>
                    <Typography>
                        {model.transmission}
                    </Typography>
                </CardContent>
            </Card>
        </li>
    )
}

ModelListItem.propTypes = {
    model: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    selected: PropTypes.bool
}

export default ModelListItem;
