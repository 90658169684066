import { OrderedMap, Map } from 'immutable';
import createReducer from "../CreateReducer";
import { createSelector } from 'reselect';
import { RequestStatus } from "../Types";
import * as Types from "./Types";

const initialState = Map({
  fetchStatus: RequestStatus.None,
  setPasswordStatus: RequestStatus.None,
  users: OrderedMap()
})

const fetchUsersSuccess = (state, { users }) => {
  const newUsers = users.reduce((result, user) => {
    result[user.id] = user;
    return result;
  }, {});

  return state.withMutations(state => {
    state
      .set("fetchStatus", RequestStatus.Success)
      .set("users", OrderedMap(newUsers));
  })
}

const fetchUsersFailure = state => {
  return state.set("fetchStatus", RequestStatus.Failure)
}

const fetchUsersRequest = state => {
  return state.set("fetchStatus", RequestStatus.Requested);
}

const addUser = (state, { user }) => {
  return state.setIn(["users", user.id], user);
}

const modifyUser = (state, { user }) => {
  return state.setIn(["users", user.id], user);
}

const setPasswordRequest = state => {
  return state.set('setPasswordStatus', RequestStatus.Requested);
}

const setPasswordSuccess = state => {
  return state.set('setPasswordStatus', RequestStatus.Success);
}

const setPasswordFailure = state => {
  return state.set('setPasswordFailure', RequestStatus.Failure);
}

const reducer = createReducer(initialState, {
  [Types.FETCH_USERS_REQUEST]: fetchUsersRequest,
  [Types.FETCH_USERS_SUCCESS]: fetchUsersSuccess,
  [Types.FETCH_USERS_FAILURE]: fetchUsersFailure,
  [Types.ADD_USER]: addUser,
  [Types.MODIFY_USER]: modifyUser,
  [Types.SET_PASSWORD_REQUEST]: setPasswordRequest,
  [Types.SET_PASSWORD_SUCCESS]: setPasswordSuccess,
  [Types.SET_PASSWORD_FAILURE]: setPasswordFailure
});

const getReducerState = state => {
  return state.get("users");
}

/**
 * Returns the status of the last operation to fetch users.
 * @param {*} state - The global state
 */
export const getFetchStatus = state => {
  return getReducerState(state).get("fetchStatus");
}

const getUserMap = state => {
  return getReducerState(state).get("users");
}

/**
 * Returns an array of users.
 * @param {object} state - The global state
 */
export const getUsersArray = createSelector(
  [getUserMap],
  users => {
    return users.toList().toJS();
  }
);

/**
 * Returns the users as a javascript object.
 * @param {object} state - The global state
 */
export const getUsersObject = createSelector(
  [getUserMap],
  users => {
    return users.toJS();
  }
);

/**
 * Returns an array of all users that are active and have the "salesman" access right.
 * @paramm {object} state - The global state.
 */
export const getSalesmen = createSelector(
  [getUserMap],
  users => {
    return users.toList().filter(user => {
      return user.active && user.accessRights.indexOf('salesman') >= 0;
    }).toJS();
  }
)

/**
 * Returns an array of all users that are active and have the manager access right.
 * @param {object} stata - The global state.
 */
export const getManagers = createSelector(
  [getUserMap],
  users => {
    return users.toList().filter(user => {
      return user.active && user.accessRights.indexOf("manager") >= 0;
    }).toJS();
  }
)

/**
 * Returns the status of the last setPassword operation.
 * @param {object} state - The global state.
 */
export const getSetPasswordStatus = state => {
  return getReducerState(state).get('setPasswordStatus');
}

export default reducer;
