import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import AccountingMenu, { Items } from './AccountingMenu';
import MainSection from './MainSection';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        width: '100%',
        height: '100%'
    },
    menu: {
        width: '20%',
        maxWidth: '300px',
        margin: theme.spacing(1)
    },
    mainSection: {
        width: '100%',
        //height: '100%',
        margin: theme.spacing(1)
    }
}));

const AccountingMainPage = () => {
    const classes = useStyles();
    const [currentSelection, setCurrentSelection] = useState(Items.ManageStocks);

    return (
        <div className={classes.main}>
            <Paper className={classes.menu}>
                <AccountingMenu
                    onSelect={s => { setCurrentSelection(s); }}
                    selection={currentSelection}
                />
            </Paper>
            <Paper className={classes.mainSection}>
                <MainSection

                    selection={currentSelection}
                />
            </Paper>
        </div>
    )
}

export default AccountingMainPage;
