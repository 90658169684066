import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import AddModel from "./AddModel";
import DragAndDropList from "./DragAndDropList";
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  card: {
    padding: '16px'
  }
}));

const ModelList = ({ onChange, name, value, ...others }) => {
  const intl = useIntl();
  const classes = useStyles();

  const handleAddModel = (newModel) => {
    const newList = value.slice();
    newList.push(newModel);
    onChange(name, newList);
  }

  return (
    <Card className={classes.card} {...others}>
      <Typography>
        {intl.formatMessage({
          id: "Models",
          defaultMessage: "Models"
        })}
      </Typography>
      <Grid container direction='row' spacing={2}>
        <Grid item>
          <DragAndDropList
            onChange={(value) => { onChange(name, value); }}
            value={value}
          />
        </Grid>
        <Grid item>
          <AddModel
            onAdd={handleAddModel}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

ModelList.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ModelList;
