import React from 'react';
import PropTypes from 'prop-types';

import { useIntl, defineMessages } from 'react-intl';

import Typography from '@material-ui/core/Typography';

import { wheelTypeToString } from './helper';

const messages = defineMessages({
  workOrder: {
    id: 'Work Order: {workOrder}',
    defaultMessage: 'Work Order: {workOrder}'
  },
  location: {
    id: 'Location: {location}',
    defaultMessage: 'Location: {location}'
  },
  name: {
    id: 'Name: {lastName}, {firstName}',
    defaultMessage: 'Name: {lastName}, {firstName}'
  },
  tires: {
    id: 'Tires: {count} {brand} {model} {size}',
    defaultMessage: 'Tires: {count} {brand} {model} {size}'
  },
  wheels: {
    id: 'Wheels: {wheelType}',
    defaultMessage: 'Wheels: {wheelType}'
  },
  dateStored: {
    id: 'Storage date: {storageDate, date, medium}',
    defaultMessage: 'Storage date: {storageDate, date, medium}'
  },
  dateRetrieved: {
    id: 'Date Retrieved: {dateRetrieved, date, medium}',
    defaultMessage: 'Date Retrieved: {dateRetrieved, date, medium}'
  },
  comments: {
    id: 'Comments:',
    defaultMessage: 'Comments:'
  },
  threadDepth: {
    id: 'Thread Depth: {threadDepth} /32th inch',
    defaultMessage: "Thread Depth: {threadDepth} /32th inch"
  }
})

const BottomPrintSection = ({ storage, ...others }) => {
  const intl = useIntl();

  return (
    <div {...others}>
      <div>
        <div>
          <Typography variant="h6">
            {intl.formatMessage(messages.name, { lastName: storage.lastName, firstName: storage.firstName })} {intl.formatMessage(messages.workOrder, { workOrder: storage.workOrder })}
          </Typography>
          <Typography>
            {intl.formatMessage(messages.location, { location: storage.location })}
          </Typography>
          <Typography>
            {intl.formatMessage(messages.tires, { count: storage.count, brand: storage.brand, model: storage.model, size: storage.size })} {intl.formatMessage(messages.threadDepth, { threadDepth: storage.threadDepth })}
          </Typography>
          <Typography>
            {intl.formatMessage(messages.wheels, { wheelType: wheelTypeToString(intl, storage.wheelType) })}
          </Typography>
          <Typography>
            {intl.formatMessage(messages.dateStored, { storageDate: new Date(storage.dateStored * 1000) })} {storage.dateRetrieved > 0 && intl.formatMessage(messages.dateRetrieved, { dateRetrieved: new Date(storage.dateRetrieved * 1000) })}
          </Typography>
          {storage.comments.length > 0 && (
            <React.Fragment>
              <Typography variant="subtitle2">
                {intl.formatMessage(messages.comments)}
              </Typography>
              <Typography>
                {storage.comments}
              </Typography>
            </React.Fragment>)}
        </div>
      </div>
    </div>
  );
}

BottomPrintSection.propTypes = {
  storage: PropTypes.object.isRequired
}

export default BottomPrintSection;
