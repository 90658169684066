import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as Actions from '../DataStore/CustomerManager/CustomerList/Actions';
import { useEventProvider } from './EventProvider';
import { Events } from '../backend';
import { wait } from '../helpers/time';

const CustomerProvider = ({ children }) => {
  const dispatch = useDispatch();
  const eventProvider = useEventProvider();

  useEffect(() => {
    var stopped = false;

    const loadCustomers = async () => {
      try {
        await dispatch(Actions.fetchCustomers());
      }
      catch (error) {
        await wait(10000);
        if (!stopped) {
          loadCustomers();
        }
      }
    }

    loadCustomers();

    const addCustomer = (event) => {
      const customer = JSON.parse(event.data);
      dispatch(Actions.addCustomer(customer));
    }

    const updateCustomer = (event) => {
      const customer = JSON.parse(event.data);
      dispatch(Actions.modifyCustomer(customer));
    }

    const deleteCustomer = (event) => {
      const customer = JSON.parse(event.data);
      dispatch(Actions.deleteCustomer(customer.id));
    }

    eventProvider.addEventListener(Events.addCustomer, addCustomer);
    eventProvider.addEventListener(Events.updateCustomer, updateCustomer);
    eventProvider.addEventListener(Events.deleteCustomer, deleteCustomer);

    return () => {
      stopped = true;
      eventProvider.removeEventListener(Events.addCustomer, addCustomer);
      eventProvider.removeEventListener(Events.updateCustomer, updateCustomer);
      eventProvider.removeEventListener(Events.deleteCustomer, deleteCustomer);
    }
  }, [dispatch, eventProvider]);

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

CustomerProvider.propTypes = {
  children: PropTypes.any
}

export default CustomerProvider;
