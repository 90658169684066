export const FETCH_CUSTOMERS_REQUEST = '@@CUSTOMERS.FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMERS_SUCCESS = '@@CUSTOMERS.FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = '@@CUSTOMERS.FETCH_CUSTOMERS_FAILURE';

export const REFRESH_CUSTOMERS_REQUEST = "@@CUSTOEMRS.REFRESH_CUSTOMERS_REQUEST";
export const REFRESH_CUSTOMERS_SUCCESS = "@@CUSTOMERS.REFRESH_CUSTOMERS_SUCCESS";
export const REFRESH_CUSTOMERS_FAILURE = "@@CUSTOMERS.REFRESH_CUSTOMERS_FAILURE";

export const ADD_CUSTOMER = '@@CUSTOMERS.ADD_CUSTOMER';
export const DELETE_CUSTOMER = '@@CUSTOMERS.DELETE_CUSTOMER';
export const MODIFY_CUSTOMER = '@@CUSTOMERS.MODIFY_CUSTOMER';

export const FETCH_SINGLE_CUSTOMER_REQUEST = '@@CUSTOMER_MANAGER.CUSTOMER_LIST.FETCH_SINGLE_CUSTOMER_REQUEST';
export const FETCH_SINGLE_CUSTOMER_SUCCESS = '@@CUSTOMER_MANAGER.CUSTOMER_LIST.FETCH_SINGLE_CUSTOMER_SUCCESS';
export const FETCH_SINGLE_CUSTOMER_FAILURE = '@@CUSTOMER_MANAGER.CUSTOMER_LIST.FETCH_SINGLE_CUSTOMER_FAILURE';

export const NEW_CUSTOMER_REQUEST = '@@CUSTOMER_MANAGER.CUSTOMER_LIST.NEW_CUSTOMER_REQUEST';
export const NEW_CUSTOMER_SUCCESS = '@@CUSTOMER_MANAGER.CUSTOMER_LIST.NEW_CUSTOMER_SUCCESS';
export const NEW_CUSTOMER_FAILURE = '@@CUSTOMER_MANAGER.CUSTOMER_LIST.NEW_CUSTOMER_FAILURE';

