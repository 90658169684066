import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

let handlers = Map();

const addHandler = (id, handler) => {
  handlers = handlers.set(id, handler);
}

const removeHandler = (id) => {
  handlers = handlers.delete(id);
}

export const dragAndDropEndHandler = (event) => {
  var handlerId = '';

  if (event.source && event.destination) {
    handlerId = event.source.droppableId + "/" + event.destination.droppableId;
    const handler = handlers.get(handlerId);
    if (!handler) {
      handlerId = event.source.droppableId;
    }
  } else if (event.source) {
    handlerId = event.source.droppableId;
  }

  const handler = handlers.get(handlerId);

  if (handler) {
    handler(event);
  }
};

const DragAndDropHandler = ({ sourceDroppableId, destinationDroppableId, handler, children }) => {
  useEffect(() => {
    const handlerId = () => {
      if (destinationDroppableId) {
        return sourceDroppableId + "/" + destinationDroppableId;
      }

      return sourceDroppableId;
    }

    addHandler(handlerId(), handler);

    return () => {
      removeHandler(handlerId())
    }
  }, [sourceDroppableId, destinationDroppableId, handler]);

  return children;
}

DragAndDropHandler.propTypes = {
  handler: PropTypes.func.isRequired,
  sourceDroppableId: PropTypes.string.isRequired,
  destinationDroppableId: PropTypes.string,
  children: PropTypes.any
};

export default DragAndDropHandler;
