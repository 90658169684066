import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { percent, elapsedTime } from '../Helper';

const FollowUpPerformanceRow = ({ salesman, data, noResponseTime, onCellClick, isInternetSource, users, ...others }) => {
  const intl = useIntl();

  const handleCellClick = (event, customerIDs) => {
    onCellClick(event.currentTarget, customerIDs);
  }

  const dataCell = (text, data) => {
    return (
      <TableCell
        onClick={(event) => { handleCellClick(event, data) }}
      >
        {text}
      </TableCell>
    )
  }

  const salesmanName = (id) => {
    if (isInternetSource) {
      return id;
    } else {
      return id === "total" ? intl.formatMessage({
        id: "Total",
        defaultMessage: "Total"
      }) : users[id].firstName + " " + users[id].lastName;
    }
  }

  return (
    <TableRow key={salesman} {...others} >
      <TableCell>{salesmanName(salesman)} </TableCell>
      {dataCell(data.internet.length, data.internet)}
      {dataCell(data.internetToWalkin.length, data.internetToWalkin)}
      {dataCell(data.internetToSale.length, data.internetToSale)}
      {dataCell(percent(data.internetToWalkin.length / data.internet.length), data.internetToWalkin)}
      {dataCell(percent(data.internetToSale.length / data.internet.length), data.internetToSale)}
      {noResponseTime !== true && dataCell(elapsedTime(data.internetResponseTime / data.internetRequests, intl))}
    </TableRow>
  );
}

FollowUpPerformanceRow.propTypes = {
  salesman: PropTypes.string,
  noResponseTime: PropTypes.bool,
  data: PropTypes.object,
  isInternetSource: PropTypes.bool,
  users: PropTypes.object,
  onCellClick: PropTypes.func
};

export default FollowUpPerformanceRow;
