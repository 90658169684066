import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles(theme => ({
  progress: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(4),
  },
}));

const WaitSpinner = ({ show, ...others }) => {
  const classes = useStyles();

  return (
    <Modal open={show} disableAutoFocus={true} {...others}>
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    </Modal>
  );
}


WaitSpinner.propTypes = {
  show: PropTypes.bool
};

export default WaitSpinner;
