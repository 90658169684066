import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ColorList from './ColorList';
import SearchBox from '../../Components/SearchBox';

const FilteredColorList = ({ selection, onSelect, colors, ...others }) => {
    const [filter, setFilter] = useState('');

    const filteredColors = filter === '' ? colors : colors.filter(c => {
        let result = true;
        const filters = filter.split(' ');

        filters.forEach(f => {
            const e = new RegExp('.*' + f + '.*', 'i');

            if (f != "") {
                result &= c.description.search(e) >= 0 || c.code.search(e) >= 0;
            }
        });

        return result;
    });

    return (
        <div {...others}>
            <SearchBox value={filter} onChange={v => setFilter(v)} />
            <ColorList
                colors={filteredColors}
                selectedItem={selection}
                onSelect={onSelect}
            />
        </div>
    )
}

FilteredColorList.propTypes = {
    selection: PropTypes.string,
    onSelect: PropTypes.func,
    colors: PropTypes.array.isRequired
}

export default FilteredColorList;