import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import FollowUpPerformanceRow from './FollowUpPerformanceRow';

const FollowUpPerformanceTable = ({ data, isInternetSource, users, onCellClick, noResponseTime, typeOfCustomer, ...others }) => {
  const intl = useIntl();

  const rows = data.map(entry => {
    const salesman = entry.salesmanID;
    const data = entry;
    return (
      <FollowUpPerformanceRow
        key={salesman}
        salesman={salesman}
        users={users}
        data={data}
        isInternetSource={isInternetSource}
        onCellClick={onCellClick}
        noResponseTime={noResponseTime}
      />
    );
  });

  return (
    <Table {...others}>
      <TableHead>
        <TableRow>
          <TableCell>
            {intl.formatMessage({
              id: "Salesman",
              defaultMessage: "Salesman"
            })}
          </TableCell>
          <TableCell>
            {typeOfCustomer || intl.formatMessage({
              id: "Internet",
              defaultMessage: "Internet"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "Converted To Walkins",
              defaultMessage: "Converted To Walkins"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "Converted To Sales",
              defaultMessage: "Converted To Sales"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "Converted To Walkins Ratio",
              defaultMessage: "Converted To Walkins Ratio"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage(
              {
                id: "Converted To Sales Ratio",
                defaultMessage: " Converted To Sales Ratio"
              }
            )}
          </TableCell>
          {noResponseTime !== true && <TableCell>
            {intl.formatMessage({
              id: "Response Time",
              defaultMessage: "Response Time"
            })}
          </TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  )
}

FollowUpPerformanceTable.propTypes = {
  users: PropTypes.object,
  noResponseTime: PropTypes.bool,
  typeOfCustomer: PropTypes.string,
  data: PropTypes.array,
  onCellClick: PropTypes.func,
  isInternetSource: PropTypes.bool
};

export default FollowUpPerformanceTable;
