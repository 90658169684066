import * as Types from "./Types";
import axios from "axios";
import { fromJS } from "immutable";
import { List, Map } from "immutable";

import { indexOfUser } from "./Reducer";
import { showMessageByID } from "../../Message/Actions";

import { User } from "../../../Models/User";

export function fetchUsersRequest() {
  return {
    type: Types.FETCH_USERS_REQUEST
  };
}

export function fetchUsersSuccess(users) {
  return {
    type: Types.FETCH_USERS_SUCCESS,
    users
  };
}

export function fetchUsersFailure() {
  return {
    type: Types.FETCH_USERS_FAILURE
  };
}

export function fetchUsers() {
  return async function (dispatch) {
    dispatch(fetchUsersRequest());

    try {
      const response = await axios.get("/user");
      const users = fromJS(response.data.result);
      dispatch(fetchUsersSuccess(users));
      return users;
    } catch (error) {
      dispatch(fetchUsersFailure());
      throw error;
    }
  };
}

export function updateUser(index, user) {
  return {
    type: Types.UPDATE_USER,
    index,
    user
  };
}

export function editUser(user) {
  let u = user;

  if (u instanceof Map) {
    u = new User(u);
  }

  if (u) {
    u = u.set("password", "");
  }

  return {
    type: Types.SET_EDITED_USER,
    user: u
  };
}

export function changeEditedUser(name, value) {
  if (name === "accessRights" && !(value instanceof List)) {
    value = List(value);
  }

  return {
    type: Types.CHANGE_EDITED_USER,
    name,
    value
  };
}

export function saveUserRequest() {
  return {
    type: Types.SAVE_USER_REQUEST
  };
}

export function saveUserSuccess(user) {
  return {
    type: Types.SAVE_USER_SUCCESS,
    user
  };
}

export function saveUserFailure() {
  return {
    type: Types.SAVE_USER_FAILURE
  };
}

export function saveUser(token, user) {
  return async function (dispatch, getState) {
    dispatch(saveUserRequest());

    try {
      if (user.get("id")) {
        const response = await axios.put("/user/" + user.get("id"), user);
        const updatedUser = fromJS(response.data.result);
        dispatch(saveUserSuccess(updatedUser));
        dispatch(updateUser(indexOfUser(getState(), updatedUser), updatedUser));
        return updatedUser;
      } else {
        const response = await axios.post("/user", user, {
          headers: { Authorization: token }
        });
        const newUser = fromJS(response.data.result);
        dispatch(saveUserSuccess(newUser));
        dispatch(updateUser(undefined, newUser));
        return newUser;
      }
    } catch (error) {
      dispatch(saveUserFailure());
      dispatch(showMessageByID("settings.users.usereditor.saveerror"));
      throw error;
    }
  };
}
