import axios from 'axios';
import { VisitType } from '../Models/Visit';

/**
 * deleteCustomer sends a request to the backend to delete the specified customer.
 * On success, it returns the deletes customer.
 * On failure, it throws an error.
 * @param {string} id - The customer id
 */
export const deleteCustomer = async (id) => {
  const result = await axios.delete("/customer/" + id);
  return result.data.result;
};

/**
 * advancedSearch sends a request to the backend for a search
 * matching the specified criterias.
 * The criteria is an object of the following form where all fields are optional:
 * {
 *  lastName: string,
 *  firstName: string,
 *  email: string,
 *  phone: string,
 *  salesman: string,
 *  takeOver: string
 *  visitType: number,
 *  visitDateStart: number    (unix timestamp in second)
 *  visitDateEnd: number      (unix timestamp in second)
 *  followUp: bool
 * }
 * @param {object} criteria - The criterias to use to make the search.
 */
export const advancedSearch = async (criteria) => {
  const response = await axios.put("/customer/search", criteria);
  return response.data.result;
}

/**
 * saveCustomer posts a requests to the backend to save the customer
 * passed. If successful, it returns the saved customer. If there
 * is an error, it throws.
 * @param {object} customer - The customer to save.
 */
export const saveCustomer = async customer => {
  let response = null;

  if (customer.id) {
    response = await axios.put("/customer/" + customer.id, customer);
  }
  else {
    response = await axios.post("/customer", customer);
  }

  return response.data.result;
}

/**
 * loadCustomer loads the customer specified from the server.
 * If successful, it returns the customer, otherwise it throws
 * an error.
 * @param {string} customerID - The id of the customer to load.
 */
export const loadCustomer = async (customerID) => {
  const response = await axios.get("/customer/" + customerID);
  return response.data.result;
};

/**
 * newCustomer sends a request to the server to create
 * a new customer. On success, a new customer is returned.
 * On error, it throws.
 */
export const newCustomer = async () => {
  const response = await axios.get("/customer/newcustomer");
  return response.data.result;
};

/**
 * loadCustomers loads the currently active customers from the server.
 * If successful, it returns an object { customers, timestamp } where
 * customers is an array of customer objects and timestamp represents
 * the time of the request to make refresh requests afterward.
 */
export const loadCustomers = async () => {
  const response = await axios.get("/customer");
  return response.data.result;
};

export const refreshCustomers = async (timestamp) => {
  const response = await axios.get("/customer/refresh?timestamp=" + timestamp);
  return response.data.result;
};

/**
 * newVisit gets a new visit for the specified customer. On success,
 * a new visit is returned. On error, it throws.
 * @param {string} customerID - The ID of the customer to which we want to add a visit.
 */
export const newVisit = async customerID => {
  const response = await axios.get("/customer/" + customerID + "/newvisit");
  return response.data.result;
}

const saveInternetResponse = async (customerID, visit, email) => {
  const formData = new FormData();
  formData.append('email', JSON.stringify(email));
  formData.append('visit', JSON.stringify(visit));

  return axios.post("/customer/" + customerID + "/addinternetresponse", formData);
}

/**
 * saveVisit saves the visit for the specified customer. On success,
 * the modified customer is returned. On error, it throws.
 * @param {string} customerID - The ID of the customer where the visit will be saved.
 * @param {number} index - The index of the visit to save. If index is less than 0, a new 
 * visit is added. Otherwise the visit is updated.
 * @param {object} visit - The visit object to save.
 * @param {object} email - If the visit has a type of internet response, the email to send.
 */
export const saveVisit = async (customerID, index, visit, email, useEditedDate) => {
  if (index < 0) {
    if (visit.type === VisitType.InternetResponse) {
      const response = await saveInternetResponse(customerID, visit, email);
      return response.data.result;
    }
    else {
      const path = "/customer/" + customerID + "/addvisit" + (useEditedDate === true ? "?useVisitTime=true" : "");
      const response = await axios.post(path, visit);
      return response.data.result;
    }
  }
  else {
    const response = await axios.put("/customer/" + customerID + "/updatevisit/" + index, visit);
    return response.data.result;
  }
}

/**
 * scanDriverLicense scans the passed driver's license image to get informations
 * such as name and address of the person.
 * @param {string} image - The driver's license image. Base64 encoded.
 */
export const scanDriverLicense = async (image) => {
  const data = {
    imageData: image
  };

  const response = await axios.put("/customer/scanlicense", data);
  return response.data.result;
}

/** attachDriverLicense sends the image to the backend to attach it to the specified
 * customer.
 */
export const attachDriverLicense = async (customerID, image) => {
  const data = {
    customerID,
    imageData: image
  };

  const response = await axios.put("/customer/attachlicense", data);
  return response.data.result;
}

/**
 * getDriverLicense gets the driver's license from the backend.
 * On success, it returns a URL encoded base64 string.
 * @param {string} customerID - The customer ID for which we want to get the driver's license.
 */
export const getDriverLicense = async (customerID) => {
  const response = await axios.get("/customer/" + customerID + "/driverlicense");
  return "data:" + response.data.result.mimeType + ";base64," + response.data.result.imageData;
}
