import * as Types from './Types';
import { hasAccessRight } from '../Login/Reducer';
import { AccessRight, Departments } from '../../Models/User';

export function setMainPage(pageId) {
    return {
        type: Types.SET_MAIN_PAGE,
        pageId
    }
}

const _setDepartment = department => {
    return {
        type: Types.SET_DEPARTMENT,
        department
    }
}

export const setDepartment = department => {
    return async (dispatch, getState) => {
        const state = getState();
        const isManager = hasAccessRight(state, AccessRight.Manager);

        dispatch(_setDepartment(department));

        switch (department) {
            case Departments.Service:
                dispatch(setMainPage(Types.PageId.Storage));
                break;
            case Departments.Accounting:
                dispatch(setMainPage(Types.PageId.Accounting));
                break;
            default:
                if (isManager) {
                    dispatch(setMainPage(Types.PageId.Dashboard));
                } else {
                    dispatch(setMainPage(Types.PageId.Customers));
                }
                break;
        }
    }
}