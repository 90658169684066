import axios from 'axios';

/**
 * allReservations loads all reservations from the server and returns an array
 * of reservations.
 * @param {object} cancelSource - The object used to cancel the request.
 */
export const allReservations = async (cancelSource) => {
  const response = await axios.get("/reservation", { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}

/**
 * activeReservations loads all active reservations from the server and returns an
 * array of the reservations.
 * @param {object} cancelSource - The object used to cancel the request.
 */
export const activeReservations = async (cancelSource) => {
  const response = await axios.get("/reservation/active", { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}

/**
 * saveReservation will save the reservation to the server. If the reservatiop id is nil
 * a new reservation is created, otherwise the reservation is updated. On success
 * the new/updated reservation object is returned.
 * @param {object} reservation - The reservation to save.
 * @param {object} cancelSource - The object used to cancel the request.
 */
export const saveReservation = async (reservation, cancelSource) => {
  const response = await axios.post("/reservation", reservation, { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}

/**
 * getReservation loads the specified reservation from the server and returns it.
 * @param {string} id - The id of the reservation we want.
 * @param {object} cancelSource - The object used to cancel the request.
 */
export const getReservation = async (id, cancelSource) => {
  const response = await axios.get("/reservation/" + id, { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}

/**
 * deleteReservation deletes the specified reservation from the server. It returns
 * the deleted reservation.
 * @param {string} id - The id of the reservation to delete.
 * @param {object} cancelSource - The object used to cancel the request.
 */
export const deleteReservation = async (id, cancelSource) => {
  const response = await axios.delete("/reservation/" + id, { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}

/**
 * availableVehicles returns the vehicles that are available for the specified
 * duration.
 * @param {number} startDate - The date that starts our interval
 * @param {number} endDate - The date ending the interval
 * @param {object} cancelSource - The object to cancel the request.
 * @param {string} currentReservationID - The ID of the current reservation, to make the currently selected vehicle available.
 */
export const availableVehicles = async (startDate, endDate, currentReservationID, cancelSource) => {
  const response = await axios.get(
    "/reservation/availablevehicles",
    {
      params: { startDate, endDate, currentReservationID },
      cancelToken: cancelSource ? cancelSource.token : undefined
    }
  );
  return response.data.result;
}

/**
 * getReservations returns the reservations for the specified time interval.
 * @param {number} startDate - The date starting the interval
 * @param {number} endDate - The date ending the interval
 * @param {object} cancelSource - The object used to cancel the request.
 */
export const getReservations = async (startDate, endDate, cancelSource) => {
  const response = await axios.get("/reservation", {
    params: {
      startDate,
      endDate
    },
    cancelToken: cancelSource ? cancelSource.token : undefined
  });
  return response.data.result;
}
