import React from 'react';

const AccountingButtons = () => {
    return (
        <React.Fragment>
        </React.Fragment>
    )
}

export default AccountingButtons;
