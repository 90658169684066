import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        width: '20em'
    },
    line: {
        marginTop: theme.spacing(1)
    }
}));

const ColorViewer = ({ color, ...others }) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Paper className={classes.paper} {...others}>
            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {intl.formatMessage({
                        id: "Color Code",
                        defaultMessage: "Color Code"
                    })}
                </Typography>
                <Typography>
                    {color.code}
                </Typography>
            </div>
            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {intl.formatMessage({
                        id: "Description",
                        defaultMessage: "Description"
                    })}
                </Typography>
                <Typography>
                    {color.description}
                </Typography>
            </div>
        </Paper>
    )
}

ColorViewer.propTypes = {
    color: PropTypes.object
}

export default ColorViewer;
