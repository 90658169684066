import * as Types from "./Types";
import axios from "axios";
import { fromJS, List } from "immutable";

import { token } from "../../Login/Reducer";

export function fetchUsersRequest() {
  return {
    type: Types.FETCH_USERS_REQUEST
  };
}

export function fetchUsersFailure() {
  return {
    type: Types.FETCH_USERS_FAILURE
  };
}

export function fetchUsersSuccess(users) {
  return {
    type: Types.FETCH_USERS_SUCCESS,
    users
  };
}

export function fetchUsers() {
  return async function (dispatch) {
    dispatch(fetchUsersRequest());

    try {
      const response = await axios.get("/user");
      const users = fromJS(response.data.result);
      dispatch(fetchUsersSuccess(users));
      return users;
    } catch (error) {
      dispatch(fetchUsersFailure());
      throw error;
    }
  };
}

export function fetchSettingsRequest() {
  return {
    type: Types.FETCH_SETTINGS_REQUEST
  };
}

export function fetchSettingsFailure() {
  return {
    type: Types.FETCH_SETTINGS_FAILURE
  };
}

export function fetchSettingsSuccess(settings) {
  return {
    type: Types.FETCH_SETTINGS_SUCCESS,
    settings
  };
}

export function fetchSettings() {
  return async function (dispatch, getState) {
    dispatch(fetchSettingsRequest());

    try {
      const tok = token(getState());
      const response = await axios.get("/settings", {
        headers: { Authorization: tok }
      });
      const settings = fromJS(response.data.result);
      dispatch(fetchSettingsSuccess(settings));
      return settings;
    } catch (error) {
      dispatch(fetchSettingsFailure());
    }
  };
}

export function saveSettingsRequest() {
  return {
    type: Types.SAVE_SETTINGS_REQUEST
  };
}

export function saveSettingsFailure() {
  return {
    type: Types.SAVE_SETTINGS_FAILURE
  };
}

export function saveSettingsSuccess(settings) {
  return {
    type: Types.SAVE_SETTINGS_SUCCESS,
    settings
  };
}

export function saveSettings(settings) {
  return async function (dispatch) {
    dispatch(saveSettingsRequest());

    try {
      const response = await axios.put("/settings", settings);
      const savedSettings = fromJS(response.data.result);
      dispatch(saveSettingsSuccess(savedSettings));
      return savedSettings;
    } catch (error) {
      dispatch(saveSettingsFailure());
    }
  };
}

export function setOpenedSection(section) {
  return {
    type: Types.SET_OPENED_SECTION,
    section
  };
}

export function changeSettings(section, name, value) {
  if (value instanceof Array) {
    value = List(value);
  }

  return {
    type: Types.CHANGE_SETTINGS,
    section,
    name,
    value
  };
}
