import axios from 'axios';

/**
 * allVehicles loads all vehicles from the server and returns an array
 * of vehicles.
 * @param {object} cancelSource - The object used to cancel the request.
 */
export const allVehicles = async (cancelSource) => {
  const response = await axios.get("/vehicle", { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}

/**
 * saveVehicle will save the vehicle to the server. If the vehicle id is nil
 * a new vehicle is created, otherwise the vehicle is updated. On success
 * the new/updated vehicle object is returned.
 * @param {object} vehicle - The vehicle to save.
 * @param {object} cancelSource - The object used to cancel the request.
 */
export const saveVehicle = async (vehicle, cancelSource) => {
  const response = await axios.post("/vehicle", vehicle, { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}

/**
 * getVehicle loads the specified vehicle from the server and returns it.
 * @param {string} id - The id of the vehicle we want.
 * @param {object} cancelSource - The object used to cancel the request.
 */
export const getVehicle = async (id, cancelSource) => {
  const response = await axios.get("/vehicle/" + id, { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}

/**
 * deleteVehicle deletes the specified vehicle from the server. It returns
 * the deleted vehicle.
 * @param {string} id - The id of the vehicle to delete.
 * @param {bool} noReservation - If true, delete only if there are no active reservations.
 * @param {object} cancelSource - The object used to cancel the request.
 */
export const deleteVehicle = async (id, noReservation, cancelSource) => {
  const response = await axios.delete(
    "/vehicle/" + id,
    {
      cancelToken: cancelSource ? cancelSource.token : undefined,
      params: {
        noReservation: Boolean(noReservation)
      }
    }
  );
  return response.data.result;
}
