import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { getUsersObject } from '../DataStore/Users/Reducer';
import SalesmanAvailability from './SalesmanAvailability';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { setSettingsDialogVisibility } from '../DataStore/SalesmanAvailability/Actions';
import { getSettingsDialogOpened } from '../DataStore/SalesmanAvailability/Reducer';
import { useEventProvider } from '../Providers';
import { Events } from '../backend';

const useStyles = makeStyles(() => ({
  salesman: {
    width: '256px'
  },
  firstSalesman: {
    backgroundColor: 'lightGray'
  },
  otherSalesman: {

  }
}));

const SalesmanTurn = ({ showDialog, dialogVisible, users, ...other }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [order, setOrder] = useState([]);
  const eventProvider = useEventProvider();

  useEffect(() => {
    const updateOrder = (event) => {
      const data = JSON.parse(event.data);
      setOrder(data);
    }

    eventProvider.addEventListener(Events.salesmenTurn, updateOrder);

    return () => {
      eventProvider.removeEventListener(Events.salesmenTurn, updateOrder);
    }
  }, [setOrder, eventProvider]);

  const idToUser = (id) => {
    const user = users[id];

    if (user) {
      return user.firstName + " " + user.lastName;
    }

    return "";
  }

  const salesmen = (order, classes) => {
    return order.map(salesman => {
      return (
        <tr key={salesman.salesmanID}>
          <td className={salesman.first ? classes.firstSalesman : classes.otherSalesman}>
            {idToUser(salesman.salesmanID)}
          </td>
          <td>
            {salesman.sales}
          </td>
          <td>
            {salesman.walkins}
          </td>
          <td>
            {salesman.phones}
          </td>
          <td>
            {salesman.internetRequests}
          </td>
          <td padding='dense'>
            {salesman.bebacks}
          </td>
        </tr>
      )
    })
  }

  return (
    <div {...other}>
      <div>
        <IconButton onClick={showDialog}>
          <SettingsIcon />
        </IconButton>
        <SalesmanAvailability open={dialogVisible} />
      </div>
      <table>
        <thead>
          <tr>
            <th className={classes.salesman}>
              {intl.formatMessage({
                id: "Salesman",
                defaultMessage: "Salesman"
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "S",
                defaultMessage: "S"
              })}
            </th>
            <th padding='dense'>
              {intl.formatMessage({
                id: "W",
                defaultMessage: "W"
              })}
            </th>
            <th padding='dense'>
              {intl.formatMessage({
                id: "P",
                defaultMessage: "P"
              })}
            </th>
            <th padding='dense'>
              {intl.formatMessage({
                id: "I",
                defaultMessage: "I"
              })}
            </th>
            <th padding='dense'>
              {intl.formatMessage({
                id: "B",
                defaultMessage: "B"
              })}
            </th>
          </tr>
        </thead>
        <tbody>
          {salesmen(order, classes)}
        </tbody>
      </table>
    </div>
  )
}

SalesmanTurn.propTypes = {
  //fetchSalesmanTurn: PropTypes.func,
  //order: PropTypes.array,
  users: PropTypes.object,
  showDialog: PropTypes.func,
  dialogVisible: PropTypes.bool
};

const mapStateToProps = state => ({
  users: getUsersObject(state),
  //order: getOrder(state),
  dialogVisible: getSettingsDialogOpened(state)
});

const mapDispatchToProps = dispatch => ({
  //fetchSalesmanTurn: () => (dispatch(fetchSalesmanTurn())),
  showDialog: () => (dispatch(setSettingsDialogVisibility(true)))
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesmanTurn);
