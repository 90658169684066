import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import * as API from '../../backend';
import { useEventProvider } from '../../Providers/EventProvider';

export const useNewStocks = (year) => {
    const [stocks, setStocks] = useState([]);
    const [status, setStatus] = useState("loading");
    const { enqueueSnackbar } = useSnackbar();
    const eventProvider = useEventProvider();
    const intl = useIntl();

    useEffect(() => {
        const cancelSource = API.getCancelSource();

        const fetchStocks = async () => {
            try {
                setStatus("loading");
                const stocks = await API.getAggregatedNewStocks(year, cancelSource);
                setStocks(stocks.sort((s1, s2) => {
                    if (s1.stockNumber < s2.stockNumber) {
                        return -1;
                    } else if (s1.stockNumber === s2.stockNumber) {
                        return 0;
                    } else {
                        return 1;
                    }
                }));
                setStatus("success");
            }
            catch (error) {
                if (API.isCancel(error)) {
                    setStatus("cancelled");
                } else {
                    setStatus("failure");
                    enqueueSnackbar(
                        intl.formatMessage({
                            id: "Error while loading new vehicle stocks.",
                            defaultMessage: "Error while loading new vehicle stocks."
                        }),
                        {
                            variant: 'error'
                        }
                    );
                }
            }
        }

        fetchStocks();

        eventProvider.addEventListener(API.Events.addNewStock, fetchStocks);
        eventProvider.addEventListener(API.Events.updateNewStock, fetchStocks);
        eventProvider.addEventListener(API.Events.deleteNewStock, fetchStocks);

        return () => {
            cancelSource.cancel();
            eventProvider.removeEventListener(API.Events.addNewStock, fetchStocks);
            eventProvider.removeEventListener(API.Events.updateNewStock, fetchStocks);
            eventProvider.removeEventListener(API.Events.deleteNewStock, fetchStocks);
        }
    }, [year, enqueueSnackbar, intl, eventProvider]);

    return [stocks, status];
}
