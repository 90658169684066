import React, { useState } from 'react';

import StorageMenu from './StorageMenu';
import StorageFinder from './StorageFinder';
import StorageEditor from './StorageEditor';
import { MenuSelection } from './Types';

import Fade from '@material-ui/core/Fade';
import { makeStyles, useTheme } from '@material-ui/styles';
import PrintDialog from './PrintDialog';


const useStyles = makeStyles(() => ({
  rootDiv: {
    width: '100%',
    height: '100%',
    overflow: 'auto'
  }
}));

const StoragePage = ({ ...others }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [printStorage, setPrintStorage] = useState(null);
  const [editorStorage, setEditorStorage] = useState(null);
  const [storageListVisibility, setStorageListVisibility] = useState(false);

  const timeouts = {
    enter: theme.transitions.duration.enteringScreen * 2,
    exit: theme.transitions.duration.leavingScreen
  }

  const handleMenuSelection = selection => {
    switch (selection) {
      case MenuSelection.AddStorage:
        setEditorStorage({
          id: null,
          count: 0,
          dateStored: Math.floor(Date.now() / 1000),
          dateRetrieved: 0,
          lastName: '',
          firstName: '',
          brand: '',
          model: '',
          size: '',
          threadDepth: 0,
          location: '',
          workOrder: '',
          wheelType: 1,
          tireType: 0,
          comments: '',
          history: []
        });
        break;

      case MenuSelection.FindStorage:
        setStorageListVisibility(true);
        break;

      default: break;
    }
  }

  return (
    <div className={classes.rootDiv} {...others}>
      <Fade
        in={editorStorage === null && printStorage === null && !storageListVisibility}
        timeout={timeouts}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <StorageMenu onSelect={handleMenuSelection} />
      </Fade >
      <Fade
        in={storageListVisibility}
        timeout={timeouts}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <StorageFinder onCancel={() => { setStorageListVisibility(false); }} />
      </Fade>
      <Fade
        in={editorStorage !== null}
        timeout={timeouts}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <StorageEditor
          initialStorage={editorStorage}
          onCancel={() => { setEditorStorage(null) }}
          onSave={(/*storage*/) => { setEditorStorage(null); }}
        />
      </Fade>
      {printStorage !== null && (
        <PrintDialog
          open={printStorage !== null}
          storage={printStorage}
          onClose={() => { setPrintStorage(null) }}
        />
      )}
    </div>
  );
}

export default StoragePage;
