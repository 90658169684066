import { padLeft } from './string';

/**
 * textToDate converts a date of the form yyyy-mm-dd or yyyy/mm/dd to 
 * a date object.
 * @param {string} s - The string to convert to date.
 */
export const textToDate = s => {
  if (s === "") {
    return new Date(0);
  }

  const exp = /(\d\d\d\d).(\d\d).(\d\d)/g;
  const matches = exp.exec(s);

  if (matches) {
    const year = parseInt(matches[1], 10);
    const month = parseInt(matches[2], 10) - 1;
    const day = parseInt(matches[3], 10);

    return new Date(year, month, day, 0, 0, 0, 0);
  }

  return new Date();
}

/**
 * dateToText converts a date object to a string of the form yyyy-mm-dd
 * @param {Date} d - The date to convert to text.
 */
export const dateToText = d => {
  if (d.getTime() === 0) {
    return ""
  }

  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();

  return year.toString() + "-" + padLeft((month + 1).toString(), '0', 2) + "-" + padLeft(day.toString(), '0', 2);
}

/**
 * previousMonth will return a Date object set to the 28th of the previous
 * month.
 * @param {Date} d - The date for which we need the previous month
 */
const previousMonth = d => {
  const year = d.getFullYear();
  const month = d.getMonth();

  if (month === 0) {
    return new Date(year - 1, 11, 28, 0, 0, 0, 0);
  }

  return new Date(year, month - 1, 28, 0, 0, 0, 0);
}

/**
 * getBusinessMonth returns a string representation for the given business date.
 * @param {Date} d - The date representing the first business day of the month.
 */
export const getBusinessMonth = (d, intl) => {
  const m = d.getMonth();

  switch (m) {
    case 0:
      return intl.formatMessage({
        id: "January",
        defaultMessage: "January"
      });

    case 1:
      return intl.formatMessage({
        id: "February",
        defaultMessage: "February"
      });

    case 2:
      return intl.formatMessage({
        id: "March",
        defaultMessage: "March"
      });

    case 3:
      return intl.formatMessage({
        id: "April",
        defaultMessage: "April"
      });

    case 4:
      return intl.formatMessage({
        id: "May",
        defaultMessage: "May"
      });

    case 5:
      return intl.formatMessage({
        id: "June",
        defaultMessage: "June"
      });

    case 6:
      return intl.formatMessage({
        id: "July",
        defaultMessage: "July"
      });

    case 7:
      return intl.formatMessage({
        id: "August",
        defaultMessage: "August"
      });

    case 8:
      return intl.formatMessage({
        id: "September",
        defaultMessage: "September"
      });

    case 9:
      return intl.formatMessage({
        id: "October",
        defaultMessage: "October"
      });

    case 10:
      return intl.formatMessage({
        id: "November",
        defaultMessage: "November"
      });

    case 11:
      return intl.formatMessage({
        id: "December",
        defaultMessage: "December"
      });

    default:
      return "";
  }
}

/**
 * firstOpenDayOfMonth returns a Date object set to the first "opened" day of the month.
 * If the 1st of the month is between tuesday and saturday, the returned Date object will be set
 * to the 1st of the month at midnight, local time.
 * If the 1st of the month falls on a sunday or monday, it will return the next tuesday at 
 * midnight, local time.
 * If the passed date object is a saturday or sunday before the "first day of the month",
 * the first day of the previous month is returned.
 * @param {Date} d - The date for which we need the first opened day of the month.
 */
export const firstOpenDayOfMonth = d => {
  const year = d.getFullYear();
  const month = d.getMonth();
  const dayOfMonth = d.getDate();

  const firstOfMonth = new Date(year, month, 1, 0, 0, 0, 0);
  const day = firstOfMonth.getDay();

  let adjustedDate = firstOfMonth;

  if (day === 0) {
    adjustedDate = new Date(year, month, 3, 0, 0, 0, 0);
    if (dayOfMonth < adjustedDate.getDate()) {
      return firstOpenDayOfMonth(previousMonth(adjustedDate));
    }
  } else if (day === 1) {
    adjustedDate = new Date(year, month, 2, 0, 0, 0, 0);
    if (dayOfMonth < adjustedDate.getDate()) {
      return firstOpenDayOfMonth(previousMonth(adjustedDate));
    }
  }

  return adjustedDate;
}

export const firstBusinessDayOfMonth = firstOpenDayOfMonth;

/**
 * lastBusinessDayOfMonth returns a Date object set the the last business day of the month.
 * If the last day of the month is during a weekend, it returns the following monday. Otherwise
 * it returns the last day of the month at 23:59:59, local time. If the passed date is the first or
 * second of the month and happens during a weekend, it returns the following monday.
 * 
 * @param {Date} d - The date for which we need the last busibess day of the month.
 */
export const lastBusinessDayOfMonth = d => {
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  let dayOfWeek = d.getDay();
  const lastDay = daysInMonth(d);

  if (day <= 2) {
    if (dayOfWeek === 0) {
      return new Date(year, month, day + 1, 23, 59, 59, 0);
    }

    if (dayOfWeek === 1) {
      return new Date(year, month, day, 23, 59, 59, 0);
    }
  }

  const lastDayOfMonth = new Date(year, month, lastDay, 23, 59, 59, 0);
  dayOfWeek = lastDayOfMonth.getDay();
  if (dayOfWeek === 0) {
    const result = nextMonth(lastDayOfMonth);
    result.setDate(1);
    result.setHours(23);
    result.setMinutes(59);
    result.setSeconds(59);

    return result;
  }

  if (dayOfWeek === 6) {
    const result = nextMonth(lastDayOfMonth);
    result.setDate(2);
    result.setHours(23);
    result.setMinutes(59);
    result.setSeconds(59);

    return result;
  }

  return lastDayOfMonth;
}

/**
 * nextMonth returns a new Date object set to the 1st of the next month.
 * @param {Date} d - The date for which we want the next month.
 */
export const nextMonth = d => {
  const year = d.getFullYear();
  const month = d.getMonth();

  const nextMonth = month === 11 ? 0 : month + 1;
  const newYear = month === 11 ? year + 1 : year;

  return new Date(newYear, nextMonth, 1, 0, 0, 0, 0);
}

/**
 * isLeapYear returns true is the year passed is a leap year.
 * @param {Numnber} year - The date to check for leap year.
 */
export const isLeapYear = year => {
  return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

/**
 * 
 * @param {Date} d - The date for which we want to know the number of days in the month.
 */
export const daysInMonth = d => {
  const m = d.getMonth();

  switch (m) {
    case 0:
      return 31;
    case 1:
      return isLeapYear(d.getFullYear()) ? 29 : 28;
    case 2:
      return 31;
    case 3:
      return 30;
    case 4:
      return 31;
    case 5:
      return 30;
    case 6:
      return 31;
    case 7:
      return 31;
    case 8:
      return 30;
    case 9:
      return 31;
    case 10:
      return 30;
    case 11:
      return 31
    default:
      return 0;
  }
}

/**
 * startOfDay returns a new Date object at the same date, but with the time set to 0:00:00.0 local time.
 * @param {Date} d - The date for which we want to start of the day.
 */
export const startOfDay = d => {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
}

/**
 * endOfDay returns a new Date object at the same date, but with the time set to 23:59:59.999 local time.
 * @param {Date} d - The date for which we want the end of the day.
 */
export const endOfDay = d => {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59, 999);
}

/**
 * startOfDayUTC returns the same date as the one passed, but in UTC time at
 * midnight. So, if we pass 2019-11-23 at 6:30 with a timezone of -5, the
 * resulting date in local time would be 2019-11-22 at 19:00:00.0 or 2019-11-23
 * 0:00:00.0 UTC time.
 * @param {Date} d - The date to convert.
 */
export const startOfDayUTC = d => {
  const result = new Date();
  result.setUTCFullYear(d.getFullYear());
  result.setUTCMonth(d.getMonth());
  result.setUTCDate(d.getDate());
  result.setUTCHours(0);
  result.setUTCMinutes(0);
  result.setUTCSeconds(0);
  result.setUTCMilliseconds(0);

  return result;
}

/**
 * endOfDayUTC returns the same date as the one passed, but in UTC time at the
 * end of the day, 23:59:59.0. So, if we pass 2019-11-23 at 6:30 with a 
 * timezone of -5, the resultting date in local time would be 2019-11-23 at
 * 18:59:59.0 or 2019-11-23 23:59:59.0 UTC time.
 * @param {Date} d - The date to convert.
 */
export const endOfDayUTC = d => {
  const result = new Date();
  result.setUTCFullYear(d.getFullYear());
  result.setUTCMonth(d.getMonth(), d.getDate());
  result.setUTCDate(d.getDate());
  result.setUTCHours(23);
  result.setUTCMinutes(59);
  result.setUTCSeconds(59);
  result.setUTCMilliseconds(0);

  console.log("d is: " + d.toUTCString() + " / result is: " + result.toUTCString());

  return result;
}

/**
 * utcTimeToLocal returns the same date as the one passed, but in local time.
 * So, if the passed time in local time is 2019-11-22 22:15:00.0 with a timezone
 * of -5, the resulting time will be 2019-11-23 3:15:00.0 local time.
 * @param {*} d 
 */
export const utcTimeToLocal = d => {
  const result = new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds(),
    d.getUTCMilliseconds());

  return result;
}
