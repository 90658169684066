import createReducer from "../../CreateReducer";
import { Map, List, fromJS } from "immutable";
import { RequestStatus } from "../../Types";
import * as Types from "./Types";
import { createSelector } from "reselect";

const initialState = Map({
  requestStatus: RequestStatus.None,
  customers: List()
});

const handleAdvancedSearchRequest = state => {
  return state.set("requestStatus", RequestStatus.Requested);
};

const handleAdvancedSearchSuccess = (state, { customers }) => {
  return state.withMutations(state => {
    state
      .set("requestStatus", RequestStatus.Success)
      .set("customers", fromJS(customers));
  });
};

const handleAdvancedSearchFailure = state => {
  return state.set("requestStatus", RequestStatus.Failure);
}

const reducer = createReducer(initialState, {
  [Types.ADVANCED_SEARCH_REQUEST]: handleAdvancedSearchRequest,
  [Types.ADVANCED_SEARCH_SUCCESS]: handleAdvancedSearchSuccess,
  [Types.ADVANCED_SEARCH_FAILURE]: handleAdvancedSearchFailure
});

const getReducerState = state => {
  return state.get("advancedSearch");
}

const getCustomersList = state => {
  return getReducerState(state).get("customers");
}

/**
 * getAdvancedSearchCustomers returns the customers for the last
 * successful search operation.
 * @param {object} state - The global state
 */
export const getAdvancedSearchCustomers = createSelector(
  [getCustomersList],
  customers => {
    return customers.toJS();
  }
)

export default reducer;
