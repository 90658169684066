import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: '8px'
  }
}));

const AddModel = ({ onAdd, ...others }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [model, setModel] = useState('');

  const handleAdd = (/*event*/) => {
    if (model.trim().length > 0) {
      onAdd(model.trim());
      setModel('');
    }
  }

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    setModel(value);
  }


  return (
    <div {...others}>
      <TextField
        value={model}
        label={intl.formatMessage({
          id: "Model Name",
          defaultMessage: "Model Name"
        })}
        onChange={handleChange}
      />
      <Fab
        className={classes.button}
        size="small"
        aria-label="add"
        onClick={handleAdd}
      >
        <AddIcon />
      </Fab>
    </div>
  )
}

AddModel.propTypes = {
  onAdd: PropTypes.func.isRequired
};

export default AddModel;
