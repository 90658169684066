import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(() => ({
  listItem: {
    width: '480px',
    maxWidth: '90vw'
  }
}));

const AddUserItem = ({ onAdd, ...others }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <ListItem className={classes.listItem} {...others}>
      <Grid container justify="center">
        <Tooltip
          id="tooltip-fab"
          title={intl.formatMessage({
            id: "Add User",
            defaultMessage: "Add User"
          })}
        >
          <Fab size="small" color="primary" aria-label="add" onClick={onAdd}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Grid>
    </ListItem>
  )
}

AddUserItem.propTypes = {
  onAdd: PropTypes.func
};

export default AddUserItem;
