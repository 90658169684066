import React from 'react';
import PropTypes from 'prop-types';

import EditableCustomerList from '../CustomerList/EditableCustomerList';
import CustomerListDialog from '../CustomerList/CustomerListDialog';

const CustomerDialog = CustomerListDialog(EditableCustomerList);

const CustomerList = ({ customerIDs, customers, ...others }) => {
  const getCustomers = () => {
    const result = customerIDs.map(id => {
      return customers[id];
    }).sort((c1, c2) => {
      return c2.lastModificationDate - c1.lastModificationDate;
    });

    return result;
  }

  const customerList = getCustomers();

  if (customerIDs && customers) {
    return (
      <CustomerDialog customers={customerList} {...others} />
    )
  } else {
    return null;
  }
}

CustomerList.propTypes = {
  customerIDs: PropTypes.array.isRequired,
  customers: PropTypes.object.isRequired
}

export default CustomerList;
