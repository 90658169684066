import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl, defineMessages } from 'react-intl';

import { followUpOverdue } from '../Models/Customer';
import { getCustomers } from '../DataStore';
import { getUser } from '../DataStore/Login/Reducer';

import { VictoryPie, VictoryLabel } from 'victory';
import Typography from '@material-ui/core/Typography';
import CustomerListDialog from '../CustomerList/CustomerListDialog';
import EditableCustoberList from '../CustomerList/EditableCustomerList';

const messages = defineMessages({
  title: {
    id: 'Follow Ups',
    defaultMessage: 'Follow Ups'
  },
  day0: {
    id: '0d',
    defaultMessage: '0d'
  },
  day1: {
    id: '1d',
    defaultMessage: '1d'
  },
  day2: {
    id: '2d',
    defaultMessage: '2d'
  }
});

const useStyles = makeStyles((/*theme*/) => ({
  rootDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const CustomerDialog = CustomerListDialog(EditableCustoberList);

const FollowUps = ({ ...others }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [showDialog, setShowDialog] = useState(false);

  const allCustomers = useSelector(getCustomers);
  const user = useSelector(getUser);

  const handleOnClick = () => {
    setShowDialog(true);
  }

  const handleCloseDialog = (event) => {
    if (event) {
      event.stopPropagation();
    }

    setShowDialog(false);
  }

  const getValues = () => {
    let followUps = 0;
    let lessThan1Day = 0;
    let singleDay = 0;
    let moreThan1Day = 0;
    let resultCustomers = [];

    for (let i = 0; i < allCustomers.length; i++) {
      const customer = allCustomers[i];
      const overdue = followUpOverdue(customer);

      if (customer.salesman !== user.id) {
        continue;
      }

      if (overdue > 0) {
        followUps = followUps + 1;
        resultCustomers.push(customer);
        if (overdue < (24 * 3600)) {
          lessThan1Day = lessThan1Day + 1;
        } else if (overdue >= (48 * 3600)) {
          moreThan1Day = moreThan1Day + 1;
        } else {
          singleDay = singleDay + 1;
        }
      }
    }

    return {
      customers: resultCustomers,
      total: followUps,
      details: [
        { x: intl.formatMessage(messages.day0), y: lessThan1Day },
        { x: intl.formatMessage(messages.day1), y: singleDay },
        { x: intl.formatMessage(messages.day2), y: moreThan1Day }
      ]
    }
  }

  const { total, details, customers } = getValues();

  return (
    <div className={classes.rootDiv} onClick={handleOnClick} {...others}>
      <Typography variant="h6">
        {intl.formatMessage(messages.title)}
      </Typography>
      <svg viewBox="0 0 400 400">
        <VictoryPie
          standalone={false}
          width={400} height={400}
          data={details}
          innerRadius={100}
          colorScale={["green", "yellow", "red"]}
          padAngle={3}
        />
        <VictoryLabel
          textAnchor="middle"
          x={200} y={200}
          style={{ fontSize: 40 }}
          text={total}
        />
      </svg>

      <CustomerDialog open={showDialog} customers={customers} onClose={handleCloseDialog} />
    </div>
  )
}

export default FollowUps;
