import React from 'react';
import PropTypes from 'prop-types';
import List from "@material-ui/core/List";
import { Draggable, Droppable } from "react-beautiful-dnd";
import ListItem from "@material-ui/core/ListItem";

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : null
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  /*  userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    */

  // styles we need to apply on draggables
  ...draggableStyle,
});

const UserList = ({ droppableId, users, ...others }) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...others}>
          <List style={getListStyle(snapshot.isDraggingOver)}>
            {users.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <ListItem>
                      {item.firstName + ' ' + item.lastName}
                    </ListItem>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        </div>
      )}
    </Droppable>
  );
}

UserList.propTypes = {
  users: PropTypes.array.isRequired,
  droppableId: PropTypes.string.isRequired
};

export default UserList;
