import * as Types from './Types';
import axios from 'axios';

export const fetchUsersSuccess = users => {
  return {
    type: Types.FETCH_USERS_SUCCESS,
    users
  }
}

export const fetchUsersFailure = error => {
  return {
    type: Types.FETCH_USERS_FAILURE,
    error
  }
}

export const fetchUsersRequest = () => {
  return {
    type: Types.FETCH_USERS_REQUEST
  }
}

export const addUser = user => {
  return {
    type: Types.ADD_USER,
    user
  }
}

export const modifyUser = user => {
  return {
    type: Types.MODIFY_USER,
    user
  }
}

export const fetchUsers = () => {
  return async function (dispatch) {
    dispatch(fetchUsersRequest());

    try {
      const response = await axios.get("/user");
      dispatch(fetchUsersSuccess(response.data.result));
      return response.data.result;
    }
    catch (error) {
      dispatch(fetchUsersFailure(error));
      throw error;
    }
  }
}

export const setPasswordRequest = () => ({
  type: Types.SET_PASSWORD_REQUEST
});

export const setPasswordSuccess = (user) => ({
  type: Types.SET_PASSWORD_SUCCESS,
  user
});

export const setPasswordFailure = () => ({
  type: Types.SET_PASSWORD_FAILURE
});

export const setPassword = newPassword => {
  return async function (dispatch) {
    try {
      dispatch(setPasswordRequest());
      const response = await axios.put("/user/password", { password: newPassword });
      dispatch(setPasswordSuccess(response.data.result));
      return response.data.result
    }
    catch (error) {
      dispatch(setPasswordFailure());
      throw error;
    }
  }
}
