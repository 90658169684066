import React from 'react';
import { useSelector } from 'react-redux';
import { getCustomers } from '../../DataStore';
import { firstBusinessDayOfMonth } from '../../helpers';
import Performance from './Performance';

const CurrentMonthPerformance = ({ ...others }) => {
  useSelector(getCustomers);
  const startTime = firstBusinessDayOfMonth(new Date());
  const endTime = new Date();

  return (
    <Performance startTime={startTime} endTime={endTime} {...others} />
  )
}

export default CurrentMonthPerformance;
