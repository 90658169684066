import React from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import { getCustomers } from '../DataStore';
import { firstBusinessDayOfMonth, lastBusinessDayOfMonth } from '../helpers';

import Typography from '@material-ui/core/Typography';
import { VictoryBar, VictoryChart, VictoryLine, VictoryAxis } from 'victory';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((/*theme*/) => ({
  rootDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const messages = defineMessages({
  title: {
    id: 'Current Month Sales',
    defaultMessage: 'Current Month Sales'
  }
});

const values = (customers) => {
  const now = new Date();
  const startOfMonth = firstBusinessDayOfMonth(now);
  const endOfMonth = lastBusinessDayOfMonth(now);
  const days = Math.floor((endOfMonth.getTime() - startOfMonth.getTime()) / (24 * 3600 * 1000)) + 1;
  const currentDays = Math.floor((now.getTime() - startOfMonth.getTime()) / (24 * 3600 * 1000)) + 1;
  const result = [];
  let totalSales = 0;

  for (let i = 0; i < days; i++) {
    result.push({
      x: i,
      y: 0,
      label: ''
    })
  }

  for (let i = 0; i < customers.length; i++) {
    const customer = customers[i];

    let sold = false;

    for (let j = 0; j < customer.visits.length; j++) {
      const visit = customer.visits[j];
      if (visit.sold && !sold) {
        sold = true;

        if ((startOfMonth.getTime() <= visit.date * 1000) && (endOfMonth.getTime() >= visit.date * 1000)) {
          const index = Math.floor((visit.date * 1000 - startOfMonth.getTime()) / (24 * 3600 * 1000));
          result[index].y = result[index].y + 1;
          result[index].label = result[index].y;
          totalSales = totalSales + 1;
        }
      }
    }
  }

  const tickFormat = [];
  for (let i = 0; i <= 4; i++) {
    const d = new Date(startOfMonth.getTime() + (i * 7 * 24 * 3600 * 1000));
    tickFormat[i] = d.getDate() + "/" + (d.getMonth() + 1);
  }

  return { data: result, average: totalSales / currentDays, tickFormat: tickFormat };
}

const MonthlySales = ({ ...others }) => {
  const classes = useStyles();
  const intl = useIntl();
  const customers = useSelector(getCustomers);
  const { data, average, tickFormat } = values(customers);

  const lineData = [
    {
      x: 0, y: average
    },
    {
      x: data.length - 1,
      y: average
    }
  ];

  const tickValues = [0, 7, 14, 21, 28];

  return (
    <div {...others}>
      <div className={classes.rootDiv}>
        <Typography variant="h6">
          {intl.formatMessage(messages.title)}
        </Typography>
        <VictoryChart>
          <VictoryAxis
            tickValues={tickValues}
            tickFormat={tickFormat}
            style={{
              tickLabels: {
                fontSize: 15,
                fill: "black",
              }
            }}
          />
          <VictoryBar
            data={data}
            style={{
              data: {
                fillOpacity: 0.9,
                strokeWidth: 2,
                fill: (d) => { return d.y >= average ? 'green' : 'yellow' }
              }
            }}
          />
          <VictoryLine
            data={lineData}
          />
        </VictoryChart>
      </div>
    </div>
  )
}

export default MonthlySales;
