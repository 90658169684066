import React from 'react';
import PropTypes from 'prop-types';
import NewStockListItem from './NewStockListItem';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    list: {
        margin: theme.spacing(1),
        listStyleType: 'none',
        paddingInlineStart: 0
    }
}));

const NewStockList = ({ stocks, onSelect, selectedItem, ...others }) => {
    const classes = useStyles();

    const items = stocks.map(s => {
        return (
            <NewStockListItem
                key={s.id}
                stock={s}
                onSelect={onSelect}
                selected={selectedItem === s.id}
            />
        );
    });

    return (
        <ul className={classes.list} {...others}>
            {items}
        </ul>
    )
}

NewStockList.propTypes = {
    stocks: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    selectedItem: PropTypes.string
}

export default NewStockList;