import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Customers from './Customers';
import CustomerEditor from '../CustomerEditor/CustomerEditor';

const useStyles = makeStyles((/*theme*/) => ({
  rootDiv: {
    position: 'relative',
    height: '70vh',
    width: '100%',
    top: 0,
    left: 0
  }
}));

const EditableCustomerList = ({ customers, salesmen, ...others }) => {
  const classes = useStyles();
  const [editedCustomer, setEditedCustomer] = useState(null);

  const handleEditCustomer = (customer) => {
    setEditedCustomer(customer);
  }

  const handleCloseEditor = () => {
    setEditedCustomer(null);
  }

  return (
    <div className={classes.rootDiv}>
      {
        editedCustomer && (
          <CustomerEditor
            customerID={editedCustomer}
            onCancel={handleCloseEditor}
            onSaved={handleCloseEditor}
          />
        )
      }
      {editedCustomer === null && (<Customers
        customers={customers}
        salesmen={salesmen}
        onEditCustomer={handleEditCustomer}
        {...others}
      />)}
    </div>
  )
}

EditableCustomerList.propTypes = {
  customers: PropTypes.array,
  salesmen: PropTypes.object
};

export default EditableCustomerList;
