import axios from 'axios';

const CancelToken = axios.CancelToken;

/**
 * getCancelSource returns a new source to cancel a server request. The same
 * source can be used to cancel multiple requests. To cancel a request, call
 * source.cancel(). You can pass an optional message to source.cancel().
 */
export const getCancelSource = () => {
  return CancelToken.source();
}

export const isCancel = (e) => {
  return axios.isCancel(e);
}
