import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    item: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    card: props => ({
        backgroundColor: props.selected ? 'blue' : (props.overdue ? 'red' : '')
    })
}))

const NewStockListItem = ({ stock, onSelect, selected, ...others }) => {
    const overdue = stock.deliveryDate === 0 && stock.expectedDate < Date.now() * 1000;
    const classes = useStyles({ selected, overdue });
    const intl = useIntl();

    return (
        <li className={classes.item} onClick={() => { onSelect && onSelect(stock) }} {...others}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography>
                        {stock.stockNumber}
                    </Typography>
                    <Typography>
                        {stock.model.description} {stock.model.trim}
                    </Typography>
                    <Typography>
                        {stock.model.vin}
                    </Typography>
                    <Typography>
                        {stock.model.tradeInDate}
                    </Typography>
                    <Typography>
                        {stock.color.description}
                    </Typography>
                    <Typography>
                        {stock.model.transmission}
                    </Typography>
                    <Typography>
                        {intl.formatMessage({
                            id: "{doors} Doors",
                            defaultMessage: "{doors} Doors"
                        }, {
                            doors: stock.model.doors
                        })}
                    </Typography>
                    <Typography>
                        {stock.boughtFrom}
                    </Typography>
                </CardContent>
            </Card>
        </li>
    )
}

NewStockListItem.propTypes = {
    stock: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    selected: PropTypes.bool
}

export default NewStockListItem;
