import { defineMessages } from 'react-intl';

const messages = defineMessages({
  days: {
    id: "d",
    defaultMessage: "d"
  },
  hours: {
    id: "h",
    defaultMessage: "h"
  },
  minutes: {
    id: "m",
    defaultMessage: "m"
  }
})

/**
 * percent converts the value to a percentage string.
 * If the value is not a number or infinite, returns "-".
 * The returned value has a precision of a single digit.
 * @param {number} value - The value to convert
 */
export const percent = value => {
  if (isNaN(value)) {
    return "-";
  }

  if (!isFinite(value)) {
    return "-";
  }

  return (value * 100).toFixed(1) + "%";
}

/**
 * elapsedTime converts the given value in seconds to a string
 * with days, hours and minutes.
 * @param {number} value - The value in seconds to convert
 */
export const elapsedTime = (value, intl) => {
  if (isNaN(value)) {
    return "-";
  }

  if (!isFinite(value)) {
    return "-";
  }

  const days = Math.floor(value / 24 / 3600);
  let remainder = value - (days * 24 * 3600);

  const hours = Math.floor(remainder / 3600);
  remainder = remainder - (hours * 3600);

  const minutes = Math.floor(remainder / 60);

  if (intl) {
    const dayStr = intl.formatMessage(messages.days);
    const hourStr = intl.formatMessage(messages.hours);
    const minuteStr = intl.formatMessage(messages.minutes);

    return days + dayStr + " " + hours + hourStr + " " + minutes + minuteStr;
  }

  return days + "d " + hours + "h " + minutes + "m";
}
