import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import SalesmanPerformanceRow from './SalesmanPerformanceRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

const VehiclePerformanceTable = ({ vehiclesData, users, onCellClick, ...others }) => {
  const intl = useIntl();

  const rows = vehiclesData.map(entry => {
    const salesman = entry.salesmanID;
    const data = entry;
    return (
      <SalesmanPerformanceRow
        key={salesman}
        salesman={salesman}
        users={users}
        data={data}
        onCellClick={onCellClick}
      />
    );
  });

  return (
    <Table {...others}>
      <TableHead>
        <TableRow>
          <TableCell>
            {intl.formatMessage({
              id: "Salesman",
              defaultMessage: "Saesman"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "Customers",
              defaultMessage: "Customers"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "Customers In Dealership",
              defaultMessage: "Customers In Dealership"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "Walkins",
              defaultMessage: "Walkins"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "Bebacks",
              defaultMessage: "Bebacks"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "Sales",
              defaultMessage: "Sales"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "Sale Ratio",
              defaultMessage: "Sale Ratio"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "Walkin Sale Ratio",
              defaultMessage: "Walkin Sale Ratio"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "Beback Sale Ratio",
              defaultMessage: "Beback Sale Ratio"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "Test Drive Ratio",
              defaultMessage: "Test Drive Ratio"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "VIP Presentations Ratio",
              defaultMessage: "VIP Presentations Ratio"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "Take Over Ratio",
              defaultMessage: "Take Over Ratio"
            })}
          </TableCell>
          <TableCell>
            {intl.formatMessage({
              id: "Take Over Sale Ratio",
              defaultMessage: "Take Over Sale Ratio"
            })}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  )
}

VehiclePerformanceTable.propTypes = {
  vehiclesData: PropTypes.array,
  users: PropTypes.object,
  onCellClick: PropTypes.func
};

export default VehiclePerformanceTable;
