import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { VictoryChart, VictoryAxis, VictoryBar, VictoryLabel, VictoryLine } from 'victory';
import { percent } from '../Reports/Helper';
import { getUsersObject } from '../DataStore/Users/Reducer';

const messages = defineMessages({
  titleLabel: {
    id: 'Closing (Current Month)',
    defaultMessage: 'Closing (Current Month)'
  }
});

const useStyles = makeStyles((/*theme*/) => ({
  rootDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const Closing = ({ data, lastYearData, ...others }) => {
  const intl = useIntl();
  const classes = useStyles();

  const users = useSelector(getUsersObject);

  const getValues = (data) => {
    return data
      .filter(stats => { return stats.salesmanID !== "total" })
      .sort((a, b) => {
        const userA = users[a.salesmanID];
        const userB = users[b.salesmanID];
        const nameA = userA.lastName + userA.firstName;
        const nameB = userB.lastName + userB.lastName;

        if (nameA < nameB) {
          return 1;
        } else if (nameA > nameB) {
          return -1;
        } else {
          return 0;
        }
      })
      .map((stats, index) => {
        let value = stats.sales / stats.inCustomers;
        if (isNaN(value) || !isFinite(value)) {
          value = 0;
        }

        return {
          x: index,
          y: value * 100,
          label: percent(value),
          salesmanID: stats.salesmanID
        }
      });
  }

  const getAverage = (data) => {
    for (let i = 0; i < data.length; i++) {
      const stats = data[i];

      if (stats.salesmanID === "total") {
        let value = stats.sales / stats.inCustomers;
        if (isNaN(value) || !isFinite(value)) {
          value = 0;
        }

        return value * 100;
      }
    }

    return 0;
  }

  const color = (value, average) => {
    if (value >= average) {
      return 'green';
    }

    if (value < 0.85 * average) {
      return 'red';
    }

    return 'yellow';
  }

  const values = getValues(data);
  const tickValues = values.map((value, index) => (index));
  const tickFormat = values.map((value) => {
    const user = users[value.salesmanID];
    if (user) {
      return user.lastName + " " + user.firstName.charAt(0);
    }

    return "-";
  });

  const monthAverage = getAverage(data);
  const monthAverageLine = [
    { y: monthAverage, x: -1 },
    { y: monthAverage, x: values.length }
  ];

  const yearAverage = getAverage(lastYearData);
  const lastYearAverageLine = [
    { y: yearAverage, x: -1 },
    { y: yearAverage, x: values.length }
  ];

  const monthAverageStyle = {
    data: {
      stroke: color(monthAverage, yearAverage)
    }
  };

  return (
    <div {...others}>
      <div className={classes.rootDiv}>
        <Typography variant="h6">
          {intl.formatMessage(messages.titleLabel)}
        </Typography>
        <div>
          <VictoryChart
            padding={{
              left: 90,
              right: 70,
              top: 20,
              bottom: 20
            }}
          >
            <VictoryAxis
              tickValues={tickValues}
              tickFormat={tickFormat}
              style={{
                tickLabels: {
                  fontSize: 15,
                  fill: "black",
                }
              }}
            />

            <VictoryBar
              horizontal={true}
              standalone={true}
              barRatio={0.9}
              labelComponent={<VictoryLabel />}
              data={values}
              style={{
                data: {
                  fillOpacity: 0.9,
                  stroke: ({ datum }) => (color(datum.y, yearAverage)),
                  strokeWidth: 2,
                  fill: ({ datum }) => (color(datum.y, yearAverage))
                },
                labels: {
                  fontSize: 20,
                  fill: "black"
                }
              }}
            />
            <VictoryLine
              data={monthAverageLine}
              style={monthAverageStyle}
            />
            <VictoryLine
              data={lastYearAverageLine}
            />
          </VictoryChart>
        </div>
      </div>
    </div>
  )
}

Closing.propTypes = {
  data: PropTypes.array,
  lastYearData: PropTypes.array
}

export default Closing;
