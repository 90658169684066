import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import UserSelect from './UserSelect';

import { changeSettings as _changeSettings } from '../../../DataStore/Settings/Settings/Actions';
import { settings as getSettings, activeUsers as getActiveUsers } from '../../../DataStore/Settings/Settings/Reducer';
import VisitTypes from "./VisitTypes";
import AvailableDays from "./AvailableDays";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

const SaleNotifications = ({ ...others }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const users = useSelector(getActiveUsers);
  const settings = useSelector(getSettings);

  const changeSettings = (name, value) => {
    return dispatch(_changeSettings('saleNotifications', name, value));
  }

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'delay' || name === 'startTime' || name === 'endTime') {
      const intValue = parseInt(value, 10);
      changeSettings(name, isNaN(intValue) ? 0 : intValue);
    }
    else {
      changeSettings(name, value);
    }
  }

  return (
    <Grid container direction={'column'} spacing={1} {...others}>
      <Grid item>
        <TextField
          label={intl.formatMessage({
            id: 'Delay',
            defaultMessage: "Delay"
          })}
          name={'delay'}
          value={settings.saleNotifications.delay}
          onChange={handleChange}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <TextField
            label={intl.formatMessage({
              id: "Start Time",
              defaultMessage: "Start Time"
            })}
            name={'startTime'}
            value={settings.saleNotifications.startTime}
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <TextField
            label={intl.formatMessage({
              id: "End Time",
              defaultMessage: "End Time"
            })}
            name={'endTime'}
            value={settings.saleNotifications.endTime}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid item>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                name={'notifyOtherUsersOnlyOnRepeat'}
                checked={settings.saleNotifications.notifyOtherUsersOnlyOnRepeat}
                onChange={handleChange}
              />
            }
            label={intl.formatMessage({
              id: 'Notify Only On Repeat',
              defaultMessage: "Notify Only On Repeat"
            })}
          />
          <FormControlLabel
            control={
              <Checkbox
                name={'repeat'}
                checked={settings.saleNotifications.repeat}
                onChange={handleChange}
              />
            }
            label={intl.formatMessage({
              id: "Repeat",
              defaultMessage: "Repeat"
            })}
          />
        </FormGroup>
      </Grid>
      <Grid item>
        <Card>
          <Typography>
            {intl.formatMessage({
              id: "Available Days",
              defaultMessage: "Available Days"
            })}
          </Typography>
          <AvailableDays
            name={'availableDays'}
            value={settings.saleNotifications.availableDays}
            onChange={changeSettings}
          />
        </Card>
      </Grid>
      <Grid item>
        <Card>
          <Typography>
            {intl.formatMessage({
              id: "Users To Notify For Sales",
              defaultMessage: "Users To Notify For Sales"
            })}
          </Typography>
          <UserSelect
            name={'usersToNotifyForSales'}
            users={users}
            value={settings.saleNotifications.usersToNotifyForSales}
            onChange={changeSettings}
          />
        </Card>
      </Grid>
      <Grid item>
        <Card>
          <Typography>
            {intl.formatMessage({
              id: "Other Users",
              defaultMessage: "Other Users"
            })}
          </Typography>
          <UserSelect
            name={'otherUsers'}
            users={users}
            value={settings.saleNotifications.otherUsers}
            onChange={changeSettings}
          />
        </Card>
      </Grid>
      <Grid item>
        <Card>
          <Typography>
            {intl.formatMessage({
              id: "Visit Types",
              defaultMessage: "Visit Types"
            })}
          </Typography>
          <VisitTypes
            name={'visitTypes'}
            value={settings.saleNotifications.visitTypes}
            onChange={changeSettings}
          />
        </Card>
      </Grid>
    </Grid>
  );
}

export default SaleNotifications;
