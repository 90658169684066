import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import * as API from '../backend';
import { useEventProvider } from '../Providers/EventProvider';

export const useActiveStorages = () => {
  const [storages, setStorages] = useState([]);
  const [status, setStatus] = useState("loading");
  const { enqueueSnackbar } = useSnackbar();
  const eventProvider = useEventProvider();
  const intl = useIntl();

  useEffect(() => {
    const cancelSource = API.getCancelSource();

    const fetchStorages = async () => {
      try {
        setStatus("loading");
        const storages = await API.getActiveStorages(cancelSource);
        setStorages(storages);
        setStatus("success");
      }
      catch (error) {
        if (API.isCancel(error)) {
          setStatus("cancelled");
        } else {
          setStatus("failure");
          enqueueSnackbar(
            intl.formatMessage({
              id: "Error while fetching storages.",
              defaultMessage: "Error while fetching storages."
            }),
            {
              variant: 'error'
            }
          );
        }
      }
    }

    fetchStorages();

    eventProvider.addEventListener(API.Events.addStorage, fetchStorages);
    eventProvider.addEventListener(API.Events.updateStorage, fetchStorages);
    eventProvider.addEventListener(API.Events.deleteStorage, fetchStorages);

    return () => {
      cancelSource.cancel();
      eventProvider.removeEventListener(API.Events.addStorage, fetchStorages);
      eventProvider.removeEventListener(API.Events.updateStorage, fetchStorages);
      eventProvider.removeEventListener(API.Events.deleteStorage, fetchStorages);
    }
  }, [enqueueSnackbar, intl, eventProvider]);

  return [storages, status];
}
