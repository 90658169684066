import React from "react";
import LanguageProvider from "./Language/LanguageProvider";

import { Provider } from "react-redux";
import { createDataStore } from "./DataStore/Store";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { DragDropContext } from "react-beautiful-dnd";

import { dragAndDropEndHandler } from "./UI/DragAndDropHandler";

import { SnackbarProvider } from 'notistack';
import Router from "./Router";

const store = createDataStore();

const theme = createTheme({
  mainPage: {
    maxWidth: "1200px"
  },
  typography: {
    useNextVariants: true
  }
});

const App = () => {
  const handleDragEnd = event => {
    dragAndDropEndHandler(event);
  }

  return (
    <Provider store={store}>
      <LanguageProvider>
        <DragDropContext onDragEnd={handleDragEnd}>
          <MuiThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
              <Router />
            </SnackbarProvider>
          </MuiThemeProvider>
        </DragDropContext>
      </LanguageProvider>
    </Provider>
  );
}

const preventBackButton = () => {
  window.history.pushState(null, document.title, window.location.href);
  window.addEventListener('popstate', function () {
    window.history.pushState(null, document.title, window.location.href);
  });
};

preventBackButton();

export default App;
