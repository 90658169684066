import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const UserSelect = ({ users, onChange, value, name, ...others }) => {
  const handleClick = (event, userId) => {
    if (onChange) {
      const target = event.target;
      const selected = target.checked;

      const newList = value.slice();

      if (selected) {
        newList.push(userId);
      }
      else {
        newList.splice(newList.indexOf(userId), 1);
      }

      onChange(name, newList);
    }
  }

  const items = users.map(user => {
    return (
      <ListItem
        key={user.id}
        dense
        button
        onClick={(event) => handleClick(event, user.id)}
        {...others}
      >
        <Checkbox
          tabIndex={-1}
          checked={value.indexOf(user.id) >= 0}
          disableRipple
        />
        <ListItemText primary={user.firstName + ' ' + user.lastName} />
      </ListItem>
    )
  });

  return (
    <List>
      {items}
    </List>
  );
}

UserSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
  users: PropTypes.array,
  onChange: PropTypes.func
};

UserSelect.defaultProps = {
  name: '',
  value: [],
  users: [],
  onChange: null
};

export default UserSelect;
