import React, { useState, useEffect } from 'react';
import * as API from '../../backend';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core';

import ColorList from './ColorList';
import SearchBox from '../../Components/SearchBox';
import ColorEditorController from './ColorEditorController';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
    mainDiv: {
        display: 'flex',
        margin: theme.spacing(1),
        height: '100%',
        overflow: 'hidden'
    },
    listDiv: {
        height: '100%',
        width: '50%',
        overflow: 'auto'
    },
    searchDiv: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(1)
    },
    searchBox: {
        width: '25em'
    },
    editorDiv: {
        paddingTop: '64px'
    }
}));

const ColorController = ({ ...others }) => {
    const [colors, setColors] = useState([]);
    const [filter, setFilter] = useState('');
    const [selectedColor, setSelectedColor] = useState(null);
    const [newColor, setNewColor] = useState(false);
    const intl = useIntl();
    const snackbar = useSnackbar();
    const classes = useStyles();

    useEffect(() => {
        const cancelSource = API.getCancelSource();
        const loadColors = async () => {
            try {
                const result = await API.getColors();
                setColors(result);
            }
            catch (error) {
                snackbar.enqueueSnackbar(
                    intl.formatMessage({
                        id: "Error while loading colors from server.",
                        defaultMessage: "Error while loading colors from server."
                    }),
                    {
                        variant: 'error'
                    }
                )
            }
        }

        loadColors();

        return () => {
            cancelSource.cancel();
        }
    }, []);

    const filteredColors = colors.filter(c => {
        const r = new RegExp('.*' + filter + '.*', 'i');
        return c.description.search(r) >= 0 || c.code.search(r) >= 0;
    });

    const handleUpdate = updatedColor => {
        setColors(colors.map(c => {
            if (c.id == updatedColor.id) {
                return updatedColor;
            }

            return c;
        }));
    }

    const handleCreate = newColor => {
        const newColorList = colors.slice();
        newColorList.push(newColor);

        setColors(newColorList);
        setSelectedColor(newColor.id);
    }

    return (
        <div className={classes.mainDiv} {...others}>
            <div className={classes.listDiv}>
                <div className={classes.searchDiv}>
                    <SearchBox
                        className={classes.searchBox}
                        value={filter}
                        onChange={v => setFilter(v)}
                        variant='outlined'
                    />
                    <IconButton
                        onClick={
                            () => {
                                setSelectedColor(null);
                                setNewColor(true);
                            }
                        }
                    >
                        <AddIcon />
                    </IconButton>
                </div>
                <ColorList
                    colors={filteredColors}
                    onSelect={c => setSelectedColor(c.id)}
                    selectedItem={selectedColor}
                />
            </div>

            {(selectedColor || newColor) && (
                <div className={classes.editorDiv}>
                    <ColorEditorController
                        colorID={selectedColor}
                        onUpdate={handleUpdate}
                        onCreate={handleCreate}
                    />
                </div>
            )}
        </div>
    );
}

export default ColorController;
