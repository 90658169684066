import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import UsersIcon from "@material-ui/icons/SupervisorAccountOutlined";
import ListItemText from "@material-ui/core/ListItemText";
import AppSettingsIcon from "@material-ui/icons/SettingsApplications";
import Hidden from '@material-ui/core/Hidden';

import * as Types from '../DataStore/Settings/Types';
import { setContent as _setContent } from '../DataStore/Settings/Actions';
import { getContentId } from "../DataStore/Settings/Reducer";

const useStyles = makeStyles((/*theme*/) => (
  {
    drawerPaper: {
      position: 'static',
      width: '100%'
    }
  }
));

const Menu = ({ ...others }) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const contentId = useSelector(getContentId);

  const setContent = id => {
    return dispatch(_setContent(id));
  }

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
      {...others}
    >
      <List>
        <ListItem
          button
          disabled={contentId === Types.ContentId.Users}
          onClick={() => { setContent(Types.ContentId.Users) }}
        >
          <ListItemIcon>
            <UsersIcon />
          </ListItemIcon>
          <Hidden smDown>
            <ListItemText
              primary={intl.formatMessage({
                id: "Users",
                defaultMessage: "Users"
              })}
            />
          </Hidden>
        </ListItem>
        <ListItem
          button
          disabled={contentId === Types.ContentId.AppSettings}
          onClick={() => { setContent(Types.ContentId.AppSettings) }}
        >
          <ListItemIcon>
            <AppSettingsIcon />
          </ListItemIcon>
          <Hidden smDown>
            <ListItemText
              primary={intl.formatMessage({
                id: "Application Settings",
                defaultMessage: "Application Settings"
              })}
            />
          </Hidden>
        </ListItem>
      </List>
    </Drawer>
  )
}

export default Menu;
