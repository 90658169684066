import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import FilterIcon from '@material-ui/icons/FilterList';

const SearchBox = ({ onChange, value, delay = 500, onFilter, filtered, ...others }) => {
    const [delayedValue, setDelayedValue] = useState(value, [value]);

    useEffect(() => {
        let timer = undefined;

        if (value != delayedValue) {
            timer = setTimeout(() => {
                onChange && onChange(delayedValue);
                timer = undefined;
            }, delay)
        }

        return () => {
            if (timer !== undefined) {
                clearTimeout(timer);
            }
        }
    }, [value, delayedValue]);

    const adornment = {
        startAdornment: (
            <InputAdornment position="start">
                <SearchIcon />
            </InputAdornment>
        )
    };

    if (onFilter) {
        adornment.endAdornment = (
            <InputAdornment position="end">
                <IconButton
                    size='small'
                    onClick={(e) => { onFilter && onFilter(e) }}
                >
                    <FilterIcon color={filtered ? 'secondary' : 'primary'} />
                </IconButton>
            </InputAdornment>
        )
    }

    return (
        <TextField {...others}
            value={delayedValue}
            onChange={e => { setDelayedValue(e.target.value); }}
            InputProps={adornment}
        />
    )
}

SearchBox.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    delay: PropTypes.number,
    onFilter: PropTypes.func,
    filtered: PropTypes.bool
}

export default SearchBox;
