import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { PageId } from "../../DataStore/MainPage/Types";

const DashboardButton = ({ setPage, currentPage, ...others }) => {
  return (
    <IconButton
      color="inherit"
      aria-label="Dashboard"
      disabled={currentPage === PageId.Dashboard}
      onClick={() => {
        setPage(PageId.Dashboard);
      }}
      {...others}
    >
      <DashboardIcon />
    </IconButton>
  );
}

DashboardButton.propTypes = {
  setPage: PropTypes.func,
  currentPage: PropTypes.string
}

export default DashboardButton;
