import { Map, List } from 'immutable';
import createReducer from '../CreateReducer';
import { RequestStatus } from '../Types';
import * as Types from './Types';
import { createSelector } from 'reselect';

const initialState = Map({
  fetchAvailableSalesmenStatus: RequestStatus.None,
  saveAvailableSalesmenStatus: RequestStatus.None,
  firstSalesman: '',
  availableSalesmen: List(),
  settingsDialogOpened: false
});

const fetchSalesmanAvailabilityRequest = state => {
  return state.set('fetchAvailableSalesmenStatus', RequestStatus.Requested);
}

const fetchSalesmanAvailabilitySuccess = (state, { firstSalesman, availableSalesmen }) => {
  return state.withMutations(state => {
    state
      .set('fetchAvailableSalesmenStatus', RequestStatus.Success)
      .set('firstSalesman', firstSalesman)
      .set('availableSalesmen', List(availableSalesmen));
  });
}

const fetchSalesmanAvailabilityFailure = state => {
  return state.set('fetchAvailabileSalesmenStatus', RequestStatus.Failure);
}

const setSettingsDialogVisibility = (state, { visible }) => {
  return state.set('settingsDialogOpened', visible);
}

const modifyFirstSalesman = (state, { salesmanID }) => {
  return state.set('firstSalesman', salesmanID);
}

const modifyAvailableSalesmen = (state, { salesmen }) => {
  return state.set('availableSalesmen', List(salesmen));
}

const reducer = createReducer(initialState, {
  [Types.FETCH_SALESMAN_AVAILABILITY_REQUEST]: fetchSalesmanAvailabilityRequest,
  [Types.FETCH_SALESMAN_AVAILABILITY_SUCCESS]: fetchSalesmanAvailabilitySuccess,
  [Types.FETCH_SALESMAN_AVAILABILITY_FAILURE]: fetchSalesmanAvailabilityFailure,
  [Types.SET_SETTINGS_DIALOG_VISIBILITY]: setSettingsDialogVisibility,
  [Types.MODIFY_FIRST_SALESMAN]: modifyFirstSalesman,
  [Types.MODIFY_AVAILABLE_SALESMEN]: modifyAvailableSalesmen
});

const getReducerState = state => {
  return state.get('salesmanAvailability');
}

/**
 * Returns the status of the last operation to fetch salesman availability.
 * @param {object} state - The global state.
 */
export const getFetchAvailableSalesmenStatus = state => {
  return getReducerState(state).get('fetchAvailableSalesmenStatus');
}

/**
 * Returns the currently salesman selected as first.
 * @param {object} state - The global state.
 */
export const getFirstSalesman = state => {
  return getReducerState(state).get('firstSalesman');
}

/**
 * Returns whether the dialog to set the salesman availability is opened or not.
 * @param {object} state - The global state.
 */
export const getSettingsDialogOpened = state => {
  return getReducerState(state).get('settingsDialogOpened');
}

const getAvailableSalesmenList = state => {
  return getReducerState(state).get('availableSalesmen');
}

/**
 * Returns an array of the currently available salesmen.
 */
export const getAvailableSalesmen = createSelector(
  [getAvailableSalesmenList],
  salesmen => {
    return salesmen.toJS();
  });

export default reducer;
