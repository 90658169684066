import React, { useState } from 'react';
import Viewer from './Viewer';
import ReservationEditor from './ReservationEditor';
import { endOfDayUTC } from "../../helpers";
import { makeStyles } from '@material-ui/core/styles';
import { Fade } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    width: '100%'
  },
  overlapped: {
    position: 'absolute',
    width: '100%'
  }
}));

const Reservations = () => {
  const classes = useStyles();
  const [reservation, setReservation] = useState(null);

  const handleAdd = (vehicle, date) => {
    const newReservation = {
      vehicle: vehicle,
      lastName: '',
      firstName: '',
      email: '',
      homePhone: '',
      cellPhone: '',
      workPhone: '',
      startDate: date,
      endDate: Math.floor(endOfDayUTC(new Date((date + 24 * 3600 - 1) * 1000)).getTime() / 1000),
      fulfilled: false
    }

    setReservation(newReservation);
  }

  const handleEdit = (reservation) => {
    setReservation(reservation);
  }

  return (
    <div className={classes.wrapper}>
      <Fade
        in={reservation === null}
      >
        <Viewer
          className={classes.overlapped}
          onAdd={handleAdd}
          onEdit={handleEdit}
        />
      </Fade>
      <Fade
        in={Boolean(reservation)}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <ReservationEditor
          className={classes.overlapped}
          initialReservation={reservation}
          onCancel={() => { setReservation(null) }}
          onSave={() => { setReservation(null) }}
        />
      </Fade>
    </div>
  )
}

export default Reservations;
