import React from 'react';
import PropTypes from 'prop-types';

import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import NewStockController from './NewStockController';

import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import PrinterSelect from '../../Components/PrinterSelect';

const useStyles = makeStyles((theme) => ({
    navigationBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    printerDiv: {
        maxWidth: '300px',
        margin: theme.spacing(1)
    }
}));

const NewStockNavigator = ({ stocks, index, onBack, onNext, onStockVehicle, printer, onPrinterChange, ...others }) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <div {...others}>
            <div className={classes.printerDiv}>
                <PrinterSelect onChange={onPrinterChange} selection={printer} fullWidth={true} />
            </div>
            <NewStockController stock={stocks[index]} onStockVehicle={onStockVehicle} />
            <div className={classes.navigationBar}>
                <IconButton
                    onClick={onBack}
                    disabled={index <= 0}
                >
                    <ArrowBack />
                </IconButton>
                <Typography>
                    {intl.formatMessage({
                        id: "Stock { index } / { total }",
                        defaultMessage: "Stock { index } / { total }"
                    },
                        {
                            index: index + 1,
                            total: stocks.length
                        })}
                </Typography>
                <IconButton
                    onClick={onNext}
                    disabled={index >= (stocks.length - 1)}
                >
                    <ArrowForward />
                </IconButton>
            </div>
        </div>
    )
}

NewStockNavigator.propTypes = {
    stocks: PropTypes.array,
    index: PropTypes.number,
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    onStockVehicle: PropTypes.func,
    printer: PropTypes.string,
    onPrinterChange: PropTypes.func
}

export default NewStockNavigator;
