import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((/*theme*/) => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    width: '480px',
    maxWidth: '90vw'
  },
  actions: {
    marginLeft: 'auto'
  }
}));

const User = ({ user, onEdit, ...others }) => {
  const intl = useIntl();
  const classes = useStyles();

  const handleEditClick = () => {
    if (onEdit) {
      onEdit(user);
    }
  }

  const lastLogin = user.lastLoginTimestamp * 1000;

  return (
    <ListItem {...others}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" color="primary">
            {intl.formatMessage({
              id: "Name: [firstName} {lastName}",
              defaultMessage: "Name {firstName} {lastName}"
            },
              { firstName: user.firstName, lastName: user.lastName })}
          </Typography>
          <Typography>
            {intl.formatMessage({
              id: "Email: {email}",
              defaultMessage: "Email: {email}"
            }, { email: user.email })}
          </Typography>
          <Typography>
            {intl.formatMessage({
              id: "Last Login: {date} {time}",
              defaultMessage: "Last Login: {date} {time}"
            }, { date: intl.formatDate(lastLogin), time: intl.formatTime(lastLogin) })}
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <IconButton
            color="primary"
            onClick={handleEditClick}
          >
            <EditIcon />
          </IconButton>
        </CardActions>
      </Card>
    </ListItem>
  );
}

User.propTypes = {
  user: PropTypes.object.isRequired,
  onEdit: PropTypes.func
};

export default User;
