import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { useSelector } from 'react-redux';

import { useIntl, defineMessages } from 'react-intl';

import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import MaskedInput from 'react-text-mask';
import { useSnackbar } from 'notistack';

import History from './History';
import { saveStorage } from '../../backend';
import { Typography } from '@material-ui/core';
import { getUser } from '../../DataStore/Login/Reducer';
import { AccessRight } from '../../Models/User';

const messages = defineMessages({
  lastName: {
    id: 'Last Name',
    defaultMessage: 'Last Name'
  },
  firstName: {
    id: 'First Name',
    defaultMessage: 'First Name'
  },
  workOrder: {
    id: 'Work Order',
    defaultMessage: 'Work Order'
  },
  count: {
    id: 'Count',
    defaultMessage: 'Count'
  },
  brand: {
    id: 'Brand',
    defaultMessage: 'Brand'
  },
  model: {
    id: 'Model',
    defaultMessage: 'Model'
  },
  threadDepth: {
    id: 'Thread Depth',
    defaultMessage: 'Thread Depth'
  },
  size: {
    id: 'Size',
    defaultMessage: 'Size'
  },
  wheelType: {
    id: 'Wheel Type',
    defaultMessage: 'Wheel Type'
  },
  wheelTypeNone: {
    id: 'No Wheel',
    defaultMessage: 'No Wheel'
  },
  wheelTypeSteel: {
    id: 'Steel Wheels',
    defaultMessage: 'Steel Wheels'
  },
  wheelTypeAlloy: {
    id: 'Alloy Wheels',
    defaultMessage: 'Alloy Wheels'
  },
  location: {
    id: 'Location',
    defaultMessage: 'Location'
  },
  dateStored: {
    id: 'Date Stored: {dateStored, date, medium}',
    defaultMessage: 'Date Stored: {dateStored, date, medium}'
  },
  dateRetrieved: {
    id: 'Date Retrieved: {dateRetrieved, date, medium}',
    defaultMessage: 'Date Retrieved: {dateRetrieved, date, medium}'
  },
  comments: {
    id: 'Comments',
    defaultMessage: 'Comments'
  },
  saveButton: {
    id: 'Save',
    defaultMessage: 'Save'
  },
  cancelButton: {
    id: 'Cancel',
    defaultMessage: 'Cancel'
  },
  saveError: {
    id: 'Error while saving storage.',
    defaultMessage: "Error while saving storage."
  },
  invalidStorageError: {
    id: 'All required fields must be entered.',
    defaultMessage: "All required fields must be entered."
  },
  completeButton: {
    id: 'Complete Storage',
    defaultMessage: 'Complete Storage'
  },
  uncompleteButton: {
    id: 'Uncomplete Storage',
    defaultMessage: 'Put Back In Storage'
  },
  history: {
    id: 'History',
    defaultMessage: 'History'
  },
  tireType: {
    id: 'Tire Type',
    defaultMessage: "Tire Type"
  },
  tireTypeUnspecified: {
    id: 'Unspecified',
    defaultMessage: 'Unspecified'
  },
  tireTypeSummer: {
    id: 'Summer',
    defaultMessage: 'Summer'
  },
  tireTypeWinter: {
    id: 'Winter',
    defaultMessage: 'Winter'
  }
});

const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: `${900 + 6 * theme.spacing(1)}px`,
    display: 'flex',
    flexDirection: 'column'
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  columnSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  inputField: {
    margin: theme.spacing(1),
    width: '300px'
  },
  buttonSection: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  comments: {
    width: '100%',
    margin: theme.spacing(1)
  }
}));

function TireSizeTextMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[1-9]/, /\d/, /\d/, '/', /[1-9]/, /\d/, 'R', /[1-9]/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TireSizeTextMask.propTypes = {
  inputRef: PropTypes.any
}

const validate = storage => {
  return storage.lastName.trim().length > 0 &&
    storage.firstName.trim().length > 0 &&
    storage.workOrder.trim().length > 0 &&
    (storage.tireType > 0 || storage.dateStored < 1643691600);
}

const StorageEditor = ({ initialStorage, onCancel, onSave, ...others }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [storageState, setStorage] = useState(Map(initialStorage));
  const storage = storageState.toJS();
  const currentUser = useSelector(getUser);
  const showHistory = currentUser.accessRights.indexOf(AccessRight.StorageHistory) >= 0;

  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    setStorage(storageState.set(name, value));
  }

  const handleSave = async () => {
    try {
      if (!validate(storage)) {
        enqueueSnackbar(intl.formatMessage(messages.invalidStorageError), { variant: 'error' });
        return;
      }

      await saveStorage(storage);

      if (onSave) {
        onSave(storage);
      }
    }
    catch (error) {
      enqueueSnackbar(intl.formatMessage(messages.saveError), { variant: 'error' });
    }
  }

  const completeStorage = () => {
    setStorage(storageState.set('dateRetrieved', Math.floor(Date.now() / 1000)));
  }

  const uncompleteStorage = () => {
    setStorage(storageState.set('dateRetrieved', 0));
  }

  return (
    <div className={classes.form} {...others}>
      <div className={classes.buttonSection}>
        <IconButton
          onClick={onCancel}
          color="secondary"
          aria-label={intl.formatMessage(messages.cancelButton)}
        >
          <CancelIcon />
        </IconButton>
        <IconButton
          onClick={handleSave}
          color="primary"
          aria-label={intl.formatMessage(messages.saveButton)}
        >
          <SaveIcon />
        </IconButton>
      </div>
      <div className={classes.section} >
        <TextField
          className={classes.inputField}
          label={intl.formatMessage(messages.lastName)}
          name="lastName"
          value={storage.lastName}
          onChange={handleChange}
          error={storage.lastName.trim().length <= 0}
        />
        <TextField
          className={classes.inputField}
          label={intl.formatMessage(messages.firstName)}
          name="firstName"
          value={storage.firstName}
          onChange={handleChange}
          error={storage.firstName.trim().length <= 0}
        />
      </div>
      <div className={classes.section}>
        <TextField
          className={classes.inputField}
          label={intl.formatMessage(messages.workOrder)}
          name="workOrder"
          value={storage.workOrder}
          onChange={handleChange}
          error={storage.workOrder.trim().length <= 0}
        />
        <TextField
          className={classes.inputField}
          label={intl.formatMessage(messages.location)}
          name="location"
          value={storage.location}
          onChange={handleChange}
        />
        <FormControl
          className={classes.inputField}
          error={storage.tireType <= 0}
        >
          <Select
            value={storage.tireType}
            onChange={handleChange}
            displayEmpty
            name="tireType"
          >
            <MenuItem value={0}>{intl.formatMessage(messages.tireTypeUnspecified)}</MenuItem>
            <MenuItem value={1}>{intl.formatMessage(messages.tireTypeSummer)}</MenuItem>
            <MenuItem value={2}>{intl.formatMessage(messages.tireTypeWinter)}</MenuItem>
          </Select>
          <FormHelperText>{intl.formatMessage(messages.tireType)}</FormHelperText>
        </FormControl>
      </div>
      <div className={classes.columnSection}>
        <Typography>
          {intl.formatMessage(messages.dateStored, { dateStored: new Date(storage.dateStored * 1000) })}
        </Typography>
        {storage.dateRetrieved > 0 && (
          <Typography>
            {intl.formatMessage(messages.dateRetrieved, { dateRetrieved: new Date(storage.dateRetrieved * 1000) })}
          </Typography>
        )}
        {storage.id && storage.dateRetrieved === 0 && (
          <Button variant="contained" onClick={completeStorage}>
            {intl.formatMessage(messages.completeButton)}
          </Button>
        )}
        {storage.id && storage.dateRetrieved !== 0 && (
          <Button variant="contained" onClick={uncompleteStorage}>
            {intl.formatMessage(messages.uncompleteButton)}
          </Button>
        )}
      </div>
      <div className={classes.section}>
        <TextField
          className={classes.comments}
          label={intl.formatMessage(messages.comments)}
          value={storage.comments}
          onChange={handleChange}
          multiline={true}
          name="comments"
        />
      </div>
      {showHistory && storage.id && storage.id !== '' && (
        <div className={classes.sec}>
          <Typography>
            {intl.formatMessage(messages.history)}
          </Typography>
          <History storage={storage} />
        </div>
      )}
    </div>
  )
}

StorageEditor.propTypes = {
  initialStorage: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
}

export default StorageEditor;
