import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import PrintSection from './PrintSection';
import BottomPrintSection from './BottomPrintSection';

import './PrintPage.css';

const useStyles = makeStyles(theme => ({
  pageDiv: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  sectionDiv: {
    width: '100%',
    height: '40%',
    display: 'flex',
    margin: theme.spacing(1)
  },
  bottomSection: {
    width: '100%',
    height: '20%',
    display: 'flex'
  },
  ticketSection: {
    width: '50%',
    height: '100%'
  }
}));

const PrintPage = ({ storage, ...others }) => {
  const classes = useStyles();

  return (
    <div className="myDivToPrint" {...others}>
      <div className={classes.pageDiv}>
        <div className={classes.sectionDiv}>
          <div className={classes.ticketSection}>
            <PrintSection storage={storage} />
          </div>
          <div className={classes.ticketSection}>
            <PrintSection storage={storage} />
          </div>
        </div>
        <div className={classes.sectionDiv}>
          <div className={classes.ticketSection}>
            <PrintSection storage={storage} />
          </div>
          <div className={classes.ticketSection}>
            <PrintSection storage={storage} />
          </div>
        </div>
        <div className={classes.bottomSection}>
          <BottomPrintSection storage={storage} />
        </div>
      </div>
    </div>
  )
}

PrintPage.propTypes = {
  storage: PropTypes.object.isRequired
}

export default PrintPage;
