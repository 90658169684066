import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDeliveries } from '../Hooks';
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, } from '@material-ui/styles';
import { IconButton, Typography } from '@material-ui/core';
import PreviousIcon from '@material-ui/icons/ArrowBackIos';
import NextIcon from '@material-ui/icons/ArrowForwardIos';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { firstBusinessDayOfMonth, getBusinessMonth, lastBusinessDayOfMonth } from '../helpers';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '128px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  oddRow: {
    backgroundColor: '#d0d0d0ff',
  },
  evenRow: {
    backgroundColor: '#b0b0b0ff',
  },
  salesman: {
    width: '160px',
    height: '64px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  delivery: {
    display: 'flex',
    flexDirection: 'column',
    width: '64px',
    height: '64px',
    overflow: 'hidden',
    marginRight: theme.spacing(1),
  },
  modeDelivery: {
    display: 'flex',
    flexDirection: 'column',
    width: '64px',
    height: '64px',
    overflow: 'hidden',
    marginRight: theme.spacing(1),
  },
  deliveredNew: {
    backgroundColor: 'yellow'
  },
  deliveredUsed: {
    backgroundColor: 'green'
  },
  deliveryCancelled: {
    animation: `$flashColor 3s infinite`
  },
  deliveryRow: {
    display: 'flex',
    height: '64px',
    width: '100%'
  },
  deliverySection: {
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0
  },
  futureDeliverySection: {
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0
  },
  emptySection: {
    display: 'flex',
    flexGrow: 2,
    flexShrink: 1
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    flexGrow: 1,
    flexShrink: 1
  },
  headerButton: {
    flexGrow: 0,
    flexShrink: 0
  },
  header: {
    display: 'flex'
  },
  fullWidth: {
    width: '100%'
  },
  "@keyframes flashColor": {
    "0%": {
      backgroundColor: "#ff0000a0"
    },
    "50%": {
      backgroundColor: "#ff000050"
    },
    "100%": {
      backgroundColor: "#ff0000a0"
    }
  }
}));

const Delivery = ({ d, ...others }) => {
  const classes = useStyles();

  return (
    <div
      key={d.stockNumber + d.firstName + d.lastName + d.model}
      className={classNames({
        [classes.delivery]: true,
        [classes.deliveredNew]: d.delivered && d.newVehicle && !d.cancelled,
        [classes.deliveredUsed]: d.delivered && !d.newVehicle && !d.cancelled,
        [classes.deliveryCancelled]: d.cancelled
      })}
      {...others}
    >
      <Typography variant="subtitle2" noWrap={true} color={d.cancelled ? "textSecondary" : "textPrimary"}>
        {d.stockNumber}
      </Typography>
      <Typography variant="caption" noWrap={true} color={d.cancelled ? "textSecondary" : "textPrimary"}>
        {d.model}
      </Typography>
      <Typography variant="caption" noWrap={true} color={d.cancelled ? "textSecondary" : "textPrimary"}>
        {d.firstName}
      </Typography>
      <Typography variant="caption" noWrap={true} color={d.cancelled ? "textSecondary" : "textPrimary"}>
        {d.lastName}
      </Typography>
    </div >
  )
}

const MoreDelivery = () => {
  const classes = useStyles();

  return (
    <div
      className={classes.moreDelivery}
    >
      <MoreIcon />
    </div >
  )
}

Delivery.propTypes = {
  d: PropTypes.object.isRequired
}

const Deliveries = () => {
  const now = new Date();
  const [startTime, setStartTime] = useState(firstBusinessDayOfMonth(now));
  const [endTime, setEndTime] = useState(lastBusinessDayOfMonth(now));
  const [deliveries, status] = useDeliveries(startTime, endTime);
  const classes = useStyles();
  const intl = useIntl();

  let totalSaleCount = 0;
  let totalDeliveryCount = 0;
  let totalFutureCount = 0;
  let totalNewSaleCount = 0;
  let totalUsedSaleCount = 0;
  let totalDemoCount = 0;
  let totalDeliveredDemos = 0;

  const goNextMonth = () => {
    const nextMonth = new Date(endTime.getTime() + 15 * 24 * 3600 * 1000);
    const nextStartTime = firstBusinessDayOfMonth(nextMonth);
    const nextEndTime = lastBusinessDayOfMonth(nextMonth);

    setStartTime(nextStartTime);
    setEndTime(nextEndTime);
  }

  const goPreviousMonth = () => {
    const nextMonth = new Date(startTime.getTime() - 15 * 24 * 3600 * 1000);
    const nextStartTime = firstBusinessDayOfMonth(nextMonth);
    const nextEndTime = lastBusinessDayOfMonth(nextMonth);

    setStartTime(nextStartTime);
    setEndTime(nextEndTime);
  }

  if (status === 'success') {
    const items = deliveries.map((s, idx) => {
      let saleCount = s.saleCount;
      let newSaleCount = s.newSaleCount;
      let usedSaleCount = s.usedSaleCount;
      let deliveryCount = s.deliveryCount;
      let futureCount = s.futureCount;
      let demoCount = s.demoCount;
      let deliveredDemoCount = s.demoDeliveryCount;

      const newDeliveries = s.newDeliveries.map(d => {
        return (<Delivery key={d.stockNumber + d.firstName + d.lastName + d.model} d={d} />);
      });

      const usedDeliveries = s.usedDeliveries.map(d => {
        return (<Delivery key={d.stockNumber + d.firstName + d.lastName + d.model} d={d} />);
      });

      let futureDeliveries = s.futureDeliveries.map(d => {
        return (<Delivery key={d.stockNumber + d.firstName + d.lastName + d.model} d={d} />);
      });

      if (futureDeliveries.length > 5) {
        futureDeliveries = futureDeliveries.slice(0, 5);
        futureDeliveries.push(<MoreDelivery />);
      }

      totalSaleCount += saleCount;
      totalNewSaleCount += newSaleCount;
      totalUsedSaleCount += usedSaleCount;
      totalFutureCount += futureCount;
      totalDeliveryCount += deliveryCount;
      totalDemoCount += demoCount;
      totalDeliveredDemos += deliveredDemoCount;

      return (
        <div key={s.salesmanID} className={classNames({
          [classes.row]: true,
          [classes.oddRow]: idx % 2 !== 0,
          [classes.evenRow]: idx % 2 === 0
        })} >
          <div className={classes.salesman}>
            <Typography variant="h6">
              {s.name}
            </Typography>
            <Typography variant="caption">
              {
                intl.formatMessage(
                  {
                    id: "S: {sales} / D: {deliveries} / F: {future}",
                    defaultMessage: "S: {sales} / D: {deliveries} / F: {future}"
                  },
                  {
                    sales: saleCount,
                    deliveries: deliveryCount,
                    future: futureCount
                  })
              }
            </Typography>
            <Typography variant="caption">
              {
                intl.formatMessage(
                  {
                    id: "NS: {newSales} / US: {usedSales}",
                    defaultMessage: "NS: {newSales} / US: {usedSales}"
                  },
                  {
                    newSales: newSaleCount,
                    usedSales: usedSaleCount
                  })
              }
            </Typography>
          </div>

          <div className={classes.fullWidth}>
            <div className={classes.deliveryRow}>
              <div className={classes.deliverySection}>
                {newDeliveries}
              </div>

              <div className={classes.emptySection}>

              </div>

              <div className={classes.futureDeliverySection}>
                {futureDeliveries}
              </div>
            </div>

            <div className={classes.deliveryRow}>
              <div className={classes.deliverySection}>
                {usedDeliveries}
              </div>
            </div>
          </div>
        </div >
      );
    });

    return (
      <div>
        <div className={classes.header}>
          <IconButton onClick={goPreviousMonth}>
            <PreviousIcon />
          </IconButton>
          <div className={classes.title}>
            <Typography variant="h4">
              {intl.formatMessage(
                {
                  id: "Sale Board for the month of {month} {year}",
                  defaultMessage: "Sale Board for the month of {month} {year}"
                },
                {
                  month: getBusinessMonth(startTime, intl),
                  year: startTime.getFullYear()
                }
              )}
            </Typography>
          </div>
          <IconButton onClick={goNextMonth}>
            <NextIcon />
          </IconButton>
        </div>
        <div>
          {items}

          <div className={classes.salesman}>
            <Typography variant="h6">
              {
                intl.formatMessage({
                  id: "Total",
                  defaultMessage: "Total"
                })
              }
            </Typography>
            <Typography variant="caption">
              {
                intl.formatMessage(
                  {
                    id: "S: {sales}({demos}) / D: {deliveries} / F: {future}",
                    defaultMessage: "S: {sales}({demos}) / D: {deliveries}({deliveredDemos}) / F: {future}"
                  },
                  {
                    sales: totalSaleCount,
                    demos: totalDemoCount,
                    deliveries: totalDeliveryCount,
                    deliveredDemos: totalDeliveredDemos,
                    future: totalFutureCount
                  })
              }
            </Typography>
            <Typography variant="caption">
              {
                intl.formatMessage(
                  {
                    id: "NS: {newSales} / US: {usedSales}",
                    defaultMessage: "NS: {newSales} / US: {usedSales}"
                  },
                  {
                    newSales: totalNewSaleCount,
                    usedSales: totalUsedSaleCount
                  })
              }
            </Typography>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <CircularProgress
          size={24}
          color="primary"
        />
      </div>
    )
  }
}

export default Deliveries;
