import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import YearListItem from './YearListItem';

const useStyles = makeStyles(theme => ({
    list: {
        margin: theme.spacing(1),
        listStyleType: 'none',
        paddingInlineStart: 0
    }
}));

const YearList = ({ years, onSelect, selectedItem, ...others }) => {
    const classes = useStyles();

    const items = years.map(y => {
        return (
            <YearListItem
                key={y.year}
                year={y}
                onSelect={() => { onSelect && onSelect(y); }}
                selected={y.year === selectedItem}
            />
        );
    })

    return (
        <ul className={classes.list} {...others}>
            {items}
        </ul>
    )
}

YearList.propTypes = {
    years: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    selectedItem: PropTypes.number
}

export default YearList;