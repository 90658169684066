import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Filter from './Filter';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
  rootDiv: {
    display: 'flex'
  },
  filter: {
    margin: theme.spacing(1)
  }
}));

const BasicSearch = ({ ownCustomers, onOwnCustomersChange, onFilterChange, ...others }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.rootDiv} {...others}>
      <FormControlLabel
        control={
          <Checkbox
            checked={ownCustomers}
            onChange={(event) => { onOwnCustomersChange(event.target.checked) }}
            value={ownCustomers}
          />
        }
        label={intl.formatMessage({
          id: "Own Customers",
          defaultMessage: "Own Customers"
        })}
      />
      <Filter
        onChange={onFilterChange}
        className={classes.filter}
      />
    </div>
  );
}

BasicSearch.propTypes = {
  onOwnCustomersChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  ownCustomers: PropTypes.bool
}

export default BasicSearch;
