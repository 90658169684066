import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as API from '../../backend';
import { wait } from '../../helpers';
import NewStockViewer from './NewStockViewer';
import ModelEditorUI from '../Models/ModelEditorUI';
import ModelViewer from '../Models/ModelViewer';
import ColorEditorUI from '../Colors/ColorEditorUI';
import ColorViewer from '../Colors/ColorViewer';
import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
    main: {
        display: 'flex'
    }
}));

const NewStockController = ({ stock, onStockVehicle, ...others }) => {
    const [model, setModel] = useState(null);
    const [color, setColor] = useState(null);
    const classes = useStyles();
    const snackbar = useSnackbar();
    const intl = useIntl();

    const changeModel = m => {
        setModel(m);
    };

    const saveModel = async m => {
        try {
            const savedModel = await API.saveModel(m);
            setModel(savedModel);
            return savedModel;
        }
        catch (error) {
            snackbar.enqueueSnackbar(
                intl.formatMessage({
                    id: "Error while saving model.",
                    defaultMessage: "Error while saving model."
                }),
                {
                    variant: 'error'
                }
            );
        }
    };

    const changeColor = c => {
        setColor(c);
    };

    const saveColor = async c => {
        try {
            const savedColor = await API.saveColor(c);
            setColor(savedColor);
            return savedColor;
        }
        catch (error) {
            snackbar.enqueueSnackbar(
                intl.formatMessage({
                    id: "Error while saving color.",
                    defaultMessage: "Error while saving color."
                }),
                {
                    variant: 'error'
                }
            );
        }
    };

    useEffect(() => {
        const cancelSource = API.getCancelSource();

        const loadModel = async () => {
            try {
                const m = await API.getModel(stock.model, cancelSource);
                setModel(m);
            }
            catch (error) {
                await wait(5000);
                loadModel();
            }
        }

        loadModel();

        return () => {
            cancelSource.cancel();
        }
    }, [stock.model]);

    useEffect(() => {
        const cancelSource = API.getCancelSource();

        const loadColor = async () => {
            try {
                const c = await API.getColor(stock.color, cancelSource);
                setColor(c);
            }
            catch (error) {
                await wait(5000);
                loadColor();
            }
        }

        loadColor();

        return () => {
            cancelSource.cancel();
        }
    }, [stock.color]);

    return (
        <div className={classes.main} {...others}>
            <NewStockViewer
                stock={stock}
                onStockVehicle={onStockVehicle}
            />
            {model !== null && model.userEdited === false && (
                <ModelEditorUI model={model}
                    onSave={saveModel}
                    onChange={changeModel}
                />
            )}
            {model !== null && model.userEdited === true && (
                <ModelViewer model={model} />
            )}
            {color != null && color.userEdited === false && (
                <ColorEditorUI color={color}
                    onChange={changeColor}
                    onSave={saveColor}
                />
            )}
            {color != null && color.userEdited === true && (
                <ColorViewer color={color} />
            )}
        </div>
    )
}

NewStockController.propTypes = {
    stock: PropTypes.object,
    onStockVehicle: PropTypes.func
}

export default NewStockController;