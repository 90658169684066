import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DeliveryIcon = (props, ref) => {
  return (
    <SvgIcon ref={ref} {...props}>
      <g id="Layer_1">
        <path d="M7.312,17.344 C7.054,17.344 6.844,17.554 6.844,17.813 C6.844,18.071 7.054,18.281 7.312,18.281 C7.571,18.281 7.781,18.071 7.781,17.813 C7.781,17.554 7.571,17.344 7.312,17.344 z" />
        <path d="M21.1,8.906 L23.863,6.144 C23.979,6.028 24.026,5.859 23.986,5.7 C23.947,5.54 23.827,5.413 23.67,5.365 L16.943,3.289 C16.337,1.339 14.534,0 12.469,0 C10.549,0 8.831,1.17 8.12,2.936 L0.329,5.365 C0.173,5.414 0.053,5.541 0.014,5.7 C-0.026,5.86 0.021,6.028 0.137,6.144 L2.972,8.979 L1.184,11.959 C1.111,12.081 1.097,12.23 1.147,12.363 C1.196,12.496 1.303,12.6 1.438,12.645 L4.031,13.509 L4.031,20.719 C4.031,20.914 4.152,21.089 4.335,21.158 C5.921,21.752 11.812,23.962 11.844,23.973 C11.937,24.006 12.042,24.01 12.144,23.977 C12.145,23.977 12.147,23.976 12.148,23.976 L20.586,21.163 C20.777,21.1 20.906,20.921 20.906,20.719 L20.906,13.316 L23.672,12.447 C23.828,12.398 23.947,12.271 23.986,12.112 C24.025,11.953 23.979,11.785 23.863,11.669 L21.1,8.906 z M17.156,4.688 C17.156,4.568 17.151,4.45 17.142,4.331 L22.65,6.031 L20.311,8.37 L16.489,7.096 C16.922,6.373 17.156,5.538 17.156,4.688 z M8.922,3.468 C9.442,1.954 10.867,0.938 12.469,0.938 C14.194,0.938 15.69,2.104 16.107,3.775 C16.181,4.072 16.219,4.379 16.219,4.688 C16.219,5.558 15.93,6.374 15.383,7.048 C14.667,7.931 13.605,8.438 12.469,8.438 C11.241,8.438 10.089,7.835 9.387,6.825 C8.95,6.196 8.719,5.457 8.719,4.688 C8.719,4.269 8.787,3.858 8.922,3.468 z M1.348,6.029 L7.831,4.008 C7.799,4.232 7.781,4.459 7.781,4.688 C7.781,5.442 7.96,6.173 8.301,6.833 L3.689,8.37 L1.348,6.029 z M2.289,11.94 L3.771,9.47 L11.297,11.979 L9.815,14.449 C9.012,14.181 3.147,12.226 2.289,11.94 z M11.531,22.855 L4.969,20.394 L4.969,13.822 C10.132,15.534 9.88,15.482 10.024,15.482 C10.185,15.482 10.339,15.398 10.426,15.254 L11.531,13.411 L11.531,22.855 z M12,11.225 L5.045,8.906 L8.832,7.644 C9.718,8.734 11.053,9.375 12.469,9.375 C13.778,9.375 15.009,8.836 15.895,7.886 L18.955,8.906 L12,11.225 z M19.969,20.381 L12.469,22.881 L12.469,13.055 L14.13,15.132 C14.251,15.283 14.453,15.344 14.637,15.286 L19.969,13.611 L19.969,20.381 z M14.662,14.296 L12.786,11.951 L20.311,9.443 L22.653,11.785 L14.662,14.296 z" />
        <path d="M10.29,18.384 L9.247,17.993 C9.005,17.903 8.734,18.025 8.644,18.268 C8.553,18.51 8.675,18.78 8.918,18.871 L9.96,19.262 C10.203,19.353 10.473,19.23 10.564,18.988 C10.655,18.745 10.532,18.475 10.29,18.384 z" />
        <path d="M10.29,20.387 L7.477,19.332 C7.235,19.241 6.964,19.364 6.874,19.606 C6.783,19.849 6.905,20.119 7.148,20.21 L9.96,21.265 C10.015,21.285 10.07,21.295 10.125,21.295 C10.315,21.295 10.493,21.179 10.564,20.99 C10.655,20.748 10.532,20.478 10.29,20.387 z" />
        <path d="M15.144,2.95 C14.961,2.767 14.664,2.767 14.481,2.95 L12,5.431 L11.394,4.825 C11.211,4.642 10.914,4.642 10.731,4.825 C10.548,5.008 10.548,5.305 10.731,5.488 L11.668,6.425 C11.852,6.608 12.148,6.608 12.331,6.425 L15.144,3.613 C15.327,3.43 15.327,3.133 15.144,2.95 z" />
      </g>
    </SvgIcon>
  );
}

export default React.forwardRef(DeliveryIcon);
