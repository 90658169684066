import { Record, List } from 'immutable';

export const AccessRight = {
  DataEntry: 'dataEntry',
  Emails: 'emails',
  FollowUp: 'followup',
  FollowUpMaps: 'followup_maps',
  FAndI: 'f&i',
  ImportFollowUp: 'followup_import',
  Manager: 'manager',
  Reports: 'reports',
  Salesman: 'salesman',
  Settings: 'settings',
  Shutdown: 'shutdown',
  Storage: 'storages',
  Customers: 'customers',
  Loaners: 'loaners',
  Vehicles: 'vehicles',
  Users: 'users',
  SaveStorage: 'service.storage.save',
  DeleteStorage: 'service.storage.delete',
  StorageHistory: 'service.storage.history',
  SaveVehicle: 'service.vehicle.save',
  DeleteVehicle: 'service.vehicle.delete',
  Reservations: 'reservations',
  SaveReservation: 'service.reservation.save',
  DeleteReservation: 'service.reservation.delete',
  AccountingModelSave: 'accounting.model.save',
  AccountingModelDelete: 'accounting.model.delete',
  AccountingModels: "models",
  AccountingColorSave: 'accounting.color.save',
  AccountingColorDelete: 'accounting.color.delete',
  AccountingColors: 'colors',
  AccountingNewStockSave: 'accounting.newstock.save',
  AccountingNewStockDelete: 'accounting.newstock.delete',
  AccountingNewStocks: 'newstocks'
};

export const Departments = {
  Sales: 'sales',
  Service: 'service',
  Accounting: 'accounting'
};

export class User extends Record({
  id: null,
  active: true,
  firstName: '',
  lastName: '',
  email: '',
  cellPhone: '',
  homePhone: '',
  language: 'fr',
  pushOverKey: '',
  password: '',
  creationTimestamp: 0,
  lastLoginTimestamp: 0,
  loginAttemps: 0,
  emailSignature: '',
  accessRights: List(),
  expoPushToken: '',
  defaultDepartment: '',
  defaultPrinter: ''
}) {
  /**
   * Check if the user is valid.
   * @returns {boolean} - True is the user is valid, false otherwise.
   */
  isValid() {
    return this.firstName.trim().length > 0 &&
      this.lastName.trim().length > 0 &&
      this.email.trim().length > 0;
  }
}
