import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { useIntl } from 'react-intl';
import FilteredModelList from './FilteredModelList';

const ModelSelectorDialog = ({ models, selection, onChange, ...others }) => {
  const intl = useIntl();

  return (
    <Dialog {...others}>
      <DialogTitle>
        {
          intl.formatMessage({
            id: "Select Model",
            defaultMessage: "Select Model"
          })
        }
      </DialogTitle>
      <DialogContent>
        <FilteredModelList
          models={models}
          onSelect={onChange}
          selection={selection}
        />
      </DialogContent>
    </Dialog>
  )
}

ModelSelectorDialog.propTypes = {
  models: PropTypes.array.isRequired,
  selection: PropTypes.string,
  onChange: PropTypes.func
}

export default ModelSelectorDialog;
