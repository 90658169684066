import React, { useState } from 'react';

import StockFileUploader from './StockFileUploader';
import NewStockNavigator from './NewStockNavigator';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import * as API from '../../backend';
import { getUser } from "../../DataStore/Login/Reducer";
import { useSelector } from 'react-redux';

const NewStockFileController = () => {
    const [stocks, setStocks] = useState([]);
    const [currentStock, setCurrentStock] = useState(0);
    const snackbar = useSnackbar();
    const intl = useIntl();
    const currentUser = useSelector(getUser);
    const [printer, setPrinter] = useState(currentUser.defaultPrinter);

    const fileUploaded = (s) => {
        setStocks(s);
        setCurrentStock(0);
    }

    const goNext = () => {
        setCurrentStock((value) => {
            if (value < stocks.length - 1) {
                return value + 1;
            }

            return value;
        });
    }

    const goBack = () => {
        setCurrentStock((value) => {
            if (value > 0) {
                return value - 1;
            }

            return value;
        })
    }

    const stockVehicle = async (stock) => {
        try {
            const result = await API.stockNewVehicle(stock, printer);
            const newStocks = stocks.map(s => {
                if (s.vin == result.vin) {
                    return result;
                }

                return s;
            });
            setStocks(newStocks);
        }
        catch (error) {
            snackbar.enqueueSnackbar(
                intl.formatMessage({
                    id: "Error while stocking vehicle.",
                    defaultMessage: "Error while stocking vehicle."
                }),
                {
                    variant: 'error'
                }
            );
        }
    }

    return (
        <React.Fragment>
            {stocks.length === 0 && (
                <StockFileUploader
                    onFileUploaded={fileUploaded}
                />
            )}
            {stocks.length > 0 && (
                <NewStockNavigator
                    stocks={stocks}
                    index={currentStock}
                    onNext={goNext}
                    onBack={goBack}
                    onStockVehicle={stockVehicle}
                    printer={printer}
                    onPrinterChange={(p) => { setPrinter(p); }}
                />
            )}
        </React.Fragment>
    );
}

export default NewStockFileController;
