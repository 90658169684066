import React from 'react';

import ReservationButton from './ToolbarButtons/ReservationButton';
import StorageButton from './ToolbarButtons/StorageButton';
import VehicleButton from './ToolbarButtons/VehicleButton';
import ServiceReportButton from './ToolbarButtons/ServiceReportButton';

const ServiceButtons = () => {
  return (
    <React.Fragment>
      <ReservationButton />
      <StorageButton />
      <VehicleButton />
      <ServiceReportButton />
    </React.Fragment>
  )
}

export default ServiceButtons;
