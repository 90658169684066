import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';

const ReportHeader = ({ title, startTime, endTime, ...others }) => {
  const intl = useIntl();

  return (
    <div {...others}>
      <Typography variant="h4">
        {intl.formatMessage({
          id: "Report: {name}",
          defaultMessage: "Report: {name}"
        }, { name: title })}
      </Typography>
      <Typography variant="h6">
        {intl.formatMessage({
          id: "Starts: {startTime, date, medium}",
          defaultMessage: "Starts: {startTime, date, medium}"
        }, { startTime: startTime })}
      </Typography>
      <Typography variant="h6">
        {intl.formatMessage({
          id: "Ends: {endTime, date, medium}",
          defaultMessage: "Ends: {endTime, date, medium}"
        }, { endTime: endTime })}
      </Typography>
    </div>
  );
}

ReportHeader.propTypes = {
  title: PropTypes.string,
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date)
};

export default ReportHeader;
