import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import StorageItem from './StorageItem';

const useStyles = makeStyles(() => ({
  list: {
    listStyleType: 'none',
    padding: 0,
  }
}));

const StorageList = ({ storages, onEdit, onPrint, ...others }) => {
  const classes = useStyles();

  const items = storages.map(storage => {
    return (
      <StorageItem storage={storage} key={storage.id} onEdit={onEdit} onPrint={onPrint} />
    )
  });

  return (
    <ul className={classes.list} {...others}>
      {items}
    </ul>
  )
}

StorageList.propTypes = {
  storages: PropTypes.array,
  onEdit: PropTypes.func,
  onPrint: PropTypes.func
}

StorageList.defaultProps = {
  storages: []
}

export default StorageList;
