import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ReportsIcon from "@material-ui/icons/Assessment";
import { PageId } from "../../DataStore/MainPage/Types";

const ReportButton = ({ setPage, currentPage, ...others }) => {
  return (
    <IconButton
      color="inherit"
      aria-label="Reports"
      disabled={currentPage === PageId.Reports}
      onClick={() => {
        setPage(PageId.Reports);
      }}
      {...others}
    >
      <ReportsIcon />
    </IconButton>
  );
}

ReportButton.propTypes = {
  setPage: PropTypes.func,
  currentPage: PropTypes.string
}

export default ReportButton;
