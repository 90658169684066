import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { dateToText } from "../../helpers";

const useStyles = makeStyles((theme) => ({
    editor: {
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
    line: {
        marginTop: theme.spacing(1)
    },
    inputField: {
        width: '20em'
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2)
    },
}));

const NewStockEditorUI = ({ stock, onStockVehicle, ...others }) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Paper className={classes.editor} {...others}>
            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {intl.formatMessage({
                        id: "Serial Number",
                        defaultMessage: "Serial Number"
                    })}
                </Typography>
                <Typography>
                    {stock.vin}
                </Typography>
            </div>

            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {
                        intl.formatMessage({
                            id: "Year",
                            defaultMessage: "Year"
                        })
                    }
                </Typography>
                <Typography>
                    {stock.year}
                </Typography>
            </div>

            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {
                        intl.formatMessage({
                            id: "Stock Number",
                            defaultMessage: "Stock Number"
                        })
                    }
                </Typography>
                <Typography>
                    {stock.stockNumber}
                </Typography>
            </div>

            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {
                        intl.formatMessage({
                            id: "Purchased From",
                            defaultMessage: "Purchased From"
                        })
                    }
                </Typography>
                <Typography>
                    {stock.boughtFrom}
                </Typography>
            </div>

            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {
                        intl.formatMessage({
                            id: "Allocation Date",
                            defaultMessage: "Allocation Date"
                        })
                    }
                </Typography>
                <Typography>
                    {dateToText(new Date(stock.dateAllocated * 1000))}
                </Typography>
            </div>

            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {
                        intl.formatMessage({
                            id: "Expected Date",
                            defaultMessage: "Expected Date"
                        })
                    }
                </Typography>
                <Typography>
                    {dateToText(new Date(stock.expectedDate * 1000))}
                </Typography>
            </div>

            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {intl.formatMessage({
                        id: "DeliveryDate",
                        defaultMessage: "DeliveryDate"
                    })}
                </Typography>
                <Typography>
                    {dateToText(new Date(stock.deliveryDate * 1000))}
                </Typography>
            </div>

            <div className={classes.line}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {intl.formatMessage({
                        id: "Trade In Date",
                        defaultMessage: "Trade In Date"
                    })}
                </Typography>
                <Typography>
                    {dateToText(new Date(stock.tradeInDate * 1000))}
                </Typography>
            </div>

            <div className={classes.buttonDiv}>
                <Button
                    onClick={() => onStockVehicle(stock)}
                    variant="contained"
                    disabled={stock.stockNumber !== ""}
                >
                    {intl.formatMessage({
                        id: "Stock",
                        defaultMessage: "Stock"
                    })}
                </Button>
            </div>
        </Paper>
    )
}

NewStockEditorUI.propTypes = {
    stock: PropTypes.object,
    onStockVehicle: PropTypes.func
}

export default NewStockEditorUI;
