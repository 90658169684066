import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import VisitItem from "./VisitItem";

const useStyles = makeStyles(() => ({
  list: {
    width: "100%"
  }
}));

const VisitList = ({ onEditVisit, canEditVisit, visits, ...others }) => {
  const classes = useStyles();

  const handleEdition = (index, visit) => {
    if (onEditVisit) {
      onEditVisit(index, visit);
    }
  }

  const visitList = visits.map((visit, index) => {
    return (
      <VisitItem
        key={index}
        visit={visit}
        canEditVisit={canEditVisit}
        onEdit={() => {
          handleEdition(index, visit);
        }}
      />
    );
  });

  return (
    <List
      className={classes.list}
      {...others}
    >
      {visitList}
    </List>
  );
}

VisitList.propTypes = {
  visits: PropTypes.array.isRequired,
  canEditVisit: PropTypes.bool,
  onEditVisit: PropTypes.func
};

export default VisitList;
