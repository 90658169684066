import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const WalkinIcon = ({ ...others }) => {
  return (
    <SvgIcon {...others}>
      <g id="Layer_1">
        <g>
          <path d="M6.545,20.441 C6.186,21.053 6.207,21.811 6.823,22.266 C7.349,22.655 8.288,22.605 8.65,21.991 C9.394,20.718 10.745,19.762 10.745,19.762 C10.745,19.762 10.248,18.666 10.012,16.611 C10.012,16.612 7.395,18.993 6.545,20.441 z M6.545,20.441" />
          <path d="M17.177,9.384 C15.647,8.022 14.007,6.801 12.213,5.818 C12.019,5.672 11.797,5.558 11.564,5.481 C11.409,5.418 11.256,5.387 11.109,5.384 C10.862,5.355 10.615,5.368 10.389,5.429 C9.792,5.589 9.388,5.954 9.159,6.419 C8.053,7.71 7.129,9.115 6.3,10.597 C5.478,12.071 7.733,13.387 8.556,11.916 C8.812,11.456 9.078,11.001 9.354,10.557 C9.593,12.164 9.83,13.774 10.07,15.381 C10.117,15.706 10.272,15.987 10.488,16.215 C10.835,18.505 11.472,20.762 12.475,22.84 C13.205,24.356 15.457,23.032 14.73,21.522 C13.98,19.97 13.479,18.25 13.171,16.519 C13.848,16.046 14.095,15.195 13.967,14.336 C13.731,12.748 13.498,11.16 13.261,9.572 C13.974,10.086 14.664,10.639 15.329,11.231 C16.584,12.346 18.438,10.506 17.177,9.384 z M17.177,9.384" />
        </g>
        <path d="M13.188,2.529 C13.188,3.786 12.168,4.806 10.911,4.806 C9.654,4.806 8.637,3.786 8.637,2.529 C8.637,1.274 9.654,0.254 10.911,0.254 C12.168,0.254 13.188,1.274 13.188,2.529 z M13.188,2.529" />
      </g>
    </SvgIcon>
  );
}

export const PhoneIcon = ({ ...others }) => {
  return (
    <SvgIcon {...others}>
      <defs>
        <clipPath id="Clip_1">
          <path d="M0.003,0 L24,0 L24,24 L0.003,24 z" />
        </clipPath>
      </defs>
      <g id="Layer_1">
        <g clipPath="url(#Clip_1)">
          <path d="M20.848,21.017 L16.145,16.315 L17.368,15.092 L22.071,19.794 C22.071,19.794 20.848,21.018 20.848,21.017 z M19.173,21.639 C19.173,21.639 19.173,21.639 19.173,21.639 C18.137,22.674 16.307,22.845 14.019,22.12 C11.569,21.344 8.953,19.649 6.652,17.348 C4.351,15.047 2.656,12.431 1.88,9.981 C1.155,7.693 1.326,5.862 2.361,4.827 L2.361,4.827 L2.491,4.697 L6.141,8.346 L5.806,8.681 C5.806,8.681 5.806,8.681 5.805,8.682 C4.419,10.07 6.085,13.189 8.448,15.552 C10.217,17.321 12.409,18.699 13.982,18.699 C14.511,18.699 14.97,18.543 15.319,18.194 C15.319,18.194 15.319,18.194 15.319,18.193 L15.654,17.859 L19.303,21.509 L19.173,21.639 z M4.206,1.929 L8.908,6.632 L7.686,7.856 C7.686,7.856 7.686,7.855 7.685,7.855 L2.983,3.152 L4.206,1.929 z M18.386,14.074 C17.825,13.512 16.911,13.512 16.35,14.074 L15.127,15.297 C14.744,15.68 14.623,16.227 14.763,16.714 L14.301,17.175 C14.301,17.176 14.301,17.176 14.301,17.176 C13.973,17.504 11.933,17 9.466,14.534 C7,12.068 6.496,10.027 6.824,9.699 C6.824,9.699 6.824,9.699 6.824,9.699 L7.286,9.237 C7.416,9.275 7.551,9.294 7.685,9.294 C8.054,9.294 8.423,9.154 8.703,8.873 L9.926,7.65 C10.487,7.089 10.487,6.175 9.926,5.614 L5.224,0.911 C4.952,0.639 4.59,0.49 4.206,0.49 C3.821,0.49 3.46,0.639 3.188,0.911 L1.965,2.134 C1.581,2.517 1.46,3.065 1.6,3.552 C1.6,3.552 1.209,3.947 1.19,3.972 C-0.111,5.418 -0.355,7.694 0.507,10.416 C1.352,13.081 3.172,15.905 5.634,18.366 C8.095,20.827 10.918,22.648 13.584,23.493 C14.654,23.832 15.656,24 16.565,24 C18.057,24 19.3,23.548 20.191,22.657 L20.448,22.4 C20.576,22.437 20.71,22.457 20.848,22.457 C21.232,22.457 21.594,22.307 21.866,22.035 L23.089,20.812 C23.65,20.251 23.65,19.338 23.089,18.776 L18.386,14.074" />
          <path d="M18.909,10.9 C18.909,11.297 19.231,11.62 19.629,11.62 C20.026,11.62 20.349,11.297 20.349,10.9 C20.349,8.964 19.595,7.143 18.226,5.774 C16.857,4.405 15.036,3.651 13.1,3.651 C12.703,3.651 12.38,3.973 12.38,4.371 C12.38,4.769 12.703,5.091 13.1,5.091 C14.652,5.091 16.111,5.695 17.208,6.792 C18.305,7.889 18.909,9.348 18.909,10.9" />
          <path d="M20.808,3.193 C18.749,1.134 16.011,-0 13.1,-0 C12.702,-0 12.38,0.322 12.38,0.72 C12.38,1.117 12.703,1.44 13.1,1.44 C15.627,1.44 18.003,2.424 19.789,4.21 C21.576,5.997 22.56,8.373 22.56,10.9 C22.56,11.297 22.883,11.62 23.28,11.62 C23.678,11.62 24,11.297 24,10.9 C24,7.988 22.866,5.251 20.808,3.193" />
        </g>
        <path d="M13.1,7.302 C12.703,7.302 12.381,7.625 12.381,8.022 C12.381,8.42 12.703,8.742 13.1,8.742 C13.101,8.742 13.101,8.742 13.101,8.742 C13.693,8.742 14.207,8.955 14.626,9.374 C15.039,9.787 15.258,10.315 15.258,10.899 C15.258,11.297 15.58,11.619 15.978,11.619 L15.978,11.619 C16.375,11.619 16.697,11.297 16.697,10.9 C16.698,9.925 16.333,9.045 15.644,8.356 C14.955,7.667 14.075,7.302 13.101,7.302 C13.101,7.302 13.1,7.302 13.1,7.302" />
      </g>
    </SvgIcon>
  )
}

export const EditIcon = ({ ...others }) => {
  return (
    <SvgIcon {...others}>
      <defs>
        <clipPath id="Clip_1">
          <path d="M0.002,0.052 L24,0.052 L24,23.946 L0.002,23.946 z" />
        </clipPath>
      </defs>
      <g id="Layer_1">
        <g clipPath="url(#Clip_1)">
          <path d="M14.91,4.069 L19.788,8.946 L7.441,21.293 L2.566,16.416 z M23.489,2.892 L21.314,0.717 C20.473,-0.124 19.108,-0.124 18.265,0.717 L16.181,2.801 L21.059,7.678 L23.489,5.248 C24.141,4.596 24.141,3.544 23.489,2.892 z M0.014,23.272 C-0.075,23.671 0.285,24.029 0.685,23.932 L6.12,22.614 L1.245,17.736 z M0.014,23.272" />
        </g>
      </g>
    </SvgIcon>
  )
}

export const SaveIcon = ({ ...others }) => {
  return (
    <SvgIcon {...others}>
      <defs>
        <clipPath id="Clip_1">
          <path d="M0.003,0 L24,0 L24,24 L0.003,24 z" />
        </clipPath>
      </defs>
      <g id="Layer_1">
        <g clipPath="url(#Clip_1)">
          <path d="M22.594,21.891 C22.594,22.278 22.278,22.594 21.891,22.594 L19.734,22.594 L19.734,13.453 C19.734,12.29 18.788,11.344 17.625,11.344 L6.375,11.344 C5.212,11.344 4.266,12.29 4.266,13.453 L4.266,22.594 L2.109,22.594 C1.722,22.594 1.406,22.278 1.406,21.891 L1.406,2.109 C1.406,1.722 1.722,1.406 2.109,1.406 L4.266,1.406 L4.266,6.328 C4.266,7.491 5.212,8.438 6.375,8.438 L14.812,8.438 C15.976,8.438 16.922,7.491 16.922,6.328 L16.922,1.406 L17.043,1.406 C17.23,1.406 17.407,1.479 17.542,1.615 L22.388,6.413 C22.521,6.546 22.594,6.723 22.594,6.911 L22.594,21.891 z M5.672,15.562 L18.328,15.562 L18.328,22.594 L5.672,22.594 z M18.328,14.156 L5.672,14.156 L5.672,13.453 C5.672,13.065 5.987,12.75 6.375,12.75 L17.625,12.75 C18.013,12.75 18.328,13.065 18.328,13.453 L18.328,14.156 z M9.891,1.406 L9.891,4.922 C9.891,5.31 10.205,5.625 10.594,5.625 L13.406,5.625 C13.795,5.625 14.109,5.31 14.109,4.922 L14.109,1.406 L15.516,1.406 L15.516,6.328 C15.516,6.716 15.2,7.031 14.812,7.031 L6.375,7.031 C5.987,7.031 5.672,6.716 5.672,6.328 L5.672,1.406 L9.891,1.406 z M11.297,1.406 L12.703,1.406 L12.703,4.219 L11.297,4.219 z M23.38,5.417 L18.534,0.618 C18.136,0.219 17.606,0 17.043,0 C14.247,0 5.527,0 2.109,0 C0.946,0 0,0.946 0,2.109 L0,21.891 C0,23.054 0.946,24 2.109,24 C2.904,24 21.047,24 21.891,24 C23.054,24 24,23.054 24,21.891 L24,6.911 C24,6.347 23.781,5.817 23.38,5.417" />
        </g>
        <path d="M16.219,16.969 L7.781,16.969 C7.393,16.969 7.078,17.284 7.078,17.672 C7.078,18.06 7.393,18.375 7.781,18.375 L16.219,18.375 C16.607,18.375 16.922,18.06 16.922,17.672 C16.922,17.284 16.607,16.969 16.219,16.969" />
        <path d="M14.812,19.781 L9.188,19.781 C8.799,19.781 8.484,20.096 8.484,20.484 C8.484,20.873 8.799,21.188 9.188,21.188 L14.812,21.188 C15.201,21.188 15.516,20.873 15.516,20.484 C15.516,20.096 15.201,19.781 14.812,19.781" />
      </g>
    </SvgIcon>
  )
}

export const CancelIcon = ({ ...others }) => {
  return (
    <SvgIcon {...others}>
      <defs>
        <clipPath id="Clip_1">
          <path d="M0.006,0.004 L24,0.004 L24,24 L0.006,24 z" />
        </clipPath>
      </defs>
      <g id="Layer_1">
        <g clipPath="url(#Clip_1)">
          <path d="M12,0 C5.383,0 0,5.383 0,12 C0,18.617 5.383,24 12,24 C18.617,24 24,18.617 24,12 C24,5.383 18.617,0 12,0 z M12,22.033 C6.468,22.033 1.967,17.532 1.967,12 C1.967,6.468 6.468,1.967 12,1.967 C17.532,1.967 22.033,6.468 22.033,12 C22.033,17.532 17.532,22.033 12,22.033 z M12,22.033" />
        </g>
        <path d="M13.391,12 L16.785,8.606 C17.169,8.222 17.169,7.599 16.785,7.215 C16.401,6.831 15.778,6.831 15.394,7.215 L12,10.609 L8.606,7.215 C8.222,6.831 7.6,6.831 7.215,7.215 C6.831,7.599 6.831,8.222 7.215,8.606 L10.609,12 L7.215,15.394 C6.831,15.778 6.831,16.401 7.215,16.785 C7.407,16.977 7.659,17.073 7.911,17.073 C8.162,17.073 8.414,16.977 8.606,16.785 L12,13.391 L15.394,16.785 C15.586,16.977 15.838,17.073 16.09,17.073 C16.341,17.073 16.593,16.977 16.785,16.785 C17.169,16.401 17.169,15.778 16.785,15.394 z M13.391,12" />
      </g>
    </SvgIcon>
  )
}
