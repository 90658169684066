import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const StopIcon = (props, ref) => {
  return (
    <SvgIcon ref={ref} {...props}>
      <g transform="matrix(0.046875,0,0,0.046875,0,0)">
        <path d="M361.613,0L150.387,0L0,150.387L0,361.613L150.387,512L361.613,512L512,361.613L512,150.387L361.613,0ZM451,336.758L336.758,451L175.242,451L61,336.758L61,175.242L175.242,61L336.758,61L451,175.242L451,336.758Z" style={{ fillRule: 'nonzero' }} />
      </g>
      <g transform="matrix(0.046875,0,0,0.046875,0,0)">
        <path d="M187.664,91L91,187.664L91,324.336L187.664,421L324.336,421L421,324.336L421,187.664L324.336,91L187.664,91Z" style={{ fillRule: 'nonzero' }} />
      </g>
    </SvgIcon>
  );
}

export default React.forwardRef(StopIcon);
