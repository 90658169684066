import React from 'react';
import { useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ValidatedTextField from '../../UI/ValidatedTextField';
import SalesmanTurnOrder from './SalesmanTurnOrder';

import { users as getUsers, settings as getSettings } from '../../DataStore/Settings/Settings/Reducer';
import { changeSettings as _changeSettings } from '../../DataStore/Settings/Settings/Actions';
import ModelList from "./ModelList/ModelList";
import { useSelector, useDispatch } from 'react-redux';

const GeneralSettings = ({ ...others }) => {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const settings = useSelector(getSettings);
  const intl = useIntl();

  const changeSettings = (name, value) => {
    return dispatch(_changeSettings('', name, value));
  }

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (changeSettings) {
      changeSettings(name, value);
    }
  }

  const handleLongevityChange = (event) => {
    const target = event.target;
    const value = target.value * 24 * 60 * 60;
    const name = target.name;

    if (changeSettings) {
      changeSettings(name, value);
    }
  }

  const longevityToDays = (seconds) => {
    return seconds / 24 / 60 / 60;
  }

  return (
    <Grid container direction="column" spacing={2} {...others}>
      <Grid item>
        <ValidatedTextField
          name="customerLongevity"
          value={longevityToDays(settings.customerLongevity)}
          label={intl.formatMessage({
            id: "Customer Longevity",
            defaultMessage: "Customer Longevity"
          })}
          margin="normal"
          fullWidth={true}
          onChange={handleLongevityChange}
          validator={/^\d+$/}
        />
        <TextField
          name="pushOverKey"
          value={settings.pushOverKey}
          label={intl.formatMessage({
            id: 'Push Over Key',
            defaultMessage: "Push Over Key"
          })}
          margin="normal"
          fullWidth={true}
          onChange={handleChange}
          validator={/^\S*$/}
        />
      </Grid>
      <Grid item>
        <SalesmanTurnOrder
          name="salesmanTurnOrder"
          users={users}
          value={settings.salesmanTurnOrder}
          onChange={changeSettings}
        />
      </Grid>
      <Grid item>
        <ModelList
          name="vehicleModels"
          value={settings.vehicleModels}
          onChange={changeSettings}
        />
      </Grid>
    </Grid>
  )
}

export default GeneralSettings;
