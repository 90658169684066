import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { uploadNewStockFile } from '../../backend/stocks';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    spacer: {
        height: '64px'
    }
}));

const StockFileUploader = ({ onFileUploaded, ...others }) => {
    const intl = useIntl();
    const fileInput = useRef(null);
    const classes = useStyles();
    const snackbar = useSnackbar();

    const preventDefault = (e) => {
        e.preventDefault();
    }

    const handleDrop = (e) => {
        e.preventDefault();
        loadFile(e.dataTransfer.files[0]);
    }

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            loadFile(e.target.files[0]);
        }
    }

    const showFileOpenDlg = () => {
        fileInput.current.click();
    }

    const loadFile = async (file) => {
        try {
            const result = await uploadNewStockFile(file);
            onFileUploaded(result);
        }
        catch (error) {
            snackbar.enqueueSnackbar(
                intl.formatMessage({
                    id: "Error while uploading file.",
                    defaultMessage: "Error while uploading file."
                }),
                {
                    variant: 'error'
                }
            );
        }
    }

    return (
        <form
            className={classes.form}
            onDrag={preventDefault}
            onDragEndCapture={preventDefault}
            onDragOver={preventDefault}
            onDragEnter={preventDefault}
            onDragLeave={preventDefault}
            onDrop={handleDrop}
            {...others}
        >
            <div>
                <CloudUpload fontSize='large' />
            </div>
            <div>
                <Typography>
                    {
                        intl.formatMessage({
                            id: "Drop File Here",
                            defaultMessage: "Drop File Here"
                        })
                    }
                </Typography>
            </div>
            <div className={classes.spacer} />
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={showFileOpenDlg}
                >
                    {
                        intl.formatMessage({
                            id: "Select File",
                            defaultMessage: "Select File"
                        })
                    }
                </Button>
            </div>
            <input
                id={'files'}
                type={'file'}
                style={{ display: 'none' }}
                ref={fileInput}
                onChange={handleFileChange}
            />
        </form>
    )
}

StockFileUploader.propTypes = {
    onFileUploaded: PropTypes.func
}

export default StockFileUploader;
