import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModelList from './ModelList';
import SearchBox from '../../Components/SearchBox';

const FilteredModelList = ({ selection, onSelect, models, ...others }) => {
    const [filter, setFilter] = useState('');

    const filteredModels = filter === '' ? models : models.filter(m => {
        let result = true;
        const filters = filter.split(' ');

        filters.forEach(f => {
            const e = new RegExp('.*' + f + '.*', 'i');

            if (f != "") {
                result &= m.description.search(e) >= 0 || m.code.search(e) >= 0 || m.trim.search(e) >= 0;
            }
        });

        return result;
    });

    return (
        <div {...others}>
            <SearchBox value={filter} onChange={v => setFilter(v)} />
            <ModelList
                models={filteredModels}
                selectedItem={selection}
                onSelect={onSelect}
            />
        </div>
    )
}

FilteredModelList.propTypes = {
    selection: PropTypes.string,
    onSelect: PropTypes.func,
    models: PropTypes.array.isRequired
}

export default FilteredModelList;