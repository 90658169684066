import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, useIntl } from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const UserSelect = ({ users, label, value, noValue, onChange, ...others }) => {
  const intl = useIntl();

  const items = users.map(user => {
    return (
      <MenuItem key={user.id} value={user.id}>{user.firstName + " " + user.lastName}</MenuItem>
    )
  })

  return (
    <FormControl {...others}>
      <InputLabel>{"" || label}</InputLabel>
      <Select
        value={value}
        onChange={(event) => { onChange && onChange(event.target.value) }}
      >
        {noValue && (<MenuItem value={"none"}>{intl.formatMessage({ id: 'None', defaultMessage: 'None' })}</MenuItem>)}
        {items}
      </Select>
    </FormControl>
  );
}

UserSelect.propTypes = {
  users: PropTypes.array.isRequired,
  label: PropTypes.string,
  noValue: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func
};

UserSelect.defaultProps = {
  noValue: true,
  label: "",
  value: "none"
}

export default injectIntl(UserSelect);