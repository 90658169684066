import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import SearchBox from '../../UI/SearchBox';
import StorageList from './StorageList';

import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import Fade from '@material-ui/core/Fade';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import StorageEditor from './StorageEditor';
import PrintDialog from './PrintDialog';

import { findStorages } from '../../backend/storage';
import { getCancelSource } from '../../backend';

const useStyles = makeStyles(theme => ({
  rootDiv: {
    display: 'flex',
    flexDirection: 'column'
  },
  searchDiv: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1)
  },
  searchBox: {
    width: '300px',
    marginRight: '16px'
  }
}));

const StorageFinder = ({ onCancel, ...others }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [editedStorage, setEditedStorage] = useState(null);
  const [listVisible, setListVisible] = useState(true);
  const [editorVisible, setEditorVisible] = useState(false);
  const [printStorage, setPrintStorage] = useState(null);
  const [storages, setStorages] = useState([]);
  const [filter, setFilter] = useState("");
  const [activeOnly, setActiveOnly] = useState(true);

  const handleOnSave = (storage) => {
    const i = storages.findIndex(s => (s.id === storage.id));

    if (i >= 0) {
      const newStorages = storages.slice();
      newStorages[i] = storage;
      setStorages(newStorages);
    }

    setEditedStorage(null);
    setEditorVisible(false);
  }

  const handleFilterChange = event => {
    const filter = event.target.value;
    setFilter(filter);
  }

  useEffect(() => {
    const cancelSource = getCancelSource();

    const _loadStorages = async () => {
      try {
        const result = await findStorages(filter, activeOnly, cancelSource);
        setStorages(result);
      }
      catch (error) {
        console.log(error);
      }
    }

    _loadStorages();

    return () => {
      cancelSource.cancel();
    }
  }, [filter, activeOnly]);

  return (
    <div className={classes.rootDiv} {...others}>
      <Fade
        in={listVisible}
        mountOnEnter={true}
        unmountOnExit={true}
        onExited={() => setEditorVisible(true)}
      >
        <div>
          <div>
            <IconButton onClick={onCancel}>
              <BackIcon />
            </IconButton>
          </div>
          <div className={classes.searchDiv}>
            <SearchBox className={classes.searchBox} onChange={handleFilterChange} value={filter} />
            <FormControlLabel
              control={
                <Switch
                  checked={activeOnly}
                  onChange={(event) => { setActiveOnly(event.target.checked) }}
                  color="primary"
                />
              }
              label={
                intl.formatMessage({
                  id: "Active Only",
                  defaultMessage: "Active Only"
                })
              }
            />
          </div>
          <div>
            <StorageList
              storages={storages}
              onEdit={(storage) => { setEditedStorage(storage); setListVisible(false); }}
              onPrint={(storage) => { setPrintStorage(storage) }}
            />
          </div>
        </div>
      </Fade>

      <Fade
        in={editorVisible}
        onExited={() => { setListVisible(true) }}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <StorageEditor
          initialStorage={editedStorage}
          onCancel={() => { setEditedStorage(null); setEditorVisible(false) }}
          onSave={handleOnSave}
        />
      </Fade>

      {printStorage !== null &&
        (<PrintDialog
          open={printStorage !== null}
          storage={printStorage}
          onClose={() => { setPrintStorage(null) }}
        />)
      }
    </div>
  )

}

StorageFinder.propTypes = {
  onCancel: PropTypes.func
}

export default StorageFinder;
