import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from 'react-redux';

import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";

import Toolbar from "@material-ui/core/Toolbar";

import { getMainPage, getDepartment } from "../DataStore/MainPage/Reducer";

import { setMainPage } from "../DataStore/MainPage/Actions";

import DepartmentButton from "./ToolbarButtons/DepartmentButton";
import SettingButton from "./ToolbarButtons/SettingButton";
import AvatarButton from "./ToolbarButtons/AvatarButton";
import SalesButtons from "./SalesButtons";
import ServiceButtons from "./ServiceButtons";
import AccountingButtons from "./AccountingButtons";
import { Departments } from "../Models/User";

const useStyles = makeStyles(theme => ({
  appBar: {
    width: "100%",
    maxWidth: theme.mainPage.maxWidth, // defined in App.js
    left: "50%",
    transform: "translateX(-50%)"
  },
  firstButton: {
    marginLeft: "24px"
  },
  emptySpace: {
    flexGrow: 1
  }
}));

const MainToolbar = () => {
  const classes = useStyles();

  const mainPage = useSelector(getMainPage);
  const department = useSelector(getDepartment);

  const dispatch = useDispatch();
  const setPage = pageId => (dispatch(setMainPage(pageId)));

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" color="inherit">
          CRM
        </Typography>

        <DepartmentButton />
        {department === Departments.Sales && (<SalesButtons setPage={setPage} mainPage={mainPage} />)}
        {department === Departments.Service && (<ServiceButtons />)}
        {department === Departments.Accounting && (<AccountingButtons />)}
        <SettingButton />

        <div className={classes.emptySpace} />

        <AvatarButton />
      </Toolbar>
    </AppBar>
  );
}

export default MainToolbar;
