import React from 'react';

import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import StorageViewer from '../../Storage/StorageViewer';
import StorageReport from './StorageReport';
import StorageEditor from '../../Storage/StorageEditor';

const useStyles = makeStyles((theme) => ({
  rootDiv: {
    position: 'relative',
    margin: theme.spacing(1),
    height: '100%'
  },
  viewer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowY: 'auto'
  }
}));

const Storage = () => {
  const classes = useStyles();

  const [storageList, setStorageList] = useState(null);
  const [editedStorage, setEditedStorage] = useState(null);

  return (
    <div className={classes.rootDiv}>
      <Fade in={storageList === null && editedStorage === null}>
        <StorageReport
          className={classes.viewer}
          showStorages={(storages) => setStorageList(storages)}
        />
      </Fade>
      <Fade in={storageList !== null && editedStorage === null}>
        <StorageViewer
          className={classes.viewer}
          storages={storageList || []}
          onEdit={(s) => { setEditedStorage(s) }}
          onClose={() => { setStorageList(null) }}
        />
      </Fade>
      <Fade
        in={editedStorage !== null}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <StorageEditor
          initialStorage={editedStorage}
          onCancel={() => setEditedStorage(null)}
          onSave={() => setEditedStorage(null)}
        />
      </Fade>
    </div>
  );
}

export default Storage;
