import React from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import GeneralSettings from './GeneralSettings';
import SaleNotifications from './SaleNotifications/SaleNotifications';
import InternetLeads from "./InternetLeads/InternetLeads";
import Templates from './Templates/Templates';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { SaveIcon } from "../../Icons/Icons";

import { settings as getSettings } from '../../DataStore/Settings/Settings/Reducer';
import { saveSettings } from '../../DataStore/Settings/Settings/Actions';

import { useIntl, defineMessages } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

const messages = defineMessages({
  general: {
    id: 'General',
    defaultMessage: 'General'
  },
  internetLeads: {
    id: 'Internet Leads',
    defaultMessage: 'Internet Leads'
  },
  saleNotifications: {
    id: 'Sale Notifications',
    defaultMessage: 'Sale Notifications'
  },
  templates: {
    id: 'Templates',
    defaultMessage: 'Templates'
  }
});

const useStyles = makeStyles(() => ({
  mainDiv: {
    overflow: 'scroll'
  }
}));

const AppSettings = ({ saveDisabled, ...others }) => {
  const intl = useIntl();
  const classes = useStyles();
  const settings = useSelector(getSettings);
  const dispatch = useDispatch();

  const handleSave = () => {
    dispatch(saveSettings(settings));
  }

  return (
    <div className={classes.mainDiv} {...others}>
      <Grid container direction="column" spacing={2}>
        <Grid container justify="flex-end">
          <Grid item>
            <IconButton aria-label="Save" onClick={handleSave} disabled={saveDisabled} color="primary">
              <SaveIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandIcon />}>
          <Typography>
            {intl.formatMessage(messages.general)}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <GeneralSettings />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandIcon />}>
          <Typography>
            {intl.formatMessage(messages.internetLeads)}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <InternetLeads />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandIcon />}>
          <Typography>
            {intl.formatMessage(messages.saleNotifications)}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <SaleNotifications />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandIcon />}>
          <Typography>
            {intl.formatMessage(messages.templates)}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Templates />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

AppSettings.propTypes = {
  saveDisabled: PropTypes.bool
}

export default AppSettings;
