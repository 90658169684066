import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NewStockList from './NewStockList';
import SearchBox from '../../Components/SearchBox';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import StockYearSelector from './StockYearSelector';
import NewStockEditorController from './NewStockEditorController';
import { useNewStocks } from './UseNewStocks';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddOutlined';
import Popover from '@material-ui/core/Popover';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    mainDiv: {
        display: 'flex',
        width: '100%',
        height: '100%'
    },
    listDiv: {
        overflow: 'auto',
        height: '100%',
        width: '40%'
    },
    searchDiv: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(1)
    },
    searchBox: {
        maxWidth: '25em',
        width: '90%'
    },
    yearDiv: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    yearSelector: {
        marginLeft: theme.spacing(1)
    },
    addButton: {
        marginRight: theme.spacing(1)
    },
    advancedFilter: {
        padding: theme.spacing(1)
    }
}))

const Filter = ({ showOnlyOverdue, onShowOnlyOverdue, ...others }) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <div className={classes.advancedFilter} {...others}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={showOnlyOverdue}
                        inputProps={{ name: "showOnlyOverdue" }}
                        onChange={(e) => { onShowOnlyOverdue && onShowOnlyOverdue(e.target.checked) }}
                    />
                }
                label={
                    intl.formatMessage({
                        id: "Show Only Overdue",
                        defaultMessage: "Show Only Overdue"
                    })
                }
            />
        </div>
    );
}

Filter.propTypes = {
    showOnlyOverdue: PropTypes.bool,
    onShowOnlyOverdue: PropTypes.func
}

const NewStocksController = ({ ...others }) => {
    const currentDate = new Date();

    const [filter, setFilter] = useState('');
    const [showOnlyOverdue, setShowOnlyOverdue] = useState(false);
    const [advancedSearchAnchor, setAdvancedSearchAnchor] = React.useState(null);
    const [year, setYear] = useState(currentDate.getFullYear());
    const [selectedStock, setSelectedStock] = useState(null);
    const [newStock, setNewStock] = useState(false);
    const [stocks] = useNewStocks(year);

    const classes = useStyles();

    const filteredStocks = stocks.filter(s => {
        const e = new RegExp('.*' + filter + '.*', 'i');
        let show = s.stockNumber.search(e) >= 0 || s.model.description.search(e) >= 0 || s.color.description.search(e) >= 0 || s.model.trim.search(e) >= 0;
        show = show && (!showOnlyOverdue || (s.deliveryDate === 0 && s.expectedDate < Date.now() * 1000));

        return show;
    });

    return (
        <div className={classes.mainDiv} {...others}>
            <div className={classes.listDiv}>
                <div className={classes.yearDiv}>
                    <StockYearSelector
                        className={classes.yearSelector}
                        year={year}
                        onChange={y => setYear(y)}
                    />
                    <IconButton
                        className={classes.addButton}
                        color="primary"
                        onClick={() => setNewStock(true)}
                    >
                        <AddIcon />
                    </IconButton>
                </div>
                <div className={classes.searchDiv}>
                    <SearchBox
                        variant="outlined"
                        value={filter}
                        className={classes.searchBox}
                        onChange={v => setFilter(v)}
                        onFilter={(e) => { setAdvancedSearchAnchor(e.currentTarget) }}
                        filtered={showOnlyOverdue}
                    />
                    <Popover
                        open={Boolean(advancedSearchAnchor)}
                        anchorEl={advancedSearchAnchor}
                        onClose={() => { setAdvancedSearchAnchor(null) }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Filter showOnlyOverdue={showOnlyOverdue} onShowOnlyOverdue={(v) => setShowOnlyOverdue(v)} />
                    </Popover>
                </div>
                <NewStockList
                    stocks={filteredStocks}
                    onSelect={s => setSelectedStock(s)}
                    selectedItem={selectedStock ? selectedStock.id : ''}
                />
            </div>
            <div>
                {(selectedStock || newStock) &&
                    <NewStockEditorController
                        stockID={selectedStock ? selectedStock.id : null}
                        onCancelled={() => { setSelectedStock(null); setNewStock(false); }}
                        onSaved={() => { setNewStock(false) }}
                    />
                }
            </div>
        </div>
    )
}

export default NewStocksController;
