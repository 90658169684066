import axios from 'axios';

/**
 * loadEmail gets the specified email from the backend. 
 * On, success, the email is returned.
 * On error, the function throws.
 * @param {string} id - The id of the email to load.
 * @param {object} cancelSource - An optional token to cancel the request.
*/
export const loadEmail = async (id, cancelSource) => {
  const response = await axios.get("/email/" + id, { cancelToken: cancelSource ? cancelSource.token : undefined });
  return response.data.result;
}
