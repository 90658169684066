import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const LabeledCheckbox = ({ label, value, onChange, ...others }) => {
  return (
    <FormControlLabel {...others}
      control={
        <Checkbox
          checked={value}
          onChange={(event) => { onChange && onChange(event.target.checked) }}
        />
      }
      label={label}
    />
  );
}

LabeledCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.bool
}

export default LabeledCheckbox;